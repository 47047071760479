import { CoordinatesConversion } from 'application/camera'
import { ActionHandler } from '../types'
import { TargetAction } from './action'
import { QuadTree } from 'application/quadtree'
import { EditorHighlightService } from 'editor/highlight/service'
import { TargetActionData } from './types'

export class TargetActionFactory {
  private coordinates: CoordinatesConversion
  private quadtree: QuadTree
  private highlight: EditorHighlightService

  constructor(
    coordinates: CoordinatesConversion,
    quadtree: QuadTree,
    highlight: EditorHighlightService
  ) {
    this.coordinates = coordinates
    this.quadtree = quadtree
    this.highlight = highlight
  }

  create = (data: TargetActionData): ActionHandler => {
    return new TargetAction(
      this.coordinates,
      this.quadtree,
      this.highlight,
      data
    )
  }
}
