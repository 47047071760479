import { useRecoilValue } from 'recoil'
import useNavigatePage from './useNavigatePage'
import { activeProjectAtom } from 'components/Pages/Projects/Projects'

export default function useRedirectNoProject() {
  const { toProjects } = useNavigatePage()

  const project = useRecoilValue(activeProjectAtom)
  if (!project) toProjects()
}
