import { AttributeType } from 'application/attributes'
import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import { ActionDraw } from 'editor'
import useAction from 'hooks/editor/useAction'
import useActionInitiator from 'hooks/editor/useActionInitiator'

export default function ToolButtons() {
  const action = useAction()
  const { draw, cancel, toggleLibrary } = useActionInitiator()

  const handleClick = (type: AttributeType) => {
    if (drawTools2.some((t) => t.action === action && t.type === type)) {
      cancel()
    } else {
      draw(type)
    }
  }

  return (
    <PanelContainer
      id={'tool-buttons'}
      width={'hug'}
      height={48}
      paddingH={10}
      direction={'row'}
    >
      {drawTools1.map((t) => (
        <ToolbarIconButton
          key={t.name}
          icon={t.icon}
          tooltipKey={t.tooltipKey}
          active={action === t.action}
          onClick={() => handleClick(t.type)}
        />
      ))}
      <ToolbarIconButton
        icon={'Structures'}
        tooltipKey="AddTemplates"
        active={action === 'openTemplateLibrary'}
        onClick={() => toggleLibrary('openTemplateLibrary')}
      />
      {drawTools2.map((t) => (
        <ToolbarIconButton
          key={t.name}
          icon={t.icon}
          tooltipKey={t.tooltipKey}
          active={action === t.action}
          onClick={() => handleClick(t.type)}
        />
      ))}
    </PanelContainer>
  )
}

const drawTools1: {
  name: string
  action: ActionDraw
  type: AttributeType
  tooltipKey: tooltipKey
  icon: IconKey
  hotkey: string
}[] = [
  {
    name: 'Page',
    action: 'drawPage',
    type: 'page',
    tooltipKey: 'Page',
    icon: 'Page',
    hotkey: 'P',
  },
]

const drawTools2: {
  name: string
  action: ActionDraw
  type: AttributeType
  tooltipKey: tooltipKey
  icon: IconKey
  hotkey: string
}[] = [
  {
    name: 'Frame',
    action: 'drawFrame',
    type: 'frame',
    tooltipKey: 'Frame',
    icon: 'Frame',
    hotkey: 'F',
  },
  {
    name: 'Rectangle',
    action: 'drawRectangle',
    type: 'rectangle',
    tooltipKey: 'Rectangle',
    icon: 'Rectangle',
    hotkey: 'R',
  },
  {
    name: 'Ellipse',
    action: 'drawEllipse',
    type: 'ellipse',
    tooltipKey: 'Ellipse',
    icon: 'Ellipse',
    hotkey: 'E',
  },
  {
    name: 'Text',
    action: 'drawText',
    type: 'text',
    tooltipKey: 'Text',
    icon: 'Text',
    hotkey: 'T',
  },
  {
    name: 'Image',
    action: 'drawImage',
    type: 'image',
    tooltipKey: 'Image',
    icon: 'Image',
    hotkey: 'I',
  },
]
