import { Point } from 'application/shapes'
import { InteractableNodeFilter } from './interactable/interactable'
import { Command, SelectNode, UnselectNode } from 'application/client/command'
import { ReadOnlyDocumentSelection } from 'application/selection'

interface CommandHandler {
  handle: (command: Command | Command[]) => void
}

export class NodeSelectionAction {
  private commandHandler: CommandHandler
  private documentSelection: ReadOnlyDocumentSelection
  private interactableNodeFilter: InteractableNodeFilter

  constructor(
    commandHandler: CommandHandler,
    documentSelection: ReadOnlyDocumentSelection,
    interactableNodeFilter: InteractableNodeFilter
  ) {
    this.commandHandler = commandHandler
    this.documentSelection = documentSelection
    this.interactableNodeFilter = interactableNodeFilter
  }

  selectAtPoint(
    point: Point,
    drill: boolean,
    multiselect: boolean,
    doubleclick: boolean
  ): boolean {
    const selected = this.getSelectedIds()

    const id = this.interactableNodeFilter.nodeAtPoint(
      point,
      drill,
      doubleclick
    )

    if (!id) {
      const command = this.buildSelectCommand([], true)
      this.commandHandler.handle(command)
      this.handleDeselect(selected)
      return false
    }

    const command = this.buildSelectCommand([id], !multiselect)
    this.commandHandler.handle(command)

    this.handleDeselect(selected)

    return true
  }

  multiselectInRectangle(start: Point, end: Point): void {
    const selected = this.getSelectedIds()

    const ids = this.interactableNodeFilter.nodesInRectangle({
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y),
      w: Math.abs(start.x - end.x),
      h: Math.abs(start.y - end.y),
    })
    if (!ids) return

    const command = this.buildSelectCommand(ids, true)
    this.commandHandler.handle(command)

    this.handleDeselect(selected)
  }

  selectNodes(ids: string[], clear: boolean): void {
    const selected = this.getSelectedIds()

    const command = this.buildSelectCommand(ids, clear)
    this.commandHandler.handle(command)

    this.handleDeselect(selected)
  }

  unselectNode(id: string): void {
    const selected = this.getSelectedIds()

    const command = this.buildUnselectCommand(id)
    this.commandHandler.handle(command)

    this.handleDeselect(selected)
  }

  selectCanvas(id: string): void {
    this.commandHandler.handle({
      type: 'setSelectedCanvas',
      params: { id: id },
    })
  }

  private buildSelectCommand = (ids: string[], clear: boolean): SelectNode => {
    return {
      type: 'selectNode',
      params: {
        ids: ids,
        clear: clear,
      },
    }
  }

  private buildUnselectCommand = (id: string): UnselectNode => {
    return {
      type: 'unselectNode',
      params: {
        id: id,
      },
    }
  }

  private handleDeselect = (before: string[]): void => {
    const deselected = this.getDeselectedIds(before)
    const commands = this.buildSelectorCommands(deselected)
    this.commandHandler.handle(commands)
  }

  private buildSelectorCommands = (deselected: string[]): Command[] => {
    const commands: Command[] = []
    for (const id of deselected) {
      commands.push({
        type: 'setNodeSelector',
        params: {
          id: id,
          pseudo: 'none',
        },
      })
    }
    return commands
  }

  private getSelectedIds = (): string[] => {
    return this.documentSelection.getSelected().map((node) => node.getId())
  }

  private getDeselectedIds = (ids: string[]): string[] => {
    const selectedIds = this.getSelectedIds()
    return ids.filter((id) => !selectedIds.includes(id))
  }
}
