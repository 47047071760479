import { AttributeType, MultiselectStyleMap } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { Size } from 'application/shapes'
import { ReadOnlyNode } from 'application/node'

type ImageLibraryPanelKeys = 'image.src' | 'image.resize' | 'fills'

type ImageLibraryPanelAttributes = Pick<
  MultiselectStyleMap,
  ImageLibraryPanelKeys
> | null

export interface ImageLibraryPanelState {
  attributes: ImageLibraryPanelAttributes
}

export interface ImageLibraryPanelHandlers {
  setSrc: (src: string, size: Size) => void
}

export class ImageLibraryPanel extends StyleAttributePanel<
  ImageLibraryPanelState,
  ImageLibraryPanelHandlers,
  ImageLibraryPanelKeys
> {
  getSettings(): ImageLibraryPanelState {
    return {
      attributes: this.attributes,
    }
  }

  getHandlers(): ImageLibraryPanelHandlers {
    return {
      setSrc: this.setSrc,
    }
  }

  private setSrc = (src: string, size: Size): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      switch (node.getBaseAttribute('type')) {
        case 'image':
          this.updateImage(node, src, size)
          break
        case 'page':
        case 'frame':
        case 'rectangle':
        case 'ellipse':
          this.updateFillImage(node, src, size)
          break
      }
    }
    this.commit()
  }

  private updateImage = (node: ReadOnlyNode, src: string, size: Size): void => {
    const id = node.getId()
    this.setOne(id, {
      'image.src': src,
      'image.originalSize.w': size.w,
      'image.originalSize.h': size.h,
    })
  }

  private updateFillImage = (
    node: ReadOnlyNode,
    src: string,
    size: Size
  ): void => {
    const fills = node.getStyleAttribute('fills')
    if (!fills) return

    const newFills = fills.map((fill) => {
      if (fill.type !== 'image') return fill
      if (fill.image === undefined) return fill
      return {
        ...fill,
        image: {
          ...fill.image,
          src: src,
          'originalSize.w': size.w,
          'originalSize.h': size.h,
        },
      }
    })

    this.setOne(node.getId(), { fills: newFills })
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = [
  'image',
  'page',
  'frame',
  'rectangle',
  'ellipse',
]
