import { Cursor } from 'application/cursor'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  Done,
  NotDone,
} from '../types'
import { TargetActionData } from './types'
import { EditorHighlightService } from 'editor/highlight/service'
import { QuadTree, QuadTreeRectangle } from 'application/quadtree'
import { CoordinatesConversion } from 'application/camera'
import { getClosestByClass, isCanvasClosest } from 'application/browser'
import { QuadTreeNodeTitleFilter } from 'application/action'

export class TargetAction implements ActionHandler {
  private coordinates: CoordinatesConversion
  private quadtree: QuadTree
  private highlight: EditorHighlightService

  private actionData: TargetActionData
  private clicked: boolean

  constructor(
    coordinates: CoordinatesConversion,
    quadtree: QuadTree,
    highlight: EditorHighlightService,
    actionData: TargetActionData
  ) {
    this.coordinates = coordinates
    this.quadtree = quadtree
    this.highlight = highlight

    this.actionData = actionData
    this.clicked = false
  }

  getType = (): Action => {
    return 'target'
  }

  getCursor = (): Cursor => {
    return 'target'
  }

  onMouseDown = (): ActionEventResult => {
    this.clicked = true
    return NotDone
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    const target = this.getTarget(e)
    if (target) {
      this.highlight.highlight(target)
    } else {
      this.highlight.unhighlight()
    }
    return NotDone
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    if (!this.clicked) return NotDone

    const target = this.getTarget(e)
    this.actionData.callback(target)

    return Done
  }

  private getTarget = (e: MouseEvent): string | null => {
    if (!isCanvasClosest(e)) {
      const target = getClosestByClass(e, 'layer-row')
      if (!target) return null

      const layerId = target.getAttribute('data-layer-id')
      if (!layerId) return null

      return layerId
    } else {
      const point = this.coordinates.get(e)
      const rectangles = this.quadtree.getAtPoint(
        point,
        QuadTreeNodeTitleFilter
      )
      if (rectangles.length === 0) return null

      const filtered = rectangles.filter((node) =>
        this.actionData.filter(this.extractQuadTreeId(node))
      )
      if (filtered.length === 0) return null

      return this.extractQuadTreeId(filtered[0])
    }
  }

  private extractQuadTreeId = (r: QuadTreeRectangle): string => {
    if (r.id.includes('title')) return r.id.split('-')[0]
    return r.id
  }
}
