import { ReadOnlyDocument } from 'application/document'
import {
  LayoutDependencyGraph,
  LayoutDependencyGraphFactory,
  LayoutDependencyMode,
  LayoutDependencyNode,
} from '../types'
import { ReadOnlyNode } from 'application/node'
import { hasDependency } from './utils'
import {
  StyleMap,
  isAutolayoutChild,
  isDynamicSizeAuto,
} from 'application/attributes'

export class SizeDependencyGraphFactory
  implements LayoutDependencyGraphFactory
{
  create(
    dirtyNodes: Set<string>,
    document: ReadOnlyDocument
  ): LayoutDependencyGraph {
    const graph: LayoutDependencyGraph = {}

    for (const id of dirtyNodes) {
      const node = document.getNode(id)
      if (!node) continue
      this.traverse(node, document, graph)
    }

    return graph
  }

  private traverse = (
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    graph: LayoutDependencyGraph
  ): void => {
    if (
      this.traverseParent(node, document, graph) ||
      this.traverseSiblings(node, document, graph)
    ) {
      const parent = document.getParent(node)
      if (!parent) return

      this.traverse(parent, document, graph)
    }

    if (this.traverseChildren(node, document, graph)) {
      const children = node.getChildren()
      if (!children) return

      for (const c of children) {
        const child = document.getNode(c)
        if (!child) continue

        this.traverse(child, document, graph)
      }
    }

    this.traverseSelf(node, graph)
  }

  private traverseParent = (
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    graph: LayoutDependencyGraph
  ): boolean => {
    const parent = document.getParent(node)
    if (!parent) return false
    if (['root', 'canvas'].includes(parent.getBaseAttribute('type')))
      return false

    let found = false

    const modes: ('w' | 'h')[] = ['w', 'h']
    for (const mode of modes) {
      if (
        parent.getStyleAttribute(`size.${mode}.auto`) === 'hug' &&
        !isDynamicSizeAuto(node.getStyleAttribute(`size.${mode}.auto`)) &&
        isAutolayoutChild(node, parent)
      ) {
        if (this.addDependency(node, mode, parent, mode, graph)) {
          found = true
        }
      }
    }

    if (node.getStyleAttribute('size.ratio.mode') === 'fixed') {
      const pairs: {
        node: 'w' | 'h'
        constraint: 'h' | 'v'
        parent: 'w' | 'h'
      }[] = [
        { node: 'w', constraint: 'v', parent: 'h' },
        { node: 'h', constraint: 'h', parent: 'w' },
      ]
      for (const pair of pairs) {
        if (
          node.getStyleAttribute(`size.${pair.node}.auto`) === 'fixed' &&
          (['fill', 'percent'].includes(
            parent.getStyleAttribute(`size.${pair.parent}.auto`)
          ) ||
            this.isConstrained(pair.parent, parent.getStyleAttributes()))
        ) {
          if (this.addDependency(parent, pair.parent, node, pair.node, graph)) {
            found = true
          }
        }
      }
    }

    return found
  }

  private traverseChildren = (
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    graph: LayoutDependencyGraph
  ): boolean => {
    const children = node.getChildren()
    if (!children) return false

    let found = false

    const modes: ('w' | 'h')[] = ['w', 'h']
    for (const c of children) {
      const child = document.getNode(c)
      if (!child) continue

      for (const mode of modes) {
        if (
          isDynamicSizeAuto(child.getStyleAttribute(`size.${mode}.auto`)) ||
          this.isConstrained(mode, child.getStyleAttributes())
        ) {
          if (this.addDependency(node, mode, child, mode, graph)) {
            found = true
          }
        }
        if (
          node.getStyleAttribute(`size.${mode}.auto`) === 'hug' &&
          !isDynamicSizeAuto(child.getStyleAttribute(`size.${mode}.auto`)) &&
          isAutolayoutChild(child, node)
        ) {
          if (this.addDependency(child, mode, node, mode, graph)) {
            found = true
          }
        }
      }
    }

    return found
  }

  private traverseSiblings = (
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    graph: LayoutDependencyGraph
  ): boolean => {
    const parent = document.getParent(node)
    if (!parent) return false

    const siblings = parent.getChildren()
    if (!siblings) return false

    siblings.forEach((siblingId) => {
      const sibling = document.getNode(siblingId)
      if (!sibling) return false

      if (
        isDynamicSizeAuto(node.getStyleAttribute('size.w.auto')) &&
        !isDynamicSizeAuto(sibling.getStyleAttribute('size.w.auto'))
      ) {
        this.addDependency(sibling, 'w', node, 'w', graph)
      }

      if (
        isDynamicSizeAuto(node.getStyleAttribute('size.h.auto')) &&
        !isDynamicSizeAuto(sibling.getStyleAttribute('size.h.auto'))
      ) {
        this.addDependency(sibling, 'h', node, 'h', graph)
      }

      if (
        isDynamicSizeAuto(sibling.getStyleAttribute('size.w.auto')) &&
        !isDynamicSizeAuto(node.getStyleAttribute('size.w.auto'))
      ) {
        this.addDependency(node, 'w', sibling, 'w', graph)
      }

      if (
        isDynamicSizeAuto(sibling.getStyleAttribute('size.h.auto')) &&
        !isDynamicSizeAuto(node.getStyleAttribute('size.h.auto'))
      ) {
        this.addDependency(node, 'h', sibling, 'h', graph)
      }
    })

    return siblings.some((siblingId) => {
      const sibling = document.getNode(siblingId)
      if (!sibling) return false

      return (
        isDynamicSizeAuto(sibling.getStyleAttribute('size.w.auto')) ||
        isDynamicSizeAuto(sibling.getStyleAttribute('size.h.auto')) ||
        sibling.getStyleAttribute('position.mode') !==
          node.getStyleAttribute('position.mode')
      )
    })
  }

  private traverseSelf = (
    node: ReadOnlyNode,
    graph: LayoutDependencyGraph
  ): void => {
    if (
      node.getBaseAttribute('type') === 'text' &&
      node.getStyleAttribute('size.h.auto') === 'hug' &&
      node.getStyleAttribute('size.w.auto') !== 'hug'
    ) {
      this.addDependency(node, 'w', node, 'h', graph)
    }

    const pairs: {
      size: 'w' | 'h'
      constraint: 'h' | 'v'
      altSize: 'w' | 'h'
    }[] = [
      { size: 'w', constraint: 'v', altSize: 'h' },
      { size: 'h', constraint: 'h', altSize: 'w' },
    ]

    if (node.getStyleAttribute('size.ratio.mode') === 'fixed') {
      for (const pair of pairs) {
        if (
          node.getStyleAttribute(`size.${pair.size}.auto`) === 'fixed' &&
          (['fill', 'percent'].includes(
            node.getStyleAttribute(`size.${pair.altSize}.auto`)
          ) ||
            this.isConstrained(pair.altSize, node.getStyleAttributes()))
        ) {
          this.addDependency(node, pair.altSize, node, pair.size, graph)
        }
      }
    }

    this.getOrAddNode(node, 'w', graph)
    this.getOrAddNode(node, 'h', graph)

    if (this.isConstrained('w', node.getStyleAttributes())) {
      this.getOrAddNode(node, 'w', graph)
    }

    if (this.isConstrained('h', node.getStyleAttributes())) {
      this.getOrAddNode(node, 'h', graph)
    }

    if (
      node.getBaseAttribute('type') === 'text' &&
      node.getStyleAttribute('size.w.auto') === 'hug'
    ) {
      this.getOrAddNode(node, 'w', graph)
    }
    if (
      node.getStyleAttribute('size.h.min.mode') !== 'none' ||
      node.getStyleAttribute('size.h.max.mode') !== 'none'
    ) {
      this.getOrAddNode(node, 'h', graph)
    }
    if (
      node.getStyleAttribute('size.w.min.mode') !== 'none' ||
      node.getStyleAttribute('size.w.max.mode') !== 'none'
    ) {
      this.getOrAddNode(node, 'w', graph)
    }
    if (node.getStyleAttribute('autolayout.mode') === 'flex') {
      if (node.getStyleAttribute('size.w.auto') === 'hug') {
        this.getOrAddNode(node, 'w', graph)
      }
      if (node.getStyleAttribute('size.h.auto') === 'hug') {
        this.getOrAddNode(node, 'h', graph)
      }
      if (
        node.getStyleAttribute('autolayout.direction') === 'wrap' &&
        isDynamicSizeAuto(node.getStyleAttribute('size.w.auto')) &&
        node.getStyleAttribute('size.h.auto') === 'hug'
      ) {
        this.addDependency(node, 'w', node, 'h', graph)
      }
    }
  }

  private addDependency(
    dependent: ReadOnlyNode,
    dependentMode: LayoutDependencyMode,
    dependency: ReadOnlyNode,
    dependencyMode: LayoutDependencyMode,
    graph: LayoutDependencyGraph
  ): boolean {
    const dependentNode = this.getOrAddNode(dependent, dependentMode, graph)
    const dependencyNode = this.getOrAddNode(dependency, dependencyMode, graph)

    if (hasDependency(dependentNode, dependencyNode)) return false

    dependentNode.dependencies.push(dependencyNode)
    dependencyNode.dependentOn.push(dependentNode)
    return true
  }

  private getOrAddNode(
    node: ReadOnlyNode,
    mode: LayoutDependencyMode,
    graph: LayoutDependencyGraph
  ): LayoutDependencyNode {
    const id = node.getId()
    const key = `${id}-${mode}`
    if (graph[key]) return graph[key]

    const newNode: LayoutDependencyNode = {
      documentNodeId: id,
      key: key,
      mode: mode,
      dependentOn: [],
      dependencies: [],
    }
    graph[key] = newNode

    return newNode
  }

  private isConstrained = (axis: 'w' | 'h', styles: StyleMap): boolean => {
    switch (axis) {
      case 'w':
        return (
          styles['position.left.auto'] !== 'none' &&
          styles['position.right.auto'] !== 'none'
        )
      case 'h':
        return (
          styles['position.top.auto'] !== 'none' &&
          styles['position.bottom.auto'] !== 'none'
        )
    }
  }
}
