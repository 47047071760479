import { Editor } from './editor'
import { EditorDependencies } from './editorDependencies'

export class EditorFactory {
  create(): Editor {
    const dependencies = new EditorDependencies()

    this.initFonts(dependencies)
    this.initIcons(dependencies)
    this.initBrowserEventListeners(dependencies)
    this.initClientListeners(dependencies)
    this.initCameraListeners(dependencies)
    this.initCanvasSizeListeners(dependencies)
    this.initCanvasListeners(dependencies)
    this.initAttributeListeners(dependencies)
    this.initActionListeners(dependencies)
    this.initActionHapticListeners(dependencies)
    this.initHighlightListeners(dependencies)
    this.initTitlesListeners(dependencies)
    this.initFontsLoadedListeners(dependencies)

    return dependencies.getEditor()
  }

  private initBrowserEventListeners(dependencies: EditorDependencies) {
    const browserEvent = dependencies.getBrowserEventService()

    const camera = dependencies.getCameraBrowserEventService()
    browserEvent.registerWheelEventListener(camera)

    const canvas = dependencies.getCanvasBrowserEventService()
    browserEvent.registerResizeEventListener(canvas)

    const actionService = dependencies.getActionService()
    browserEvent.registerKeyDownListener(actionService)
    browserEvent.registerKeyUpListener(actionService)
    browserEvent.registerMouseDownListener(actionService)
    browserEvent.registerMouseUpListener(actionService)
    browserEvent.registerMouseMoveListener(actionService)
    browserEvent.registerPasteEventListener(actionService)
    browserEvent.registerDropEventListener(actionService)

    const highlight = dependencies.getHighlightBrowserEventListener()
    browserEvent.registerMouseMoveListener(highlight)
    browserEvent.registerKeyDownListener(highlight)
    browserEvent.registerKeyUpListener(highlight)

    const distanceLines = dependencies.getHapticDistanceLines()
    browserEvent.registerMouseMoveListener(distanceLines)
    browserEvent.registerKeyDownListener(distanceLines)
    browserEvent.registerKeyUpListener(distanceLines)
  }

  private initClientListeners(dependencies: EditorDependencies) {
    const client = dependencies.getClient()

    const quadtree = dependencies.getEditorQuadTreeService()
    client.subscribe('quadtree', quadtree)
    quadtree.init()

    const attributeStore = dependencies.getAttributeStore()
    client.subscribe('attributeStore', attributeStore)

    const defaultTextAttributes = dependencies.getDefaultTextAttributes()
    client.subscribe('defaultTextAttributes', defaultTextAttributes)

    const editorCameraService = dependencies.getEditorCameraService()
    client.subscribe('editorCameraService', editorCameraService)

    const documentRendering = dependencies.getDocumentRenderingService()
    client.subscribe('documentRendering', documentRendering)

    const constraintLines = dependencies.getHapticConstraintLines()
    client.subscribe('constraintLines', constraintLines)

    const highlightAutolayoutChildren =
      dependencies.getHapticHighlightAutolayoutChildren()
    client.subscribe('highlightAutolayoutChildren', highlightAutolayoutChildren)

    const highlightWindow = dependencies.getHapticHighlightWindow()
    client.subscribe('highlightWindow', highlightWindow)

    const selectionWindow = dependencies.getHapticSelectionWindow()
    client.subscribe('selectionWindow', selectionWindow)

    const textEditor = dependencies.getHapticTextEditor()
    client.subscribe('textEditor', textEditor)

    const textEditorActionInitiator =
      dependencies.getTextEditorActionInitiator()
    client.subscribe('textEditorActionInitiator', textEditorActionInitiator)

    const selectionColorsPanel = dependencies.getSelectionColorsPanel()
    client.subscribe('selectionColorsPanel', selectionColorsPanel)

    const textPanelTextEditor = dependencies.getTextPanelTextEditor()
    client.subscribe('textPanelTextEditor', textPanelTextEditor)

    const breakpointModePanel = dependencies.getBreakpointModePanel()
    client.subscribe('breakpointModePanel', breakpointModePanel)

    const canvasPanel = dependencies.getCanvasPanel()
    client.subscribe('canvasPanel', canvasPanel)

    const layersPanel = dependencies.getLayersPanel()
    client.subscribe('layersPanel', layersPanel)

    const detailsPanel = dependencies.getDetailsPanel()
    client.subscribe('detailsPanel', detailsPanel)

    const interactionAnimationEffectPanel =
      dependencies.getInteractionAnimationEffectPanel()
    client.subscribe(
      'interactionAnimationEffectPanel',
      interactionAnimationEffectPanel
    )

    const highlight = dependencies.getEditorHighlightService()
    client.subscribe('highlight', highlight)

    const titles = dependencies.getNodeTitlesService()
    client.subscribe('titles', titles)

    const editorProjectService = dependencies.getEditorProjectService()
    client.subscribe('editorProjectService', editorProjectService)

    const ui = dependencies.getUI()
    client.subscribe('ui', ui)
  }

  private initCameraListeners(dependencies: EditorDependencies) {
    const camera = dependencies.getCameraService()

    const editorCameraService = dependencies.getEditorCameraService()
    camera.subscribe('editorCameraService', editorCameraService)

    const alignmentLines = dependencies.getHapticAlignmentLines()
    camera.subscribe('alignmentLines', alignmentLines)

    const autolayoutChildLine = dependencies.getHapticAutolayoutChildLine()
    camera.subscribe('autolayoutChildLine', autolayoutChildLine)

    const autolayoutChild = dependencies.getHapticAutolayoutChildWindows()
    camera.subscribe('autolayoutChild', autolayoutChild)

    const constraintLines = dependencies.getHapticConstraintLines()
    camera.subscribe('constraintLines', constraintLines)

    const distanceLines = dependencies.getHapticDistanceLines()
    camera.subscribe('distanceLines', distanceLines)

    const drawingWindow = dependencies.getHapticDrawingWindow()
    camera.subscribe('drawingWindow', drawingWindow)

    const highlightAutolayoutChildren =
      dependencies.getHapticHighlightAutolayoutChildren()
    camera.subscribe('highlightAutolayoutChildren', highlightAutolayoutChildren)

    const highlightWindow = dependencies.getHapticHighlightWindow()
    camera.subscribe('highlightWindow', highlightWindow)

    const multiselectWindow = dependencies.getHapticMultiselectWindow()
    camera.subscribe('multiselectWindow', multiselectWindow)

    const newParentWindow = dependencies.getHapticNewParentWindow()
    camera.subscribe('newParentWindow', newParentWindow)

    const nodeTitles = dependencies.getHapticNodeTitles()
    camera.subscribe('nodeTitles', nodeTitles)

    const selectionWindow = dependencies.getHapticSelectionWindow()
    camera.subscribe('selectionWindow', selectionWindow)

    const gridlines = dependencies.getHapticGridlines()
    camera.subscribe('gridlines', gridlines)

    const gridlinesSmall = dependencies.getHapticGridlinesSmall()
    camera.subscribe('gridlinesSmall', gridlinesSmall)

    const textEditor = dependencies.getHapticTextEditor()
    camera.subscribe('textEditor', textEditor)

    const titles = dependencies.getNodeTitlesService()
    camera.subscribe('titles', titles)

    const cameraPanel = dependencies.getCameraPanel()
    camera.subscribe('cameraPanel', cameraPanel)
  }

  private initCanvasSizeListeners(dependencies: EditorDependencies) {
    const canvasSizeService = dependencies.getCanvasSizeService()

    const gridlines = dependencies.getHapticGridlines()
    canvasSizeService.subscribe('gridlines', gridlines)

    const gridlinesSmall = dependencies.getHapticGridlinesSmall()
    canvasSizeService.subscribe('gridlinesSmall', gridlinesSmall)
  }

  private initCanvasListeners(dependencies: EditorDependencies) {
    const canvas = dependencies.getCanvas()

    const alignmentLines = dependencies.getHapticAlignmentLines()
    canvas.subscribeToCanvas('alignmentLines', alignmentLines)

    const autolayoutChildLine = dependencies.getHapticAutolayoutChildLine()
    canvas.subscribeToCanvas('autolayoutChildLine', autolayoutChildLine)

    const autolayoutChild = dependencies.getHapticAutolayoutChildWindows()
    canvas.subscribeToCanvas('autolayoutChild', autolayoutChild)

    const distanceLines = dependencies.getHapticDistanceLines()
    canvas.subscribeToCanvas('distanceLines', distanceLines)

    const constraintLines = dependencies.getHapticConstraintLines()
    canvas.subscribeToCanvas('constraintLines', constraintLines)

    const drawingWindow = dependencies.getHapticDrawingWindow()
    canvas.subscribeToCanvas('drawingWindow', drawingWindow)

    const highlightAutolayoutChildren =
      dependencies.getHapticHighlightAutolayoutChildren()
    canvas.subscribeToCanvas(
      'highlightAutolayoutChildren',
      highlightAutolayoutChildren
    )

    const highlightWindow = dependencies.getHapticHighlightWindow()
    canvas.subscribeToCanvas('highlightWindow', highlightWindow)

    const multiselectWindow = dependencies.getHapticMultiselectWindow()
    canvas.subscribeToCanvas('multiselectWindow', multiselectWindow)

    const hapticNewParentWindow = dependencies.getHapticNewParentWindow()
    canvas.subscribeToCanvas('newParentWindow', hapticNewParentWindow)

    const selectionWindow = dependencies.getHapticSelectionWindow()
    canvas.subscribeToCanvas('selectionWindow', selectionWindow)

    const gridlines = dependencies.getHapticGridlines()
    canvas.subscribeToCanvas('gridlines', gridlines)

    const gridlinesSmall = dependencies.getHapticGridlinesSmall()
    canvas.subscribeToCanvas('gridlinesSmall', gridlinesSmall)

    const fpsService = dependencies.getFPSPanel()
    canvas.subscribeToRenderLoop('fpsService', fpsService)
  }

  private initAttributeListeners(dependencies: EditorDependencies) {
    const attributeStore = dependencies.getAttributeStore()

    const alignmentPanel = dependencies.getAlignmentPanel()
    attributeStore.subscribe('alignmentPanel', alignmentPanel)

    const autolayoutPanel = dependencies.getAutolayoutPanel()
    attributeStore.subscribe('autolayoutPanel', autolayoutPanel)

    const backgroundPanel = dependencies.getBackgroundPanel()
    attributeStore.subscribe('backgroundPanel', backgroundPanel)

    const borderPanel = dependencies.getBorderPanel()
    attributeStore.subscribe('borderPanel', borderPanel)

    const clipContentPanel = dependencies.getClipContentPanel()
    attributeStore.subscribe('clipContentPanel', clipContentPanel)

    const cursorPanel = dependencies.getCursorPanel()
    attributeStore.subscribe('cursorPanel', cursorPanel)

    const filterBlurPanel = dependencies.getFilterBlurPanel()
    attributeStore.subscribe('filterBlurPanel', filterBlurPanel)

    const imageLibraryPanel = dependencies.getImageLibraryPanel()
    attributeStore.subscribe('imageLibraryPanel', imageLibraryPanel)

    const imagePanel = dependencies.getImagePanel()
    attributeStore.subscribe('imagePanel', imagePanel)

    const opacityPanel = dependencies.getOpacityPanel()
    attributeStore.subscribe('opacityPanel', opacityPanel)

    const positionModePanel = dependencies.getPositionModePanel()
    attributeStore.subscribe('positionModePanel', positionModePanel)

    const positionPanel = dependencies.getPositionPanel()
    attributeStore.subscribe('positionPanel', positionPanel)

    const screenSizePanel = dependencies.getScreenSizePanel()
    attributeStore.subscribe('screenSizePanel', screenSizePanel)

    const roundingPanel = dependencies.getRoundingPanel()
    attributeStore.subscribe('roundingPanel', roundingPanel)

    const shadowPanel = dependencies.getShadowPanel()
    attributeStore.subscribe('shadowPanel', shadowPanel)

    const sizePanel = dependencies.getSizePanel()
    attributeStore.subscribe('sizePanel', sizePanel)

    const styleOverridesPanel = dependencies.getStyleOverridesPanel()
    attributeStore.subscribe('styleOverridesPanel', styleOverridesPanel)

    const textPanel = dependencies.getTextPanel()
    attributeStore.subscribe('textPanel', textPanel)

    const textPanelTextEditor = dependencies.getTextPanelTextEditor()
    attributeStore.subscribe('textPanelTextEditor', textPanelTextEditor)

    const transitionPanel = dependencies.getTransitionPanel()
    attributeStore.subscribe('transitionPanel', transitionPanel)

    const interactionAnimationPanel =
      dependencies.getInteractionAnimationPanel()
    attributeStore.subscribe(
      'interactionAnimationPanel',
      interactionAnimationPanel
    )

    const interactionLinkPanel = dependencies.getInteractionLinkPanel()
    attributeStore.subscribe('interactionLinkPanel', interactionLinkPanel)

    const interactionVisibilityPanel =
      dependencies.getInteractionVisibilityPanel()
    attributeStore.subscribe(
      'interactionVisibilityPanel',
      interactionVisibilityPanel
    )
  }

  private initActionListeners(dependencies: EditorDependencies) {
    const activeAction = dependencies.getActiveAction()

    const highlight = dependencies.getEditorHighlightService()
    activeAction.subscribe('highlight', highlight)

    const highlightEvents = dependencies.getHighlightBrowserEventListener()
    activeAction.subscribe('highlightEvents', highlightEvents)

    const distanceLines = dependencies.getHapticDistanceLines()
    activeAction.subscribe('distanceLines', distanceLines)

    const constraintLines = dependencies.getHapticConstraintLines()
    activeAction.subscribe('constraintLines', constraintLines)

    const selectionWindow = dependencies.getHapticSelectionWindow()
    activeAction.subscribe('selectionWindow', selectionWindow)

    const newParentWindow = dependencies.getHapticNewParentWindow()
    activeAction.subscribe('newParentWindow', newParentWindow)

    const layersPanel = dependencies.getLayersPanel()
    activeAction.subscribe('layersPanel', layersPanel)

    const selectionColorsPanel = dependencies.getSelectionColorsPanel()
    activeAction.subscribe('selectionColorsPanel', selectionColorsPanel)

    const ui = dependencies.getUI()
    activeAction.subscribe('ui', ui)
  }

  private initActionHapticListeners(dependencies: EditorDependencies) {
    const layersLine = dependencies.getDraggingLayersLine()

    const layersPanel = dependencies.getLayersPanel()
    layersLine.subscribe('layersPanel', layersPanel)

    const canvasLine = dependencies.getDraggingCanvasLine()

    const canvasPanel = dependencies.getCanvasPanel()
    canvasLine.subscribe('canvasPanel', canvasPanel)
  }

  private initHighlightListeners(dependencies: EditorDependencies) {
    const highlight = dependencies.getEditorHighlightService()

    const layersPanel = dependencies.getLayersPanel()
    highlight.subscribe('layersPanel', layersPanel)

    const titles = dependencies.getNodeTitlesService()
    highlight.subscribe('titles', titles)

    const highlightAutolayoutChildren =
      dependencies.getHapticHighlightAutolayoutChildren()
    highlight.subscribe(
      'highlightAutolayoutChildren',
      highlightAutolayoutChildren
    )

    const highlightWindow = dependencies.getHapticHighlightWindow()
    highlight.subscribe('highlightWindow', highlightWindow)
  }

  private initTitlesListeners(dependencies: EditorDependencies) {
    const titles = dependencies.getNodeTitlesService()

    const hapticTitles = dependencies.getHapticNodeTitles()
    titles.subscribe('hapticTitles', hapticTitles)

    const quadtree = dependencies.getEditorQuadTreeService()
    titles.subscribe('quadtree', quadtree)
  }

  private initFontsLoadedListeners(dependencies: EditorDependencies) {
    const fontLoader = dependencies.getFontLoader()

    const titleService = dependencies.getNodeTitlesService()
    fontLoader.subscribe('titles', titleService)
  }

  private initFonts(dependencies: EditorDependencies) {
    const fontLoader = dependencies.getFontLoader()
    fontLoader.init(['inter'])
  }

  private initIcons(dependencies: EditorDependencies) {
    const iconLoader = dependencies.getIconLoader()
    iconLoader.init()
  }
}
