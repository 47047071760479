import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import RepaintButton from './RepaintButton/RepaintButton'
import ToolbarDivider from 'components/Library/Components/Toolbar/ToolbarDivider/ToolbarDivider'
import ProjectNameInput from './ProjectNameInput/ProjectNameInput'

export default function LeftTopbar() {
  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        left: 8,
      }}
      width={232}
      height={48}
      paddingH={10}
      direction="row"
      zIndex={3}
    >
      <RepaintButton />
      <ToolbarDivider />
      <ProjectNameInput />
    </PanelContainer>
  )
}
