import {
  AttributeFill,
  StyleMap,
  getTextAttributes,
  isFillEqual,
} from 'application/attributes'
import { NodeSelectionColorsHandler } from './selectionColorsPanel'
import { ReadOnlyNode } from 'application/node'

export class FillsText implements NodeSelectionColorsHandler {
  get = (node: ReadOnlyNode): AttributeFill[] => {
    const color = this.getColor(node)
    if (!color) return []
    return [color]
  }

  updateFill(
    node: ReadOnlyNode,
    before: AttributeFill,
    after: AttributeFill
  ): Partial<StyleMap> {
    return this.updateFills(node, before, after)
  }

  private getColor = (node: ReadOnlyNode): AttributeFill | null => {
    const textSettings = getTextAttributes(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!textSettings) return null

    return textSettings['text.color']
  }

  private updateFills = (
    node: ReadOnlyNode,
    before: AttributeFill,
    after: AttributeFill
  ): Partial<StyleMap> => {
    const color = this.getColor(node)
    if (!color) return {}

    if (!isFillEqual(color, before)) return {}
    return {
      'text.color': after,
    }
  }
}
