import { NodeTitle, NodeTitlesListener } from 'editor/titles/types'
import { NodeTitleTransformer } from './transformer/nodeTitles'
import { Canvas } from 'editor/canvas/canvas'
import { FontLoaderInterface } from 'application/text'
import { IconLoader } from 'application/render/svg/iconLoader'
import { Camera, CameraUpdateListener } from 'application/camera'

export const hapticNodeTitlesKey = 'nodeTitles'

export class HapticNodeTitles
  implements NodeTitlesListener, CameraUpdateListener
{
  private canvas: Canvas
  private fontLoader: FontLoaderInterface
  private iconLoader: IconLoader

  private titles: NodeTitle[]
  private camera: Camera | null

  constructor(
    canvas: Canvas,
    fontLoader: FontLoaderInterface,
    iconLoader: IconLoader
  ) {
    this.canvas = canvas
    this.fontLoader = fontLoader
    this.iconLoader = iconLoader
    this.titles = []
    this.camera = null
  }

  onNodeTitles = (titles: NodeTitle[]): void => {
    this.titles = titles
    this.render()
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (this.titles.length === 0 || this.camera === null) {
      this.canvas.deleteHaptic(hapticNodeTitlesKey)
    } else {
      this.canvas.setHaptic(
        hapticNodeTitlesKey,
        NodeTitleTransformer.transform(
          this.canvas.getContext(),
          this.titles,
          this.camera,
          this.fontLoader,
          this.iconLoader
        )
      )
    }
  }
}
