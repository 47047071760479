import { ExportCalculator } from './calculator'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { FontLoaderInterface } from 'application/text'
import { Context, Renderable } from 'application/render'
import { DocumentRenderTransformer } from 'editor/document/transformer'
import { ReadOnlyNode } from 'application/node'

export class NodeExporter {
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private fontLoader: FontLoaderInterface
  private calculator: ExportCalculator
  private canvas: HTMLCanvasElement | null
  private context: Context

  constructor(
    clientDocument: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    fontLoader: FontLoaderInterface
  ) {
    this.document = clientDocument
    this.selection = selection
    this.fontLoader = fontLoader
    this.calculator = new ExportCalculator()
    this.canvas = null
    this.context = new Context()
  }

  init = () => {
    this.canvas = document.createElement('canvas')
    this.context.init(this.canvas)
  }

  export = async () => {
    const selected = this.selection.getSelected()
    for (const node of selected) {
      await this.exportNode(node)
    }
  }

  cleanup = () => {
    this.context.cleanup()
    this.canvas = null
  }

  private exportNode = async (node: ReadOnlyNode) => {
    if (!this.canvas) return

    const window = this.calculator.getExportWindow([node], 8192, 8192)

    this.context.updateCanvasSize(window.bounds.w, window.bounds.h, 1)
    this.context.setCamera(window.camera)

    this.context.setDrawingTarget(0)
    this.context.setClearColor({ r: 0, g: 0, b: 0, a: 0 })
    this.context.clear()

    await this.renderNode(node.getId(), this.context, 0)

    this.context.drawToCanvas()

    const dataURL = this.canvas.toDataURL('image/png')
    const link = document.createElement('a')
    link.href = dataURL
    link.download = `${this.nameToCamelCase(node.getBaseAttribute('name'))}.png`
    link.click()
  }

  private renderNode = async (id: string, context: Context, depth: number) => {
    const node = this.document.getNode(id)
    if (!node) return
    const parent = this.document.getParent(node)

    const nodeRenderable = DocumentRenderTransformer.transform(
      context,
      node,
      parent,
      this.fontLoader
    )
    if (!nodeRenderable) return

    let activeDepth = depth
    if (nodeRenderable.opacity && nodeRenderable.opacity < 100) {
      activeDepth = depth + 1
      context.setDrawingTarget(activeDepth)
    }

    const renderables = nodeRenderable.renderables
    await this.preloadRenderables(renderables.before)
    await this.preloadRenderables(renderables.after)

    renderables.before.forEach((renderable) => renderable.draw(activeDepth))

    for (let i = nodeRenderable.children.length - 1; i >= 0; i--) {
      const child = nodeRenderable.children[i]
      await this.renderNode(child, context, activeDepth)
    }

    renderables.after.forEach((renderable) => renderable.draw(activeDepth))

    if (activeDepth > depth) {
      context.transferScene(activeDepth, depth, nodeRenderable.opacity)
    }
  }

  private preloadRenderables = async (renderables: Renderable[]) => {
    for (const renderable of renderables) {
      if (!renderable.preload) continue
      await renderable.preload()
    }
  }

  private nameToCamelCase = (name: string): string => {
    return name
      .replace(/\s(.)/g, ($1) => $1.toUpperCase())
      .replace(/\s/g, '')
      .replace(/^(.)/, ($1) => $1.toLowerCase())
  }
}
