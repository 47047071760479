import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import { useState } from 'react'

interface ToolbarCTAButtonDarkProps {
  buttonRef?: React.RefObject<HTMLDivElement>

  text: string
  active?: boolean

  onClick?: () => void
}

export default function ToolbarCTAButtonDark({
  buttonRef,
  text,
  active = false,
  onClick,
}: ToolbarCTAButtonDarkProps) {
  const [hover, setHover] = useState(false)

  const background = getBackground(active || hover)

  return (
    <div
      ref={buttonRef}
      style={{
        height: 28,
        padding: '0px 10px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        background: background,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <Text styles={{ size: 12 }} color={DesignColor('inverseText1')}>
        {text}
      </Text>
    </div>
  )
}

function getBackground(active: boolean) {
  return active
    ? DesignColor('inverseBackgroundHover')
    : DesignColor('inverseBackground')
}
