import { ReadOnlyDocumentSelection } from 'application/selection'
import { CopyAnimationSnapshot } from '../types'
import _ from 'lodash'

export class CopyAnimation {
  private documentSelection: ReadOnlyDocumentSelection

  private snapshot: CopyAnimationSnapshot | null

  constructor(documentSelection: ReadOnlyDocumentSelection) {
    this.documentSelection = documentSelection

    this.snapshot = null
  }

  getSnapshot = (index: number): CopyAnimationSnapshot | null => {
    const selected = this.documentSelection.getSelected()
    if (selected.length !== 1) return null

    const node = selected[0]
    const animations = node.getBaseAttribute('animations')
    if (!animations) return null

    const animation = animations[index]
    if (!animation) return null

    return {
      animation: _.cloneDeep(animation),
    }
  }

  getSavedSnapshot = (): CopyAnimationSnapshot | null => {
    return this.snapshot
  }

  saveSnapshot = (index: number): void => {
    this.snapshot = this.getSnapshot(index)
  }

  clearSnapshot = (): void => {
    this.snapshot = null
  }
}
