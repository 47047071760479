import { BaseMap, StyleMap, getTextAttributes } from 'application/attributes'
import { State, Style } from 'application/textEditor'

export function textEditorStateToAttributes(state: State): {
  base: Partial<BaseMap>
  style: Partial<StyleMap>
} {
  const content = state.content
  const base: Partial<BaseMap> = {}
  const style: Partial<StyleMap> = {}

  base['text.content'] = content.content
  style['text.font.family'] = content.styles.fontFamily
  style['text.font.weight'] = content.styles.fontWeight
  style['text.font.size'] = content.styles.fontSize
  style['text.color'] = content.styles.fill
  style['text.align'] = content.styles.align
  style['text.letterSpacing'] = content.styles.letterSpacing
  style['text.lineHeight'] = content.styles.lineHeight
  style['text.italic'] = content.styles.italic
  style['text.underline'] = content.styles.underline

  return { base, style }
}

export function attributesToStyle(
  attributes: Partial<StyleMap>
): Partial<Style> {
  const fontFamily = attributes['text.font.family']
  const fontWeight = attributes['text.font.weight']
  const fontSize = attributes['text.font.size']
  const color = attributes['text.color']
  const align = attributes['text.align']
  const letterSpacing = attributes['text.letterSpacing']
  const lineHeight = attributes['text.lineHeight']

  const styles: Partial<Style> = {}

  if (fontFamily !== undefined) styles.fontFamily = fontFamily
  if (fontWeight !== undefined) styles.fontWeight = fontWeight
  if (fontSize !== undefined) styles.fontSize = fontSize
  if (color !== undefined) styles.fill = color
  if (align !== undefined) styles.align = align
  if (letterSpacing !== undefined) styles.letterSpacing = letterSpacing
  if (lineHeight !== undefined) styles.lineHeight = lineHeight

  return styles
}

export function attributesToTextEditorState(
  base: BaseMap,
  styles: StyleMap
): State | null {
  const textAttributes = getTextAttributes(base, styles)
  if (!textAttributes) return null

  const state: State = {
    selection: {
      focus: {
        index: 0,
        wrapped: false,
      },
      anchor: null,
    },
    content: {
      content: textAttributes['text.content'],
      contentStyles: Array(textAttributes['text.content'].length).fill(0),
      styles: {
        fontFamily: textAttributes['text.font.family'],
        fontWeight: textAttributes['text.font.weight'],
        fontSize: textAttributes['text.font.size'],
        fill: textAttributes['text.color'],
        align: textAttributes['text.align'],
        letterSpacing: textAttributes['text.letterSpacing'],
        lineHeight: textAttributes['text.lineHeight'],
        italic: textAttributes['text.italic'],
        underline: textAttributes['text.underline'],
        link: null,
      },
      styleOverrides: {},
      lineTypes: [],
    },
    nextCharacter: null,
    width: base['w'],
  }

  return state
}
