import { CommandHandler, SetChildOrder } from 'application/client'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  Done,
  NotDone,
} from '../types'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { getById, getClosestByClass } from 'application/browser'
import { DraggingCanvasLine } from './line'
import { ReadOnlyNode } from 'application/node'
import { Point } from 'application/shapes'

type CanvasPosition = 'top' | 'bottom'

export class DragCanvasAction implements ActionHandler {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private line: DraggingCanvasLine

  private startPoint: Point | null
  private started: boolean
  private lastTarget: string | null
  private lastPosition: CanvasPosition | null

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    line: DraggingCanvasLine
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.selection = selection
    this.line = line

    this.startPoint = null
    this.started = false
    this.lastTarget = null
    this.lastPosition = null
  }

  getType = (): Action => {
    return 'dragCanvas'
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    const point = { x: e.clientX, y: e.clientY }
    if (!this.startPoint) {
      this.startPoint = point
      return NotDone
    }

    if (!this.isPastDeltaThreshold(this.startPoint, point) && !this.started) {
      return NotDone
    } else {
      this.started = true
    }

    const id = this.getCanvasAtPoint(e)

    if (!id) return NotDone

    const position = this.getCanvasTargetPosition(e, id)
    if (!position) return NotDone

    this.lastPosition = position
    this.lastTarget = id
    this.line.set({ id, position })

    return NotDone
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    this.line.set(null)

    const point = { x: e.clientX, y: e.clientY }
    if (!this.startPoint) return Done
    if (!this.isPastDeltaThreshold(this.startPoint, point)) return Done

    const targetId = this.getCanvasAtPoint(e) || this.lastTarget
    if (!targetId) return Done

    const position =
      this.getCanvasTargetPosition(e, targetId) || this.lastPosition
    if (!position) return Done

    this.reorderCanvas(targetId, position)
    this.commandHandler.handle({ type: 'commit' })

    return Done
  }

  private getCanvasAtPoint = (e: MouseEvent): string | null => {
    const target = getClosestByClass(e, 'canvas-row')
    if (!target) return null

    const canvasId = target.getAttribute('data-canvas-id')
    return canvasId || null
  }

  private getCanvasTargetPosition = (
    e: MouseEvent,
    canvasId: string
  ): CanvasPosition | null => {
    const target = getById(`canvas-row-${canvasId}`)
    if (!target) return null

    const node = this.document.getNode(canvasId)
    if (!node) return null

    const selected = this.selection.getSelectedCanvas()
    if (selected === canvasId) return null

    const rectangle = target.getBoundingClientRect()
    const half = rectangle.height / 2
    const y = rectangle.top + half

    if (e.clientY < y) return 'top'
    return 'bottom'
  }

  private reorderCanvas = (
    targetId: string,
    position: CanvasPosition
  ): void => {
    const index = this.getIndex(targetId, position)
    if (index === null) return

    const canvas = this.document.getNode(this.selection.getSelectedCanvas())
    if (!canvas) return

    this.reorderChildren(canvas, index)
  }

  private reorderChildren = (node: ReadOnlyNode, index: number): void => {
    const currentParentId = node.getParent()
    if (!currentParentId) return

    const root = this.document.getNode('root')
    if (!root) return

    const children = root.getChildren()
    if (!children) return

    const nodeIndex = children.indexOf(node.getId())
    if (nodeIndex === -1) return

    const newOrder = children.filter((id) => id !== node.getId())
    newOrder.splice(nodeIndex > index ? index : index - 1, 0, node.getId())

    const command = this.buildReorderCommand(currentParentId, newOrder)
    this.commandHandler.handle(command)
  }

  private getIndex = (id: string, position: CanvasPosition): number | null => {
    const node = this.document.getNode(id)
    if (!node) return null

    const parent = this.document.getParent(node)
    if (!parent) return null

    const children = parent.getChildren()
    if (!children) return null

    const index = children.indexOf(id)
    if (index === -1) return null

    return position === 'top' ? index : index + 1
  }

  private buildReorderCommand = (
    parentId: string,
    children: string[]
  ): SetChildOrder => {
    return {
      type: 'setChildOrder',
      params: { parentId: parentId, children: children },
    }
  }

  private isPastDeltaThreshold = (start: Point, current: Point): boolean => {
    return Math.abs(current.y - start.y) > 20
  }
}
