import { NodeCreateAction, NodeSelectionAction } from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { CommandHandler } from 'application/client'
import { Point } from 'application/shapes'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  Done,
  DoneNext,
  DonePassthrough,
  NotDone,
} from '../types'
import { Cursor } from 'application/cursor'
import { isCanvasClosest } from 'application/browser'
import { AttributeType } from 'application/attributes'
import { HapticDrawingWindow } from 'editor/haptic/drawingWindow'

export const hotkeyToAttributeType: { [key: string]: AttributeType } = {
  p: 'page',
  P: 'page',
  f: 'frame',
  F: 'frame',
  t: 'text',
  T: 'text',
  r: 'rectangle',
  R: 'rectangle',
  e: 'ellipse',
  E: 'ellipse',
  o: 'ellipse',
  O: 'ellipse',
  i: 'image',
  I: 'image',
}

export class DrawAction implements ActionHandler {
  private commandHandler: CommandHandler
  private create: NodeCreateAction
  private select: NodeSelectionAction
  private coordinates: CoordinatesConversion
  private haptic: HapticDrawingWindow
  private startPoint: Point | null
  private type: AttributeType
  private keyUp: boolean

  constructor(
    commandHandler: CommandHandler,
    create: NodeCreateAction,
    select: NodeSelectionAction,
    coordinates: CoordinatesConversion,
    haptic: HapticDrawingWindow,
    type: AttributeType,
    hotkey: boolean
  ) {
    this.commandHandler = commandHandler
    this.create = create
    this.select = select
    this.coordinates = coordinates
    this.haptic = haptic
    this.startPoint = null
    this.type = type
    this.keyUp = !hotkey
  }

  getType = (): Action => {
    switch (this.type) {
      case 'page':
        return 'drawPage'
      case 'text':
        return 'drawText'
      case 'rectangle':
        return 'drawRectangle'
      case 'ellipse':
        return 'drawEllipse'
      case 'image':
        return 'drawImage'
      default:
        return 'drawFrame'
    }
  }

  getCursor = (): Cursor => {
    switch (this.type) {
      case 'page':
        return 'drawPage'
      case 'text':
        return 'drawText'
      case 'rectangle':
        return 'drawRectangle'
      case 'ellipse':
        return 'drawEllipse'
      case 'image':
        return 'drawImage'
      default:
        return 'drawFrame'
    }
  }

  onMouseDown = (e: MouseEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) return NotDone

    const point = this.coordinates.get(e)
    this.startPoint = point

    return NotDone
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    if (!this.startPoint) return NotDone

    const point = this.coordinates.get(e)
    this.setWindow(point)

    return NotDone
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    if (!this.startPoint) return NotDone

    this.clearWindow()

    const point = this.coordinates.get(e)

    let newId: string | null = null
    if (this.isDrawBelowThreshold(point)) {
      newId = this.create.click(this.type, point)
    } else {
      newId = this.create.draw(this.type, this.startPoint, point)
    }
    if (!newId) return Done

    this.select.selectNodes([newId], true)

    if (this.type === 'text') {
      return DoneNext('editText')
    } else {
      this.commandHandler.handle({ type: 'commit' })
      return Done
    }
  }

  onKeyDown = (e: KeyboardEvent): ActionEventResult => {
    if (!this.keyUp) return NotDone
    if (['v', 'V', 'Escape'].includes(e.key)) return Done

    const type = hotkeyToAttributeType[e.key]
    if (!type) return NotDone

    if (type === this.type) return Done
    return DonePassthrough
  }

  onKeyUp = (): ActionEventResult => {
    this.keyUp = true
    return NotDone
  }

  private setWindow = (point: Point) => {
    if (!this.startPoint) return
    const rectangle = {
      x: Math.min(this.startPoint.x, point.x),
      y: Math.min(this.startPoint.y, point.y),
      w: Math.abs(this.startPoint.x - point.x),
      h: Math.abs(this.startPoint.y - point.y),
    }
    const shape = this.type === 'ellipse' ? 'circle' : 'rectangle'
    this.haptic.setRectangle(rectangle, shape)
  }

  private clearWindow = () => {
    this.haptic.setRectangle(null, 'rectangle')
  }

  private isDrawBelowThreshold = (point: Point): boolean => {
    if (!this.startPoint) return false
    const threshold = 5
    return (
      Math.abs(this.startPoint.x - point.x) < threshold &&
      Math.abs(this.startPoint.y - point.y) < threshold
    )
  }
}
