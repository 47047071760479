import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import PlanDetails from './PlanDetails'
import { Stripe, loadStripe } from '@stripe/stripe-js'

interface PlanSettingsProps {
  premium: boolean

  startCheckout: (annual: boolean) => Promise<{ sessionId: string }>
  startManage: () => Promise<{ sessionUrl: string }>

  close: () => void
}

let stripePromise: Promise<Stripe | null> | null = null

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')
  }
  return stripePromise
}

export default function PlanSettings({
  premium,
  startCheckout,
  startManage,
  close,
}: PlanSettingsProps) {
  const handleSubmit = async (annual: boolean) => {
    const stripe = await getStripe()
    if (!stripe) return

    const { sessionId } = await startCheckout(annual)
    if (sessionId === '') return

    await stripe.redirectToCheckout({ sessionId })
  }

  const handleManage = async () => {
    const stripe = await getStripe()
    if (!stripe) return

    const { sessionUrl } = await startManage()
    window.open(sessionUrl, '_blank')
  }

  return (
    <>
      <PanelSection width={'fill'}>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="title">
            Plan
          </TextBlock>
          <IconBlock icon="X" onClick={close} />
        </PanelRow>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={96} mode="label2">
            Current Plan:
          </TextBlock>
          <TextBlock width={'fill'} mode="label1">
            {premium ? 'Premium' : 'Free'}
          </TextBlock>
        </PanelRow>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="label2">
            Repaint has simplified pricing while we’re building out our core
            features.
          </TextBlock>
        </PanelRow>
      </PanelSection>
      <PanelSection width={'fill'} bottom={true}>
        <PlanDetails
          premium={premium}
          upgrade={handleSubmit}
          cancel={handleManage}
        />
      </PanelSection>
    </>
  )
}
