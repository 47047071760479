import {
  AttributeSizeAuto,
  AttributeType,
  MultiselectStyleMap,
  isDynamicSizeAuto,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import { computeAvailableSpace } from 'application/layout/size/utils'
import { truncate } from 'application/math'

type SizePanelKeys =
  | 'size.w'
  | 'size.h'
  | 'size.w.percent'
  | 'size.h.percent'
  | 'size.h.min'
  | 'size.h.max'
  | 'size.w.min'
  | 'size.w.max'
  | 'size.h.min.mode'
  | 'size.h.max.mode'
  | 'size.w.min.mode'
  | 'size.w.max.mode'
  | 'size.ratio'
  | 'size.ratio.mode'
  | 'size.w.auto'
  | 'size.h.auto'

type SizePanelAttributes = Pick<MultiselectStyleMap, SizePanelKeys> | null

export interface SizePanelState {
  attributes: SizePanelAttributes
  canLockRatio: boolean
  widthOptions: ('min' | 'max')[]
  heightOptions: ('min' | 'max')[]
  widthAutoOptions: AttributeSizeAuto[]
  heightAutoOptions: AttributeSizeAuto[]
}

export interface SizePanelHandlers {
  setSize: (value: number, mode: 'w' | 'h') => void
  slideSize: (value: number, mode: 'w' | 'h') => void
  addMinMax: (type: 'min' | 'max', mode: 'w' | 'h') => void
  removeMinMax: (type: 'min' | 'max', mode: 'w' | 'h') => void
  setMinMax: (value: number, type: 'min' | 'max', mode: 'w' | 'h') => void
  slideMinMax: (value: number, type: 'min' | 'max', mode: 'w' | 'h') => void
  setRatioLocked: (value: boolean) => void
  setSizeAuto: (value: AttributeSizeAuto, mode: 'w' | 'h') => void
}

export class SizePanel extends StyleAttributePanel<
  SizePanelState,
  SizePanelHandlers,
  SizePanelKeys
> {
  getSettings(): SizePanelState {
    return {
      attributes: this.attributes,
      canLockRatio: this.canNodeLockRatio(),
      widthOptions: this.getMinMaxOptions('w'),
      heightOptions: this.getMinMaxOptions('h'),
      widthAutoOptions: this.getAutoOptions(),
      heightAutoOptions: this.getAutoOptions(),
    }
  }

  getHandlers(): SizePanelHandlers {
    return {
      setSize: this.setSize,
      slideSize: this.slideSize,
      setSizeAuto: this.setSizeAuto,
      addMinMax: this.addMinMax,
      removeMinMax: this.removeMinMax,
      setMinMax: this.setMinMax,
      slideMinMax: this.slideMinMax,
      setRatioLocked: this.setRatioLocked,
    }
  }

  private setSize = (value: number, mode: 'w' | 'h'): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const auto = node.getStyleAttribute(`size.${mode}.auto`)
      switch (auto) {
        case 'percent':
          this.setSizePercent(node, mode, value)
          break
        default:
          this.setSizeFixed(node, mode, value)
          break
      }
    }
  }

  private setSizeAuto = (value: AttributeSizeAuto, mode: 'w' | 'h'): void => {
    const pairs = this.getNodesAndParents()
    for (const [node, parent] of pairs) {
      if (!parent) continue
      switch (value) {
        case 'fill':
        case 'hug':
          this.setOne(node.getId(), {
            [`size.${mode}.auto`]: value,
            [`size.${mode}.percent`]: undefined,
            [`size.${mode}`]: undefined,
          })
          break
        case 'percent':
          const percent = this.computePercent(node, parent, mode)
          this.setOne(node.getId(), {
            [`size.${mode}.auto`]: value,
            [`size.${mode}.percent`]: percent,
            [`size.${mode}`]: undefined,
          })
          break
        case 'fixed':
          this.setOne(node.getId(), {
            [`size.${mode}.auto`]: value,
            [`size.${mode}.percent`]: undefined,
            [`size.${mode}`]: node.getBaseAttribute(mode),
          })
          break
      }
    }
    this.commit()
  }

  private slideSize = (value: number, mode: 'w' | 'h'): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const auto = node.getStyleAttribute(`size.${mode}.auto`)
      switch (auto) {
        case 'fixed':
          const currentPx = node.getStyleAttribute(`size.${mode}`)
          if (currentPx === undefined) break
          this.setSizeFixed(node, mode, Math.max(currentPx + value, 0))
          break
        case 'percent':
          const currentPer = node.getStyleAttribute(`size.${mode}.percent`)
          if (currentPer === undefined) break
          this.setSizePercent(
            node,
            mode,
            Math.max(truncate(currentPer + value, 0), 0)
          )
          break
      }
    }
  }

  private setSizeFixed(
    node: ReadOnlyNode,
    mode: 'w' | 'h',
    value: number
  ): void {
    const ratio = node.getStyleAttribute('size.ratio')
    const ratioMode = node.getStyleAttribute('size.ratio.mode')
    if (ratioMode === 'fixed' && ratio !== undefined) {
      const other = mode === 'w' ? 'h' : 'w'
      const otherMode = node.getStyleAttribute(`size.${other}.auto`)
      if (isDynamicSizeAuto(otherMode) && value !== 0) {
        if (mode === 'w') {
          const newRatio = value / node.getBaseAttribute('h')
          this.setOne(node.getId(), { 'size.ratio': newRatio })
        } else {
          const newRatio = node.getBaseAttribute('w') / value
          this.setOne(node.getId(), { 'size.ratio': newRatio })
        }
      } else if (otherMode === 'fixed') {
        if (mode === 'w') {
          const newHeight = value / ratio
          this.setOne(node.getId(), { 'size.h': newHeight })
        } else {
          const newWidth = value * ratio
          this.setOne(node.getId(), { 'size.w': newWidth })
        }
      }
    }
    this.setOne(node.getId(), {
      [`size.${mode}`]: value,
      [`size.${mode}.auto`]: 'fixed',
    })
  }

  private setSizePercent(
    node: ReadOnlyNode,
    mode: 'w' | 'h',
    value: number
  ): void {
    this.setOne(node.getId(), {
      [`size.${mode}.percent`]: value,
      [`size.${mode}.auto`]: 'percent',
    })
  }

  private addMinMax = (type: 'min' | 'max', mode: 'w' | 'h'): void => {
    const options = this.getMinMaxOptions(mode)
    if (!options.includes(type)) return

    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      this.setOne(node.getId(), {
        [`size.${mode}.${type}`]: node.getBaseAttribute(mode),
        [`size.${mode}.${type}.mode`]: 'fixed',
      })
    }

    this.commit()
  }

  private removeMinMax = (type: 'min' | 'max', mode: 'w' | 'h'): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      this.setOne(node.getId(), {
        [`size.${mode}.${type}`]: undefined,
        [`size.${mode}.${type}.mode`]: 'none',
      })
    }
    this.commit()
  }

  private setMinMax = (
    value: number,
    type: 'min' | 'max',
    mode: 'w' | 'h'
  ): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      this.setOne(node.getId(), {
        [`size.${mode}.${type}`]: value,
      })
    }
  }

  private slideMinMax = (
    value: number,
    type: 'min' | 'max',
    mode: 'w' | 'h'
  ): void => {
    this.slideMulti(`size.${mode}.${type}`, value)
  }

  private getMinMaxOptions = (mode: 'w' | 'h'): ('min' | 'max')[] => {
    if (!this.attributes) return []

    const options: ('min' | 'max')[] = []

    if (this.attributes[`size.${mode}.min`] === undefined) options.push('min')
    if (this.attributes[`size.${mode}.max`] === undefined) options.push('max')

    return options
  }

  private setRatioLocked = (value: boolean): void => {
    const nodes = this.getNodes()
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      if (value) {
        const ratio = node.getBaseAttribute('w') / node.getBaseAttribute('h')
        this.setOne(node.getId(), {
          'size.ratio.mode': 'fixed',
          'size.ratio': ratio,
        })
      } else {
        this.setOne(node.getId(), {
          'size.ratio.mode': 'none',
          'size.ratio': undefined,
        })
      }
    }
    this.commit()
  }

  private canNodeLockRatio = (): boolean => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return false

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const widthAuto = node.getStyleAttribute('size.w.auto')
      const heightAuto = node.getStyleAttribute('size.h.auto')
      return widthAuto !== 'hug' && heightAuto !== 'hug'
    }

    return false
  }

  private getAutoOptions = (): AttributeSizeAuto[] => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return []

    const options: AttributeSizeAuto[] = ['fixed']

    if (this.canAllHug(pairs)) options.push('hug')
    if (this.canAllDynamic(pairs)) options.push('fill', 'percent')

    return options
  }

  private computePercent(
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    mode: 'w' | 'h'
  ): number | null {
    const availableSpace = computeAvailableSpace(parent, mode, this.document)
    if (availableSpace === null) return null

    const size = node.getBaseAttribute(mode)
    const percent = (size / availableSpace) * 100

    return truncate(percent, 1)
  }

  private canAllHug(pairs: [ReadOnlyNode, ReadOnlyNode | null][]): boolean {
    for (const [node] of pairs) {
      switch (node.getBaseAttribute('type')) {
        case 'frame':
        case 'page':
          return node.getStyleAttribute('autolayout.mode') === 'flex'
        case 'text':
          return true
        default:
          return false
      }
    }
    return true
  }

  private canAllDynamic(pairs: [ReadOnlyNode, ReadOnlyNode | null][]): boolean {
    for (const [node, parent] of pairs) {
      if (!parent) return false
      if (parent.getBaseAttribute('type') === 'canvas') return false
      if (parent.getStyleAttribute('autolayout.direction') === 'wrap')
        return false
      if (!typesWithDynamic.includes(node.getBaseAttribute('type')))
        return false
    }
    return true
  }

  protected getSlideMax = (): number => {
    return 50_000
  }

  protected getSlideMin = (): number => {
    return 0
  }
}

const typesWithDynamic: AttributeType[] = [
  'frame',
  'rectangle',
  'image',
  'ellipse',
  'text',
]
