import TabsBlock from 'components/Library/Components/Tabs/TabsBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import StyleSettings from './StyleSettings/StyleSettings'
import PresetSection from './PresetSection/PresetSection'
import { atom, useRecoilState } from 'recoil'
import useSelected from 'hooks/editor/useSelected'
import useAction from 'hooks/editor/useAction'
import BreakpointModeSettings from './BreakpointModeSettings/BreakpointModeSettings'
import InteractionSettings from './InteractionSettings/InteractionSettings'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { ThemeColorKey } from 'themes'
import { SelectorBreakpoint, SelectorPseudo } from 'application/attributes'
import EffectSettings from './EffectSettings/EffectSettings'

const tabState = atom({
  key: 'rightSidepanelTab',
  default: 'style',
})

export default function RightSidepanel() {
  const breakpointSettings = usePanelHelper(
    editor.getUI().getBreakpointModePanel()
  )
  const effectSettings = usePanelHelper(
    editor.getUI().getInteractionAnimationEffectPanel()
  )
  const selected = useSelected()
  const action = useAction()
  const [tab, setTab] = useRecoilState(tabState)
  const screenPresetsOpen = ['drawPage', 'drawFrame'].includes(action || '')

  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 64,
        right: 8,
      }}
      height={'calc(100vh - 72px)'}
      backgroundColor={getBackgroundColor(
        breakpointSettings.settings?.breakpoint,
        breakpointSettings.settings?.pseudo
      )}
    >
      {effectSettings.settings?.editing ? (
        <EffectSettings />
      ) : (
        <>
          {screenPresetsOpen && <PresetSection />}
          {!screenPresetsOpen && selected.length > 0 && (
            <>
              <PanelSection>
                <PanelRow>
                  <TabsBlock
                    tabs={['style', 'interactions']}
                    selected={tab}
                    select={setTab}
                  />
                </PanelRow>
                {tab === 'style' && <BreakpointModeSettings />}
              </PanelSection>
              {'style' === tab && <StyleSettings />}
              {'interactions' === tab && <InteractionSettings />}
            </>
          )}
        </>
      )}
    </PanelContainer>
  )
}

function getBackgroundColor(
  breakpoint: 'Mixed' | SelectorBreakpoint | undefined,
  pseudo: 'Mixed' | SelectorPseudo | undefined
): ThemeColorKey {
  if (
    breakpoint === 'landscape' ||
    breakpoint === 'tablet' ||
    breakpoint === 'mobile' ||
    pseudo === 'hover'
  ) {
    return 'overridePanel'
  }
  return 'panelBackground'
}
