import {
  ClientUpdateListener,
  CommandHandler,
  Update,
} from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ProjectDocument, ProjectDocumentNode } from 'application/service'
import { BackendService } from 'application/service/backend'
import { FontDataMap, FontLoaderInterface } from 'application/text'
import { EditorWebsocketService } from 'editor/websocket/websocket'

export class EditorProjectService implements ClientUpdateListener {
  private commandHandler: CommandHandler
  private backendService: BackendService
  private websocketService: EditorWebsocketService
  private document: ReadOnlyDocument
  private fontLoader: FontLoaderInterface
  private fontMap: FontDataMap

  private projectId: string | null
  private documentId: string | null
  private documentSchema: number

  constructor(
    commandHandler: CommandHandler,
    backendService: BackendService,
    websocketService: EditorWebsocketService,
    document: ReadOnlyDocument,
    fontLoader: FontLoaderInterface,
    fontMap: FontDataMap
  ) {
    this.commandHandler = commandHandler
    this.backendService = backendService
    this.websocketService = websocketService
    this.document = document
    this.fontLoader = fontLoader
    this.fontMap = fontMap

    this.projectId = null
    this.documentId = null
    this.documentSchema = 0
  }

  getTypes = (): Update['type'][] => {
    return ['node_created', 'node_deleted', 'node_updated']
  }

  onUpdate = (updates: Update[]) => {
    this.persistProject(updates)
  }

  getProjectId = (): string | null => {
    return this.projectId
  }

  getDocumentId = (): string | null => {
    return this.documentId
  }

  setProjectId = async (projectId: string) => {
    const project = await this.backendService.getProject(projectId)
    if (!project || !project.documentId) return

    const document = await this.backendService.getDocument(project.documentId)
    if (!document) return

    this.projectId = projectId
    this.documentId = project.documentId
    this.documentSchema = document.schema

    await this.initProjectFonts(projectId)
    await this.initDocumentFonts(document)

    this.commandHandler.handle({
      type: 'initializeDocument',
      params: { document },
    })
  }

  private persistProject = (updates: Update[]) => {
    if (!this.documentId) return

    let hasChanges = false
    const updated: { [key: string]: ProjectDocumentNode } = {}
    const deleted: string[] = []

    for (const update of updates) {
      switch (update.type) {
        case 'node_deleted':
          deleted.push(update.data.id)
          hasChanges = true
          break
        case 'node_created':
        case 'node_updated':
          const node = this.document.getNode(update.data.id)
          if (!node) continue
          updated[node.getId()] = {
            id: node.getId(),
            parent: node.getParent(),
            children: node.getChildren(),
            baseAttributes: node.getBaseAttributes(),
            defaultSelector: node.getDefaultSelector(),
            selectors: node.getSelectors(),
            activeBreakpoint: node.getActiveBreakpoint(),
            activePseudo: node.getActivePseudo(),
          }
          hasChanges = true
          break
      }
    }

    if (hasChanges) {
      this.websocketService.sendDocumentUpdate({
        type: 'document_update',
        token: '',
        data: {
          documentId: this.documentId,
          update: {
            schema: this.documentSchema,
            updated: updated,
            deleted: deleted,
          },
        },
      })
    }
  }

  private initProjectFonts = async (projectId: string) => {
    const fonts = await this.backendService.getFonts(projectId)
    if (!fonts) return

    for (const font of fonts) {
      this.fontMap.addProjectFont(font)
      await this.fontLoader.loadExternalFont(
        font.family,
        font.weight,
        font.fileUrl,
        font.msdfJsonUrl,
        font.msdfPngUrl
      )
    }
  }

  private initDocumentFonts = async (document: ProjectDocument) => {
    for (const node of Object.values(document.data)) {
      const fontFamily = node.defaultSelector['styles']['text.font.family']
      if (!fontFamily || !this.fontMap.isInternalFont(fontFamily)) continue
      await this.fontLoader.loadInternalFont(fontFamily)
    }
  }
}
