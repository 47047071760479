import { WriteDocument } from 'application/document'
import { Node, ReadOnlyNode } from 'application/node'
import { PositionEngineCalculator } from './types'
import { isAutolayoutChild, isCanvasChild } from 'application/attributes'

export class AbsolutePositionCalculator implements PositionEngineCalculator {
  update(node: Node, document: WriteDocument): void {
    const parent = document.getParent(node)
    if (!parent) return
    if (isAutolayoutChild(node, parent)) return
    if (isCanvasChild(parent)) return

    const x = this.computePosition('x', node, parent)
    const y = this.computePosition('y', node, parent)

    node.setBaseAttribute('x', x)
    node.setBaseAttribute('y', y)
  }

  private computePosition = (
    mode: 'x' | 'y',
    node: ReadOnlyNode,
    parent: ReadOnlyNode
  ): number => {
    const parentPosition = parent.getBaseAttribute(mode)
    const parentSize = parent.getBaseAttribute(mode === 'x' ? 'w' : 'h')
    const nodeSize = node.getBaseAttribute(mode === 'x' ? 'w' : 'h')
    const mode1Key = mode === 'x' ? 'left' : 'top'
    const mode2Key = mode === 'x' ? 'right' : 'bottom'
    const mode1 = node.getStyleAttribute(`position.${mode1Key}.auto`)
    const mode2 = node.getStyleAttribute(`position.${mode2Key}.auto`)
    const mode1Pixel = node.getStyleAttribute(`position.${mode1Key}`)
    const mode2Pixel = node.getStyleAttribute(`position.${mode2Key}`)
    const mode1Percent = node.getStyleAttribute(`position.${mode1Key}.percent`)
    const mode2ValuePercent = node.getStyleAttribute(
      `position.${mode2Key}.percent`
    )

    let value = 0

    if (mode1 !== 'none') {
      switch (mode1) {
        case 'fixed':
          if (mode1Pixel === undefined) break
          value = parentPosition + mode1Pixel
          break
        case 'percent':
          if (mode1Percent === undefined) break
          value = parentPosition + (mode1Percent / 100) * parentSize
          break
      }
    } else if (mode2 !== 'none') {
      switch (mode2) {
        case 'fixed':
          if (mode2Pixel === undefined) break
          value = parentPosition + parentSize - mode2Pixel - nodeSize
          break
        case 'percent':
          if (mode2ValuePercent === undefined) break
          value =
            parentPosition +
            parentSize -
            (mode2ValuePercent / 100) * parentSize -
            nodeSize
          break
      }
    }

    return value
  }
}
