import AnimationRow from 'components/Library/Components/Button/AnimationRow/AnimationRow'
import TitleRow from 'components/Library/Components/Button/TitleRow/TitleRow'
import AnimationTriggerPopup from 'components/Library/Components/Inputs/AnimationTriggerPopup/AnimationTriggerPopup'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useRef, useState } from 'react'

export default function AnimationSettings() {
  const [open, setOpen] = useState(false)

  const animationsPanel = usePanelHelper(
    editor.getUI().getInteractionAnimationPanel()
  )
  const effectPanel = usePanelHelper(
    editor.getUI().getInteractionAnimationEffectPanel()
  )
  const sectionRef = useRef<HTMLDivElement>(null)

  if (!animationsPanel.settings || !animationsPanel.settings.attributes)
    return <></>

  const { add, remove, copy } = animationsPanel.handlers
  const { setIndex } = effectPanel.handlers
  const { animations } = animationsPanel.settings.attributes
  const inactive =
    animations === 'Mixed' ||
    animations === undefined ||
    animations?.length === 0

  return (
    <PanelSection sectionRef={sectionRef}>
      <TitleRow
        title={'Custom Animation'}
        active={!inactive}
        sectionRef={sectionRef}
        onClickRow={() => {
          if (!inactive) return
          setOpen(true)
        }}
        onClickIcon={() => setOpen(true)}
      />
      {!inactive && (
        <>
          {animations.map((a, i) => (
            <AnimationRow
              key={i}
              type={a.action.trigger}
              onOpen={() => setIndex(i)}
              onDelete={() => remove(i)}
              onCopy={() => copy(i)}
            />
          ))}
        </>
      )}
      {open && (
        <AnimationTriggerPopup
          sectionRef={sectionRef}
          buttonRef={sectionRef}
          triggers={animationsPanel.settings.triggers}
          select={(mode) => {
            const index = animations?.length || 0
            add(mode)
            setOpen(false)
            setIndex(index)
          }}
          close={() => setOpen(false)}
        />
      )}
    </PanelSection>
  )
}
