import { IconKey } from 'assets/iconLibrary'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import AutolayoutInput from 'components/Library/Components/Inputs/AutolayoutInput/AutolayoutInput'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TitleIconBlock from 'components/Library/Components/IconBlock/TitleIconBlock/TitleIconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import IconSetBlock from 'components/Library/Containers/IconSetBlock/IconSetBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { tooltipKey } from 'assets/tooltips'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { AttributeAutolayoutDirection } from 'application/attributes'
import { useState } from 'react'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'

export default function AutolayoutSettings() {
  const [displaySplit, setDisplaySplit] = useState(false)

  const autolayout = usePanelHelper(editor.getUI().getAutolayoutPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!autolayout.settings || !autolayout.settings.attributes) return <></>
  if (autolayout.settings.mode === 'disabled') return <></>

  const {
    activate,
    deactivate,
    setDirection,
    setAlign,
    setCounter,
    setGap,
    slideGap,
    setPadding,
    collapsePadding,
    slidePadding,
  } = autolayout.handlers
  const { attributes, mode } = autolayout.settings

  const direction = attributes['autolayout.direction'] || 'row'
  const main = attributes['autolayout.align.main'] || 'start'
  const counter = attributes['autolayout.align.counter'] || 'start'
  const gap = attributes['autolayout.gap'] || 0
  const left = attributes['padding.left'] || 0
  const right = attributes['padding.right'] || 0
  const top = attributes['padding.top'] || 0
  const bottom = attributes['padding.bottom'] || 0
  const split = left !== right || top !== bottom || displaySplit
  const activated = mode === 'remove'

  const { hasOverride, sectionHasOverride } = overrides.handlers

  const handleClickRow = () => {
    if (activated) return
    activate()
  }

  const handleClickButton = () => {
    activated ? deactivate() : activate()
  }

  return (
    <PanelSection>
      <PanelRow>
        <OverrideReset section={'autolayout'}>
          <TitleButtonBlock
            title="Auto Layout"
            active={activated}
            hasOverride={sectionHasOverride('autolayout')}
            onClick={handleClickRow}
          />
        </OverrideReset>
        <TitleIconBlock
          icon={'Plus'}
          active={activated}
          canDeactivate={activated}
          onClick={handleClickButton}
        />
      </PanelRow>
      {activated && (
        <>
          <PanelRow height={64} paddingH={8} paddingV={0}>
            <PanelBlock height={64} paddingL={8} paddingR={4}>
              <OverrideReset
                keys={['autolayout.align.main', 'autolayout.align.counter']}
              >
                <AutolayoutInput
                  main={main}
                  counter={counter}
                  direction={direction}
                  setAlign={setAlign}
                  hasOverride={hasOverride([
                    'autolayout.align.main',
                    'autolayout.align.counter',
                  ])}
                />
              </OverrideReset>
            </PanelBlock>
            <PanelBlock height={64} direction={'column'} gap={8} paddingV={4}>
              <IconSetBlock>
                {directionModes.map((d) => (
                  <OverrideReset
                    key={d.direction}
                    keys={['autolayout.direction']}
                  >
                    <IconBlock
                      key={d.direction}
                      icon={d.iconKey}
                      tooltipKey={d.tooltipKey}
                      selected={d.direction === direction}
                      hasOverride={hasOverride(['autolayout.direction'])}
                      onClick={() => setDirection(d.direction)}
                    />
                  </OverrideReset>
                ))}
              </IconSetBlock>
              <OverrideReset keys={['autolayout.gap']}>
                <NumberBlock
                  icon={'Gap'}
                  iconRotation={direction === 'column' ? 90 : 0}
                  tooltipKey="AutolayoutGap"
                  value={gap}
                  setValue={setGap}
                  increment={(v) => slideGap(v)}
                  decrement={(v) => slideGap(-v)}
                  min={0}
                  dim={counter === 'spaced'}
                  hasOverride={hasOverride(['autolayout.gap'])}
                />
              </OverrideReset>
            </PanelBlock>
            <PanelBlock
              width={24}
              height={64}
              direction={'column'}
              paddingV={4}
              gap={8}
            >
              <PanelBlock width={24} height={24} />
              <OverrideReset keys={['autolayout.align.counter']}>
                <IconBlock
                  icon="SpreadGap"
                  iconRotation={direction === 'column' ? 90 : 0}
                  tooltipKey="AutolayoutSpread"
                  tooltipDirection="left"
                  selected={counter === 'spaced'}
                  hasOverride={hasOverride(['autolayout.align.counter'])}
                  onClick={() =>
                    setCounter(counter === 'spaced' ? 'start' : 'spaced')
                  }
                />
              </OverrideReset>
            </PanelBlock>
          </PanelRow>
          <PanelRow>
            <OverrideReset
              keys={
                split ? ['padding.left'] : ['padding.left', 'padding.right']
              }
            >
              <NumberBlock
                icon={split ? 'PaddingLeft' : 'PaddingH'}
                tooltipKey={
                  split
                    ? 'AutolayoutPaddingLeft'
                    : 'AutolayoutHorizontalPadding'
                }
                value={left}
                min={0}
                setValue={(v) => setPadding(v, 'left', split)}
                increment={(v) => slidePadding(v, 'left', split)}
                decrement={(v) => slidePadding(-v, 'left', split)}
                hasOverride={hasOverride(
                  split ? ['padding.left'] : ['padding.left', 'padding.right']
                )}
              />
            </OverrideReset>
            <OverrideReset
              keys={split ? ['padding.top'] : ['padding.top', 'padding.bottom']}
            >
              <NumberBlock
                icon={split ? 'PaddingTop' : 'PaddingV'}
                tooltipKey={
                  split ? 'AutolayoutPaddingTop' : 'AutolayoutVerticalPadding'
                }
                value={top}
                min={0}
                setValue={(v) => setPadding(v, 'top', split)}
                increment={(v) => slidePadding(v, 'top', split)}
                decrement={(v) => slidePadding(-v, 'top', split)}
                hasOverride={hasOverride(
                  split ? ['padding.top'] : ['padding.top', 'padding.bottom']
                )}
              />
            </OverrideReset>
            <IconBlock
              icon="BorderSplit"
              tooltipKey="AutolayoutSplitPadding"
              tooltipDirection="left"
              selected={split}
              onClick={() => {
                if (split) {
                  if (!displaySplit) collapsePadding()
                  setDisplaySplit(false)
                } else {
                  setDisplaySplit(true)
                }
              }}
            />
          </PanelRow>
          {split && (
            <PanelRow>
              <OverrideReset keys={['padding.right']}>
                <NumberBlock
                  icon={'PaddingRight'}
                  tooltipKey="AutolayoutPaddingRight"
                  value={right}
                  min={0}
                  setValue={(v) => setPadding(v, 'right', split)}
                  increment={(v) => slidePadding(v, 'right', split)}
                  decrement={(v) => slidePadding(-v, 'right', split)}
                  hasOverride={hasOverride(['padding.right'])}
                />
              </OverrideReset>
              <OverrideReset keys={['padding.bottom']}>
                <NumberBlock
                  icon={'PaddingBottom'}
                  tooltipKey="AutolayoutPaddingBottom"
                  value={bottom}
                  min={0}
                  setValue={(v) => setPadding(v, 'bottom', split)}
                  increment={(v) => slidePadding(v, 'bottom', split)}
                  decrement={(v) => slidePadding(-v, 'bottom', split)}
                  hasOverride={hasOverride(['padding.bottom'])}
                />
              </OverrideReset>
            </PanelRow>
          )}
        </>
      )}
    </PanelSection>
  )
}

const directionModes: {
  direction: AttributeAutolayoutDirection
  tooltipKey: tooltipKey
  iconKey: IconKey
}[] = [
  {
    direction: 'row',
    tooltipKey: 'AutolayoutRow',
    iconKey: 'Row',
  },
  {
    direction: 'column',
    tooltipKey: 'AutolayoutColumn',
    iconKey: 'Column',
  },
  {
    direction: 'wrap',
    tooltipKey: 'AutolayoutRowWrap',
    iconKey: 'Wrap',
  },
]
