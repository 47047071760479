import { Node, ReadOnlyNode } from 'application/node'
import { SizeEngineCalculator } from './types'
import { WriteDocument, ReadOnlyDocument } from 'application/document'
import { LayoutDependencyMode } from '../types'
import { BaseMap, isAbsolutePositionMode } from 'application/attributes'
import { truncate } from 'application/math'

type LayoutDelta = {
  x: number
  y: number
  w: number
  h: number
}

export class RatioSizeCalculator implements SizeEngineCalculator {
  update(
    node: Node,
    mode: LayoutDependencyMode,
    initialDocument: ReadOnlyDocument,
    document: WriteDocument
  ): void {
    const initial = initialDocument.getNode(node.getId())
    if (!initial) return

    const ratioMode = node.getStyleAttribute('size.ratio.mode')
    if (ratioMode !== 'fixed') return

    const ratio = node.getStyleAttribute('size.ratio')
    if (ratio === undefined) return

    const isParentZero = this.isParentDeltaZero(node, initialDocument, document)
    const isAuto = !isAbsolutePositionMode(node)
    if (isParentZero && !isAuto) return

    let width = node.getBaseAttribute('w')
    let height = node.getBaseAttribute('h')

    switch (mode) {
      case 'w':
      case 'wh':
        if (this.isHeightLocked(node)) {
          height = truncate(width / ratio, 2)
        }
        break
      case 'h':
        if (this.isWidthLocked(node)) {
          width = truncate(height * ratio, 2)
        }
        break
    }

    if (this.isWidthLocked(node)) {
      node.setBaseAttribute('w', width)
      node.setStyleAttribute('size.w', width)
    }
    if (this.isHeightLocked(node)) {
      node.setBaseAttribute('h', height)
      node.setStyleAttribute('size.h', height)
    }
  }

  private getDelta(a1: BaseMap, a2: BaseMap): LayoutDelta {
    return {
      x: a1['x'] - a2['x'],
      y: a1['y'] - a2['y'],
      w: a1['w'] - a2['w'],
      h: a1['h'] - a2['h'],
    }
  }

  private isParentDeltaZero(
    node: Node,
    initialDocument: ReadOnlyDocument,
    document: WriteDocument
  ): boolean {
    const parent = document.getParent(node)
    if (!parent) return false

    const parentInitial = initialDocument.getNode(parent.getId())
    if (!parentInitial) return false

    const parentAttributesInitial = parentInitial.getBaseAttributes()
    const parentAttributes = parent.getBaseAttributes()
    const parentDelta = this.getDelta(parentAttributes, parentAttributesInitial)

    return parentDelta.w === 0 && parentDelta.h === 0
  }

  private isWidthLocked(node: ReadOnlyNode): boolean {
    return (
      (node.getStyleAttribute('size.w.auto') === 'fixed' &&
        ['fill', 'percent'].includes(node.getStyleAttribute('size.h.auto'))) ||
      (node.getStyleAttribute('position.top.auto') !== 'none' &&
        node.getStyleAttribute('position.bottom.auto') !== 'none')
    )
  }

  private isHeightLocked(node: ReadOnlyNode): boolean {
    return (
      (node.getStyleAttribute('size.h.auto') === 'fixed' &&
        ['fill', 'percent'].includes(node.getStyleAttribute('size.w.auto'))) ||
      (node.getStyleAttribute('position.left.auto') !== 'none' &&
        node.getStyleAttribute('position.right.auto') !== 'none')
    )
  }
}
