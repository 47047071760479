import { DocumentRenderingService } from './document/render'
import { CanvasSizeService } from 'application/camera'
import { BrowserEventService } from 'application/browser'
import { EditorUI } from './ui/ui'
import { Canvas } from './canvas/canvas'
import { NodeExporter } from './exporter/exporter'

export class Editor {
  private canvasSizeService: CanvasSizeService
  private canvas: Canvas
  private exporter: NodeExporter
  private renderingService: DocumentRenderingService
  private browserEventService: BrowserEventService
  private ui: EditorUI

  constructor(
    canvasSizeStateService: CanvasSizeService,
    canvas: Canvas,
    exporter: NodeExporter,
    renderingService: DocumentRenderingService,
    browserEventService: BrowserEventService,
    ui: EditorUI
  ) {
    this.canvasSizeService = canvasSizeStateService
    this.canvas = canvas
    this.exporter = exporter
    this.renderingService = renderingService
    this.browserEventService = browserEventService
    this.ui = ui
  }

  init() {
    this.canvasSizeService.init()
    this.canvas.init()
    this.exporter.init()
    this.renderingService.init()
    this.browserEventService.init()
  }

  cleanup() {
    this.canvas.cleanup()
    this.browserEventService.cleanup()
    this.exporter.cleanup()
  }

  getUI = (): EditorUI => {
    return this.ui
  }
}
