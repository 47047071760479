import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import ExitPreviewButton from './ExitPreviewButton/ExitPreviewButton'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function RightTopbar() {
  const { settings } = usePanelHelper(editor.getUI().getPreviewPanel())
  if (!settings || settings.fullscreen) return <></>

  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        right: 8,
        zIndex: 2,
      }}
      width={'hug'}
      height={48}
      paddingH={10}
      direction="row"
    >
      <ExitPreviewButton />
    </PanelContainer>
  )
}
