import { useRef, useState } from 'react'
import ImageIcon from './ImageIcon/ImageIcon'
import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import ImagePopup from '../ImagePopup/ImagePopup'
import { Size } from 'application/shapes'
import { PLACEHOLDER_IMAGE } from 'application/attributes'

interface ImageBlockProps {
  src: 'Mixed' | string
  setSrc: (src: string, size: Size) => void
  clearMixed: () => void

  hasOverride?: boolean
}

export default function ImageInput({
  src,
  setSrc,
  clearMixed,
  hasOverride = false,
}: ImageBlockProps) {
  const blockRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [hover, setHover] = useState(false)

  const boxShadow = getBoxShadow(open || hover)
  const background = getBackground(open)

  const handleClick = () => {
    if (src === 'Mixed') {
      clearMixed()
      setOpen(true)
    } else {
      setOpen(!open)
    }
  }

  return (
    <>
      <div
        ref={blockRef}
        style={{
          width: 96,
          height: 24,
          flexShrink: 0,
          padding: '0px 8px 0px 4px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: 4,
          borderRadius: 4,
          userSelect: 'none',
          boxShadow: boxShadow,
          background: background,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
      >
        <ImageIcon src={getSrc(src)} />
        <Text color={DesignColor(hasOverride ? 'overrideParent' : 'text1')}>
          {src === 'Mixed' ? 'Mixed' : 'Image'}
        </Text>
      </div>
      {open && src !== 'Mixed' && (
        <ImagePopup
          src={src}
          setSrc={setSrc}
          close={() => setOpen(false)}
          exceptionRef={blockRef}
        />
      )}
    </>
  )
}

function getSrc(src: 'Mixed' | string): string {
  if (src === 'Mixed') return PLACEHOLDER_IMAGE
  return src
}

function getBoxShadow(active: boolean) {
  if (active) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}

function getBackground(open: boolean) {
  return open ? DesignColor('inputTint') : 'transparent'
}
