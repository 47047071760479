import Text from 'components/Library/Text/Text'
import useUploadImage from 'hooks/editor/useUploadImage'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'

interface FaviconRowProps {
  faviconUrl: string
  setFavicon: (url: string) => void
}

export default function FaviconRow({
  faviconUrl,
  setFavicon,
}: FaviconRowProps) {
  const upload = useUploadImage()

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered({ ref: containerRef })

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    upload(async (i) => setFavicon(i.url), e, undefined)
  }

  const url =
    faviconUrl ||
    'https://dev-repaint-public-assets.s3.us-east-2.amazonaws.com/images/Logo.svg'

  return (
    <div
      style={{
        width: '100%',
        padding: 8,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        gap: 16,
      }}
    >
      <div
        style={{
          width: 192,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'start',
          gap: 8,
        }}
      >
        <Text styles={{ weight: 'bold' }}>Favicon</Text>
        <Text maxWidth={192} wrap={true}>
          A favicon is the little icon on a browser tab. The recommended size is
          32 x 32.
        </Text>
      </div>
      <div
        ref={containerRef}
        style={{
          width: 224,
          height: 'fit-content',
          padding: 16,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: DesignColor('panelTint'),
          borderRadius: 4,
          boxShadow: hovered
            ? `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
            : 'none',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => inputRef.current?.click()}
      >
        <input
          type="file"
          accept=".png,.webp,.jpg,.jpeg,.gif,.svg,.avif"
          ref={inputRef}
          onChange={handleUploadImage}
          hidden
        />
        <img style={{ width: 32, height: 32 }} src={url} alt="favicon" />
      </div>
    </div>
  )
}
