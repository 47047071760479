import { AttributeFill, AttributeType, StyleMap } from 'application/attributes'

export class FillAttributeFactory {
  create = (type: AttributeType): Partial<StyleMap> => {
    switch (type) {
      case 'page':
      case 'frame':
        return { fills: [createWhite()] }
      case 'rectangle':
      case 'ellipse':
        return { fills: [createGrey()] }
    }
    return {}
  }
}

function createWhite(): AttributeFill {
  return {
    type: 'color',
    color: { r: 255, g: 255, b: 255, a: 1 },
  }
}

function createGrey(): AttributeFill {
  return {
    type: 'color',
    color: { r: 128, g: 128, b: 128, a: 1 },
  }
}
