import { Node } from 'application/node'
import { SizeEngineCalculator } from './types'
import { WriteDocument } from 'application/document'
import { LayoutDependencyMode } from '../types'
import {
  AutolayoutAttributes,
  getAutolayoutAttributes,
  isAbsolutePositionMode,
  isDynamicSizeAuto,
} from 'application/attributes'
import { ReadOnlyDocument } from 'application/document/types'
import { truncate } from 'application/math'
import { splitChildrenIntoRows } from '../utils'
import { minMaxSize } from './utils'

export class HugSizeCalculator implements SizeEngineCalculator {
  update(
    node: Node,
    mode: LayoutDependencyMode,
    _: ReadOnlyDocument,
    document: WriteDocument
  ): void {
    const autolayoutAttrs = getAutolayoutAttributes(node)
    if (!autolayoutAttrs) return

    let width = node.getBaseAttribute('w')
    let height = node.getBaseAttribute('h')

    if (
      ['w', 'wh'].includes(mode) &&
      node.getStyleAttribute('size.w.auto') === 'hug'
    ) {
      const children = this.getAutolayoutChildren('w', node, document)
      if (children.length === 0) {
        width = autolayoutAttrs.paddingLeft + autolayoutAttrs.paddingRight
      } else {
        switch (autolayoutAttrs.direction) {
          case 'row':
          case 'wrap':
            width = this.computeHugMainAxis(autolayoutAttrs, children)
            break
          case 'column':
            width = this.computeHugCrossAxis(autolayoutAttrs, children)
            break
        }
      }
    }

    if (
      ['h', 'wh'].includes(mode) &&
      node.getStyleAttribute('size.h.auto') === 'hug'
    ) {
      const children = this.getAutolayoutChildren('h', node, document)
      if (children.length === 0) {
        height = autolayoutAttrs.paddingTop + autolayoutAttrs.paddingBottom
      } else {
        switch (autolayoutAttrs.direction) {
          case 'row':
            height = this.computeHugCrossAxis(autolayoutAttrs, children)
            break
          case 'column':
            height = this.computeHugMainAxis(autolayoutAttrs, children)
            break
          case 'wrap':
            height = this.computeHugCrossAxisWrap(autolayoutAttrs, children)
            break
        }
      }
    }

    node.setBaseAttribute('w', minMaxSize(width, 'w', node))
    node.setBaseAttribute('h', minMaxSize(height, 'h', node))
  }

  private computeHugMainAxis = (
    attrs: AutolayoutAttributes,
    children: Node[]
  ): number => {
    const {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      direction,
      alignMain,
      gap,
    } = attrs

    const padding1 = direction !== 'column' ? paddingLeft : paddingTop
    const padding2 = direction !== 'column' ? paddingRight : paddingBottom
    const sizeKey = direction !== 'column' ? 'w' : 'h'
    const spacing = alignMain === 'spaced' ? 0 : gap
    const count = children.length

    const childrenSize = children.reduce((acc, child) => {
      return acc + (child.getBaseAttribute(sizeKey) || 0)
    }, 0)

    return truncate(
      childrenSize + padding1 + padding2 + spacing * (count - 1),
      2
    )
  }

  private computeHugCrossAxis = (
    attrs: AutolayoutAttributes,
    children: Node[]
  ): number => {
    const { paddingLeft, paddingRight, paddingTop, paddingBottom, direction } =
      attrs

    const padding1 = direction !== 'column' ? paddingTop : paddingLeft
    const padding2 = direction !== 'column' ? paddingBottom : paddingRight
    const sizeKey = direction !== 'column' ? 'h' : 'w'

    const max = children.reduce((acc, child) => {
      const size = child.getBaseAttribute(sizeKey) || 0
      return Math.max(acc, size)
    }, 0)

    return truncate(max + padding1 + padding2, 2)
  }

  private computeHugCrossAxisWrap = (
    attrs: AutolayoutAttributes,
    children: Node[]
  ): number => {
    const { paddingTop, paddingBottom, gap } = attrs

    const rows = splitChildrenIntoRows(attrs, children)
    let totalHeight = 0
    for (const row of rows) {
      const max = row.reduce((acc, child) => {
        return Math.max(acc, child.getBaseAttribute('h'))
      }, 0)
      totalHeight += max
    }

    return totalHeight + paddingTop + paddingBottom + (rows.length - 1) * gap
  }

  private getAutolayoutChildren(
    axis: 'w' | 'h',
    node: Node,
    document: WriteDocument
  ): Node[] {
    const children: Node[] = []
    for (const childId of node.getChildren() || []) {
      const child = document.getNode(childId)
      if (!child || child.getStyleAttribute('hidden')) continue
      if (isAbsolutePositionMode(child)) continue

      const sizeAuto = child.getStyleAttribute(`size.${axis}.auto`)
      if (isDynamicSizeAuto(sizeAuto)) continue

      children.push(child)
    }

    return children
  }
}
