import { AttributeType, MultiselectStyleMap } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

export type RoundingPanelSide =
  | 'topLeft'
  | 'topRight'
  | 'bottomRight'
  | 'bottomLeft'
  | 'all'

export type RoundingPanelMode = 'all' | 'custom'

type RoundingPanelKeys =
  | 'rounding.topLeft'
  | 'rounding.topRight'
  | 'rounding.bottomRight'
  | 'rounding.bottomLeft'

type RoundingPanelAttributes = Pick<
  MultiselectStyleMap,
  RoundingPanelKeys
> | null

export interface RoundingPanelState {
  attributes: RoundingPanelAttributes
  mode: RoundingPanelMode
}

export interface RoundingPanelHandlers {
  setRounding: (value: number, mode: RoundingPanelSide) => void
  slideRounding: (value: number, mode: RoundingPanelSide) => void
  clearCustom: () => void
}

export class RoundingPanel extends StyleAttributePanel<
  RoundingPanelState,
  RoundingPanelHandlers,
  RoundingPanelKeys
> {
  getSettings(): RoundingPanelState {
    return {
      attributes: this.attributes,
      mode: this.getMode(),
    }
  }

  getHandlers(): RoundingPanelHandlers {
    return {
      setRounding: this.setRounding,
      slideRounding: this.slideRounding,
      clearCustom: this.clearCustom,
    }
  }

  private getMode = (): RoundingPanelMode => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'all'

    for (const node of nodes) {
      const tl = node.getStyleAttribute('rounding.topLeft')
      const tr = node.getStyleAttribute('rounding.topRight')
      const br = node.getStyleAttribute('rounding.bottomRight')
      const bl = node.getStyleAttribute('rounding.bottomLeft')
      if (tl !== tr || tl !== br || tl !== bl) return 'custom'
    }

    return 'all'
  }

  private setRounding = (value: number, mode: RoundingPanelSide): void => {
    if (mode === 'all') {
      this.setMulti({
        'rounding.topLeft': value,
        'rounding.topRight': value,
        'rounding.bottomRight': value,
        'rounding.bottomLeft': value,
      })
    } else {
      this.setMulti({ [`rounding.${mode}`]: value })
    }
  }

  private slideRounding = (value: number, mode: RoundingPanelSide): void => {
    if (mode === 'all') {
      this.slideMulti('rounding.topLeft', value)
      this.slideMulti('rounding.topRight', value)
      this.slideMulti('rounding.bottomRight', value)
      this.slideMulti('rounding.bottomLeft', value)
    } else {
      this.slideMulti(`rounding.${mode}`, value)
    }
  }

  private clearCustom = (): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    let maxRounding = 0
    for (const node of nodes) {
      const tl = node.getStyleAttribute('rounding.topLeft') || 0
      const tr = node.getStyleAttribute('rounding.topRight') || 0
      const br = node.getStyleAttribute('rounding.bottomRight') || 0
      const bl = node.getStyleAttribute('rounding.bottomLeft') || 0
      maxRounding = Math.max(maxRounding, tl, tr, br, bl)
    }

    this.setMulti({
      'rounding.topLeft': maxRounding,
      'rounding.topRight': maxRounding,
      'rounding.bottomRight': maxRounding,
      'rounding.bottomLeft': maxRounding,
    })
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 1_000
  }
}

const allowedTypes: AttributeType[] = ['frame', 'rectangle', 'image']
