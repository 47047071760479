import { DesignColor, getActiveColorTheme } from 'themes'
import RepaintLogoAnimated from 'components/Library/Components/Logo/RepaintLogoAnimated/RepaintLogoAnimated'

export default function LoadingTitle() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 12,
      }}
    >
      <RepaintLogoAnimated
        size={120}
        color={getActiveColorTheme() === 'dark' ? 'white' : 'black'}
      />
      <p
        className={'.loading_screen_text'}
        style={{
          fontSize: 46,
          fontFamily: 'Poppins',
          fontWeight: 500,
          color: DesignColor('text1'),
        }}
      >
        Repaint
      </p>
    </div>
  )
}
