import {
  BaseMap,
  StyleMap,
  isAbsolutePositionMode,
} from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'
import { truncate } from 'application/math'
import { Node, ReadOnlyNode } from 'application/node'

type SizeMap = { [key: string]: number }

export function computeDynamicSize(
  node: ReadOnlyNode,
  axis: 'w' | 'h',
  document: ReadOnlyDocument
): number {
  const unitKey: keyof BaseMap = axis
  const percentKey: keyof StyleMap = `size.${axis}.percent`
  const minAllowedKey: keyof StyleMap = `size.${axis}.min.mode`
  const maxAllowedKey: keyof StyleMap = `size.${axis}.max.mode`
  const minKey: keyof StyleMap = `size.${axis}.min`
  const maxKey: keyof StyleMap = `size.${axis}.max`
  const autoKey: keyof StyleMap = `size.${axis}.auto`

  const parent = document.getParent(node)
  if (!parent) return 0

  const parentSpace = computeAvailableSpace(parent, axis, document)
  if (!parentSpace) return 0

  const totalPercents = computeTotalPercents(parent, axis, document)
  const autoChildren = getAutolayoutChildren(parent, document)
  const percentChildren = autoChildren.filter(
    (c) => c.getStyleAttribute(autoKey) === 'percent'
  )
  const fillChildren = autoChildren.filter(
    (c) => c.getStyleAttribute(autoKey) === 'fill'
  )

  const sizes: SizeMap = {}
  const minSizes: SizeMap = {}
  const done: string[] = []

  let fillMinSum = 0
  let percentMinSum = 0
  let percentMaxSum = 0

  for (const child of autoChildren) {
    const id = child.getId()
    const percent = child.getStyleAttribute(percentKey) || 0
    const size = child.getBaseAttribute(unitKey) || 0
    const minAllowed = child.getStyleAttribute(minAllowedKey) === 'fixed'
    const maxAllowed = child.getStyleAttribute(maxAllowedKey) === 'fixed'
    const min = minAllowed
      ? child.getStyleAttribute(minKey) || -Infinity
      : -Infinity
    const max = maxAllowed
      ? child.getStyleAttribute(maxKey) || Infinity
      : Infinity

    switch (child.getStyleAttribute(autoKey)) {
      case 'fill':
        fillMinSum += min
        sizes[id] = 0
        break
      case 'percent':
        percentMinSum += min
        percentMaxSum += Math.min((percent / 100) * parentSpace, max)
        sizes[id] = 0
        break
      default:
        sizes[id] = size
        done.push(id)
    }

    minSizes[id] = truncate(Math.min(min, max), 2)
  }

  const spaceMinusFixed = computeRemaining(parentSpace, sizes)
  for (const child of fillChildren) {
    const id = child.getId()
    const maxAllowed = child.getStyleAttribute(maxAllowedKey) === 'fixed'
    const max = maxAllowed
      ? child.getStyleAttribute(maxKey) || Infinity
      : Infinity
    const percent = Math.max(percentMaxSum, percentMinSum)
    const allocation = (spaceMinusFixed - percent) / fillChildren.length

    if (allocation <= minSizes[id]) {
      sizes[id] = minSizes[id]
      done.push(id)
    } else if (allocation > max) {
      sizes[id] = max
      done.push(id)
    } else if (percent + fillMinSum > spaceMinusFixed) {
      sizes[id] = 0
      done.push(id)
    }
  }

  const spaceMinusFillFixed = computeRemaining(parentSpace, sizes)
  for (const child of percentChildren) {
    const id = child.getId()
    const maxAllowed = child.getStyleAttribute(maxAllowedKey) === 'fixed'
    const max = maxAllowed
      ? child.getStyleAttribute(maxKey) || Infinity
      : Infinity
    const percent = child.getStyleAttribute(percentKey) || 0

    const space = spaceMinusFillFixed
    const totalRatio = percent / Math.max(totalPercents, 100)
    const target = Math.min(totalRatio * parentSpace, max)

    const remainingRatio = percent / totalPercents
    const allocation = remainingRatio * space

    if (target < minSizes[id] || allocation < minSizes[id]) {
      sizes[id] = minSizes[id]
      done.push(id)
    } else if (allocation > target) {
      sizes[id] = target
      done.push(id)
    } else {
      sizes[id] = 0
    }
  }

  const remaining = computeRemaining(parentSpace, sizes)
  const filteredPercentChildren = percentChildren.filter(
    (c) => !done.includes(c.getId())
  )
  const totalPercentRemaining = filteredPercentChildren.reduce(
    (acc, c) => acc + (c.getStyleAttribute(percentKey) || 0),
    0
  )
  for (const child of filteredPercentChildren) {
    const id = child.getId()
    const percent = child.getStyleAttribute(percentKey) || 0
    const target = parentSpace * (percent / 100)

    if (
      target > remaining ||
      (totalPercentRemaining / 100) * parentSpace > remaining
    ) {
      sizes[id] = remaining * (percent / totalPercentRemaining)
    } else {
      sizes[id] = target
    }
  }

  const available = computeRemaining(parentSpace, sizes)
  const filteredFillChildren = fillChildren.filter(
    (c) => !done.includes(c.getId())
  )
  for (const child of filteredFillChildren) {
    const id = child.getId()
    sizes[id] = available / filteredFillChildren.length
  }

  return truncate(sizes[node.getId()], 2)
}

export function computeAvailableSpace(
  node: ReadOnlyNode,
  mode: 'w' | 'h',
  document: ReadOnlyDocument
): number | null {
  const children = node.getChildren()
  if (!children) return null

  const padding1Key = mode === 'w' ? 'padding.left' : 'padding.top'
  const padding2Key = mode === 'w' ? 'padding.right' : 'padding.bottom'

  const direction = node.getStyleAttribute('autolayout.direction')
  const counter = node.getStyleAttribute('autolayout.align.counter')
  const padding1 = node.getStyleAttribute(padding1Key) || 0
  const padding2 = node.getStyleAttribute(padding2Key) || 0
  const size = node.getBaseAttribute(mode)
  const gap = node.getStyleAttribute('autolayout.gap') || 0

  let availableSpace = size - padding1 - padding2

  if (
    mode === 'w' &&
    direction === 'row' &&
    children.length > 0 &&
    counter !== 'spaced' &&
    node.getStyleAttribute('size.w.auto') !== 'hug'
  ) {
    const autoChildren = getAutolayoutChildren(node, document)
    availableSpace -= gap * (autoChildren.length - 1)
  }

  if (
    mode === 'h' &&
    direction === 'column' &&
    children.length > 0 &&
    counter !== 'spaced' &&
    node.getStyleAttribute('size.h.auto') !== 'hug'
  ) {
    const autoChildren = getAutolayoutChildren(node, document)
    availableSpace -= gap * (autoChildren.length - 1)
  }

  return availableSpace
}

function computeTotalPercents(
  node: ReadOnlyNode,
  mode: 'w' | 'h',
  document: ReadOnlyDocument
): number {
  const children = node.getChildren()
  if (!children) return 0

  let total = 0
  for (const childId of children) {
    const child = document.getNode(childId)
    if (!child) continue
    if (child.getStyleAttribute(`size.${mode}.auto`) === 'percent') {
      total += child.getStyleAttribute(`size.${mode}.percent`) || 0
    }
  }

  return total
}

function getAutolayoutChildren(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): Node[] {
  const children = node.getChildren()
  if (!children) return []

  return children
    .map((c) => document.getNode(c))
    .filter(
      (c) =>
        c &&
        !isAbsolutePositionMode(c) &&
        c.getStyleAttribute('hidden') !== true
    ) as Node[]
}

function computeRemaining(total: number, sizes: SizeMap): number {
  return total - Object.values(sizes).reduce((a, b) => a + b, 0)
}

export function minMaxSize(size: number, axis: 'w' | 'h', node: Node): number {
  const maxMode = node.getStyleAttribute(`size.${axis}.max.mode`)
  const max = node.getStyleAttribute(`size.${axis}.max`) || Infinity
  const allowedMax = maxMode === 'fixed' ? max : Infinity

  const minMode = node.getStyleAttribute(`size.${axis}.min.mode`)
  const min = node.getStyleAttribute(`size.${axis}.min`) || -Infinity
  const allowedMin = minMode === 'fixed' ? min : -Infinity

  return Math.min(Math.max(size, allowedMin), allowedMax)
}
