export type panelRowWidth = 232 | 400 | 'fill'
export type panelRowHeight = 32 | 64 | 72 | 80 | 'hug'
export type panelRowPadding = 0 | 4 | 8 | 16 | 24

interface PanelRowProps {
  rowRef?: React.RefObject<HTMLDivElement>

  width?: panelRowWidth
  height?: panelRowHeight

  align?: 'start' | 'center' | 'end'

  paddingV?: panelRowPadding
  paddingH?: panelRowPadding
  paddingL?: panelRowPadding
  paddingR?: panelRowPadding
  background?: string

  children?: React.ReactNode

  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onMouseUp?: () => void
}

export default function PanelRow({
  rowRef,
  width = 232,
  height = 32,
  paddingV = 4,
  paddingH = 8,
  paddingL = undefined,
  paddingR = undefined,
  background = 'transparent',
  align = 'center',
  children,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
}: PanelRowProps) {
  return (
    <div
      ref={rowRef}
      style={{
        width: width === 'fill' ? '100%' : width,
        height: height === 'hug' ? 'auto' : height,
        padding: getPadding(paddingH, paddingV, paddingL, paddingR),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: align,
        justifyContent: 'start',
        background,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
    >
      {children}
    </div>
  )
}

function getPadding(
  paddingH?: panelRowPadding,
  paddingV?: panelRowPadding,
  paddingL?: panelRowPadding,
  paddingR?: panelRowPadding
): string {
  if (paddingL !== undefined && paddingR !== undefined) {
    return `${paddingV}px ${paddingR}px ${paddingV}px ${paddingL}px`
  } else if (paddingH !== undefined && paddingV !== undefined) {
    return `${paddingV}px ${paddingH}px`
  } else {
    return '0px'
  }
}
