import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useEffect, useRef } from 'react'

export default function PreviewIFrame() {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPreviewPanel()
  )
  const { setOpen } = handlers

  useEffect(() => {
    function handleIframeMessage(event: MessageEvent) {
      if (event.data === 'disablePreviewExit') {
        setOpen(false)
        window.focus()
      }
    }

    window.addEventListener('message', handleIframeMessage)
    return () => {
      window.removeEventListener('message', handleIframeMessage)
    }
  }, [iframeRef, setOpen])

  if (!settings) return <></>

  return (
    <iframe
      ref={iframeRef}
      id={'preview-iframe-container'}
      src={settings.link}
      title="Preview"
      style={{
        gridArea: 'content',
        overflowY: 'auto',
        width: `100%`,
        height: `100%`,
        boxSizing: 'border-box',
        border: 'none',
      }}
    />
  )
}
