import { CameraService } from 'application/camera'
import { ActionHandler } from '../types'
import { HandAction } from './action'

export class HandActionFactory {
  private cameraService: CameraService

  constructor(cameraService: CameraService) {
    this.cameraService = cameraService
  }

  create = (mode: 'wheel' | 'mouse' | 'key'): ActionHandler => {
    return new HandAction(this.cameraService, mode)
  }
}
