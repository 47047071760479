import { Resizable } from 're-resizable'
import { panelBorderBottom } from '../constants'

interface ResizePanelSectionProps {
  children?: React.ReactNode
  minHeight?: number
  maxHeight?: number
  canResize?: boolean
  zIndex?: number

  height: number
  setHeight: (height: number) => void
}

export default function ResizePanelSection({
  children,
  minHeight = 80,
  maxHeight = 400,
  canResize = true,
  zIndex = 1,
  height,
  setHeight,
}: ResizePanelSectionProps) {
  return (
    <ResizeableContainer
      key={height}
      height={height}
      setHeight={setHeight}
      minHeight={minHeight}
      maxHeight={maxHeight}
      canResize={canResize}
      zIndex={zIndex}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          padding: canResize ? '8px 0px 0px 0px' : '8px 0px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          boxShadow: panelBorderBottom,
        }}
      >
        {children}
      </div>
    </ResizeableContainer>
  )
}

function ResizeableContainer({
  height,
  setHeight,
  minHeight,
  maxHeight,
  canResize,
  zIndex,
  children,
}: {
  height: number
  setHeight: (height: number) => void
  minHeight: number
  maxHeight: number
  canResize: boolean
  zIndex: number
  children?: React.ReactNode
}) {
  return (
    <Resizable
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        borderRadius: 4,
        pointerEvents: 'auto',
        zIndex: zIndex,
      }}
      defaultSize={{
        width: '100%',
        height: height,
      }}
      onResizeStop={(_, __, ___, d) => {
        setHeight(height + d.height)
      }}
      minHeight={minHeight}
      maxHeight={canResize ? maxHeight : minHeight}
      handleClasses={{
        bottom: canResize ? 'resize-handle' : 'resize-handle-none',
      }}
      handleStyles={{
        top: { display: 'none' },
        right: { display: 'none' },
        bottom: canResize
          ? {
              height: '10px',
              width: '100%',
              position: 'absolute',
              cursor: 'row-resize',
            }
          : {
              display: 'none',
            },
        left: { display: 'none' },
      }}
    >
      {children}
    </Resizable>
  )
}
