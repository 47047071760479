import { AttributeImageResize } from 'application/attributes'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import ImageInput from 'components/Library/Components/Inputs/ImageInput/ImageInput'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function ImageSettings() {
  const { settings, handlers } = usePanelHelper(editor.getUI().getImagePanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || !settings.attributes) return <></>

  const { 'image.src': src, 'image.resize': resize } = settings.attributes
  if (!src || !resize) return <></>

  const { setResize, setSrc, clearMixed, resetSize } = handlers
  const { hasOverride } = overrides.handlers

  return (
    <PanelSection>
      <PanelRow>
        <TitleButtonBlock title="Image" active={true} />
      </PanelRow>
      <PanelRow>
        <OverrideReset
          keys={['image.src', 'image.originalSize.w', 'image.originalSize.h']}
        >
          <ImageInput
            src={src}
            setSrc={setSrc}
            clearMixed={clearMixed}
            hasOverride={hasOverride([
              'image.src',
              'image.originalSize.w',
              'image.originalSize.h',
            ])}
          />
        </OverrideReset>
        <OverrideReset keys={['image.resize']}>
          <Dropdown
            width={96}
            tooltipKey="ImageSizeMode"
            selected={resize === 'Mixed' ? null : resize}
            mixed={resize === 'Mixed'}
            select={setResize}
            options={resizeModeOptions}
            commit={true}
            hasOverride={hasOverride(['image.resize'])}
          />
        </OverrideReset>
        <IconBlock
          icon="Refresh"
          tooltipKey="ImageToOriginalSize"
          tooltipDirection="left"
          onClick={resetSize}
        />
      </PanelRow>
    </PanelSection>
  )
}

const resizeModeOptions: dropdownOption<AttributeImageResize>[] = [
  {
    value: 'fill',
    text: 'Fill',
  },
  {
    value: 'fit',
    text: 'Fit',
  },
  {
    value: 'stretch',
    text: 'Stretch',
  },
]
