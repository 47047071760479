import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function ClipBlock() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getClipContentPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || !settings.attributes) return <PanelBlock />

  const { clip } = settings.attributes
  const { setClip } = handlers
  const { hasOverride } = overrides.handlers

  const handleToggle = () => {
    clip === 'Mixed' ? setClip(true) : setClip(!clip)
  }

  return (
    <OverrideReset keys={['clip']}>
      <ButtonBlock
        icon={getClipIcon(clip || false)}
        tooltipKey="ClipContent"
        onClick={handleToggle}
        text={clip === 'Mixed' ? 'Mixed' : 'Clip'}
        hasOverride={hasOverride(['clip'])}
      />
    </OverrideReset>
  )
}

function getClipIcon(clip: 'Mixed' | boolean) {
  return clip ? 'CheckboxChecked' : 'CheckboxUnchecked'
}
