import OnboardingCta from 'components/Library/Onboarding/OnboardingCta'
import OnboardingDivider from 'components/Library/Onboarding/OnboardingDivider'
import OnboardingInputField from 'components/Library/Onboarding/OnboardingInputField'
import OnboardingInputWrapper from 'components/Library/Onboarding/OnboardingInputWrapper'
import OnboardingLogo from 'components/Library/Onboarding/OnboardingLogo'
import OnboardingPanel from 'components/Library/Onboarding/OnboardingPanel'
import OnboardingRedirectBlock from 'components/Library/Onboarding/OnboardingRedirectBlock'
import OnboardingTitle from 'components/Library/Onboarding/OnboardingTitle'
import OnboardingBackground from 'components/Library/Onboarding/OnboardingBackground'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import useRedirectLoggedIn from 'hooks/admin/useRedirectLoggedIn'
import useUserService from 'hooks/services/useUserService'
import { useCallback, useEffect, useState } from 'react'
import useToast from 'hooks/editor/useToast'

export default function Reset() {
  useRedirectLoggedIn()

  const addToast = useToast()
  const userService = useUserService()
  const { toSignUp } = useNavigatePage()

  const [email, setEmail] = useState('')

  const submitHandler = useCallback(async () => {
    if (!email) {
      addToast(`Please fill in all fields.`, 'error')
      return
    }
    try {
      await userService.reset({ email })
      addToast(`Password reset email sent to: ${email}`, 'success')
    } catch (e) {
      addToast('Repaint is offline, please try again later.', 'error')
    }
  }, [email, userService, addToast])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submitHandler()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [email, submitHandler])

  return (
    <OnboardingBackground>
      <OnboardingLogo />
      <OnboardingPanel>
        <OnboardingTitle>Reset Password</OnboardingTitle>
        <OnboardingInputWrapper>
          <OnboardingInputField
            icon="User"
            label="Email"
            placeholder="Email"
            value={email}
            setValue={setEmail}
          />
        </OnboardingInputWrapper>
        <OnboardingCta onClick={submitHandler}>Reset</OnboardingCta>
        <OnboardingDivider />
        <OnboardingRedirectBlock
          text="Don't have an account?"
          buttonLabel="Signup"
          onClick={toSignUp}
        />
      </OnboardingPanel>
    </OnboardingBackground>
  )
}
