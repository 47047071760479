import { LayoutEngine } from './engine'
import { LayoutEngineCalculator, LayoutEngineListener } from './types'
import { PositionLayoutEngine } from './position/position'
import { PositionDependencyGraphFactory } from './dependency/position'
import { AutolayoutPositionCalculator } from './position/autolayout'
import { SizeDependencyGraphFactory } from './dependency/size'
import { DynamicSizeCalculator } from './size/dynamic'
import { SizeLayoutEngine } from './size/size'
import { HugSizeCalculator } from './size/hug'
import { FixedSizeCalculator } from './size/fixed'
import { RatioSizeCalculator } from './size/ratio'
import { BoundingBoxCalculator } from './position/bounds'
import { TextSizeCalculator } from './size/text'
import { Shaper } from 'application/text'
import { AbsolutePositionCalculator } from './position/absolute'
import { AbsoluteSizeCalculator } from './size/absolute'
import { RenderBoxCalculator } from './position/render'

export class LayoutEngineFactory {
  create(listener: LayoutEngineListener, shaper: Shaper) {
    const sizeCalculator = this.createSizeCalculator(shaper)
    const positionCalculator = this.createPositionCalculator()

    return new LayoutEngine([sizeCalculator, positionCalculator], listener)
  }

  private createPositionCalculator(): LayoutEngineCalculator {
    const positionDependencyGraphFactory = new PositionDependencyGraphFactory()

    const autolayoutCalculator = new AutolayoutPositionCalculator()
    const absoluteCalculator = new AbsolutePositionCalculator()
    const boundingBoxCalculator = new BoundingBoxCalculator()
    const renderBoxCalculator = new RenderBoxCalculator()

    const positionCalculator = new PositionLayoutEngine(
      positionDependencyGraphFactory,
      [
        absoluteCalculator,
        autolayoutCalculator,
        boundingBoxCalculator,
        renderBoxCalculator,
      ]
    )

    return positionCalculator
  }

  private createSizeCalculator(shaper: Shaper): LayoutEngineCalculator {
    const sizeDependencyGraphFactory = new SizeDependencyGraphFactory()

    const hugSizeCalculator = new HugSizeCalculator()
    const constraintSizeCalculator = new AbsoluteSizeCalculator()
    const dynamicSizeCalculator = new DynamicSizeCalculator()
    const ratioSizeCalculator = new RatioSizeCalculator()
    const textCalculator = new TextSizeCalculator(shaper)
    const fixedSizeCalculator = new FixedSizeCalculator()

    const sizeCalculator = new SizeLayoutEngine(sizeDependencyGraphFactory, [
      dynamicSizeCalculator,
      hugSizeCalculator,
      constraintSizeCalculator,
      ratioSizeCalculator,
      textCalculator,
      fixedSizeCalculator,
    ])

    return sizeCalculator
  }
}
