import { DesignColor } from 'themes'
import { panelShadow } from '../Containers/constants'
import styled from 'styled-components'

interface OnboardingContainerProps {
  children?: React.ReactNode
}

export default function OnboardingPanel({
  children,
}: OnboardingContainerProps) {
  return (
    <ResponsivePanel
      style={{
        padding: 32,
        position: 'absolute',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        gap: 32,
        background: DesignColor('panelBackground'),
        boxShadow: panelShadow,
      }}
    >
      {children}
    </ResponsivePanel>
  )
}

const ResponsivePanel = styled.div`
  width: 400px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 450px) {
    width: calc(100% - 48px);
    left: 24px;
    top: 102px;
    transform: none;
  }
`
