import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import IconSetBlock from 'components/Library/Containers/IconSetBlock/IconSetBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import {
  AlignmentPanelAxis,
  AlignmentPanelDirection,
} from 'editor/ui/alignmentPanel'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function AlignmentSettings() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getAlignmentPanel()
  )
  if (!settings) return <></>

  const { alignEnabled, distributeEnabled, distributeMode } = settings
  const { align, distribute } = handlers

  if (!alignEnabled && !distributeEnabled) return <></>

  return (
    <PanelSection>
      <PanelRow>
        <IconSetBlock>
          {horizontalModes.map((mode) => (
            <IconBlock
              key={mode.mode}
              icon={mode.icon}
              tooltipKey={mode.tooltipKey}
              onClick={() => align('h', mode.mode)}
              disabled={!alignEnabled}
            />
          ))}
        </IconSetBlock>
        <IconSetBlock>
          {verticalModes.map((mode) => (
            <IconBlock
              key={mode.mode}
              icon={mode.icon}
              tooltipKey={mode.tooltipKey}
              onClick={() => align('v', mode.mode)}
              disabled={!alignEnabled}
            />
          ))}
        </IconSetBlock>
        <IconBlock
          icon={distributeToIcon(distributeMode)}
          tooltipKey={distributeToTooltip(distributeMode)}
          tooltipDirection="left"
          onClick={() => distribute(distributeMode)}
          disabled={!distributeEnabled}
        />
      </PanelRow>
    </PanelSection>
  )
}

const horizontalModes: {
  mode: AlignmentPanelDirection
  icon: IconKey
  tooltipKey: tooltipKey
}[] = [
  { mode: 'start', icon: 'AlignLeft', tooltipKey: 'AlignLeft' },
  { mode: 'center', icon: 'AlignCenterH', tooltipKey: 'AlignCenter' },
  { mode: 'end', icon: 'AlignRight', tooltipKey: 'AlignRight' },
]

const verticalModes: {
  mode: AlignmentPanelDirection
  icon: IconKey
  tooltipKey: tooltipKey
}[] = [
  { mode: 'start', icon: 'AlignTop', tooltipKey: 'AlignTop' },
  { mode: 'center', icon: 'AlignCenterV', tooltipKey: 'AlignMiddle' },
  { mode: 'end', icon: 'AlignBottom', tooltipKey: 'AlignBottom' },
]

function distributeToIcon(mode: AlignmentPanelAxis): IconKey {
  switch (mode) {
    case 'h':
      return 'DistributeH'
    case 'v':
      return 'DistributeV'
  }
}

function distributeToTooltip(mode: AlignmentPanelAxis): tooltipKey {
  switch (mode) {
    case 'h':
      return 'DistributeHorizontal'
    case 'v':
      return 'DistributeVertical'
  }
}
