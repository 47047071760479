import { AttributePositionMode } from 'application/attributes'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function PositionModeRow() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPositionModePanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || !settings.attributes || !handlers) return null

  const { setAuto } = handlers
  const { attributes, warning, disabled } = settings
  const { [`position.mode`]: mode } = attributes
  const { hasOverride } = overrides.handlers

  if (disabled) return <></>

  return (
    <PanelRow>
      <OverrideReset keys={['position.mode']}>
        <Dropdown
          width={96}
          mixed={mode === 'Mixed'}
          tooltipKey={'PositionMode'}
          warning={warning}
          selected={mode}
          options={dropdownOptions}
          select={(o) => {
            if (o === 'Mixed') return
            setAuto(o)
          }}
          mixedIcon={'PositionAuto'}
          hasIcon={true}
          commit={true}
          hasOverride={hasOverride(['position.mode'])}
        />
      </OverrideReset>
    </PanelRow>
  )
}

const dropdownOptions: dropdownOption<AttributePositionMode>[] = [
  {
    value: 'auto',
    text: 'Auto',
    icon: 'PositionAuto',
  },
  {
    value: 'absolute',
    text: 'Absolute',
    icon: 'PositionAbsolute',
  },
  {
    value: 'fixed',
    text: 'Fixed',
    icon: 'PositionFixed',
  },
  {
    value: 'sticky',
    text: 'Sticky',
    icon: 'PositionSticky',
  },
]
