import { Window } from './types'
import { Camera, CameraUpdateListener } from 'application/camera'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { Rectangle } from 'application/shapes'
import { DesignColor } from 'themes'
import { ReadOnlyNode } from 'application/node'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'
import { WindowTransformer } from './transformer/window'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { computeSelectionRectangle } from 'application/selection'
import { WindowSizeDisplayTransformer } from './transformer/windowSizeDisplay'
import { FontLoaderInterface } from 'application/text'

export const hapticSelectionWindowKey = 'selectionWindow'
export const hapticSelectionWindowBubblesKey = 'selectionWindowBubbles'
export const hapticSelectionWindowNodesKey = 'selectionWindowNodes'
export const hapticSelectionWindowTextKey = 'selectionWindowText'

export class HapticSelectionWindow
  implements
    ClientUpdateListener,
    CameraUpdateListener,
    ActiveActionListener,
    CanvasUpdateListener
{
  private document: ReadOnlyDocument
  private fontLoader: FontLoaderInterface
  private canvas: Canvas

  private ids: string[]
  private actionType: Action | null
  private editingText: boolean

  private window: Window | null
  private nodeWindows: Window[]
  private camera: Camera | null

  constructor(
    document: ReadOnlyDocument,
    fontLoader: FontLoaderInterface,
    canvas: Canvas
  ) {
    this.document = document
    this.fontLoader = fontLoader
    this.canvas = canvas

    this.ids = []
    this.actionType = null
    this.editingText = false

    this.window = null
    this.nodeWindows = []
    this.camera = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.window = this.computeWindow(this.ids)
    this.nodeWindows = this.computeNodeWindows(this.ids)
    this.render()
  }

  onActiveAction = (handler: ActionHandler | null): void => {
    this.actionType = handler ? handler.getType() : null
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  getTypes = (): Update['type'][] => {
    return ['selection', 'node_updated', 'edit_text']
  }

  onUpdate = (updates: Update[]) => {
    for (const update of updates) {
      switch (update.type) {
        case 'selection':
          this.updateSelection(update.data.ids)
          break
        case 'node_updated':
          this.updateNode(update.data.id)
          break
        case 'edit_text':
          this.updateText(update.data.id)
          break
      }
    }
  }

  private updateSelection = (ids: string[]) => {
    this.ids = ids
    this.window = this.computeWindow(ids)
    this.nodeWindows = this.computeNodeWindows(ids)
    this.render()
  }

  private updateNode = (id: string) => {
    if (this.ids.includes(id)) {
      this.window = this.computeWindow(this.ids)
      this.nodeWindows = this.computeNodeWindows(this.ids)
      this.render()
    }
  }

  private updateText = (id: string | null) => {
    this.editingText = id !== null
    this.window = this.computeWindow(this.ids)
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (
      this.window === null ||
      this.camera === null ||
      this.deactivatedAction()
    ) {
      this.canvas.deleteHaptic(hapticSelectionWindowKey)
      this.canvas.deleteHaptic(hapticSelectionWindowNodesKey)
      this.canvas.deleteHaptic(hapticSelectionWindowTextKey)
    } else {
      this.canvas.setHaptic(
        hapticSelectionWindowKey,
        WindowTransformer.transform(
          this.canvas.getContext(),
          [this.window],
          this.camera
        )
      )
      this.canvas.setHaptic(
        hapticSelectionWindowNodesKey,
        WindowTransformer.transform(
          this.canvas.getContext(),
          this.nodeWindows,
          this.camera
        )
      )
      this.canvas.setHaptic(
        hapticSelectionWindowTextKey,
        WindowSizeDisplayTransformer.transform(
          this.canvas.getContext(),
          this.fontLoader,
          this.window.rectangle,
          this.camera
        )
      )
    }
  }

  private computeWindow = (ids: string[]): Window | null => {
    if (ids.length === 0) return null
    return {
      rectangle: this.computeRectangle(ids),
      border: DesignColor('inputHighlight'),
      fill: null,
      borderWidth: 1.5,
      bubbles: !this.editingText,
      shape: 'rectangle',
    }
  }

  private computeNodeWindows = (ids: string[]): Window[] => {
    if (ids.length === 0) return []
    const windows: Window[] = []
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue
      windows.push({
        rectangle: {
          x: node.getBaseAttribute('x'),
          y: node.getBaseAttribute('y'),
          w: node.getBaseAttribute('w'),
          h: node.getBaseAttribute('h'),
        },
        border: DesignColor('inputHighlight'),
        fill: null,
        borderWidth: 1.5,
        bubbles: false,
        shape: 'rectangle',
      })
    }
    return windows
  }

  private computeRectangle = (ids: string[]): Rectangle => {
    const nodes = ids
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
    const selectionRectangle = computeSelectionRectangle(nodes)
    if (!selectionRectangle) return { x: 0, y: 0, w: 0, h: 0 }

    const widthBelowMin = selectionRectangle.w < 1
    const heightBelowMin = selectionRectangle.h < 1
    const xOffset = widthBelowMin ? 0.5 / (this.camera?.z || 1) : 0
    const yOffset = heightBelowMin ? 0.5 / (this.camera?.z || 1) : 0
    return {
      x: selectionRectangle.x - xOffset,
      y: selectionRectangle.y - yOffset,
      w: selectionRectangle.w + (widthBelowMin ? xOffset * 2 : 0),
      h: selectionRectangle.h + (heightBelowMin ? yOffset * 2 : 0),
    }
  }

  private deactivatedAction = (): boolean => {
    switch (this.actionType) {
      case 'move':
      case 'editInput':
        return true
      default:
        return false
    }
  }
}
