const publicURL = process.env.PUBLIC_URL

export const Cursors = {
  DrawPage: `url(${publicURL}/cursors/DrawPage.svg) 5 19, auto`,
  DrawFrame: `url(${publicURL}/cursors/DrawFrame.svg) 5 19, auto`,
  DrawText: `url(${publicURL}/cursors/DrawText.svg) 5 19, auto`,
  DrawImage: `url(${publicURL}/cursors/DrawImage.svg) 5 19, auto`,
  DrawInput: `url(${publicURL}/cursors/DrawInput.svg) 5 19, auto`,
  DrawIcon: `url(${publicURL}/cursors/DrawIcon.svg) 5 19, auto`,
  DrawRectangle: `url(${publicURL}/cursors/DrawRectangle.svg) 5 19, auto`,
  DrawEllipse: `url(${publicURL}/cursors/DrawEllipse.svg) 5 19, auto`,
  Duplicate: `url(${publicURL}/cursors/Duplicate.svg) 5 19, auto`,
  Target: `url(${publicURL}/cursors/Target.svg) 10 10, auto`,
  HandOpen: `url(${publicURL}/cursors/HandOpen.svg) 10 12, auto`,
  HandClosed: `url(${publicURL}/cursors/HandClosed.svg) 10 12, auto`,
  Eyedropper: `url(${publicURL}/cursors/Eyedropper.svg) 3.5 20, auto`,
}
