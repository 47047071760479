import { DesignColor } from 'themes'
import { Resizable } from 're-resizable'
import styled from 'styled-components'
import PreviewIFrame from './PreviewIFrame/PreviewIFrame'
import { useEffect } from 'react'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function PreviewContent() {
  const { settings } = usePanelHelper(editor.getUI().getPreviewPanel())
  if (!settings || !settings.open) return <></>

  return (
    <ResizablePreviewContainer>
      <PreviewIFrame />
    </ResizablePreviewContainer>
  )
}

function ResizablePreviewContainer(props: { children: React.ReactNode }) {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPreviewPanel()
  )
  const { setSize } = handlers

  useEffect(() => {
    const handleResize = () => {
      if (!settings?.fullscreen) return
      setSize(window.innerWidth, window.innerHeight)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setSize, settings?.fullscreen])

  if (!settings) return <></>

  const { fullscreen, width, height, zoom } = settings
  const top = settings.fullscreen ? 0 : 'calc(50% + 32px)'
  const left = settings.fullscreen ? 0 : '50%'
  const transform = settings.fullscreen
    ? ''
    : `translate(-50%, -50%) scale(${zoom})`

  return (
    <Resizable
      style={{
        position: 'absolute',
        top: top,
        left: left,
        pointerEvents: 'auto',
        transform: transform,
      }}
      size={{
        width: fullscreen ? window.innerWidth : width,
        height: fullscreen ? window.innerHeight : height,
      }}
      onResize={async (event, direction, ref) => {
        if (fullscreen) return false
        setSize(ref.offsetWidth, ref.offsetHeight)
      }}
      resizeRatio={2 / zoom}
      minWidth={100}
      minHeight={100}
      enable={{
        top: !fullscreen,
        right: !fullscreen,
        bottom: !fullscreen,
        left: !fullscreen,
        topRight: !fullscreen,
        bottomRight: !fullscreen,
        bottomLeft: !fullscreen,
        topLeft: !fullscreen,
      }}
      handleClasses={{
        right: 'resize-handle',
        left: 'resize-handle',
        bottom: 'resize-handle',
        top: 'resize-handle',
      }}
      handleComponent={{
        right: <ResizeHandleRight />,
        left: <ResizeHandleLeft />,
      }}
      handleStyles={{
        right: {
          width: '10px',
          height: '100%',
          position: 'absolute',
          cursor: 'col-resize',
        },
        left: {
          width: '10px',
          height: '100%',
          position: 'absolute',
          cursor: 'col-resize',
        },
        bottom: {
          height: '10px',
          width: '100%',
          position: 'absolute',
          cursor: 'row-resize',
        },
        top: {
          height: '10px',
          width: '100%',
          position: 'absolute',
          cursor: 'row-resize',
        },
      }}
    >
      {props.children}
    </Resizable>
  )
}

const ResizeHandle = styled.div`
  position: absolute;
  top: 50%;
  width: 8px;
  height: 100px;

  border: 1px solid ${DesignColor('panelBorder')};
  background-color: ${DesignColor('text1')};
  border-radius: 4px;

  transform: translate(-50%, -50%);

  cursor: col-resize;

  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);

  &:hover {
    background-color: ${DesignColor('inputHighlight')};
  }
`

const ResizeHandleRight = styled(ResizeHandle)`
  left: calc(100% + 16px);
`

const ResizeHandleLeft = styled(ResizeHandle)`
  left: calc(0% - 16px);
`
