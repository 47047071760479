import { DesignColor } from 'themes'

interface TextRowProps {
  width?: 232 | 'fill'

  mode: 'label1' | 'label2' | 'title'
  children?: React.ReactNode
}

export default function TextRow({ width = 232, mode, children }: TextRowProps) {
  return (
    <div
      style={{
        width: getWidth(width),
        padding: '8px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        flexShrink: width === 'fill' ? 1 : 0,
        color: getTextColor(mode),
        fontWeight: getTextWeight(mode),
        fontFamily: 'Inter',
        fontSize: 11,
      }}
    >
      {children}
    </div>
  )
}

function getTextColor(mode: 'label1' | 'label2' | 'title') {
  switch (mode) {
    case 'label1':
    case 'title':
      return DesignColor('text1')
    case 'label2':
      return DesignColor('text2')
  }
}

function getTextWeight(mode: 'label1' | 'label2' | 'title') {
  switch (mode) {
    case 'label1':
    case 'label2':
      return 'regular'
    case 'title':
      return 'bold'
  }
}

function getWidth(width: 232 | 'fill') {
  if (width === 'fill') return '100%'
  return width
}
