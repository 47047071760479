import { DesignColor } from 'themes'
import Icon from '../../Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import { useRef } from 'react'
import useHovered from 'hooks/ui/useHovered'
import {
  AttributeAutolayoutAlign,
  AttributeAutolayoutDirection,
} from 'application/attributes'
import useAction from 'hooks/editor/useAction'

interface AutolayoutInputProps {
  direction: 'Mixed' | AttributeAutolayoutDirection
  main: 'Mixed' | AttributeAutolayoutAlign
  counter: 'Mixed' | AttributeAutolayoutAlign
  setAlign: (
    main: AttributeAutolayoutAlign,
    counter: AttributeAutolayoutAlign
  ) => void

  dim?: boolean
  hasOverride?: boolean
}

type HoveredState = {
  main: AttributeAutolayoutAlign | null
  counter: AttributeAutolayoutAlign | null
}

export default function AutolayoutInput({
  direction,
  main,
  counter,
  setAlign,
  dim,
  hasOverride = false,
}: AutolayoutInputProps) {
  const inputRef = useRef<HTMLDivElement>(null)

  const action = useAction()
  const { hovered, setHovered } = useHovered<HoveredState>({
    ref: inputRef,
  })

  if (direction === 'Mixed') {
    return (
      <div
        ref={inputRef}
        style={{
          width: 64,
          height: 64,
          padding: 2,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 20px)',
          gridTemplateRows: 'repeat(3, 20px)',
          boxShadow: `inset 0px 0px 0px 1px ${DesignColor('panelBorder')}`,
          borderRadius: 4,
          opacity: dim ? 0.4 : 1,
        }}
      >
        {Array.from({ length: 9 }).map((_, index) => (
          <div
            key={index}
            style={{
              width: 20,
              height: 20,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon
              size={16}
              icon={'AutolayoutEmpty'}
              color={DesignColor('text2')}
            />
          </div>
        ))}
      </div>
    )
  }

  const pairs = direction !== 'column' ? rowSquares : columnSquares
  const squares = pairs.map((position) =>
    getSquareProps(
      hasOverride,
      direction,
      main,
      counter,
      position[0],
      position[1],
      !action
        ? hovered || { main: null, counter: null }
        : { main: null, counter: null },
      setHovered,
      setAlign
    )
  )

  return (
    <div
      ref={inputRef}
      style={{
        width: 64,
        height: 64,
        padding: 2,
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 20px)',
        gridTemplateRows: 'repeat(3, 20px)',
        boxShadow: `inset 0px 0px 0px 1px ${DesignColor('panelBorder')}`,
        borderRadius: 4,
        opacity: dim ? 0.4 : 1,
      }}
      onMouseLeave={() => setHovered(null)}
    >
      {squares.map((square) => (
        <Square key={`${square.main}-${square.counter}`} {...square} />
      ))}
    </div>
  )
}

const rowSquares: AttributeAutolayoutAlign[][] = [
  ['start', 'start'],
  ['start', 'center'],
  ['start', 'end'],
  ['center', 'start'],
  ['center', 'center'],
  ['center', 'end'],
  ['end', 'start'],
  ['end', 'center'],
  ['end', 'end'],
]

const columnSquares: AttributeAutolayoutAlign[][] = [
  ['start', 'start'],
  ['center', 'start'],
  ['end', 'start'],
  ['start', 'center'],
  ['center', 'center'],
  ['end', 'center'],
  ['start', 'end'],
  ['center', 'end'],
  ['end', 'end'],
]

function getSquareProps(
  hasOverride: boolean,
  direction: AttributeAutolayoutDirection,
  activeMain: 'Mixed' | AttributeAutolayoutAlign,
  activeCounter: 'Mixed' | AttributeAutolayoutAlign,
  main: AttributeAutolayoutAlign,
  counter: AttributeAutolayoutAlign,
  hovered: HoveredState,
  setHovered: (state: HoveredState) => void,
  setAlign: (
    main: AttributeAutolayoutAlign,
    counter: AttributeAutolayoutAlign
  ) => void
): SquareProps {
  const isSelected = isSquareSelected(activeMain, activeCounter, main, counter)
  const isHovered = isSquareHovered(activeCounter, hovered, main, counter)
  return {
    active: isSelected || isHovered,
    hovered: isHovered,
    hasOverride: hasOverride,
    main: main,
    counter: counter,
    direction: direction,
    spread: activeCounter === 'spaced',
    setHovered: (hovered: boolean) => {
      if (hovered) {
        setHovered({ main, counter })
      } else {
        setHovered({ main: null, counter: null })
      }
    },
    onClick: () => {
      if (activeCounter === 'spaced') {
        setAlign(main, 'spaced')
      } else {
        setAlign(main, counter)
      }
    },
  }
}

interface SquareProps {
  active: boolean
  main: AttributeAutolayoutAlign
  counter: AttributeAutolayoutAlign
  hovered: boolean
  hasOverride: boolean
  direction: AttributeAutolayoutDirection
  spread: boolean
  setHovered: (hovered: boolean) => void
  onClick: () => void
}

function Square({
  active,
  main,
  counter,
  hovered,
  hasOverride,
  direction,
  spread,
  setHovered,
  onClick,
}: SquareProps) {
  const icon = getIcon(active, main, counter, direction, spread)
  const color = getSquareColor(hovered, active, hasOverride)
  return (
    <div
      style={{
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <Icon size={16} icon={icon} color={color} />
    </div>
  )
}

function getSquareColor(
  hovered: boolean,
  selected: boolean,
  hasOverride: boolean
) {
  return hovered
    ? DesignColor(hasOverride ? 'overrideParent' : 'inputHighlight')
    : selected
    ? DesignColor(hasOverride ? 'overrideParent' : 'text1')
    : DesignColor('text2')
}

function isSquareSelected(
  activeMain: 'Mixed' | AttributeAutolayoutAlign,
  activeCounter: 'Mixed' | AttributeAutolayoutAlign,
  main: AttributeAutolayoutAlign,
  counter: AttributeAutolayoutAlign
) {
  if (activeMain === 'Mixed' || activeCounter === 'Mixed') return false
  if (activeCounter === 'spaced') {
    if (activeMain === main) return true
    return false
  }
  if (activeMain === main && activeCounter === counter) return true
  return false
}

function isSquareHovered(
  activeCounter: 'Mixed' | AttributeAutolayoutAlign,
  hovered: HoveredState,
  main: AttributeAutolayoutAlign,
  counter: AttributeAutolayoutAlign
) {
  if (!hovered.main || !hovered.counter) return false
  if (activeCounter === 'spaced') {
    if (hovered.main === main) return true
    return false
  }
  if (hovered.main === main && hovered.counter === counter) return true
  return false
}

function getIcon(
  active: boolean,
  main: AttributeAutolayoutAlign,
  counter: AttributeAutolayoutAlign,
  direction: AttributeAutolayoutDirection,
  spread: boolean
): IconKey {
  if (!active) return 'AutolayoutEmpty'
  switch (main) {
    case 'start':
      return getStartIcon(counter, direction, spread)
    case 'center':
      return getCenterIcon(counter, direction, spread)
    case 'end':
      return getEndIcon(counter, direction, spread)
  }
  return 'AutolayoutEmpty'
}

function getStartIcon(
  counter: AttributeAutolayoutAlign,
  direction: AttributeAutolayoutDirection,
  spread: boolean
): IconKey {
  if (spread) {
    switch (counter) {
      case 'start':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowTopLeftSpread'
          case 'column':
            return 'AutolayoutColumnTopLeftSpread'
        }
        break
      case 'center':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowTopCenterSpread'
          case 'column':
            return 'AutolayoutColumnCenterLeftSpread'
        }
        break
      case 'end':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowTopRightSpread'
          case 'column':
            return 'AutolayoutColumnBottomLeftSpread'
        }
    }
  }

  switch (counter) {
    case 'start':
    case 'center':
    case 'end':
      switch (direction) {
        case 'row':
        case 'wrap':
          return 'AutolayoutRowTop'
        case 'column':
          return 'AutolayoutColumnLeft'
        default:
          return 'AutolayoutEmpty'
      }
  }
  return 'AutolayoutEmpty'
}

function getCenterIcon(
  counter: AttributeAutolayoutAlign,
  direction: AttributeAutolayoutDirection,
  spread: boolean
): IconKey {
  if (spread) {
    switch (counter) {
      case 'start':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowCenterLeftSpread'
          case 'column':
            return 'AutolayoutColumnTopCenterSpread'
        }
        break
      case 'center':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowCenterCenterSpread'
          case 'column':
            return 'AutolayoutColumnCenterCenterSpread'
        }
        break
      case 'end':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowCenterRightSpread'
          case 'column':
            return 'AutolayoutColumnBottomCenterSpread'
        }
    }
  }

  switch (counter) {
    case 'start':
    case 'center':
    case 'end':
      switch (direction) {
        case 'row':
        case 'wrap':
          return 'AutolayoutRowCenter'
        case 'column':
          return 'AutolayoutColumnCenter'
        default:
          return 'AutolayoutEmpty'
      }
  }
  return 'AutolayoutEmpty'
}

function getEndIcon(
  counter: AttributeAutolayoutAlign,
  direction: AttributeAutolayoutDirection,
  spread: boolean
): IconKey {
  if (spread) {
    switch (counter) {
      case 'start':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowBottomLeftSpread'
          case 'column':
            return 'AutolayoutColumnTopRightSpread'
        }
        break
      case 'center':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowBottomCenterSpread'
          case 'column':
            return 'AutolayoutColumnCenterRightSpread'
        }
        break
      case 'end':
        switch (direction) {
          case 'row':
          case 'wrap':
            return 'AutolayoutRowBottomRightSpread'
          case 'column':
            return 'AutolayoutColumnBottomRightSpread'
        }
    }
  }

  switch (counter) {
    case 'start':
    case 'center':
    case 'end':
      switch (direction) {
        case 'row':
        case 'wrap':
          return 'AutolayoutRowBottom'
        case 'column':
          return 'AutolayoutColumnRight'
        default:
          return 'AutolayoutEmpty'
      }
  }
  return 'AutolayoutEmpty'
}
