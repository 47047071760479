import { DesignColor } from 'themes'
import { AlignmentGap } from 'application/action'
import { Camera } from 'application/camera'
import { rgbaStringToRgba } from 'application/color'
import {
  Context,
  Renderable,
  WebglLine,
  WebglLineData,
  WebglRectangle,
  WebglRectangleData,
  WebglText,
  WebglTextData,
} from 'application/render'
import { Point, Rectangle } from 'application/shapes'
import { FontLoaderInterface, TextContent, TextShaper } from 'application/text'
import { truncate } from 'application/math'

const labelPaddingV = 2
const labbelPaddingH = 4
const labelRounding = 4
const labelOffset = 8

export class AlignmentGapsTransformer {
  static transform = (
    context: Context,
    fontLoader: FontLoaderInterface,
    gaps: AlignmentGap[],
    camera: Camera
  ): Renderable[] => {
    const lines: Renderable[] = []

    for (const line of gaps) {
      const data: WebglLineData = {
        lines: [],
        thickness: 1 / camera.z,
        color: rgbaStringToRgba(DesignColor('alignment')),
      }

      for (const l of line.lines) {
        data.lines.push({ start: l[0], end: l[1] })

        const labels = this.getLabel(context, fontLoader, camera, l)
        labels.forEach((label) => lines.push(label))
      }

      const renderable = new WebglLine(context, data)
      renderable.init()

      lines.push(renderable)
    }

    return lines
  }

  private static getLabel = (
    context: Context,
    fontLoader: FontLoaderInterface,
    camera: Camera,
    line: [Point, Point]
  ): Renderable[] => {
    const distance = Math.max(
      Math.abs(line[0].x - line[1].x),
      Math.abs(line[0].y - line[1].y)
    )
    const labelText = this.getLabelText(distance, camera.z)
    const rectangle = this.getLabelRectangle(
      line,
      camera.z,
      labelText,
      new TextShaper(fontLoader)
    )
    if (!rectangle) return []

    const labelTextData: WebglTextData = {
      ...rectangle,
      bb: rectangle,
      text: labelText,
    }

    const text = new WebglText(context, labelTextData, fontLoader)
    text.init()

    const backgroundRectangle = {
      x: rectangle.x - labbelPaddingH / camera.z,
      y: rectangle.y - labelPaddingV / camera.z,
      w: rectangle.w + (labbelPaddingH * 2) / camera.z,
      h: rectangle.h + (labelPaddingV * 2) / camera.z,
    }
    const labelBackground: WebglRectangleData = {
      ...backgroundRectangle,
      bb: backgroundRectangle,
      fill: {
        type: 'color',
        color: rgbaStringToRgba(DesignColor('alignment')),
      },
      rounding: {
        tl: labelRounding / camera.z,
        tr: labelRounding / camera.z,
        br: labelRounding / camera.z,
        bl: labelRounding / camera.z,
      },
    }

    const background = new WebglRectangle(context, labelBackground)
    background.init()

    return [background, text]
  }

  private static getLabelRectangle = (
    line: [Point, Point],
    z: number,
    textContent: TextContent,
    textShaper: TextShaper
  ): Rectangle | null => {
    const shapedText = textShaper.getShapedText(textContent)
    if (!shapedText) return null

    const x = Math.min(line[0].x, line[1].x)
    const y = Math.min(line[0].y, line[1].y)
    const xDelta = Math.abs(line[0].x - line[1].x)
    const yDelta = Math.abs(line[0].y - line[1].y)

    if (xDelta > yDelta) {
      const yOffset = shapedText.h / 2 + (labelOffset + labelPaddingV * 2) / z
      return {
        x: x + xDelta / 2 - shapedText.w / 2,
        y: y - yOffset,
        w: shapedText.w,
        h: shapedText.h,
      }
    } else {
      const xOffset = shapedText.w / 2 + (labelOffset + labbelPaddingH * 2) / z
      return {
        x: x - xOffset,
        y: y + yDelta / 2 - shapedText.h / 2,
        w: shapedText.w,
        h: shapedText.h,
      }
    }
  }

  private static getLabelText = (value: number, z: number): TextContent => {
    const displayText = truncate(value).toString()
    return {
      content: displayText,
      contentStyles: new Array(displayText.length).fill(0),
      styles: {
        fontFamily: 'inter',
        fontWeight: 'regular',
        fontSize: 12 / z,
        align: 'left',
        fill: {
          type: 'color',
          color: rgbaStringToRgba(DesignColor('alignmentText')),
        },
        letterSpacing: 0,
        lineHeight: 0,
        italic: false,
        underline: false,
        link: null,
      },
      styleOverrides: {},
      lineTypes: [],
    }
  }
}
