import {
  AttributeFill,
  AttributeFillType,
  AttributeType,
  StyleMap,
  createNewWhiteFill,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import _ from 'lodash'

export type BackgroundPanelKeys = 'fills'

export interface BackgroundPanelState {
  fills: 'Mixed' | AttributeFill[] | null
  options: AttributeFillType[]
  mode: 'add' | 'remove' | 'none'
}

export interface BackgroundPanelHandlers {
  addFill: () => void
  removeFill: () => void
  setFill: (value: AttributeFill) => void
  clearMixed: () => void
}

interface NodeBackgroundAttributeHandler {
  getDefaultFills: (node: ReadOnlyNode) => AttributeFill[]
  getFills: (node: ReadOnlyNode) => 'Mixed' | AttributeFill[]
  getOptions: () => AttributeFillType[]
  setFills: (node: ReadOnlyNode, fills: AttributeFill[]) => Partial<StyleMap>
}

export class BackgroundPanel extends StyleAttributePanel<
  BackgroundPanelState,
  BackgroundPanelHandlers,
  BackgroundPanelKeys
> {
  private defaultHandler = new DefaultNodeFillAttributeHandler()

  getSettings(): BackgroundPanelState {
    return {
      fills: this.getFills(),
      options: this.getOptions(),
      mode: this.getMode(),
    }
  }

  getHandlers(): BackgroundPanelHandlers {
    return {
      addFill: this.addFill,
      removeFill: this.removeFill,
      setFill: this.setFill,
      clearMixed: this.clearMixed,
    }
  }

  private getMode = (): 'add' | 'remove' | 'none' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'none'

    let hasText = false
    let hasFill = false
    for (const node of nodes) {
      const nodeFills = node.getStyleAttribute('fills')
      if (!nodeFills) continue
      if (nodeFills.length > 0) hasFill = true

      const nodeType = node.getBaseAttribute('type')
      if (nodeType === 'text') hasText = true
    }

    if (hasText) return 'none'
    if (hasFill) return 'remove'
    return 'add'
  }

  private getFills = (): 'Mixed' | AttributeFill[] | null => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return null

    let fills: AttributeFill[] | null = null
    for (const node of nodes) {
      const handler = this.getHandler()
      const nodeFills = handler.getFills(node)
      if (nodeFills === 'Mixed') return 'Mixed'
      if (!fills) {
        fills = nodeFills
      } else if (!_.isEqual(fills, nodeFills)) {
        return 'Mixed'
      }
    }

    return fills
  }

  private getOptions = (): AttributeFillType[] => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return []

    const handler = this.getHandler()
    return handler.getOptions()
  }

  private addFill = (): void => {
    this.setFill(createNewWhiteFill())
    this.commit()
  }

  private removeFill = (): void => {
    this.setFill(null)
    this.commit()
  }

  private setFill = (value: AttributeFill | null): void => {
    this.setMulti({ fills: value ? [value] : [] })
  }

  private clearMixed = (): void => {
    const options = this.getOptions()
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    let fills: AttributeFill[] = []
    for (const node of nodes) {
      const handler = this.getHandler()
      const defaultFills = handler.getDefaultFills(node)
      if (defaultFills.length === 0) continue
      if (defaultFills.some((f) => !options.includes(f.type))) continue
      fills = defaultFills
      break
    }

    for (const node of nodes) {
      const handler = this.getHandler()
      const newAttributes = handler.setFills(node, fills)
      this.setOne(node.getId(), newAttributes)
    }

    this.commit()
  }

  private getHandler = (): NodeBackgroundAttributeHandler => {
    return this.defaultHandler
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = ['page', 'frame', 'rectangle', 'ellipse']

class DefaultNodeFillAttributeHandler
  implements NodeBackgroundAttributeHandler
{
  getDefaultFills = (node: ReadOnlyNode): AttributeFill[] => {
    const fills = node.getStyleAttribute('fills')
    if (!fills) return []

    return fills
  }

  getFills = (node: ReadOnlyNode): 'Mixed' | AttributeFill[] => {
    const fills = node.getStyleAttribute('fills')
    if (!fills) return 'Mixed'

    return fills
  }

  getOptions = (): AttributeFillType[] => {
    return ['color', 'gradient', 'image']
  }

  setFills = (_: ReadOnlyNode, fills: AttributeFill[]): Partial<StyleMap> => {
    return { fills }
  }
}
