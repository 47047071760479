export type warningKey =
  | 'HoverColor'
  | 'HoverResize'
  | 'Sticky'
  | 'Overlay'
  | 'NestedNav'
  | 'ScrollPercents'
  | 'Scroll'
  | 'SizeLock'

type warningMap = {
  [key in warningKey]: {
    text: string
  }
}

export const WarningMap: warningMap = {
  // Interactions
  HoverColor: {
    text: `Hover transitions only work with solid background colors`,
  },
  HoverResize: {
    text: `Hover resizing only works with elements that have fixed width and height`,
  },
  Overlay: {
    text: `Open overlay doesn't work when the overlay frame has an "Animate In" interaction`,
  },
  Scroll: {
    text: `Scroll transforms don't work with "Animate In" scroll interactions`,
  },
  NestedNav: {
    text: `Navigate interactions don't work when a parent has a navigate interaction`,
  },
  ScrollPercents: {
    text: `Scroll transforms only work if the start percent is less than the end percent`,
  },

  // Scroll
  Sticky: {
    text: `Sticky only works if clip content is turned off on all layers above this`,
  },

  // Size
  SizeLock: {
    text: `Both width and height can't change size while the aspect ratio is locked`,
  },
}
