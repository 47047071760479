import { CommandHandler } from 'application/client'
import { TemplateInfo } from 'application/service'
import { BackendService } from 'application/service/backend'

interface TemplatesPanelState {
  templates: TemplateInfo[]
  tab: string
  query: string
}

interface TemplatesPanelHandlers {
  setTab: (tab: string) => void
  setQuery: (term: string) => void
  select: (templateInfo: TemplateInfo) => Promise<void>
  copy: (templateInfo: TemplateInfo) => Promise<void>
  load: () => Promise<void>
  close: () => void
}

type TemplatesPanelListener = (state: TemplatesPanelState) => void

export class TemplatesPanel {
  private commandHandler: CommandHandler
  private backendService: BackendService

  private templates: TemplateInfo[]
  private filtered: TemplateInfo[]
  private query: string
  private tab: string
  private selecting: boolean

  private listeners: { [key: string]: TemplatesPanelListener } = {}

  constructor(commandHandler: CommandHandler, backendService: BackendService) {
    this.commandHandler = commandHandler
    this.backendService = backendService

    this.templates = []
    this.filtered = []
    this.query = ''
    this.tab = 'all'
    this.selecting = false

    this.listeners = {}
  }

  getSettings = (): TemplatesPanelState => {
    return {
      templates: this.filtered,
      tab: this.tab,
      query: this.query,
    }
  }

  getHandlers = (): TemplatesPanelHandlers => {
    return {
      setTab: this.setTab,
      setQuery: this.setQuery,
      select: this.select,
      copy: this.copy,
      load: this.load,
      close: this.close,
    }
  }

  subscribe = (id: string, listener: TemplatesPanelListener) => {
    this.listeners[id] = listener
  }

  unsubscribe = (id: string) => {
    delete this.listeners[id]
  }

  private select = async (templateInfo: TemplateInfo): Promise<void> => {
    if (this.selecting) return
    this.selecting = true
    try {
      const data = await this.backendService.getTemplateData(templateInfo)
      this.commandHandler.handle({
        type: 'pasteOutsideDocumentNodes',
        params: data,
      })
      this.commandHandler.handle({ type: 'commit' })
    } catch (e) {}
  }

  private copy = async (templateInfo: TemplateInfo): Promise<void> => {
    try {
      const data = await this.backendService.getTemplateData(templateInfo)
      this.commandHandler.handle({
        type: 'copyOutsideDocumentNodes',
        params: data,
      })
    } catch (e) {}
  }

  private setQuery = (term: string) => {
    this.query = term
    this.filtered = this.templates.filter((t) =>
      t.name.toLowerCase().includes(term.toLowerCase())
    )
    this.notifyListeners()
  }

  private setTab = (tab: string) => {
    this.tab = tab
    this.query = ''
    this.filtered = this.templates.filter((t) =>
      tab === 'all' ? true : t.tags.includes(tab)
    )
    this.notifyListeners()
  }

  private load = async () => {
    try {
      const templates = await this.backendService.getTemplates()
      this.templates = templates
      this.filtered = templates
      this.query = ''
      this.tab = 'all'
      this.selecting = false
      this.notifyListeners()
    } catch (e) {
      console.error('Failed to get templates', e)
    }
  }

  private close = () => {
    this.selecting = false
  }

  private notifyListeners = () => {
    for (const listener of Object.values(this.listeners)) {
      listener(this.getSettings())
    }
  }
}
