import { useRef, useState } from 'react'
import NumberBlock from '../../NumberBlock/NumberBlock'
import { Color } from 'application/color'
import { AttributeFill } from 'application/attributes'
import ColorBlock from '../ColorBlock/ColorBlock'
import FillPopup from '../FillPopup/FillPopup'

interface ColorInputProps {
  color: 'Mixed' | Color
  updateColor: (color: Color) => void

  opacity?: boolean

  popupTop?: number

  hasOverride?: boolean
}

export default function ColorInput({
  color,
  updateColor,
  opacity,
  popupTop,
  hasOverride = false,
}: ColorInputProps) {
  const [open, setOpen] = useState(false)
  const displayRef = useRef<HTMLDivElement>(null)

  const fill: AttributeFill | null =
    color === 'Mixed'
      ? null
      : {
          type: 'color',
          color: color,
        }
  const handleUpdateFill = (fill: AttributeFill) => {
    if (!fill.color) return
    updateColor(fill.color)
  }

  if (!fill && color !== 'Mixed') return <></>

  return (
    <>
      <div
        style={{
          width: opacity ? 192 : 96,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <ColorBlock
          color={color}
          updateColor={updateColor}
          open={open}
          setOpen={setOpen}
          hasOverride={hasOverride}
        />
        {opacity && color !== 'Mixed' && (
          <NumberBlock
            icon="Opacity"
            value={color.a * 100}
            setValue={(a) => updateColor({ ...color, a: a / 100 })}
            min={0}
            max={100}
            step={1}
            unit="%"
            hasOverride={hasOverride}
          />
        )}
      </div>
      {open && fill && (
        <FillPopup
          popupTop={popupTop}
          fill={fill}
          updateFill={handleUpdateFill}
          setOpen={setOpen}
          exceptionRef={displayRef}
          modes={['color']}
        />
      )}
    </>
  )
}
