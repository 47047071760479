import { HistoryActionListener, HistoryEvent } from 'application/history'
import {
  State,
  TextEditor,
  attributesToTextEditorState,
} from 'application/textEditor'
import { TextEditorUpdateAccumulator } from '../update/textEditor'
import { WriteDocument } from 'application/document'

export class TextEditorHistoryActionHandler implements HistoryActionListener {
  private document: WriteDocument
  private textEditor: TextEditor
  private update: TextEditorUpdateAccumulator

  constructor(
    document: WriteDocument,
    textEditor: TextEditor,
    update: TextEditorUpdateAccumulator
  ) {
    this.document = document
    this.textEditor = textEditor
    this.update = update
  }

  onUndo = (event: HistoryEvent): void => {
    switch (event.type) {
      case 'edit_text':
        this.setEditing(event.data.initialId)
        return
      case 'text_state':
        this.setState(event.data.initial)
        return
    }
  }

  onRedo = (event: HistoryEvent): void => {
    switch (event.type) {
      case 'edit_text':
        this.setEditing(event.data.currentId)
        return
      case 'text_state':
        this.setState(event.data.current)
        return
    }
  }

  private setEditing = (id: string | null): void => {
    if (id === null) {
      this.stopEditing()
    } else {
      this.startEditing(id)
    }
  }

  private setState = (state: State | null): void => {
    if (state === null) {
      this.textEditor.clearState()
    } else {
      this.textEditor.setState(state)
    }

    this.update.onTextState(state)
  }

  private startEditing = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const state = attributesToTextEditorState(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (state === null) return

    this.textEditor.setId(id)
    this.textEditor.setState(state)

    this.update.onEditText(id)
  }

  private stopEditing = (): void => {
    this.textEditor.setId(null)
    this.textEditor.clearState()

    this.update.onEditText(null)
  }
}
