import { QuadTree, QuadTreeRectangle } from 'application/quadtree'
import { Point, Rectangle, encapsulates } from 'application/shapes'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocument } from 'application/document'
import { isAutolayout, isInteractable } from 'application/attributes'
import { QuadTreeNodeFilter, QuadTreeNodeTitleFilter } from '../types'

export class InteractableNodeFilter {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private quadtree: QuadTree

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    quadtree: QuadTree
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.quadtree = quadtree
  }

  nodeAtPoint = (
    point: Point,
    drill: boolean,
    withChildren: boolean
  ): string | undefined => {
    const selectable = this.getInteractable(withChildren)

    const rectanglesAtPoint = this.quadtree.getAtPoint(
      point,
      QuadTreeNodeTitleFilter
    )

    const filteredRectangles = rectanglesAtPoint
      .filter((r) => {
        if (this.isTitle(r)) return true
        return drill ? this.isNodeInteractable(r.id) : selectable.includes(r.id)
      })
      .map(this.extractQuadTreeId)

    if (filteredRectangles.length === 0) return undefined

    return filteredRectangles[0]
  }

  nodesInRectangle = (rectangle: Rectangle): string[] => {
    const interactable = this.getCanvasChildren(true)

    const rectanglesInRectangle = this.quadtree.getInterscected(
      rectangle,
      QuadTreeNodeFilter
    )

    const filteredRectangles = rectanglesInRectangle
      .map((r) => r.id)
      .filter((id) => interactable.includes(id))

    return filteredRectangles.flatMap((id) => {
      const node = this.document.getNode(id)
      if (!node) return []

      const x = node.getBaseAttribute('x')
      const y = node.getBaseAttribute('y')
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      const children = this.getChildren(node)
      if (children.length === 0) return [id]

      if (children.length === 0) return [id]
      if (encapsulates(rectangle, { x, y, w, h })) return [id]

      return children.filter((c) =>
        rectanglesInRectangle.some((r) => r.id === c)
      )
    })
  }

  isSelectedAtPoint = (point: Point): boolean => {
    const selectedIds = this.documentSelection
      .getSelected()
      .map((n) => n.getId())

    const rectanglesAtPoint = this.quadtree.getAtPoint(
      point,
      QuadTreeNodeTitleFilter
    )

    const filteredRectangles = rectanglesAtPoint
      .map((r) => this.extractQuadTreeId(r))
      .filter((id) => selectedIds.includes(id))
    return filteredRectangles.length > 0
  }

  private getInteractable = (includeChildren: boolean): string[] => {
    return [
      ...this.getSelectedTree(includeChildren),
      ...this.getCanvasChildren(false),
      ...this.getCanvasGrandChildren(),
    ]
  }

  private isNodeInteractable = (id: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const ancestors = this.document.getAncestors(node)
    if (ancestors.some((a) => !isInteractable(a))) return false

    return isInteractable(node)
  }

  private getChildren = (node: ReadOnlyNode): string[] => {
    const children = node.getChildren()
    if (!children) return []

    return children.filter((c) => {
      const child = this.document.getNode(c)
      if (!child) return false

      if (child.getBaseAttribute('locked')) return false
      if (child.getStyleAttribute('hidden')) return false

      return true
    })
  }

  private getParents = (node: ReadOnlyNode): string[] => {
    const parent = this.document.getParent(node)
    if (!parent) return []

    const parents: string[] = []
    let current: ReadOnlyNode | undefined = parent
    while (current) {
      const type = current.getBaseAttribute('type')
      if (type === 'canvas') break
      parents.push(current.getId())
      current = this.document.getParent(current)
    }

    return parents
  }

  private getSelectedTree = (includeChildren: boolean): string[] => {
    return this.documentSelection.getSelected().flatMap((node) => {
      if (node.getBaseAttribute('locked')) return []
      if (node.getStyleAttribute('hidden')) return []

      const ids: string[] = []

      if (includeChildren) {
        ids.push(...this.getChildren(node))
      }

      const parents = this.getParents(node)
      for (const parentId of parents) {
        const parent = this.document.getNode(parentId)
        if (!parent) continue

        const parentType = parent.getBaseAttribute('type')
        if (parentType === 'canvas') continue

        const grandParent = this.document.getParent(parent)
        if (!grandParent) continue

        const grandParentType = grandParent.getBaseAttribute('type')
        if (grandParentType === 'canvas') continue

        ids.push(parentId)
      }

      const parent = this.document.getParent(node)
      if (!parent) return ids

      const parentType = parent.getBaseAttribute('type')
      if (parentType === 'canvas') return ids

      return [...ids, ...this.getChildren(parent)]
    })
  }

  private getCanvasChildren = (withChildren: boolean): string[] => {
    const canvasId = this.documentSelection.getSelectedCanvas()

    const canvas = this.document.getNode(canvasId)
    if (!canvas) return []

    const canvasChildren = this.getChildren(canvas)
    if (!canvasChildren) return []

    return canvasChildren.filter((c) => {
      const child = this.document.getNode(c)
      if (!child) return false

      const children = this.getChildren(child)
      return withChildren || children.length === 0 || isAutolayout(child)
    })
  }

  private getCanvasGrandChildren = (): string[] => {
    const canvasId = this.documentSelection.getSelectedCanvas()

    const canvas = this.document.getNode(canvasId)
    if (!canvas) return []

    const canvasChildren = this.getChildren(canvas)
    if (!canvasChildren) return []

    return canvasChildren.flatMap((c) => {
      const child = this.document.getNode(c)
      if (!child) return []

      const children = this.getChildren(child)
      if (!children) return []

      return children
    })
  }

  private extractQuadTreeId = (r: QuadTreeRectangle): string => {
    if (r.id.includes('title')) return r.id.split('-')[0]
    return r.id
  }

  private isTitle = (r: QuadTreeRectangle): boolean => {
    return r.id.includes('title')
  }
}
