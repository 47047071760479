import { ReadOnlyNode } from 'application/node'
import {
  LayoutDependencyGraphFactory,
  LayoutDependencyGraph,
  LayoutDependencyNode,
} from '../types'
import { hasDependency } from './utils'
import { ReadOnlyDocument } from 'application/document'

export class PositionDependencyGraphFactory
  implements LayoutDependencyGraphFactory
{
  create(
    dirtyNodes: Set<string>,
    document: ReadOnlyDocument
  ): LayoutDependencyGraph {
    const graph: LayoutDependencyGraph = {}

    const visited = new Set<string>()
    for (const id of dirtyNodes) {
      const node = document.getNode(id)
      if (!node) continue
      this.traverse(node, document, graph, visited)
    }

    return graph
  }

  private traverse = (
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    graph: LayoutDependencyGraph,
    visited: Set<string>
  ): void => {
    if (visited.has(node.getId())) return
    visited.add(node.getId())
    this.getOrAddNode(node, graph)
    this.traverseUp(node, document, graph, visited)
    this.traverseDown(node, document, graph)
  }

  private traverseUp(
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    graph: LayoutDependencyGraph,
    visited: Set<string>
  ): void {
    const parent = document.getParent(node)
    if (!parent) return
    if (['root', 'canvas'].includes(parent.getBaseAttribute('type'))) return

    this.addDependency(parent, node, graph)
    this.traverse(parent, document, graph, visited)
  }

  private traverseDown(
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    graph: LayoutDependencyGraph
  ): void {
    const children = node.getChildren()
    if (!children) return

    for (const child of children) {
      const childNode = document.getNode(child)
      if (!childNode) continue
      this.addDependency(node, childNode, graph)
      this.traverseDown(childNode, document, graph)
    }
  }

  private addDependency(
    dependent: ReadOnlyNode,
    dependency: ReadOnlyNode,
    graph: LayoutDependencyGraph
  ): void {
    const dependentNode = this.getOrAddNode(dependent, graph)
    const dependencyNode = this.getOrAddNode(dependency, graph)

    if (hasDependency(dependentNode, dependencyNode)) return

    dependentNode.dependencies.push(dependencyNode)
    dependencyNode.dependentOn.push(dependentNode)
  }

  private getOrAddNode(
    node: ReadOnlyNode,
    graph: LayoutDependencyGraph
  ): LayoutDependencyNode {
    const id = node.getId()
    const key = `${id}-wh`
    if (graph[key]) return graph[key]

    const newNode: LayoutDependencyNode = {
      documentNodeId: id,
      key: key,
      mode: 'wh',
      dependentOn: [],
      dependencies: [],
    }
    graph[key] = newNode

    return newNode
  }
}
