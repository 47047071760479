import { Rectangle } from 'application/shapes'
import {
  FontLoaderInterface,
  Shaper,
  TextContent,
  TextShaper,
} from 'application/text'
import { calcTextBoxes } from '../../data'
import { Context } from '../../gpu/context'
import { VaoData } from '../../types'
import { cleanupVaoData, clearBuffers } from '../../utils'
import { WebglTextSolidBox } from './solid'
import { TextGradienBoxVaoData, WebglTextGradientBox } from './gradient'

export type WebglTextData = Rectangle & {
  text: TextContent
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglText {
  private context: Context
  private fontLoader: FontLoaderInterface
  private shaper: Shaper
  private data: WebglTextData
  private solidBoxes: VaoData[]
  private gradientBoxes: TextGradienBoxVaoData[]

  constructor(
    context: Context,
    data: WebglTextData,
    fontLoader: FontLoaderInterface
  ) {
    this.context = context
    this.fontLoader = fontLoader
    this.shaper = new TextShaper(fontLoader)
    this.data = data
    this.solidBoxes = []
    this.gradientBoxes = []
  }

  init() {
    this.updateRenderData()
  }

  updateRenderData() {
    this.clearBufffers()
    const text = this.data.text
    const shaped = this.shaper.getShapedText(text, this.data.w)
    const boxes = calcTextBoxes(text, shaped)

    for (let i = 0; i < boxes.length; i++) {
      const box = boxes[i]

      switch (box.fill.type) {
        case 'color':
          this.solidBoxes.push(
            WebglTextSolidBox.createVaoData(
              this.context,
              this.data,
              box,
              this.fontLoader
            )
          )
          break
        case 'gradient':
          this.gradientBoxes.push(
            WebglTextGradientBox.createVaoData(
              this.context,
              this.data,
              box,
              boxes,
              this.fontLoader
            )
          )
          break
      }
    }
  }

  draw(depth: number = 0): void {
    for (const boxVaoData of this.solidBoxes) {
      WebglTextSolidBox.draw(this.context, boxVaoData)
    }
    for (const gradientBoxVaoData of this.gradientBoxes) {
      WebglTextGradientBox.draw(this.context, gradientBoxVaoData, depth)
    }
  }

  cleanup() {
    this.solidBoxes.forEach((vaoData) => cleanupVaoData(vaoData))
  }

  private clearBufffers() {
    this.solidBoxes.forEach((vaoData) => clearBuffers(vaoData))
    this.gradientBoxes.forEach((vaoData) => {
      clearBuffers(vaoData.fill)
      clearBuffers(vaoData.glyphs)
    })
  }

  async preload() {
    for (const vaoData of this.solidBoxes) {
      await WebglTextSolidBox.preload(this.context, vaoData)
    }
    for (const vaoData of this.gradientBoxes) {
      await WebglTextGradientBox.preload(this.context, vaoData.glyphs)
    }
  }
}
