import { Cursor } from 'application/cursor'
import { Cursors } from 'assets/cursorIndex'

type CursorMode = 'canvas' | 'all'

export class EditorCursor {
  private targetId: string
  private activeCursor: Cursor

  constructor(targetId: string) {
    this.targetId = targetId
    this.activeCursor = 'default'
  }

  set(cursor: Cursor) {
    if (this.activeCursor === cursor) return
    const mode = this.getCursorMode(cursor)
    const canvas = this.getCanvasTarget()
    const mappedCursor = this.mapCursorByType(cursor)

    if (canvas) canvas.style.cursor = mappedCursor
    if (mode === 'all') document.body.style.cursor = mappedCursor
    this.activeCursor = cursor
  }

  reset() {
    const currentMode = this.getCursorMode(this.activeCursor)
    const canvas = this.getCanvasTarget()
    if (canvas) {
      canvas.style.cursor = 'default'
    }
    if (currentMode === 'all') {
      document.body.style.cursor = 'default'
    }
    this.activeCursor = 'default'
  }

  private getCanvasTarget = (): HTMLCanvasElement | null => {
    const canvas = document.getElementById(this.targetId)
    if (canvas instanceof HTMLCanvasElement) {
      return canvas
    } else {
      return null
    }
  }

  private mapCursorByType = (cursor: Cursor) => {
    switch (cursor) {
      case 'handOpen':
        return Cursors.HandOpen
      case 'handClosed':
        return Cursors.HandClosed
      case 'drawPage':
        return Cursors.DrawPage
      case 'drawFrame':
        return Cursors.DrawFrame
      case 'drawRectangle':
        return Cursors.DrawRectangle
      case 'drawEllipse':
        return Cursors.DrawEllipse
      case 'drawText':
        return Cursors.DrawText
      case 'drawImage':
        return Cursors.DrawImage
      case 'drawInput':
        return Cursors.DrawInput
      case 'drawIcon':
        return Cursors.DrawIcon
      case 'target':
        return Cursors.Target
      case 'eyedropper':
        return Cursors.Eyedropper
      case 'duplicate':
        return Cursors.Duplicate
      case 'top':
        return 'ns-resize'
      case 'bottom':
        return 'ns-resize'
      case 'left':
        return 'ew-resize'
      case 'right':
        return 'ew-resize'
      case 'top-left':
        return 'nwse-resize'
      case 'top-right':
        return 'nesw-resize'
      case 'bottom-left':
        return 'nesw-resize'
      case 'bottom-right':
        return 'nwse-resize'
      default:
        return 'default'
    }
  }

  private getCursorMode = (cursor: Cursor): CursorMode => {
    switch (cursor) {
      case 'target':
        return 'all'
      default:
        return 'canvas'
    }
  }
}
