import { Command, DeleteNode } from 'application/client'
import { ReadOnlyDocument } from 'application/document'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeDeleteAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument

  constructor(commandHandler: CommandHandler, document: ReadOnlyDocument) {
    this.commandHandler = commandHandler
    this.document = document
  }

  delete = (ids: string[]): void => {
    const commands = this.buildDeleteCommands(ids)
    this.commandHandler.handle(commands)
  }

  private buildDeleteCommands = (ids: string[]): DeleteNode[] => {
    return ids.flatMap((id) => this.deleteTree(id))
  }

  private deleteTree = (id: string): DeleteNode[] => {
    const node = this.document.getNode(id)
    if (!node) return []

    const children = node.getChildren()
    if (!children) return [{ type: 'deleteNode', params: { id } }]

    const commands: DeleteNode[] = []
    for (const child of children) {
      commands.push(...this.deleteTree(child))
    }

    return [...commands, { type: 'deleteNode', params: { id } }]
  }
}
