import { MultiselectStyleMap } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'

type OpacityPanelKeys = 'opacity' | 'hidden'

type OpacityPanelAttributes = Pick<MultiselectStyleMap, OpacityPanelKeys> | null

export interface OpacityPanelState {
  attributes: OpacityPanelAttributes
}

export interface OpacityPanelHandlers {
  setOpacity: (value: number) => void
  slideOpacity: (value: number) => void
  setHidden: (value: boolean) => void
}

export class OpacityPanel extends StyleAttributePanel<
  OpacityPanelState,
  OpacityPanelHandlers,
  OpacityPanelKeys
> {
  getSettings(): OpacityPanelState {
    return {
      attributes: this.attributes,
    }
  }

  getHandlers(): OpacityPanelHandlers {
    return {
      setOpacity: this.setOpacity,
      slideOpacity: this.slideOpacity,
      setHidden: this.setHidden,
    }
  }

  private setOpacity = (value: number): void => {
    this.setMulti({ opacity: value })
  }

  private slideOpacity = (value: number): void => {
    this.slideMulti('opacity', value)
  }

  private setHidden = (value: boolean): void => {
    this.setMulti({ hidden: value })
    this.commit()
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 100
  }
}
