import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'

interface LayerLockBlockProps {
  componentDescendant: boolean

  active: boolean

  parentLocked: boolean
  locked: boolean
  setLocked: (locked: boolean) => void

  hidden: boolean
}

export default function LayerLockBlock({
  componentDescendant,
  active,
  parentLocked,
  locked,
  setLocked,
  hidden,
}: LayerLockBlockProps) {
  const buttonRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: buttonRef })

  const icon = getIcon(locked, parentLocked, active)
  if (!active && !locked && !parentLocked) return <></>

  return (
    <div
      ref={buttonRef}
      style={{
        width: 24,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        opacity: !hidden ? 1 : 0.4,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={() => setLocked(!locked)}
    >
      {icon && (
        <Icon
          icon={icon}
          size={16}
          color={getColor(componentDescendant, hovered || false)}
        />
      )}
    </div>
  )
}

function getIcon(
  locked: boolean,
  parentLocked: boolean,
  hovered: boolean
): IconKey | null {
  if (locked) return 'LockClosed'
  if (parentLocked) return 'LockChild'
  return hovered ? 'LockOpen' : null
}

function getColor(componetDescendant: boolean, hovered: boolean): string {
  if (hovered) return DesignColor('inputHighlight')
  return DesignColor(componetDescendant ? 'component' : 'text1')
}
