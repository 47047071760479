import { NodeDeleteAction, NodeSelectionAction } from 'application/action'
import { ActionInitiator } from '../initiator'
import { HotkeyHandler } from './hotkey'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CommandHandler } from 'application/client'
import { AddAutolayoutAction } from '../node/autolayout'
import { GroupAction } from '../node/group'
import { RearrangeAction } from '../node/rearrange'
import { PositionDeltaAction } from '../node/positionDelta'
import { SizeDeltaAction } from '../node/sizeDelta'
import { TreeSelectionAction } from '../node/treeSelection'
import { LayersPanel } from 'editor/ui/layersPanel'
import { AttributesAction } from '../node/attributes'
import { AlignNodesAction } from '../node/align'
import { TextAction } from '../node/text'
import { hotkeyToAttributeType } from '../draw/action'
import { PreviewPanel } from 'editor/ui/previewPanel'
import { NodeExporter } from 'editor/exporter/exporter'
import { CameraService } from 'application/camera'
import { InteractionAnimationEffectPanel } from 'editor/ui/interactions/interactionAnimationEffectPanel'

export class HotkeyHandlersFactory {
  private actionInitiator: ActionInitiator
  private commandHandler: CommandHandler
  private selection: NodeSelectionAction
  private deletion: NodeDeleteAction
  private attributes: AttributesAction
  private autolayout: AddAutolayoutAction
  private group: GroupAction
  private rearrange: RearrangeAction
  private positionDelta: PositionDeltaAction
  private sizeDelta: SizeDeltaAction
  private textAction: TextAction
  private alignNodesAction: AlignNodesAction
  private treeSelection: TreeSelectionAction
  private nodeExporter: NodeExporter
  private layersPanel: LayersPanel
  private previewPanel: PreviewPanel
  private animationEffectPanel: InteractionAnimationEffectPanel
  private documentSelection: ReadOnlyDocumentSelection
  private cameraService: CameraService

  constructor(
    actionInitiator: ActionInitiator,
    commandHandler: CommandHandler,
    selection: NodeSelectionAction,
    deletion: NodeDeleteAction,
    attributes: AttributesAction,
    autolayout: AddAutolayoutAction,
    group: GroupAction,
    rearrange: RearrangeAction,
    positionDelta: PositionDeltaAction,
    sizeDelta: SizeDeltaAction,
    textAction: TextAction,
    alignNodesAction: AlignNodesAction,
    treeSelection: TreeSelectionAction,
    nodeExporter: NodeExporter,
    layersPanel: LayersPanel,
    previewPanel: PreviewPanel,
    animationEffectPanel: InteractionAnimationEffectPanel,
    documentSelection: ReadOnlyDocumentSelection,
    cameraService: CameraService
  ) {
    this.actionInitiator = actionInitiator
    this.commandHandler = commandHandler
    this.selection = selection
    this.deletion = deletion
    this.attributes = attributes
    this.autolayout = autolayout
    this.group = group
    this.rearrange = rearrange
    this.positionDelta = positionDelta
    this.sizeDelta = sizeDelta
    this.textAction = textAction
    this.alignNodesAction = alignNodesAction
    this.treeSelection = treeSelection
    this.nodeExporter = nodeExporter
    this.layersPanel = layersPanel
    this.previewPanel = previewPanel
    this.animationEffectPanel = animationEffectPanel
    this.documentSelection = documentSelection
    this.cameraService = cameraService
  }

  getHandlers = (): HotkeyHandler[] => {
    return [
      this.getDrawHandler(),
      this.getDeleteHandler(),
      this.getUndoRedoHandler(),
      this.getEditTextHandler(),
      this.getCopyHandlers(),
      this.getCopyAttributesHandler(),
      this.getCopyToClipboardHandler(),
      this.getPasteAttributesHandler(),
      this.getCutHandler(),
      this.getReplaceHandler(),
      this.getDuplicateHandler(),
      this.getAutolayoutHandler(),
      this.getGroupHandler(),
      this.getMoveToFrontHandler(),
      this.getMoveToBackHandler(),
      this.getMoveForwardHandler(),
      this.getMoveBackwardHandler(),
      this.getPositionDeltaHandler(),
      this.getSizeDeltaHandler(),
      this.getTreeSelectionHandler(),
      this.getRenameHandler(),
      this.getCollapseLayersHandler(),
      this.getLockHandler(),
      this.getHideHandler(),
      this.getHandActionHandler(),
      this.getAlignNodesHandler(),
      this.getTextHandler(),
      this.getPreviewHandler(),
      this.getTemplateHandler(),
      this.getExportHandler(),
      this.getZoomHandler(),
    ]
  }

  private getDrawHandler = (): HotkeyHandler => {
    return {
      getKeys: () => Object.keys(hotkeyToAttributeType),
      handle: (e: KeyboardEvent) => {
        if (e.metaKey || e.ctrlKey || e.shiftKey) return
        if (!hotkeyToAttributeType[e.key]) return
        this.actionInitiator.draw(hotkeyToAttributeType[e.key], true)
      },
      canTriggerMulti: () => false,
    }
  }

  private getDeleteHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['Delete', 'Backspace'],
      handle: () => {
        const index = this.animationEffectPanel.getSettings().index
        if (index !== null) return
        const nodes = this.documentSelection.getSelected()
        this.selection.selectNodes([], true)
        this.deletion.delete(nodes.map((n) => n.getId()))
        this.commandHandler.handle({ type: 'commit' })
      },
      canTriggerMulti: () => false,
    }
  }

  private getUndoRedoHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['z', 'Z'],
      handle: (e: KeyboardEvent) => {
        e.shiftKey
          ? this.commandHandler.handle({ type: 'redo' })
          : this.commandHandler.handle({ type: 'undo' })
      },
      canTriggerMulti: () => true,
    }
  }

  private getEditTextHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['Enter'],
      handle: (e: KeyboardEvent) => {
        if (e.metaKey || e.ctrlKey || e.shiftKey) return
        this.commandHandler.handle({ type: 'editText' })
        this.commandHandler.handle({ type: 'commit' })
      },
      canTriggerMulti: () => false,
    }
  }

  private getCopyHandlers = (): HotkeyHandler => {
    return {
      getKeys: () => ['c', 'C'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta) return
        e.preventDefault()
        navigator.clipboard.writeText('')
        this.commandHandler.handle({
          type: 'copySelectedNodes',
          params: { clipboard: false },
        })
      },
      canTriggerMulti: () => true,
    }
  }

  private getCopyAttributesHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['ç'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || e.shiftKey) return
        e.preventDefault()
        this.commandHandler.handle({ type: 'copySelectedNodeAttributes' })
      },
      canTriggerMulti: () => true,
    }
  }

  private getCopyToClipboardHandler = (): HotkeyHandler => {
    return {
      getKeys: () => [','],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta) return
        e.preventDefault()
        this.commandHandler.handle({
          type: 'copySelectedNodes',
          params: { clipboard: true },
        })
      },
      canTriggerMulti: () => true,
    }
  }

  private getPasteAttributesHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['√'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || e.shiftKey) return
        e.preventDefault()
        this.commandHandler.handle({ type: 'pasteNodeAttributes' })
      },
      canTriggerMulti: () => true,
    }
  }

  private getCutHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['x', 'X'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta) return
        e.preventDefault()
        this.commandHandler.handle({ type: 'cutSelectedNodes' })
        this.commandHandler.handle({ type: 'commit' })
      },
      canTriggerMulti: () => true,
    }
  }

  private getReplaceHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['r', 'R'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || !e.shiftKey) return
        e.preventDefault()
        this.commandHandler.handle({ type: 'replaceSelectedNodes' })
        this.commandHandler.handle({ type: 'commit' })
      },
      canTriggerMulti: () => true,
    }
  }

  private getDuplicateHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['d', 'D'],
      handle: (e: KeyboardEvent) => {
        const index = this.animationEffectPanel.getSettings().index
        if (index !== null) return
        const meta = e.metaKey || e.ctrlKey
        if (!meta) return
        e.preventDefault()
        this.commandHandler.handle({
          type: 'duplicateSelectedNodes',
          params: { addOffset: true },
        })
        this.commandHandler.handle({ type: 'commit' })
      },
      canTriggerMulti: () => true,
    }
  }

  private getAutolayoutHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['a', 'A'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || !e.shiftKey) return
        e.preventDefault()
        this.autolayout.addAutolayout()
      },
      canTriggerMulti: () => true,
    }
  }

  private getGroupHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['g', 'G'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta) return
        e.preventDefault()
        e.shiftKey ? this.group.ungroup() : this.group.group()
      },
      canTriggerMulti: () => true,
    }
  }

  private getMoveToFrontHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['[', '{'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || e.shiftKey) return
        e.preventDefault()
        this.rearrange.moveToBack()
      },
      canTriggerMulti: () => true,
    }
  }

  private getMoveToBackHandler = (): HotkeyHandler => {
    return {
      getKeys: () => [']', '}'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || e.shiftKey) return
        e.preventDefault()
        this.rearrange.moveToFront()
      },
      canTriggerMulti: () => true,
    }
  }

  private getMoveForwardHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['ArrowRight', 'ArrowDown'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || e.shiftKey) return
        e.preventDefault()
        this.rearrange.moveForward(e.key === 'ArrowDown' ? 'v' : 'h')
      },
      canTriggerMulti: () => true,
    }
  }

  private getMoveBackwardHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['ArrowLeft', 'ArrowUp'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || e.shiftKey) return
        e.preventDefault()
        this.rearrange.moveBackward(e.key === 'ArrowUp' ? 'v' : 'h')
      },
      canTriggerMulti: () => true,
    }
  }

  private getPositionDeltaHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta) return
        e.preventDefault()
        switch (e.key) {
          case 'ArrowUp':
            this.positionDelta.move('up', e.shiftKey)
            break
          case 'ArrowDown':
            this.positionDelta.move('down', e.shiftKey)
            break
          case 'ArrowLeft':
            this.positionDelta.move('left', e.shiftKey)
            break
          case 'ArrowRight':
            this.positionDelta.move('right', e.shiftKey)
            break
        }
      },
      canTriggerMulti: () => true,
    }
  }

  private getSizeDeltaHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta) return
        e.preventDefault()
        switch (e.key) {
          case 'ArrowUp':
            this.sizeDelta.resize('up', e.shiftKey)
            break
          case 'ArrowDown':
            this.sizeDelta.resize('down', e.shiftKey)
            break
          case 'ArrowLeft':
            this.sizeDelta.resize('left', e.shiftKey)
            break
          case 'ArrowRight':
            this.sizeDelta.resize('right', e.shiftKey)
            break
        }
      },
      canTriggerMulti: () => true,
    }
  }

  private getTreeSelectionHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['Enter', 'Return'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta) return
        e.preventDefault()
        e.shiftKey
          ? this.treeSelection.selectParents()
          : this.treeSelection.selectChildren()
      },
      canTriggerMulti: () => true,
    }
  }

  private getRenameHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['r', 'R'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || e.shiftKey) return
        e.preventDefault()
        this.layersPanel.rename()
      },
      canTriggerMulti: () => true,
    }
  }

  private getCollapseLayersHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['¬'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || e.shiftKey) return
        if (!e.altKey) return
        e.preventDefault()
        this.layersPanel.toggleCollapsed()
      },
      canTriggerMulti: () => true,
    }
  }

  private getLockHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['l', 'L'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || !e.shiftKey) return
        e.preventDefault()
        this.attributes.toggleLocked()
      },
      canTriggerMulti: () => true,
    }
  }

  private getHideHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['h', 'H'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || !e.shiftKey) return
        e.preventDefault()
        this.attributes.toggleHidden()
      },
      canTriggerMulti: () => true,
    }
  }

  private getHandActionHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['h', 'H', ' '],
      handle: (e: KeyboardEvent) => {
        if (e.ctrlKey || e.metaKey) return
        switch (e.key) {
          case ' ':
            e.preventDefault()
            this.actionInitiator.hand('key')
            break
          case 'h':
          case 'H':
            e.preventDefault()
            this.actionInitiator.hand('mouse')
            break
        }
      },
      canTriggerMulti: () => true,
    }
  }

  private getAlignNodesHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['∑', 'å', 'ß', '∂', '√', '˙'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || e.shiftKey || !e.altKey) return
        e.preventDefault()
        switch (e.key) {
          case '∑':
            this.alignNodesAction.align('v', 'start')
            break
          case '√':
            this.alignNodesAction.align('v', 'center')
            break
          case 'ß':
            this.alignNodesAction.align('v', 'end')
            break
          case 'å':
            this.alignNodesAction.align('h', 'start')
            break
          case '˙':
            this.alignNodesAction.align('h', 'center')
            break
          case '∂':
            this.alignNodesAction.align('h', 'end')
            break
        }
      },
      canTriggerMulti: () => true,
    }
  }

  private getTextHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['b', 'B'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || e.shiftKey) return
        e.preventDefault()
        this.textAction.toggleBold()
      },
      canTriggerMulti: () => true,
    }
  }

  private getPreviewHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['p', 'P'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta || e.shiftKey) return
        e.preventDefault()
        this.previewPanel.setOpen(!this.previewPanel.getSettings().open)
      },
      canTriggerMulti: () => true,
    }
  }

  private getTemplateHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['a', 'A'],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (meta || e.shiftKey) return
        e.preventDefault()
        this.actionInitiator.toggleLibrary('openTemplateLibrary')
      },
      canTriggerMulti: () => true,
    }
  }

  private getExportHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['<'],
      handle: (e: KeyboardEvent) => {
        if (!process.env.REACT_APP_DEV_MODE) return
        if (!e.shiftKey) return
        e.preventDefault()
        this.nodeExporter.export()
      },
      canTriggerMulti: () => true,
    }
  }

  private getZoomHandler = (): HotkeyHandler => {
    return {
      getKeys: () => ['_', '-', '+', '='],
      handle: (e: KeyboardEvent) => {
        const meta = e.metaKey || e.ctrlKey
        if (!meta) return
        e.preventDefault()
        switch (e.key) {
          case '-':
          case '_':
            this.cameraService.zoomCameraByFactor(0.5)
            break
          case '+':
          case '=':
            this.cameraService.zoomCameraByFactor(2)
            break
        }
      },
      canTriggerMulti: () => true,
    }
  }
}
