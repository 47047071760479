import { IconKey } from 'assets/iconLibrary'
import { useState } from 'react'
import { DesignColor } from 'themes'
import Text, { textStyles } from 'components/Library/Text/Text'
import ButtonIcon from '../../Icon/ButtonIcon/ButtonIcon'
import { tooltipKey } from 'assets/tooltips'
import Tooltip from '../../Tooltip/Tooltip'
import useAction from 'hooks/editor/useAction'

interface ButtonBlockProps {
  buttonRef?: React.RefObject<HTMLDivElement>

  width?: 96 | 120 | 168 | 192 | 216

  text: string
  styles?: textStyles

  icon?: IconKey
  tooltipKey?: tooltipKey

  selected?: boolean
  dim?: boolean
  disabled?: boolean
  mixed?: boolean
  hasOverride?: boolean

  onClick?: () => void
}

export default function ButtonBlock({
  buttonRef,
  width = 96,
  text,
  styles = {
    size: 11,
    family: 'inter',
    weight: 'regular',
  },
  icon,
  tooltipKey,
  selected = false,
  dim = false,
  disabled = false,
  mixed = false,
  hasOverride = false,
  onClick,
}: ButtonBlockProps) {
  const action = useAction()
  const [hover, setHover] = useState(false)

  const opacity = getOpacity(disabled)
  const background = getBackground(selected, disabled)
  const boxShadow = getBoxShadow(hover && !action, disabled)

  return (
    <TooltipWrapper tooltipKey={tooltipKey}>
      <div
        ref={buttonRef}
        style={{
          width: width,
          height: 24,
          paddingLeft: icon ? 4 : 8,
          paddingRight: 8,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: 4,
          borderRadius: 4,
          opacity: opacity,
          background: background,
          boxShadow: boxShadow,
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
      >
        {icon && (
          <ButtonIcon
            icon={icon}
            color={DesignColor(hasOverride ? 'overrideParent' : 'text2')}
          />
        )}
        <Text
          color={DesignColor(
            hasOverride ? 'overrideParent' : dim ? 'text2' : 'text1'
          )}
          styles={styles}
        >
          {mixed ? 'Mixed' : text}
        </Text>
      </div>
    </TooltipWrapper>
  )
}

function TooltipWrapper({
  tooltipKey,
  children,
}: {
  tooltipKey?: tooltipKey
  children: React.ReactNode
}) {
  if (!tooltipKey) return <>{children}</>
  return <Tooltip tooltipKey={tooltipKey}>{children}</Tooltip>
}

function getBackground(selected: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (selected) return DesignColor('inputTint')
  return 'none'
}

function getBoxShadow(hover: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
}

function getOpacity(disabled: boolean) {
  return disabled ? 0.4 : 1
}
