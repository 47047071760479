import ToolbarCTAButtonLight from 'components/Library/Components/Toolbar/ToolbarCTAButtonLight/ToolbarCTAButtonLight'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function PreviewButton() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPreviewPanel()
  )
  if (!settings) return <></>
  const { open } = settings
  const { setOpen } = handlers

  return <ToolbarCTAButtonLight text="Preview" onClick={() => setOpen(!open)} />
}
