import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import { useAnimatedTabs } from 'hooks/ui/useAnimatedTabs'
import _ from 'lodash'
import { useState } from 'react'
import { TabsLine } from './TabsLine'

interface TabsBlockProps {
  width?: 192 | 'fill'

  tabs: string[]

  selected: string
  select(tab: string): void
}

export default function TabsBlock({
  width = 192,
  tabs,
  selected,
  select,
}: TabsBlockProps) {
  const { line, refs } = useAnimatedTabs(selected, tabs, 0)
  const [hover, setHover] = useState(-1)

  const single = tabs.length === 1

  return (
    <div
      style={{
        position: 'relative',
        width: width === 'fill' ? '100%' : width,
        height: 24,
        padding: '0 8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 16,
      }}
    >
      {tabs.map((tab, index) => {
        const isSelected = selected === tab
        const isHovered = hover === index
        return (
          <Text
            key={index}
            textRef={refs[tab]}
            styles={{ weight: 'bold' }}
            color={getTextColor(isSelected, isHovered, single)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(-1)}
            onClick={() => select(tab)}
          >
            {_.capitalize(tab)}
          </Text>
        )
      })}
      {!single && (
        <TabsLine line={line} color={DesignColor('inputHighlight')} />
      )}
    </div>
  )
}

function getTextColor(selected: boolean, hovered: boolean, single: boolean) {
  if (single) return DesignColor('text1')
  if (selected) return DesignColor('inputHighlight')
  if (hovered) return DesignColor('text1')
  return DesignColor('text2')
}
