import OnboardingCta from 'components/Library/Onboarding/OnboardingCta'
import OnboardingDivider from 'components/Library/Onboarding/OnboardingDivider'
import OnboardingDoubleInputRow from 'components/Library/Onboarding/OnboardingDoubleInputRow'
import OnboardingInputField from 'components/Library/Onboarding/OnboardingInputField'
import OnboardingInputWrapper from 'components/Library/Onboarding/OnboardingInputWrapper'
import OnboardingLogo from 'components/Library/Onboarding/OnboardingLogo'
import OnboardingPanel from 'components/Library/Onboarding/OnboardingPanel'
import OnboardingRedirectBlock from 'components/Library/Onboarding/OnboardingRedirectBlock'
import OnboardingTitle from 'components/Library/Onboarding/OnboardingTitle'
import OnboardingBackground from 'components/Library/Onboarding/OnboardingBackground'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import useRedirectLoggedIn from 'hooks/admin/useRedirectLoggedIn'
import useToast from 'hooks/editor/useToast'
import useUserService from 'hooks/services/useUserService'
import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/

export default function Signup() {
  useRedirectLoggedIn()

  const toast = useToast()
  const userService = useUserService()
  const { toLogin, toProjects } = useNavigatePage()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const submitHandler = useCallback(async () => {
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      toast(`Please fill in all fields.`, 'error')
      return
    }
    if (password !== confirmPassword) {
      toast(`The supplied passwords do not match.`, 'error')
      return
    }
    if (!emailRegex.test(email)) {
      toast('Please enter a valid email.', 'error')
      return
    }

    try {
      await userService.createAccount({
        email,
        password,
        firstName,
        lastName,
      })
      await userService.login({ email, password })
      toProjects()
    } catch (e) {
      if (!axios.isAxiosError(e)) return
      if (e.response?.status === 401) {
        toast('Account already exists, please login instead.', 'error')
      } else {
        toast('Repaint is offline, please try again later.', 'error')
      }
    }
  }, [
    email,
    password,
    firstName,
    lastName,
    confirmPassword,
    userService,
    toProjects,
    toast,
  ])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        submitHandler()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [submitHandler])

  return (
    <OnboardingBackground>
      {
        <>
          <OnboardingLogo />
          <OnboardingPanel>
            <OnboardingTitle>Sign up</OnboardingTitle>
            <OnboardingInputWrapper>
              <OnboardingDoubleInputRow>
                <OnboardingInputField
                  label="First Name"
                  placeholder="First Name"
                  value={firstName}
                  setValue={setFirstName}
                />
                <OnboardingInputField
                  label="Last Name"
                  placeholder="Last Name"
                  value={lastName}
                  setValue={setLastName}
                />
              </OnboardingDoubleInputRow>
              <OnboardingInputField
                label="Email"
                icon="User"
                placeholder="Email Address"
                value={email}
                setValue={setEmail}
              />
              <OnboardingInputField
                label="Password"
                icon="PasswordLock"
                placeholder="Password"
                value={password}
                setValue={setPassword}
                password={true}
              />
              <OnboardingInputField
                label="Confirm Password"
                icon="PasswordLock"
                placeholder="Confirm Password"
                value={confirmPassword}
                setValue={setConfirmPassword}
                password={true}
              />
            </OnboardingInputWrapper>
            <OnboardingCta onClick={submitHandler}>Submit</OnboardingCta>
            <OnboardingDivider />
            <OnboardingRedirectBlock
              text="Already have an account?"
              buttonLabel="Log in"
              onClick={toLogin}
            />
          </OnboardingPanel>
        </>
      }
    </OnboardingBackground>
  )
}
