import { WheelEventListener } from 'application/browser'
import { CameraService } from 'application/camera'

export class CameraBrowserEventHandler implements WheelEventListener {
  private cameraService: CameraService

  constructor(cameraService: CameraService) {
    this.cameraService = cameraService
  }

  handleWheel = (e: WheelEvent) => {
    if (e.ctrlKey) e.preventDefault()
    const target = e.target as HTMLElement
    if (target.id !== 'webgl-canvas') return
    e.preventDefault()

    if (e.metaKey || e.ctrlKey) {
      this.cameraService.zoomCamera(e)
    } else {
      this.cameraService.panCamera(e, e.shiftKey ? 'horizontal' : 'vertical')
    }
  }
}
