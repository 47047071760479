import { ReadOnlyDocumentSelection, SelectionSide } from 'application/selection'
import { Action, ActionEventResult, ActionHandler } from '../types'
import { CommandHandler } from 'application/client'
import { NodeResizeAction } from 'application/action/resize'
import { CoordinatesConversion } from 'application/camera'
import { Cursor } from 'application/cursor'
import {
  AlignmentLine,
  PointAlignmentCalculator,
  ProportionMap,
} from 'application/action'
import { Point, Rectangle } from 'application/shapes'
import { HapticAlignment } from 'editor/haptic/alignment'
import { ReadOnlyDocument } from 'application/document'
import _ from 'lodash'

export class ResizeAction implements ActionHandler {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private commandHandler: CommandHandler
  private resize: NodeResizeAction
  private coordinates: CoordinatesConversion
  private lines: AlignmentLine[]
  private align: PointAlignmentCalculator

  private hapticAlignment: HapticAlignment

  private resizeSide: SelectionSide
  private start: Point
  private proportionMap: ProportionMap
  private window: Rectangle

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    commandHandler: CommandHandler,
    resize: NodeResizeAction,
    coordinates: CoordinatesConversion,
    lines: AlignmentLine[],
    align: PointAlignmentCalculator,

    handleAlignment: HapticAlignment,

    side: SelectionSide,
    startPoint: Point,
    proportionMap: ProportionMap,
    window: Rectangle
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.commandHandler = commandHandler
    this.resize = resize
    this.coordinates = coordinates
    this.lines = lines
    this.align = align

    this.hapticAlignment = handleAlignment

    this.resizeSide = side
    this.start = startPoint
    this.proportionMap = proportionMap
    this.window = window
  }

  getType = (): Action => {
    return 'resize'
  }

  getCursor = (): Cursor => {
    return this.resizeSide
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    this.resizeNodes(e)
    return { done: false, passthrough: false }
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    this.resizeNodes(e)
    this.clearLines()

    this.commandHandler.handle({ type: 'commit' })

    return { done: true, passthrough: false }
  }

  private resizeNodes = (e: MouseEvent): void => {
    const ids = this.getSelectedIds()
    const point = this.coordinates.get(e)
    const aligned = this.alignPoint(point)
    const delta = { dx: aligned.x - this.start.x, dy: aligned.y - this.start.y }

    this.resize.resize(
      ids,
      this.proportionMap,
      this.window,
      delta,
      this.resizeSide
    )
  }

  private getSelectedIds = (): string[] => {
    return this.documentSelection.getSelected().map((node) => node.getId())
  }

  private alignPoint = (point: Point): Point => {
    const magnet = this.coordinates.getZoomAdjusted(8)

    const window = this.computeWindow(point)

    const filteredLineModes = this.getFilteredLineModes()
    const aligned = this.align.alignResize(
      point,
      window,
      this.lines.filter((line) => !filteredLineModes.includes(line.direction)),
      this.resizeSide,
      magnet
    )
    this.hapticAlignment.setLines(aligned.lines)
    return aligned.point
  }

  private computeWindow = (point: Point): Rectangle => {
    const window = { ...this.window }
    const delta = { dx: point.x - this.start.x, dy: point.y - this.start.y }

    if (this.resizeSide.includes('top')) {
      window.y += delta.dy
      window.h -= delta.dy
    }
    if (this.resizeSide.includes('bottom')) {
      window.h += delta.dy
    }
    if (this.resizeSide.includes('left')) {
      window.x += delta.dx
      window.w -= delta.dx
    }
    if (this.resizeSide.includes('right')) {
      window.w += delta.dx
    }

    return window
  }

  private clearLines = (): void => {
    this.hapticAlignment.setLines([])
  }

  private getFilteredLineModes = (): ('h' | 'v')[] => {
    const modes: ('h' | 'v')[] = []
    const selected = this.documentSelection.getSelected()
    for (const node of selected) {
      const parent = this.document.getParent(node)
      if (!parent) continue

      const parentMode = parent.getStyleAttribute('autolayout.mode')
      if (parentMode !== 'flex') continue

      const direction = parent.getStyleAttribute('autolayout.direction')
      if (direction === 'row') modes.push('v')
      if (direction === 'column') modes.push('h')
    }

    return _.uniq(modes)
  }
}
