import { Node } from 'application/node'
import { PositionEngineCalculator } from './types'
import { WriteDocument } from 'application/document'
import {
  AutolayoutAttributes,
  getAutolayoutAttributes,
  isAbsolutePositionMode,
} from 'application/attributes'
import { truncate } from 'application/math'
import { splitChildrenIntoRows } from '../utils'

export class AutolayoutPositionCalculator implements PositionEngineCalculator {
  update(node: Node, document: WriteDocument): void {
    const autolayoutAttributes = getAutolayoutAttributes(node)
    if (!autolayoutAttributes) return

    const children = this.getAutolayoutChildren(node, document)
    if (children.length === 0) return

    if (autolayoutAttributes.direction !== 'wrap') {
      this.positionChildren(autolayoutAttributes, children, document)
    } else {
      this.positionChildrenWrap(autolayoutAttributes, children, document)
    }
  }

  positionChildren(
    attributes: AutolayoutAttributes,
    children: Node[],
    document: WriteDocument
  ): void {
    const {
      x,
      y,
      w,
      h,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      gap,
      direction,
      alignMain,
      alignCounter,
    } = attributes

    const mainSizeKey = direction === 'row' ? 'w' : 'h'
    const mainPositionKey = direction === 'row' ? 'x' : 'y'
    const counterSizeKey = direction === 'row' ? 'h' : 'w'
    const counterPositionKey = direction === 'row' ? 'y' : 'x'

    const parentMainSize = direction === 'row' ? w : h
    const parentCounterSize = direction === 'row' ? h : w
    const parentMainPoint = direction === 'row' ? x : y
    const parentCounterPoint = direction === 'row' ? y : x

    const paddingMain1 = direction === 'row' ? paddingLeft : paddingTop
    const paddingMain2 = direction === 'row' ? paddingRight : paddingBottom
    const paddingCounter1 = direction === 'row' ? paddingTop : paddingLeft
    const paddingCounter2 = direction === 'row' ? paddingBottom : paddingRight

    let totalSize = children.reduce((acc, child) => {
      return acc + child.getBaseAttribute(mainSizeKey)
    }, 0)

    let mainPoint = parentMainPoint
    let spacing = gap

    if (alignCounter === 'spaced') {
      spacing =
        (parentMainSize - paddingMain1 - paddingMain2 - totalSize) /
        (children.length - 1)
      mainPoint += paddingMain1
    } else {
      totalSize += spacing * (children.length - 1)
      switch (alignCounter) {
        case 'start':
          mainPoint += paddingMain1
          break
        case 'center':
          mainPoint +=
            (parentMainSize - totalSize) / 2 + (paddingMain1 - paddingMain2) / 2
          break
        case 'end':
          mainPoint += parentMainSize - paddingMain2 - totalSize
          break
      }
    }

    for (const child of children) {
      const childPositionMain = child.getBaseAttribute(mainPositionKey)
      const childPositionCounter = child.getBaseAttribute(counterPositionKey)
      const childSizeMain = child.getBaseAttribute(mainSizeKey)
      const childCounterSize = child.getBaseAttribute(counterSizeKey)

      let counterPoint = parentCounterPoint
      switch (alignMain) {
        case 'start':
          counterPoint += paddingCounter1
          break
        case 'center':
          counterPoint +=
            (parentCounterSize - childCounterSize) / 2 +
            (paddingCounter1 - paddingCounter2) / 2
          break
        case 'end':
          counterPoint += parentCounterSize - childCounterSize - paddingCounter2
          break
      }

      const childDeltaMain = mainPoint - childPositionMain
      const childDeltaCounter = counterPoint - childPositionCounter

      child.setBaseAttribute(mainPositionKey, truncate(mainPoint, 2))
      child.setBaseAttribute(counterPositionKey, truncate(counterPoint, 2))

      this.moveSubtreeByDelta(
        child,
        mainPositionKey,
        counterPositionKey,
        childDeltaMain,
        childDeltaCounter,
        document
      )

      mainPoint += childSizeMain + spacing
    }
  }

  private positionChildrenWrap(
    attributes: AutolayoutAttributes,
    children: Node[],
    document: WriteDocument
  ): void {
    const {
      x,
      y,
      w,
      h,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      alignMain,
      alignCounter,
    } = attributes

    const rows = splitChildrenIntoRows(attributes, children)
    const rowHeightTotal = this.computeRowHeightTotal(rows)
    const columnGap = this.computeColumnGap(attributes, rowHeightTotal, rows)
    const rowHeightTotalWithGap = rowHeightTotal + columnGap * (rows.length - 1)

    let rowTop = y
    if (alignCounter === 'spaced' && rows.length > 1) {
      rowTop += paddingTop
    } else {
      switch (alignMain) {
        case 'start':
          rowTop += paddingTop
          break
        case 'center':
          rowTop += (h - rowHeightTotalWithGap) / 2
          break
        case 'end':
          rowTop += h - rowHeightTotalWithGap - paddingBottom
          break
      }
    }

    for (const row of rows) {
      const rowWidth = this.computeRowWidth(row)
      const rowGap = this.computeRowGap(attributes, rowWidth, row.length)
      const rowTotalWidth = rowWidth + rowGap * (row.length - 1)

      let rowLeft = x
      switch (alignCounter) {
        case 'spaced':
        case 'start':
          rowLeft += paddingLeft
          break
        case 'center':
          rowLeft += (w - rowTotalWidth) / 2
          break
        case 'end':
          rowLeft += w - rowTotalWidth - paddingRight
          break
      }

      for (const child of row) {
        const childWidth = child.getBaseAttribute('w')

        const childDeltaX = rowLeft - child.getBaseAttribute('x')
        const childDeltaY = rowTop - child.getBaseAttribute('y')

        child.setBaseAttribute('x', truncate(rowLeft, 2))
        child.setBaseAttribute('y', truncate(rowTop, 2))

        this.moveSubtreeByDelta(
          child,
          'x',
          'y',
          childDeltaX,
          childDeltaY,
          document
        )

        rowLeft += childWidth + rowGap
      }

      rowTop += this.computeMaxRowHeight(row) + columnGap
    }
  }

  private computeRowHeightTotal(rows: Node[][]): number {
    let total = 0
    for (const row of rows) {
      total += row.reduce((a, c) => Math.max(c.getBaseAttribute('h'), a), 0)
    }
    return total
  }

  private computeRowWidth = (row: Node[]): number => {
    return row.reduce((acc, child) => acc + child.getBaseAttribute('w'), 0)
  }

  private computeRowGap(
    attributes: AutolayoutAttributes,
    rowWidth: number,
    rowLength: number
  ): number {
    const { w, paddingLeft, paddingRight, gap, alignCounter } = attributes
    const spaced = alignCounter === 'spaced'
    const rowGap = spaced
      ? (w - paddingLeft - paddingRight - rowWidth) / (rowLength - 1)
      : gap
    return Math.max(rowGap, 0)
  }

  private computeMaxRowHeight = (row: Node[]): number => {
    return row.reduce(
      (acc, child) => Math.max(acc, child.getBaseAttribute('h')),
      0
    )
  }

  private computeColumnGap(
    attributes: AutolayoutAttributes,
    rowHeightTotal: number,
    rows: Node[][]
  ): number {
    const { h, paddingTop, paddingBottom, gap, alignCounter } = attributes
    const spaced = alignCounter === 'spaced'
    if (spaced) {
      if (rows.length === 1) return 0
      return Math.max(
        (h - paddingTop - paddingBottom - rowHeightTotal) / (rows.length - 1),
        0
      )
    }
    return gap
  }

  private moveSubtreeByDelta(
    node: Node,
    mainKey: 'x' | 'y',
    counterKey: 'x' | 'y',
    deltaMain: number,
    deltaCounter: number,
    document: WriteDocument
  ): void {
    const children = node.getChildren()
    if (!children) return

    for (const childId of children) {
      const child = document.getNode(childId)
      if (!child) continue

      const childPositionMain = child.getBaseAttribute(mainKey)
      const childPositionCounter = child.getBaseAttribute(counterKey)

      child.setBaseAttribute(
        mainKey,
        truncate(childPositionMain + deltaMain, 2)
      )
      child.setBaseAttribute(
        counterKey,
        truncate(childPositionCounter + deltaCounter, 2)
      )
    }
  }

  private getAutolayoutChildren(node: Node, document: WriteDocument): Node[] {
    const children: Node[] = []
    for (const childId of node.getChildren() || []) {
      const child = document.getNode(childId)
      if (!child || child.getStyleAttribute('hidden')) continue
      if (isAbsolutePositionMode(child)) continue

      children.push(child)
    }

    return children
  }
}
