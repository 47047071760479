import { Project, UserInfo } from 'application/service'
import useRedirectLoggedOut from 'hooks/admin/useRedirectLoggedOut'
import { atom, useRecoilState } from 'recoil'
import persistAtom from 'components/State/persistAtom'
import ProjectTopbar from 'components/Library/Projects/ProjectTopbar'
import ProjectBackground from 'components/Library/Projects/ProjectBackground'
import ProjectMainContainer from 'components/Library/Projects/ProjectsMainContainer'
import ProjectSidebar from 'components/Library/Projects/ProjectSidebar'
import ProjectBody from 'components/Library/Projects/ProjectBody/ProjectBody'
import ProjectSettingBody from 'components/Library/Projects/ProjectSettingsBody/ProjectSettingBody'
import usePopulateUserInfo from 'hooks/editor/usePopulateUserInfo'
import IntercomMenu from 'components/Library/Support/IntercomMenu'

export const activeProjectAtom = atom<Project | null>(
  persistAtom({
    key: 'activeProject',
    default: null,
    persistMode: 'local',
  })
)

export const projectListAtom = atom<Project[]>(
  persistAtom({
    key: 'projectsList2',
    default: [],
    persistMode: 'local',
  })
)

export const userInfoAtom = atom<UserInfo>({
  key: 'userInfo',
  default: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
  },
})

export type ProjectTab = 'Projects' | 'Settings'

const tabAtom = atom<ProjectTab>(
  persistAtom({
    key: 'projectTab',
    default: 'Projects',
    persistMode: 'local',
  })
)

export default function Projects() {
  useRedirectLoggedOut()
  usePopulateUserInfo()

  const [tab, setTab] = useRecoilState(tabAtom)

  return (
    <ProjectBackground>
      <IntercomMenu />
      <ProjectTopbar activeTab={tab} />
      <ProjectMainContainer>
        <ProjectSidebar activeTab={tab} setTab={setTab} />
        {tab === 'Projects' && <ProjectBody />}
        {tab === 'Settings' && <ProjectSettingBody />}
      </ProjectMainContainer>
    </ProjectBackground>
  )
}
