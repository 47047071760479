import { DesignColor } from 'themes'
import { Rectangle } from 'application/shapes'
import { DraggingLine } from 'editor/action/dragCanvas/line'

interface CanvasDraggingLineProps {
  line: DraggingLine | null
}

export default function CanvasDraggingLine({ line }: CanvasDraggingLineProps) {
  if (!line) return <></>

  const box = getCanvasRowBox(line.id)
  const topOffset = getTopOffset(line.id)
  if (!box) return <></>

  const top = line.position === 'top' ? box.y : box.y + box.h

  return <Line top={top - topOffset} thickness={2} />
}

function getCanvasRowBox(id: string | null): Rectangle | null {
  if (!id) return null

  const layerRow = document.getElementById(`canvas-row-${id}`)
  if (!layerRow) return null

  const { left, top, height, width } = layerRow.getBoundingClientRect()
  return {
    x: left,
    y: top,
    w: width,
    h: height,
  }
}

function getTopOffset(id: string | null): number {
  if (!id) return 0
  const canvasContainer = document.getElementById('canvases')

  if (!canvasContainer) return 0
  return canvasContainer.getBoundingClientRect().top
}

function Line({ top, thickness }: { top: number; thickness: number }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: top - thickness / 2,
        right: 0,
        width: '100%',
        height: thickness,
        background: DesignColor('text1'),
        zIndex: 1000,
      }}
    />
  )
}
