import { InteractableNodeFilter, NodeSelectionAction } from 'application/action'
import { isCanvasClosest } from 'application/browser'
import { CoordinatesConversion } from 'application/camera'
import { CommandHandler } from 'application/client/command'
import { ActionInitiator } from './initiator'
import {
  ReadOnlyDocumentSelection,
  SelectionSide,
  getSelectionSide,
} from 'application/selection'
import { Point, pointInRectangle } from 'application/shapes'
import { HotkeyActionHandler } from './hotkey/hotkey'
import { ActiveAction } from './active'
import { EditorCursor } from 'editor/cursor/cursor'
import { Action } from './types'
import { ReadOnlyDocument } from 'application/document'
import { PasteAction } from './paste/action'
import { UploadImageAction } from './uploadImage/action'

export class ActionCoordinator {
  private selection: NodeSelectionAction
  private interactable: InteractableNodeFilter
  private pasteAction: PasteAction
  private uploadImageAction: UploadImageAction
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private coordinates: CoordinatesConversion
  private commandHandler: CommandHandler
  private initiator: ActionInitiator
  private activeAction: ActiveAction
  private cursor: EditorCursor
  private hotkeyActionHandler: HotkeyActionHandler

  private mouseActionStartPoint: Point | null
  private mouseActionType: 'move' | 'resize' | null
  private mouseResizeSide: SelectionSide | null
  private lastClickedKey: string | null
  private lastClickedTime: number | null

  constructor(
    selection: NodeSelectionAction,
    interactable: InteractableNodeFilter,
    pasteAction: PasteAction,
    uploadImageAction: UploadImageAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    coordinates: CoordinatesConversion,
    commandHandler: CommandHandler,
    initiator: ActionInitiator,
    activeAction: ActiveAction,
    cursor: EditorCursor,
    hotkeyActionHandler: HotkeyActionHandler
  ) {
    this.selection = selection
    this.interactable = interactable
    this.pasteAction = pasteAction
    this.uploadImageAction = uploadImageAction
    this.document = document
    this.documentSelection = documentSelection
    this.coordinates = coordinates
    this.commandHandler = commandHandler
    this.initiator = initiator
    this.activeAction = activeAction
    this.cursor = cursor
    this.hotkeyActionHandler = hotkeyActionHandler

    this.mouseActionStartPoint = null
    this.mouseActionType = null
    this.mouseResizeSide = null
    this.lastClickedKey = null
    this.lastClickedTime = null
  }

  mouseDown = (e: MouseEvent): void => {
    if (!isCanvasClosest(e)) return
    if (e.button === 2) return
    if (e.button === 1) {
      this.initiator.hand('wheel')
      return
    }

    const resizeMode = this.getResizeType(e)
    if (resizeMode) {
      this.setStartPoint(e)
      this.mouseResizeSide = resizeMode
      this.mouseActionType = 'resize'
      return
    }

    const uploadImage = this.uploadImageAtPoint(e)
    if (uploadImage) return

    const editText = this.editTextAtPoint(e)
    if (editText) return

    const clickedSelected = this.clickedMoveTarget(e)
    if (clickedSelected && e.detail === 1) {
      this.setStartPoint(e)
      this.mouseActionType = 'move'
      return
    }

    const selected = this.selectNodes(e, false)
    if (selected) {
      this.setStartPoint(e)
      this.mouseActionType = 'move'
      return
    }

    const point = this.coordinates.get(e)
    this.initiator.multiselect(point)
  }

  mouseMove = (e: MouseEvent): void => {
    if (!isCanvasClosest(e)) return

    const point = this.coordinates.get(e)
    if (this.canStartAction(point) && this.mouseActionStartPoint) {
      switch (this.mouseActionType) {
        case 'move':
          this.initiator.move(this.mouseActionStartPoint, e.altKey)
          break
        case 'resize':
          if (!this.mouseResizeSide) break
          this.initiator.resize(
            this.mouseResizeSide,
            this.mouseActionStartPoint
          )
      }
      this.clearStartPoint()
      this.setResizeCursor(null)
    } else if (this.mouseActionType !== 'move') {
      const resizeSide = this.getResizeType(e)
      this.setResizeCursor(resizeSide)
    }
  }

  mouseUp = (e: MouseEvent): void => {
    if (!isCanvasClosest(e)) return
    if (e.button === 2) return

    this.clearStartPoint()
    this.selectNodes(e, true)
  }

  keyDown = (e: KeyboardEvent): void => {
    this.hotkeyActionHandler.handleKeyDown(e)
    if (this.activeAction.get() !== null) {
      this.hotkeyActionHandler.reset()
    }
  }

  keyUp = (): void => {
    this.hotkeyActionHandler.handleKeyUp()
  }

  paste = (e: ClipboardEvent): void => {
    this.pasteAction.paste(e)
  }

  drop = (e: DragEvent): void => {
    this.pasteAction.drop(e)
  }

  nextAction = (action: Action): void => {
    this.activeAction.reset()
    switch (action) {
      case 'editText':
        this.commandHandler.handle({ type: 'editText' })
        this.commandHandler.handle({ type: 'commit' })
        break
    }
  }

  private selectNodes = (e: MouseEvent, up: boolean): boolean => {
    const selected = this.selection.selectAtPoint(
      this.coordinates.get(e),
      e.metaKey || e.ctrlKey,
      e.shiftKey,
      e.detail > 1 && !up
    )
    this.commandHandler.handle({ type: 'commit' })
    return selected
  }

  private canStartAction = (point: Point): boolean => {
    if (!this.mouseActionStartPoint) return false
    const xDelta = Math.abs(point.x - this.mouseActionStartPoint.x)
    const yDelta = Math.abs(point.y - this.mouseActionStartPoint.y)
    const threshold = this.coordinates.getZoomAdjusted(5)
    return xDelta > threshold || yDelta > threshold
  }

  private setStartPoint = (e: MouseEvent): void => {
    const point = this.coordinates.get(e)
    this.mouseActionStartPoint = point
  }

  private clearStartPoint = (): void => {
    this.mouseActionStartPoint = null
    this.mouseActionType = null
    this.mouseResizeSide = null
  }

  private clickedMoveTarget = (e: MouseEvent): boolean => {
    const point = this.coordinates.get(e)
    const selected = this.interactable.isSelectedAtPoint(point)
    if (selected) return true

    const rectangle = this.documentSelection.getSelectionRectangle()
    if (!rectangle) return false

    return pointInRectangle(point, rectangle)
  }

  private getResizeType = (e: MouseEvent): SelectionSide | null => {
    const bubble = this.coordinates.getZoomAdjusted(8)
    const bar = this.coordinates.getZoomAdjusted(12)
    const point = this.coordinates.get(e)
    const rectangle = this.documentSelection.getSelectionRectangle()
    if (!rectangle) return null
    return getSelectionSide(point, rectangle, bubble, bar)
  }

  private setResizeCursor = (resizeSide: SelectionSide | null): void => {
    this.cursor.set(resizeSide ? resizeSide : 'default')
  }

  private editTextAtPoint = (e: MouseEvent): boolean => {
    if (!isCanvasClosest(e)) return false

    const point = this.coordinates.get(e)
    const drill = e.metaKey || e.ctrlKey
    const interactable = this.interactable.nodeAtPoint(
      point,
      drill,
      e.detail > 1
    )
    if (!interactable) return false

    const node = this.document.getNode(interactable)
    if (!node || node.getBaseAttribute('type') !== 'text') return false

    if (this.checkTime() && this.lastClickedKey === interactable) {
      this.commandHandler.handle({ type: 'editText' })
      this.commandHandler.handle({ type: 'commit' })
      this.clearLastClicked()
      return true
    }

    this.lastClickedKey = interactable
    return false
  }

  private uploadImageAtPoint = (e: MouseEvent): boolean => {
    if (!isCanvasClosest(e)) return false

    const point = this.coordinates.get(e)
    const drill = e.metaKey || e.ctrlKey
    const interactable = this.interactable.nodeAtPoint(
      point,
      drill,
      e.detail > 1
    )
    if (!interactable) return false

    const node = this.document.getNode(interactable)
    if (!node || node.getBaseAttribute('type') !== 'image') return false

    if (this.checkTime() && this.lastClickedKey === interactable) {
      this.uploadImageAction.upload(node.getId())
    }

    this.lastClickedKey = interactable
    return false
  }

  private checkTime = (): boolean => {
    const now = new Date().getTime()
    if (this.lastClickedTime === null) {
      this.lastClickedTime = now
      return false
    }
    const diff = now - this.lastClickedTime
    this.lastClickedTime = now
    return diff < 300
  }

  private clearLastClicked = (): void => {
    this.lastClickedKey = null
    this.lastClickedTime = null
  }
}
