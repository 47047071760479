import { LayoutDependencyMode } from '../types'
import { Node, ReadOnlyNode } from 'application/node'
import { SizeEngineCalculator } from './types'
import { StyleMap } from 'application/attributes'
import { ReadOnlyDocument, WriteDocument } from 'application/document'
import { truncate } from 'application/math'
import { minMaxSize } from './utils'

export class AbsoluteSizeCalculator implements SizeEngineCalculator {
  update(
    node: Node,
    mode: LayoutDependencyMode,
    _: ReadOnlyDocument,
    document: WriteDocument
  ): void {
    const parent = document.getParent(node)
    if (!parent) return

    if (parent.getBaseAttribute('type') === 'canvas') return

    let setWidth = false
    let setHeight = false
    let width = node.getBaseAttribute('w')
    let height = node.getBaseAttribute('h')

    const isWConstrained = this.isConstrained('w', node.getStyleAttributes())
    const isHConstrained = this.isConstrained('h', node.getStyleAttributes())

    if (['w', 'wh'].includes(mode) && isWConstrained) {
      const left = this.computePosition(node, parent, 'left')
      const right = this.computePosition(node, parent, 'right')
      width = truncate(right - left)
      setWidth = true
    }
    if (['h', 'wh'].includes(mode) && isHConstrained) {
      const top = this.computePosition(node, parent, 'top')
      const bottom = this.computePosition(node, parent, 'bottom')
      height = truncate(bottom - top)
      setHeight = true
    }

    width = minMaxSize(width, 'w', node)
    height = minMaxSize(height, 'h', node)

    if (setWidth) {
      node.setBaseAttribute('w', width)
      node.setStyleAttribute('size.w', width)
    }
    if (setHeight) {
      node.setBaseAttribute('h', height)
      node.setStyleAttribute('size.h', height)
    }
  }

  private isConstrained = (axis: 'w' | 'h', styles: StyleMap): boolean => {
    switch (axis) {
      case 'w':
        return (
          styles['position.left.auto'] !== 'none' &&
          styles['position.right.auto'] !== 'none'
        )
      case 'h':
        return (
          styles['position.top.auto'] !== 'none' &&
          styles['position.bottom.auto'] !== 'none'
        )
    }
  }

  private computePosition = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    mode: 'left' | 'right' | 'top' | 'bottom'
  ): number => {
    switch (mode) {
      case 'top':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const top = node.getStyleAttribute(`position.${mode}`)
            if (top === undefined) return 0
            return parent.getBaseAttribute('y') + top
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return (
              parent.getBaseAttribute('y') +
              (percent / 100) * parent.getBaseAttribute('h')
            )
        }
        break
      case 'bottom':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('h')
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const bottom = node.getStyleAttribute(`position.${mode}`)
            if (bottom === undefined) return 0
            return (
              parent.getBaseAttribute('y') +
              parent.getBaseAttribute('h') -
              bottom
            )
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return (
              parent.getBaseAttribute('y') +
              parent.getBaseAttribute('h') -
              (percent / 100) * parent.getBaseAttribute('h')
            )
        }
        break
      case 'left':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const left = node.getStyleAttribute(`position.${mode}`)
            if (left === undefined) return 0
            return parent.getBaseAttribute('x') + left
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return (
              parent.getBaseAttribute('x') +
              (percent / 100) * parent.getBaseAttribute('w')
            )
        }
        break
      case 'right':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('w')
        switch (node.getStyleAttribute(`position.${mode}.auto`)) {
          case 'fixed':
            const right = node.getStyleAttribute(`position.${mode}`)
            if (right === undefined) return 0
            return (
              parent.getBaseAttribute('x') +
              parent.getBaseAttribute('w') -
              right
            )
          case 'percent':
            const percent = node.getStyleAttribute(`position.${mode}.percent`)
            if (percent === undefined) return 0
            return (
              parent.getBaseAttribute('x') +
              parent.getBaseAttribute('w') -
              (percent / 100) * parent.getBaseAttribute('w')
            )
        }
        break
    }
    return 0
  }
}
