import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { Rectangle } from 'application/shapes'
import { ParentOrder } from './types'
import { PositionMap } from '../types'

type ChildPositions = {
  [key: string]: Rectangle
}

export class ReorderHandler {
  private document: ReadOnlyDocument

  constructor(document: ReadOnlyDocument) {
    this.document = document
  }

  sortNodeChildren = (ids: string[], positionMap: PositionMap): ParentOrder => {
    const nodes = this.getNodes(ids)
    const parents = this.getParents(nodes)
    const parentOrder: ParentOrder = {}
    const updatedPositions: ChildPositions = {}

    this.addChildrenPositions(parents, updatedPositions)
    this.updatePositions(ids, positionMap, updatedPositions)

    this.addChildrenOrder(parents, parentOrder)
    this.sortAllChildren(updatedPositions, parentOrder)

    return parentOrder
  }

  private getNodes = (ids: string[]): ReadOnlyNode[] => {
    return ids
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private getParents = (nodes: ReadOnlyNode[]): ReadOnlyNode[] => {
    return nodes
      .map((node) => this.document.getParent(node))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private addChildrenPositions = (
    parents: ReadOnlyNode[],
    positions: ChildPositions
  ): void => {
    for (const parent of parents) {
      const children = parent.getChildren()
      if (!children) return

      for (const id of children) {
        const child = this.document.getNode(id)
        if (!child) continue

        positions[id] = {
          x: child.getBaseAttribute('x'),
          y: child.getBaseAttribute('y'),
          w: child.getBaseAttribute('w'),
          h: child.getBaseAttribute('h'),
        }
      }
    }
  }

  private addChildrenOrder = (
    parents: ReadOnlyNode[],
    order: ParentOrder
  ): void => {
    for (const parent of parents) {
      const children = parent.getChildren()
      if (!children) return

      const mode = parent.getStyleAttribute('autolayout.mode')
      if (mode !== 'flex') return

      order[parent.getId()] = children
    }
  }

  private updatePositions = (
    ids: string[],
    positionMap: PositionMap,
    updatedPositions: ChildPositions
  ): void => {
    ids.forEach((id) => {
      const position = updatedPositions[id]
      if (!position) return

      const newPosition = positionMap[id]
      if (!newPosition) return

      updatedPositions[id] = {
        x: newPosition.x,
        y: newPosition.y,
        w: position.w,
        h: position.h,
      }
    })
  }

  private sortAllChildren = (
    updatedPositions: ChildPositions,
    parentOrder: ParentOrder
  ): void => {
    Object.keys(parentOrder).forEach((id) => {
      const parent = this.document.getNode(id)
      if (!parent) return

      const direction = parent.getStyleAttribute('autolayout.direction')
      if (!direction || direction === 'wrap') return

      const mode = direction === 'row' ? 'x' : 'y'
      parentOrder[id] = this.sortChildren(
        mode,
        parentOrder[id],
        updatedPositions
      )
    })
  }

  private sortChildren = (
    mode: 'x' | 'y',
    children: string[],
    updatedPositions: ChildPositions
  ): string[] => {
    return [...children].sort((a, b) => {
      const aPos = updatedPositions[a]
      const bPos = updatedPositions[b]
      if (!aPos || !bPos) return 0
      switch (mode) {
        case 'x':
          return aPos.x + aPos.w / 2 - (bPos.x + bPos.w / 2)
        default:
          return aPos.y + aPos.h / 2 - (bPos.y + bPos.h / 2)
      }
    })
  }
}
