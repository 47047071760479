import { InteractableNodeFilter } from 'application/action'
import {
  KeyDownListener,
  KeyUpListener,
  MouseMoveListener,
  isCanvasClosest,
  isTargetClosest,
} from 'application/browser'
import { CoordinatesConversion } from 'application/camera'
import {
  ReadOnlyDocumentSelection,
  SelectionSide,
  getSelectionSide,
} from 'application/selection'
import { Point } from 'application/shapes'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'
import { EditorHighlightService } from 'editor/highlight/service'

export class HighlightServiceEventHandler
  implements
    ActiveActionListener,
    MouseMoveListener,
    KeyDownListener,
    KeyUpListener
{
  private coordinates: CoordinatesConversion
  private highlight: EditorHighlightService
  private interactable: InteractableNodeFilter
  private selection: ReadOnlyDocumentSelection

  private action: Action | null
  private resizeSide: SelectionSide | null
  private point: Point | undefined
  private drill: boolean

  constructor(
    coordinates: CoordinatesConversion,
    highlight: EditorHighlightService,
    interactable: InteractableNodeFilter,
    selection: ReadOnlyDocumentSelection
  ) {
    this.coordinates = coordinates
    this.highlight = highlight
    this.interactable = interactable
    this.selection = selection

    this.action = null
    this.resizeSide = null
    this.point = undefined
    this.drill = false
  }

  onActiveAction(handler: ActionHandler | null): void {
    this.action = handler?.getType() || null
  }

  handleMouseMove = (e: MouseEvent) => {
    this.drill = this.isMouseDrill(e)
    if (isTargetClosest(e, 'layers')) return
    if (!isCanvasClosest(e)) {
      this.highlight.unhighlight()
      this.point = undefined
      return
    }
    this.updatePoint(e)
    this.updateSelectionSide(e)
    this.updateHighlight()
  }

  handleKeyDown = (e: KeyboardEvent) => {
    this.drill = this.isKeyboardDrill(e)
    this.updateHighlight()
  }

  handleKeyUp = (e: KeyboardEvent) => {
    this.drill = this.isKeyboardDrill(e)
    this.updateHighlight()
  }

  private updateHighlight = (): void => {
    if (this.action !== null || this.action === 'target') return

    if (!this.point || this.resizeSide) {
      this.highlight.unhighlight()
      return
    }

    const nodeId = this.interactable.nodeAtPoint(this.point, this.drill, false)
    if (!nodeId || this.isNodeSelected(nodeId)) {
      this.highlight.unhighlight()
      return
    }

    this.highlight.highlight(nodeId)
  }

  private isKeyboardDrill = (e: KeyboardEvent): boolean => {
    return e.metaKey || e.ctrlKey
  }

  private isMouseDrill = (e: MouseEvent): boolean => {
    return e.metaKey || e.ctrlKey
  }

  private updatePoint = (e: MouseEvent): void => {
    this.point = this.coordinates.get(e)
  }

  private updateSelectionSide = (e: MouseEvent): void => {
    const bubble = this.coordinates.getZoomAdjusted(8)
    const bar = this.coordinates.getZoomAdjusted(12)
    const point = this.coordinates.get(e)
    const rectangle = this.selection.getSelectionRectangle()
    if (!rectangle) return
    this.resizeSide = getSelectionSide(point, rectangle, bubble, bar)
  }

  private isNodeSelected = (id: string): boolean => {
    return this.selection.getSelected().some((node) => node.getId() === id)
  }
}
