import { AttributeType, StyleMap } from 'application/attributes'
import {
  CopiedDefaultSelector,
  CopiedStyles,
  CopyStylesSnapshot,
} from '../types'

const pageAttributes: (keyof StyleMap)[] = [
  'fills',
  'padding.left',
  'padding.right',
  'padding.top',
  'padding.bottom',
  'autolayout.mode',
  'autolayout.direction',
  'autolayout.align.main',
  'autolayout.align.counter',
  'autolayout.gap',
]

const frameAttributes: (keyof StyleMap)[] = [
  'fills',
  'rounding.topRight',
  'rounding.topLeft',
  'rounding.bottomRight',
  'rounding.bottomLeft',
  'border.mode',
  'border.side',
  'border.top',
  'border.right',
  'border.bottom',
  'border.left',
  'border.color',
  'padding.left',
  'padding.right',
  'padding.top',
  'padding.bottom',
  'autolayout.mode',
  'autolayout.direction',
  'autolayout.align.main',
  'autolayout.align.counter',
  'autolayout.gap',
  'shadows',
  'filter.mode',
  'filter.blur.radius',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

const textAttributes: (keyof StyleMap)[] = [
  'text.align',
  'text.font.family',
  'text.font.weight',
  'text.font.size',
  'text.letterSpacing',
  'text.lineHeight',
  'text.color',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

const shapeAttributes: (keyof StyleMap)[] = [
  'fills',
  'rounding.topRight',
  'rounding.topLeft',
  'rounding.bottomRight',
  'rounding.bottomLeft',
  'border.mode',
  'border.side',
  'border.top',
  'border.right',
  'border.bottom',
  'border.left',
  'border.color',
  'shadows',
  'filter.mode',
  'filter.blur.radius',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

const imageAttributes: (keyof StyleMap)[] = [
  'rounding.topRight',
  'rounding.topLeft',
  'rounding.bottomRight',
  'rounding.bottomLeft',
  'image.src',
  'image.resize',
  'image.originalSize.w',
  'image.originalSize.h',
  'transition.mode',
  'transition.duration',
  'transition.timing',
]

export class PasteAttributesFilter {
  filter = (
    type: AttributeType,
    copyAttributesSnapshot: CopyStylesSnapshot
  ): CopyStylesSnapshot => {
    const filteredDefaultSelector: CopiedDefaultSelector = {
      ...copyAttributesSnapshot.defaultSelector,
      styles: this.filterAttributes(
        type,
        copyAttributesSnapshot.defaultSelector.styles
      ),
    }
    const filteredSelectors = copyAttributesSnapshot.selectors.map((s) => {
      return {
        name: s.name,
        styles: this.filterAttributes(type, s.styles),
      }
    })

    return {
      defaultSelector: filteredDefaultSelector,
      selectors: filteredSelectors,
    }
  }

  private getAttributesList = (type: AttributeType): (keyof StyleMap)[] => {
    switch (type) {
      case 'page':
        return pageAttributes
      case 'frame':
        return frameAttributes
      case 'text':
        return textAttributes
      case 'rectangle':
      case 'ellipse':
        return shapeAttributes
      case 'image':
        return imageAttributes
      default:
        return []
    }
  }

  private getAttributeKey = (key: keyof CopiedStyles): keyof StyleMap => {
    return key
  }

  private filterAttributes = (
    type: AttributeType,
    attributes: Partial<StyleMap>
  ): Partial<StyleMap> => {
    const filteredAttributes: Partial<StyleMap> = {}
    const attributesList = this.getAttributesList(type)

    for (const key of Object.keys(attributes)) {
      const copiedKey = key as keyof CopiedStyles
      const attributeKey = this.getAttributeKey(copiedKey)
      if (attributesList.includes(attributeKey)) {
        this.setAttributeMapValue(
          attributeKey,
          attributes[copiedKey],
          filteredAttributes
        )
      }
    }

    return filteredAttributes
  }

  private setAttributeMapValue<K extends keyof StyleMap>(
    key: K,
    value: StyleMap[K],
    attributes: Partial<StyleMap>
  ): void {
    attributes[key] = value
  }
}
