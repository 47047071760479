import ToolButtons from './ToolButtons/ToolButtons'
import ImageLibrary from './ImageLibrary/ImageLibrary'
import TemplateLibrary from './TemplateLibrary/TemplateLibrary'

export default function LeftToolbar() {
  return (
    <div
      style={{
        position: 'absolute',
        top: 8,
        left: 248,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        gap: 8,
      }}
    >
      <ToolButtons />
      <ImageLibrary />
      <TemplateLibrary />
    </div>
  )
}
