import { WriteDocument } from 'application/document'
import {
  LayoutDependencyGraphFactory,
  LayoutDependencyNode,
  LayoutEngineCalculator,
} from '../types'
import { getNonDependentNodes, removeNode } from '../dependency/utils'
import { SizeEngineCalculator } from './types'
import { ReadOnlyDocument } from 'application/document/types'

export class SizeLayoutEngine implements LayoutEngineCalculator {
  private graphFactory: LayoutDependencyGraphFactory
  private positionCalculators: SizeEngineCalculator[]

  constructor(
    graphFactory: LayoutDependencyGraphFactory,
    calculators: SizeEngineCalculator[]
  ) {
    this.graphFactory = graphFactory
    this.positionCalculators = calculators
  }

  layout(
    nodes: Set<string>,
    document: WriteDocument,
    initialDocument: ReadOnlyDocument
  ): void {
    const graph = this.graphFactory.create(nodes, document)
    let terminalNodes = getNonDependentNodes(graph)
    while (terminalNodes.length > 0) {
      for (const terminalNode of terminalNodes) {
        this.recomputeSize(terminalNode, initialDocument, document)
        removeNode(terminalNode, graph)
      }
      terminalNodes = getNonDependentNodes(graph)
    }
  }

  private recomputeSize = (
    layoutNode: LayoutDependencyNode,
    initialDocument: ReadOnlyDocument,
    document: WriteDocument
  ): void => {
    const node = document.getNode(layoutNode.documentNodeId)
    if (!node) return

    for (const calculator of this.positionCalculators) {
      calculator.update(node, layoutNode.mode, initialDocument, document)
    }
  }
}
