import { panelShadow } from 'components/Library/Containers/constants'
import React from 'react'
import { DesignColor } from 'themes'

interface ProjectSettingCardProps {
  children: React.ReactNode
}

export default function ProjectSettingCard({
  children,
}: ProjectSettingCardProps) {
  return (
    <div
      style={{
        height: 'fit-content',
        padding: '16px',
        backgroundColor: DesignColor('panelBackground'),
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        boxShadow: [
          panelShadow,
          `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
        ].join(', '),
      }}
    >
      {children}
    </div>
  )
}
