import { Window } from './types'
import { Camera, CameraUpdateListener } from 'application/camera'
import { ReadOnlyDocument } from 'application/document'
import { Rectangle } from 'application/shapes'
import { DesignColor } from 'themes'
import { WindowTransformer } from './transformer/window'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { ActionHandler, ActiveActionListener } from 'editor/action/types'

export const hapticHighlightWindowKey = 'highlightWindow'

export class HapticNewParentWindow
  implements ActiveActionListener, CameraUpdateListener, CanvasUpdateListener
{
  private document: ReadOnlyDocument
  private canvas: Canvas

  private id: string | null
  private window: Window | null
  private camera: Camera | null

  constructor(document: ReadOnlyDocument, canvas: Canvas) {
    this.document = document
    this.canvas = canvas

    this.id = null
    this.window = null
    this.camera = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  onActiveAction = (handler: ActionHandler | null): void => {
    if (!handler || handler.getType() !== 'move') {
      this.id = null
      this.window = null
      this.render()
    }
  }

  setNewParent = (id: string | null) => {
    this.id = id
    this.window = this.computeWindow()
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (this.window === null || this.camera === null) {
      this.canvas.deleteHaptic(hapticHighlightWindowKey)
    } else {
      this.canvas.setHaptic(
        hapticHighlightWindowKey,
        WindowTransformer.transform(
          this.canvas.getContext(),
          [this.window],
          this.camera
        )
      )
    }
  }

  private computeWindow = (): Window | null => {
    return {
      rectangle: this.computeRectangle(),
      border: DesignColor('inputHighlight'),
      fill: null,
      borderWidth: 2,
      bubbles: false,
      shape: 'rectangle',
    }
  }

  private computeRectangle = (): Rectangle => {
    if (!this.id) return { x: 0, y: 0, w: 0, h: 0 }

    const node = this.document.getNode(this.id)
    if (!node) return { x: 0, y: 0, w: 0, h: 0 }

    return {
      x: node.getBaseAttribute('x'),
      y: node.getBaseAttribute('y'),
      w: node.getBaseAttribute('w'),
      h: node.getBaseAttribute('h'),
    }
  }
}
