import { LayoutDependencyGraph, LayoutDependencyNode } from '../types'

export function hasDependency(
  dependent: LayoutDependencyNode,
  dependency: LayoutDependencyNode
): boolean {
  return dependent.dependencies.some(
    (d) => d.key === dependency.key && d.mode === dependency.mode
  )
}

export function getNonDependentNodes(
  graph: LayoutDependencyGraph
): LayoutDependencyNode[] {
  return Object.values(graph).filter((n) => n.dependentOn.length === 0)
}

export function removeNode(
  node: LayoutDependencyNode,
  graph: LayoutDependencyGraph
): void {
  for (const dependent of node.dependentOn) {
    dependent.dependencies = dependent.dependencies.filter((n) => n !== node)
  }
  for (const dependency of node.dependencies) {
    dependency.dependentOn = dependency.dependentOn.filter((n) => n !== node)
  }
  delete graph[node.key]
}
