import { StyleMap } from 'application/attributes'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useState } from 'react'

export default function RoundingRow() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getRoundingPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  const [custom, setCustom] = useState(settings?.mode === 'custom' || false)
  if (!settings || !settings.attributes) return <></>

  const { setRounding, slideRounding, clearCustom } = handlers
  const { hasOverride } = overrides.handlers
  const {
    'rounding.topLeft': topLeft,
    'rounding.topRight': topRight,
    'rounding.bottomRight': bottomRight,
    'rounding.bottomLeft': bottomLeft,
  } = settings.attributes
  const { mode } = settings

  return (
    <>
      <PanelRow>
        <OverrideReset keys={getTopLeftKeys(custom || mode === 'custom')}>
          <NumberBlock
            icon="RadiusTopLeft"
            tooltipKey={mode === 'custom' ? 'Rounding' : 'TopLeftRounding'}
            value={topLeft}
            setValue={(value: number) =>
              setRounding(value, mode === 'all' && !custom ? 'all' : 'topLeft')
            }
            increment={(v) =>
              slideRounding(v, mode === 'all' && !custom ? 'all' : 'topLeft')
            }
            decrement={(v) =>
              slideRounding(-v, mode === 'all' && !custom ? 'all' : 'topLeft')
            }
            min={0}
            step={2}
            presets={presets}
            hasOverride={hasOverride(
              getTopLeftKeys(custom || mode === 'custom')
            )}
          />
        </OverrideReset>
        {mode === 'all' && !custom ? (
          <PanelBlock />
        ) : (
          <OverrideReset keys={getTopRightKeys()}>
            <NumberBlock
              icon="RadiusTopRight"
              tooltipKey="TopRightRounding"
              value={topRight}
              setValue={(v: number) => setRounding(v, 'topRight')}
              increment={(v) => slideRounding(v, 'topRight')}
              decrement={(v) => slideRounding(-v, 'topRight')}
              min={0}
              step={2}
              presets={presets}
              hasOverride={hasOverride(getTopRightKeys())}
            />
          </OverrideReset>
        )}
        <IconBlock
          icon={'RadiusSplit'}
          tooltipKey="SplitRounding"
          tooltipDirection="left"
          selected={mode === 'custom' || custom}
          onClick={() => {
            if (mode === 'custom' || custom) clearCustom()
            setCustom(!custom)
          }}
        />
      </PanelRow>
      {(mode === 'custom' || custom) && (
        <PanelRow>
          <OverrideReset keys={getBottomLeftKeys()}>
            <NumberBlock
              icon="RadiusBottomLeft"
              tooltipKey="BottomLeftRounding"
              value={bottomLeft}
              setValue={(v: number) => setRounding(v, 'bottomLeft')}
              increment={(v) => slideRounding(v, 'bottomLeft')}
              decrement={(v) => slideRounding(-v, 'bottomLeft')}
              min={0}
              step={2}
              presets={presets}
              hasOverride={hasOverride(getBottomLeftKeys())}
            />
          </OverrideReset>
          <OverrideReset keys={getBottomRightKeys()}>
            <NumberBlock
              icon="RadiusBottomRight"
              tooltipKey="BottomRightRounding"
              value={bottomRight}
              setValue={(v: number) => setRounding(v, 'bottomRight')}
              increment={(v) => slideRounding(v, 'bottomRight')}
              decrement={(v) => slideRounding(-v, 'bottomRight')}
              min={0}
              step={2}
              presets={presets}
              hasOverride={hasOverride(getBottomRightKeys())}
            />
          </OverrideReset>
        </PanelRow>
      )}
    </>
  )
}

const presets = [0, 4, 8, 12, 16, 20, 24, 28, 32, 40, 48, 64, 128, 256]

function getTopLeftKeys(custom: boolean): (keyof StyleMap)[] {
  return custom
    ? ['rounding.topLeft']
    : [
        'rounding.topLeft',
        'rounding.topRight',
        'rounding.bottomRight',
        'rounding.bottomLeft',
      ]
}

function getTopRightKeys(): (keyof StyleMap)[] {
  return ['rounding.topRight']
}

function getBottomLeftKeys(): (keyof StyleMap)[] {
  return ['rounding.bottomLeft']
}

function getBottomRightKeys(): (keyof StyleMap)[] {
  return ['rounding.bottomRight']
}
