import { useCallback, useEffect } from 'react'
import useAction from '../editor/useAction'
import { Action } from 'editor'
import { isTargetClosest } from 'application/browser'

interface ClosePopupProps {
  ref: React.RefObject<HTMLDivElement>
  exceptionRef?: React.RefObject<HTMLDivElement>
  exceptionIds?: string[]
  exceptionActions?: Action[]

  close: () => void

  ignorePopup?: boolean
}

export default function useClosePopup({
  ref,
  exceptionRef,
  exceptionIds = [],
  exceptionActions = [],
  close,
  ignorePopup = true,
}: ClosePopupProps) {
  const activeAction = useAction()

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (ignorePopup && isPopup(e)) return
      if (exceptionRef && isExempt(e, exceptionRef)) return
      if (isIdDescendant(e, exceptionIds)) return
      if (isActionExempt(activeAction, exceptionActions)) return
      if (isSelf(e, ref)) return
      close()
    },
    [
      ignorePopup,
      exceptionRef,
      exceptionIds,
      activeAction,
      exceptionActions,
      ref,
      close,
    ]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [ref, close, handleMouseDown])
}

function isPopup(e: MouseEvent): boolean {
  return isPopupDescendant(e.target as HTMLElement)
}

function isSelf(e: MouseEvent, ref: React.RefObject<HTMLElement>): boolean {
  return (ref.current || false) && ref.current.contains(e.target as Node)
}

function isExempt(
  e: MouseEvent,
  exceptionRef: React.RefObject<HTMLElement>
): boolean {
  return (
    (exceptionRef.current || false) &&
    exceptionRef.current.contains(e.target as Node)
  )
}

function isPopupDescendant(target: HTMLElement | null): boolean {
  if (!target) return false
  if (!target || target === document.body) return false
  if (!target.className || !target.className.includes) {
    return isPopupDescendant(target.parentElement)
  }
  if (target.className.includes('popup')) return true
  return isPopupDescendant(target.parentElement)
}

function isIdDescendant(e: MouseEvent, ids: string[]): boolean {
  return ids.some((id) => isTargetClosest(e, id))
}

function isActionExempt(
  activeAction: Action | null,
  exempt: Action[]
): boolean {
  if (!activeAction) return false
  return exempt.includes(activeAction)
}
