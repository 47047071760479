import { useEffect, useRef, useState } from 'react'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import ElementsRightClickMenu from './Elements/ElementsRightClickMenu'
import { Portal } from '../PortalRoot/PortalRoot'
import CanvasesRightClickMenu from './Canvases/CanvasesRightClickMenu'
import useSelected from 'hooks/editor/useSelected'
import useAction from 'hooks/editor/useAction'
import { isCanvasClosest, isTargetClosest } from 'application/browser'
import { ReadOnlyNode } from 'application/node'
import EffectsRightClickMenu from './Effects/EffectsRightClickMenu'

export type RightClickMode = 'elements' | 'canvases' | 'effect' | 'none'
export type RightClickElementMode = 'none' | 'selected'

export default function RightClickMenu() {
  const menuRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const selected = useSelected()
  const activeAction = useAction()

  const menuMode = useRef<RightClickMode>('none')
  const canvasId = useRef<string | null>(null)
  const eventId = useRef<string | null>(null)

  const elementMode = getRightClickMode(selected)
  const height = getHeightByMode(elementMode)

  useEffect(() => {
    const handleRightClick = (e: MouseEvent) => {
      e.preventDefault()
      if (e.ctrlKey) return
      if (isCanvasClosest(e) || isTargetClosest(e, 'layers')) {
        menuMode.current = 'elements'
      } else if (isTargetClosest(e, 'canvases')) {
        menuMode.current = 'canvases'
        canvasId.current = getCanvasId(e)
      } else if (isTargetClosest(e, 'timelineRow')) {
        menuMode.current = 'effect'
        eventId.current = getEventId(e)
      } else {
        menuMode.current = 'none'
        return
      }
      setVisible(true)
      setPosition({
        x: Math.min(e.pageX, window.innerWidth - 248),
        y: Math.min(e.pageY, window.innerHeight - height - 16),
      })
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        setVisible(false)
      }
    }

    document.addEventListener('contextmenu', handleRightClick)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('contextmenu', handleRightClick)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [height])

  useEffect(() => {
    if (activeAction) setVisible(false)
  }, [activeAction])

  if (!visible) return <></>

  return (
    <Portal>
      <PanelContainer
        panelRef={menuRef}
        position={{
          position: 'absolute',
          top: position.y,
          left: position.x,
        }}
      >
        {menuMode.current === 'elements' && (
          <ElementsRightClickMenu
            mode={elementMode}
            close={() => setVisible(false)}
          />
        )}
        {menuMode.current === 'canvases' && canvasId.current && (
          <CanvasesRightClickMenu
            close={() => setVisible(false)}
            canvasId={canvasId.current}
          />
        )}
        {menuMode.current === 'effect' && eventId.current && (
          <EffectsRightClickMenu
            eventId={eventId.current}
            close={() => setVisible(false)}
          />
        )}
      </PanelContainer>
    </Portal>
  )
}

function getRightClickMode(selected: ReadOnlyNode[]): RightClickElementMode {
  if (selected.length === 0) return 'none'
  return 'selected'
}

function getHeightByMode(mode: RightClickElementMode) {
  switch (mode) {
    case 'none':
      return 104
    case 'selected':
      return 448
  }
}

function getCanvasId(e: MouseEvent): string | null {
  const target = e.target as HTMLElement
  const closest = target.closest('.canvas-row')
  if (!closest) return null
  return closest.getAttribute('data-canvas-id')
}

function getEventId(e: MouseEvent): string | null {
  const target = e.target as HTMLElement
  const closest = target.closest('.timelineEvent')
  if (!closest) return null
  return closest.getAttribute('data-event-id')
}
