import { StyleMap, getTextAttributes } from 'application/attributes'
import { Command, SetNodeAttribute } from 'application/client'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { FontWeight, ReadOnlyFontDataMap } from 'application/text'
import {
  Styles,
  TextEditorStyles,
  attributesToTextEditorState,
  textEditorStateToAttributes,
} from 'application/textEditor'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class TextAction {
  private commandHandler: CommandHandler
  private documentSelection: ReadOnlyDocumentSelection
  private styles: Styles

  constructor(
    commandHandler: CommandHandler,
    documentSelection: ReadOnlyDocumentSelection,
    fontMap: ReadOnlyFontDataMap
  ) {
    this.commandHandler = commandHandler
    this.documentSelection = documentSelection
    this.styles = new TextEditorStyles(fontMap)
  }

  toggleBold = (): void => {
    const selected = this.documentSelection.getSelected()
    const textNodes = selected.filter(
      (n) => n.getBaseAttribute('type') === 'text'
    )
    if (textNodes.length === 0) return

    const allBold = this.allBold(textNodes)
    const updates: SetNodeAttribute[] = []
    for (const node of textNodes) {
      const style = this.setFontWeight(node, allBold ? 'regular' : 'bold')
      updates.push({
        type: 'setNodeAttribute',
        params: {
          id: node.getId(),
          base: {},
          style: style,
        },
      })
    }

    this.commandHandler.handle([...updates])
    this.commandHandler.handle([{ type: 'commit' }])
  }

  private setFontWeight = (
    node: ReadOnlyNode,
    fontWeight: FontWeight
  ): Partial<StyleMap> => {
    const state = attributesToTextEditorState(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!state) return {}

    this.styles.applyAll(state.content, { fontWeight: fontWeight })

    const { style } = textEditorStateToAttributes(state)
    return { 'text.font.weight': style['text.font.weight'] }
  }

  private allBold = (nodes: ReadOnlyNode[]): boolean => {
    return nodes.every((n) => {
      const textAttributes = getTextAttributes(
        n.getBaseAttributes(),
        n.getStyleAttributes()
      )
      if (!textAttributes) return false

      return textAttributes['text.font.weight'] === 'bold'
    })
  }
}
