import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import useClosePopup from 'hooks/ui/useClosePopup'
import { useEffect, useRef, useState } from 'react'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import _ from 'lodash'
import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import { useSetRecoilState } from 'recoil'
import { settingsOpen } from '../../SettingsButton/SettingsButton'
import { settingsTab } from '../../SettingsPopup/SettingsPopup'
import useToast from 'hooks/editor/useToast'

interface PublishPopupProps {
  buttonRef: React.RefObject<HTMLDivElement>
  close: () => void
}

export default function PublishPopup({ buttonRef, close }: PublishPopupProps) {
  const toast = useToast()

  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({ ref: popupRef, close: close, exceptionRef: buttonRef })

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getWebsitePanel()
  )
  const [publishSuccess, setPublishSucess] = useState(false)

  const setSettingsOpen = useSetRecoilState(settingsOpen)
  const setSettingsTab = useSetRecoilState(settingsTab)

  const { load, stop, publish } = handlers

  useEffect(() => {
    load()
    return () => stop()
  }, [load, stop])

  if (!settings) return <></>

  const { domain, status, publishPages, hasPublished, homepageId } = settings
  const anySelected = publishPages.some((p) => p.checked)

  return (
    <Portal>
      <PanelContainer
        panelRef={popupRef}
        position={{ position: 'absolute', top: 64, right: 248 }}
        popup={true}
      >
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode="title">
              Publish
            </TextBlock>
            <IconBlock icon="X" onClick={close} />
          </PanelRow>
        </PanelSection>
        <PanelSection>
          <PanelRow>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigator.clipboard.writeText(domain)
                toast('Domain copied to clipboard', 'info')
              }}
            >
              <TextBlock width={192} mode="label1">
                {domain}
              </TextBlock>
            </div>
            <IconBlock
              icon={'Settings'}
              onClick={() => {
                setSettingsTab('domain')
                setSettingsOpen(true)
                close()
              }}
            />
          </PanelRow>
          <PanelRow>
            <TextBlock
              width={192}
              mode={status === 'unpublished' ? 'label2' : 'label1'}
            >
              {_.capitalize(status)}
            </TextBlock>
            {status === 'live' && (
              <IconBlock
                icon="Navigate"
                tooltipKey="VisitSite"
                tooltipDirection="left"
                onClick={() => window.open(domain, '_blank')}
              />
            )}
          </PanelRow>
        </PanelSection>
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode="title">
              Pages
            </TextBlock>
          </PanelRow>
          <ScrollContainer maxHeight={250}>
            {publishPages.map((page) => (
              <PanelRow
                key={page.id}
                onMouseUp={() => handlers.togglePage(page.id)}
              >
                <TextBlock
                  icon={page.id === homepageId ? 'Home' : 'Page'}
                  width={'fill'}
                  mode={page.checked ? 'label1' : 'label2'}
                >
                  {page.name}
                </TextBlock>
                <IconBlock
                  icon={page.checked ? 'CheckboxChecked' : 'CheckboxUnchecked'}
                />
              </PanelRow>
            ))}
          </ScrollContainer>
          {publishPages.length === 0 && (
            <PanelRow>
              <TextBlock width={'fill'} mode="label2">
                Create at least one page to publish
              </TextBlock>
            </PanelRow>
          )}
        </PanelSection>
        {publishPages.length > 0 && !anySelected && (
          <PanelSection>
            <PanelRow>
              <TextBlock width={'fill'} mode="label2">
                Turn on at least one page to publish
              </TextBlock>
            </PanelRow>
          </PanelSection>
        )}
        {publishPages.length > 0 && anySelected && (
          <PanelSection>
            <PanelRow>
              <PanelBlock />
              <CTAButton
                text={hasPublished ? 'Publish Changes' : 'Publish'}
                onClick={async () => {
                  await publish()
                  setPublishSucess(true)
                  setTimeout(() => setPublishSucess(false), 10000)
                }}
              />
            </PanelRow>
          </PanelSection>
        )}
      </PanelContainer>
      {publishSuccess && (
        <PanelContainer
          position={{
            position: 'absolute',
            top: getSuccessTop(popupRef),
            right: 248,
          }}
          popup={true}
        >
          <PanelSection bottom={true}>
            <PanelRow>
              <TextBlock mode="label1">Published!</TextBlock>
              <CTAButton
                text="Visit Site"
                onClick={() => window.open(domain, '_blank')}
              />
            </PanelRow>
          </PanelSection>
        </PanelContainer>
      )}
    </Portal>
  )
}

function getSuccessTop(popupRef: React.RefObject<HTMLDivElement>) {
  if (popupRef.current) {
    return popupRef.current.getBoundingClientRect().bottom + 8
  }
  return 0
}
