import { DesignColor, ThemeColorKey } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import { useRef, useState } from 'react'
import DraggingIcon from './DraggingIcon/DraggingIcon'
import NumberInput from './NumberInput/NumberInput'
import PresetArrow from './Preset/PresetArrow'
import useEditInput from 'hooks/editor/useEditInput'
import PresetsDropdown from './Preset/PresetDropdown'
import { tooltipKey } from 'assets/tooltips'
import Tooltip from '../Tooltip/Tooltip'
import useHovered from 'hooks/ui/useHovered'
import DeleteIcon from './DeleteIcon/DeleteIcon'
import { warningKey } from 'assets/warnings'
import useAction from 'hooks/editor/useAction'

interface NumberBlockProps {
  width?: 48 | 72 | 96

  icon?: IconKey
  iconRotation?: number
  tooltipKey?: tooltipKey
  warningKey?: warningKey

  value: 'Mixed' | number | undefined
  setValue: (value: number) => void
  increment?: (value: number) => void
  decrement?: (value: number) => void
  setEmpty?: () => void

  presets?: number[]
  onDelete?: () => void

  unit?: string
  step?: number
  min?: number
  max?: number
  decimals?: number

  dim?: boolean
  disabled?: boolean
  hasOverride?: boolean

  commit?: boolean
}

export default function NumberBlock({
  width = 96,
  icon,
  iconRotation = 0,
  tooltipKey,
  warningKey,
  value,
  setValue,
  increment,
  decrement,
  setEmpty,
  unit,
  step = 1,
  min = 0,
  max = 50_000,
  decimals = 1,
  dim = false,
  disabled = false,
  hasOverride = false,
  presets,
  onDelete,
  commit = true,
}: NumberBlockProps) {
  const blockRef = useRef<HTMLDivElement>(null)

  const action = useAction()
  const { hovered, setHovered } = useHovered<boolean>({ ref: blockRef })
  const [editing, setEditing] = useEditInput(commit)
  const [dragging, setDragging] = useState(false)
  const [presetsOpen, setPresetsOpen] = useState(false)

  const active =
    ((hovered && action === null) || editing || dragging) &&
    !presetsOpen &&
    !disabled
  const numberInputWidth = getNumberInputWidth(
    width,
    presets !== undefined || onDelete !== undefined
  )

  const handleOpenPresets = () => {
    setEditing(true)
    setHovered(false)
    setPresetsOpen(true)
  }

  const handleSetValue = (value: number) => {
    setValue(value)
  }

  const handleSetNumberInputValue = (value: number) => {
    setValue(value)
    setEditing(false)
  }

  const handleSetEmpty = () => {
    if (setEmpty) {
      setEmpty()
      setEditing(false)
    }
  }

  const handleSelectPreset = (value: number) => {
    setHovered(false)
    setPresetsOpen(false)
    handleSetValue(value)
    setEditing(false)
  }

  const handleSetDragging = (dragging: boolean) => {
    setEditing(dragging)
    setDragging(dragging)
  }

  const handleSetEditing = (editing: boolean) => {
    setEditing(editing)
  }

  return (
    <TooltipWrapper
      tooltipKey={tooltipKey}
      warningKey={warningKey}
      disabled={presetsOpen}
    >
      <Block
        icon={icon}
        blockRef={blockRef}
        width={width}
        active={active}
        disabled={disabled || dim}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {icon && (
          <DraggingIcon
            icon={icon}
            warning={warningKey !== undefined}
            iconRotation={iconRotation}
            increment={increment || (() => {})}
            decrement={decrement || (() => {})}
            dragging={dragging}
            setDragging={handleSetDragging}
            step={step}
            action={action !== null}
            disabled={disabled}
            hasOverride={hasOverride}
          />
        )}
        <NumberInput
          dragging={dragging}
          editing={editing}
          setEditing={handleSetEditing}
          width={numberInputWidth}
          value={value}
          increment={increment || (() => {})}
          decrement={decrement || (() => {})}
          setValue={handleSetNumberInputValue}
          setEmpty={handleSetEmpty}
          unit={unit}
          step={step}
          min={min}
          max={max}
          decimals={decimals}
          hasOverride={hasOverride}
          disabled={disabled}
        />
        {presets && !disabled && (
          <>
            <PresetArrow
              visible={(hovered || false) && !action}
              onClick={handleOpenPresets}
            />
            {presetsOpen && (
              <PresetsDropdown
                close={() => {
                  setEditing(false)
                  setPresetsOpen(false)
                }}
                value={value}
                update={handleSetValue}
                select={handleSelectPreset}
                presets={presets}
                blockRef={blockRef}
              />
            )}
          </>
        )}
        {onDelete && !presets && (
          <DeleteIcon visible={hovered || false} onClick={onDelete} />
        )}
      </Block>
    </TooltipWrapper>
  )
}

function TooltipWrapper({
  children,
  tooltipKey,
  warningKey,
  disabled,
}: {
  children: React.ReactNode
  tooltipKey?: tooltipKey
  warningKey?: warningKey
  disabled: boolean
}) {
  if (tooltipKey || warningKey) {
    return (
      <Tooltip
        tooltipKey={tooltipKey}
        warningKey={warningKey}
        disabled={disabled}
        long={warningKey !== undefined}
      >
        {children}
      </Tooltip>
    )
  } else {
    return <>{children}</>
  }
}

function Block({
  icon,
  blockRef,
  width,
  active,
  disabled,
  backgroundColor,
  children,
  onMouseEnter,
  onMouseLeave,
}: {
  icon?: IconKey
  blockRef: React.RefObject<HTMLDivElement>
  width: number
  active: boolean
  disabled: boolean
  backgroundColor?: ThemeColorKey
  children?: React.ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}) {
  return (
    <div
      ref={blockRef}
      style={{
        width: width,
        height: 24,
        padding: icon ? '0 4px' : '0 8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        borderRadius: 4,
        opacity: disabled ? 0.4 : 1,
        boxShadow: active
          ? `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
          : 'none',
        backgroundColor: backgroundColor
          ? DesignColor(backgroundColor)
          : 'transparent',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}

function getNumberInputWidth(width: 48 | 72 | 96, icon: boolean) {
  if (width === 72) return 36
  return icon ? 40 : 52
}
