import useToast from 'hooks/editor/useToast'
import { DesignColor } from 'themes'

interface DNSGridProps {
  ip1: string
  ip2: string
  fileserver: string
}

export default function DNSGrid({ ip1, ip2, fileserver }: DNSGridProps) {
  const toast = useToast()

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        padding: '4px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: `inset 0px 0px 0px 1px ${DesignColor('panelBorder')}`,
        }}
      >
        <Row color={DesignColor('panelTint')}>
          <Cell color={DesignColor('text1')} weight="bold">
            Name
          </Cell>
          <Cell color={DesignColor('text1')} weight="bold">
            Type
          </Cell>
          <Cell fill={true} color={DesignColor('text1')} weight="bold">
            Value
          </Cell>
        </Row>
        <Row>
          <Cell>@</Cell>
          <Cell>A</Cell>
          <Cell
            fill={true}
            onClick={() => {
              navigator.clipboard.writeText(ip1)
              toast(`Copied ${ip1} to clipboard`, 'info')
            }}
          >
            {ip1}
          </Cell>
        </Row>
        <Row>
          <Cell>@</Cell>
          <Cell>A</Cell>
          <Cell
            fill={true}
            onClick={() => {
              navigator.clipboard.writeText(ip2)
              toast(`Copied ${ip2} to clipboard`, 'info')
            }}
          >
            {ip2}
          </Cell>
        </Row>
        <Row>
          <Cell>www</Cell>
          <Cell>CNAME</Cell>
          <Cell
            fill={true}
            onClick={() => {
              navigator.clipboard.writeText(fileserver)
              toast(`Copied ${fileserver} to clipboard`, 'info')
            }}
          >
            {fileserver}
          </Cell>
        </Row>
      </div>
    </div>
  )
}

function Row({
  children,
  color,
}: {
  children: React.ReactNode
  color?: string
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        backgroundColor: color,
      }}
    >
      {children}
    </div>
  )
}

function Cell({
  children,
  fill = false,
  color = DesignColor('text2'),
  weight = 'normal',
  onClick,
}: {
  children: React.ReactNode
  fill?: boolean
  color?: string
  weight?: 'bold' | 'normal'
  onClick?: () => void
}) {
  return (
    <div
      style={{
        width: fill ? undefined : 96,
        flex: fill ? 1 : undefined,
        flexShrink: fill ? 1 : 0,
        height: 32,
        padding: `0px 12px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        color: color,
        fontSize: 11,
        fontFamily: 'Inter',
        fontWeight: weight,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
