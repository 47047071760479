import {
  AttributeAutolayoutAlign,
  AttributeAutolayoutDirection,
  AttributeAutolayoutMode,
  AttributeFill,
  AttributeGradient,
  AttributeImage,
  AttributeShadow,
  AttributeSizeAuto,
  AttributeTextAlign,
  AttributeType,
  BaseMap,
  StyleMap,
} from './types'
import {
  Color,
  createNewColor,
  isColorEqual,
  rgbaToHex,
} from 'application/color'
import { v4 } from 'uuid'
import { Rectangle } from 'application/shapes'
import { FontKey, FontWeight } from 'application/text'
import _ from 'lodash'
import { PLACEHOLDER_IMAGE } from './const'
import { ReadOnlyNode } from 'application/node'

export function isContainerType(type: AttributeType): boolean {
  switch (type) {
    case 'canvas':
    case 'page':
    case 'frame':
      return true
    default:
      return false
  }
}

export function canTypeInsertInto(
  child: AttributeType,
  parent: AttributeType
): boolean {
  if (child === 'canvas') return false
  if (child === 'page') return parent === 'canvas'
  return isContainerType(parent)
}

export function canAttributeInsertInto(
  childStyles: StyleMap,
  childType: AttributeType,
  parentType: AttributeType
): boolean {
  if (!canTypeInsertInto(childType, parentType)) return false

  const fixed = childStyles['position.mode'] === 'fixed'
  if (!fixed) return true

  return parentType === 'page' || parentType === 'canvas'
}

export function isInteractable(node: ReadOnlyNode): boolean {
  return !node.getBaseAttribute('locked') && !node.getStyleAttribute('hidden')
}

export function isAutolayoutChild(
  node: ReadOnlyNode,
  parent: ReadOnlyNode
): boolean {
  const auto = parent.getStyleAttribute('autolayout.mode') === 'flex'
  const absolute = isAbsolutePositionMode(node)
  return auto && !absolute
}

export function isCanvasChild(parent: ReadOnlyNode): boolean {
  return parent.getBaseAttribute('type') === 'canvas'
}

export function isAbsolutePositionMode(node: ReadOnlyNode): boolean {
  return ['absolute', 'fixed'].includes(node.getStyleAttribute('position.mode'))
}

export function isAutolayout(node: ReadOnlyNode): boolean {
  return node.getStyleAttribute('autolayout.mode') === 'flex'
}

export function isDynamicSizeAuto(auto: AttributeSizeAuto): boolean {
  return auto === 'percent' || auto === 'fill'
}

export function isDynamicPosition(
  axis: 'x' | 'y',
  attributes: StyleMap
): boolean {
  const mode1Key = axis === 'x' ? 'position.left.auto' : 'position.top.auto'
  const mode2Key = axis === 'x' ? 'position.right.auto' : 'position.bottom.auto'

  const mode1 = attributes[mode1Key]
  const mode2 = attributes[mode2Key]
  return mode1 !== 'none' && mode2 !== 'none'
}

export type AutolayoutAttributes = {
  x: number
  y: number
  w: number
  h: number
  wAuto: AttributeSizeAuto
  hAuto: AttributeSizeAuto
  paddingLeft: number
  paddingRight: number
  paddingTop: number
  paddingBottom: number
  mode: AttributeAutolayoutMode
  direction: AttributeAutolayoutDirection
  alignMain: AttributeAutolayoutAlign
  alignCounter: AttributeAutolayoutAlign
  gap: number
}

export function getAutolayoutAttributes(
  node: ReadOnlyNode
): AutolayoutAttributes | null {
  if (!isAutolayout(node)) return null

  const paddingLeft = node.getStyleAttribute('padding.left')
  const paddingRight = node.getStyleAttribute('padding.right')
  const paddingTop = node.getStyleAttribute('padding.top')
  const paddingBottom = node.getStyleAttribute('padding.bottom')
  const mode = node.getStyleAttribute('autolayout.mode')
  const direction = node.getStyleAttribute('autolayout.direction')
  const alignMain = node.getStyleAttribute('autolayout.align.main')
  const alignCounter = node.getStyleAttribute('autolayout.align.counter')
  const gap = node.getStyleAttribute('autolayout.gap')

  if (
    mode === undefined ||
    mode === 'none' ||
    paddingLeft === undefined ||
    paddingRight === undefined ||
    paddingTop === undefined ||
    paddingBottom === undefined ||
    direction === undefined ||
    alignMain === undefined ||
    alignCounter === undefined ||
    gap === undefined
  ) {
    return null
  }

  return {
    x: node.getBaseAttribute('x'),
    y: node.getBaseAttribute('y'),
    w: node.getBaseAttribute('w'),
    h: node.getBaseAttribute('h'),
    wAuto: node.getStyleAttribute('size.w.auto'),
    hAuto: node.getStyleAttribute('size.h.auto'),
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    mode,
    direction,
    alignMain,
    alignCounter,
    gap,
  }
}

export type TextAttributes = {
  w: number
  h: number
  'size.w.auto': AttributeSizeAuto
  'size.h.auto': AttributeSizeAuto
  'text.content': string
  'text.font.family': FontKey
  'text.font.weight': FontWeight
  'text.font.size': number
  'text.color': AttributeFill
  'text.align': AttributeTextAlign
  'text.letterSpacing': number
  'text.lineHeight': number
  'text.italic': boolean
  'text.underline': boolean
}

export function getTextAttributes(
  base: BaseMap,
  style: StyleMap
): TextAttributes | null {
  if (base['type'] !== 'text') return null

  const width = base['w']
  const height = base['h']
  const widthAuto = style['size.w.auto']
  const heightAuto = style['size.h.auto']
  const content = base['text.content']
  const fontFamily = style['text.font.family']
  const fontWeight = style['text.font.weight']
  const fontSize = style['text.font.size']
  const color = style['text.color']
  const align = style['text.align']
  const letterSpacing = style['text.letterSpacing']
  const lineHeight = style['text.lineHeight']
  const italic = style['text.italic']
  const underline = style['text.underline']

  if (
    width === undefined ||
    height === undefined ||
    widthAuto === undefined ||
    heightAuto === undefined ||
    content === undefined ||
    fontFamily === undefined ||
    fontWeight === undefined ||
    fontSize === undefined ||
    align === undefined ||
    letterSpacing === undefined ||
    lineHeight === undefined ||
    italic === undefined ||
    underline === undefined ||
    color === undefined
  ) {
    return null
  }

  return {
    w: width,
    h: height,
    'size.w.auto': widthAuto,
    'size.h.auto': heightAuto,
    'text.content': content,
    'text.font.family': fontFamily,
    'text.font.weight': fontWeight,
    'text.font.size': fontSize,
    'text.color': color,
    'text.align': align,
    'text.letterSpacing': letterSpacing,
    'text.lineHeight': lineHeight,
    'text.italic': italic,
    'text.underline': underline,
  }
}

export function areTextAttributesEqual(
  a1: TextAttributes,
  a2: TextAttributes
): boolean {
  if (a1['w'] !== a2['w']) return false
  if (a1['h'] !== a2['h']) return false
  if (a1['size.w.auto'] !== a2['size.w.auto']) return false
  if (a1['size.h.auto'] !== a2['size.h.auto']) return false
  if (a1['text.content'] !== a2['text.content']) return false
  if (a1['text.font.family'] !== a2['text.font.family']) return false
  if (a1['text.font.weight'] !== a2['text.font.weight']) return false
  if (a1['text.font.size'] !== a2['text.font.size']) return false
  if (a1['text.align'] !== a2['text.align']) return false
  if (a1['text.letterSpacing'] !== a2['text.letterSpacing']) return false
  if (a1['text.lineHeight'] !== a2['text.lineHeight']) return false
  if (a1['text.italic'] !== a2['text.italic']) return false
  if (a1['text.underline'] !== a2['text.underline']) return false
  if (!_.isEqual(a1['text.color'], a2['text.color'])) return false
  return true
}

export function createNewWhiteFill(): AttributeFill {
  return {
    type: 'color',
    color: { r: 255, g: 255, b: 255, a: 1 },
  }
}

export function createNewBlackFill(): AttributeFill {
  return {
    type: 'color',
    color: { r: 0, g: 0, b: 0, a: 1 },
  }
}

export const createNewGradientFill = (color?: Color): AttributeGradient => {
  return {
    type: 'linear',
    angle: 0,
    steps: [
      {
        position: 0,
        color: color || createNewColor(),
        key: v4().replace(/-/g, '').slice(0, 12),
      },
      {
        position: 100,
        color: createNewColor(),
        key: v4().replace(/-/g, '').slice(0, 12),
      },
    ],
  }
}

export function createNewImageFill(): AttributeImage {
  return {
    src: PLACEHOLDER_IMAGE,
    resize: 'fill',
    'originalSize.w': 200,
    'originalSize.h': 200,
  }
}

export function createAutolayoutAttributes(
  parent: ReadOnlyNode,
  children: ReadOnlyNode[],
  childrenOriginal: StyleMap[] = [],
  wrapper: boolean = false
): Partial<StyleMap> {
  const wAuto = parent.getStyleAttribute('size.w.auto')
  const hAuto = parent.getStyleAttribute('size.h.auto')
  const wFill = childrenOriginal.some((c) => c['size.w.auto'] === 'fill')
  const hFill = childrenOriginal.some((c) => c['size.h.auto'] === 'fill')
  const newWAuto = wFill
    ? 'fill'
    : wAuto === 'fixed' &&
      parent.getBaseAttribute('type') !== 'page' &&
      wrapper &&
      !isDynamicPosition('x', parent.getStyleAttributes())
    ? 'hug'
    : wAuto
  const newHAuto = hFill
    ? 'fill'
    : hAuto === 'fixed' &&
      parent.getBaseAttribute('type') !== 'page' &&
      wrapper &&
      !isDynamicPosition('y', parent.getStyleAttributes())
    ? 'hug'
    : hAuto

  let direction: 'row' | 'column' = 'row'
  let gap = 8
  if (parent.getBaseAttribute('type') === 'page') {
    direction = 'column'
    gap = 0
  } else if (isVerticalOverlap(children)) {
    direction = 'column'
    gap = averageGap('h', children)
  } else {
    gap = averageGap('w', children)
  }

  const hPadding = minPadding('h', parent, children)
  const vPadding = minPadding('v', parent, children)

  return {
    'autolayout.mode': 'flex',
    'autolayout.direction': direction,
    'autolayout.align.main': 'start',
    'autolayout.align.counter': 'start',
    'autolayout.gap': gap,
    'padding.top': children.length >= 2 || !wrapper ? vPadding : 8,
    'padding.right': children.length >= 2 || !wrapper ? hPadding : 8,
    'padding.bottom': children.length >= 2 || !wrapper ? vPadding : 8,
    'padding.left': children.length >= 2 || !wrapper ? hPadding : 8,
    'size.w': newWAuto === 'fixed' ? parent.getBaseAttribute('w') : undefined,
    'size.h': newHAuto === 'fixed' ? parent.getBaseAttribute('h') : undefined,
    'size.w.auto': newWAuto,
    'size.h.auto': newHAuto,
  }
}

function minPadding(
  axis: 'h' | 'v',
  parent: ReadOnlyNode,
  children: ReadOnlyNode[]
): number {
  if (parent.getBaseAttribute('type') === 'page') return 0

  const positionKey = axis === 'h' ? 'x' : 'y'
  const sizeKey = axis === 'h' ? 'w' : 'h'
  const top = parent.getBaseAttribute(positionKey)
  const bottom =
    parent.getBaseAttribute(positionKey) + parent.getBaseAttribute(sizeKey)

  let minPadding = Infinity
  for (const child of children) {
    const childTop = child.getBaseAttribute(positionKey)
    const childBottom =
      child.getBaseAttribute(positionKey) + child.getBaseAttribute(sizeKey)
    if (childTop - top < minPadding) {
      minPadding = childTop - top
    }
    if (bottom - childBottom < minPadding) {
      minPadding = bottom - childBottom
    }
  }

  if (minPadding === Infinity) return 8
  return Math.max(minPadding, 0)
}

function averageGap(axis: 'w' | 'h', children: ReadOnlyNode[]): number {
  if (children.length < 2) return 8

  const positionKey = axis === 'w' ? 'x' : 'y'
  const sizeKey = axis === 'w' ? 'w' : 'h'

  let totalGap = 0
  const sortedChildren = children.sort(
    (a, b) => a.getBaseAttribute(positionKey) - b.getBaseAttribute(positionKey)
  )

  for (let i = 0; i < sortedChildren.length - 1; i++) {
    const child = sortedChildren[i]
    const next = sortedChildren[i + 1]
    totalGap +=
      next.getBaseAttribute(positionKey) -
      (child.getBaseAttribute(positionKey) + child.getBaseAttribute(sizeKey))
  }

  return Math.max(Math.round(totalGap / (children.length - 1)), 0)
}

function isVerticalOverlap(children: ReadOnlyNode[]): boolean {
  const sortedChildren = children.sort(
    (a, b) => a.getBaseAttribute('x') - b.getBaseAttribute('x')
  )

  for (let i = 0; i < sortedChildren.length - 1; i++) {
    const child = sortedChildren[i]
    const next = sortedChildren[i + 1]
    if (
      child.getBaseAttribute('x') + child.getBaseAttribute('w') >
      next.getBaseAttribute('x')
    ) {
      return true
    }
  }

  return false
}

export function createNewShadow(): AttributeShadow {
  return {
    x: 4,
    y: 4,
    blur: 8,
    spread: 0,
    color: { ...createNewColor(), a: 0.2 },
    mode: 'outer',
  }
}

export function isFillEqual(
  fill1: AttributeFill,
  fill2: AttributeFill
): boolean {
  switch (fill1.type) {
    case 'color':
      if (!fill1.color || !fill2.color) return false
      return isColorEqual(fill1.color, fill2.color)
    case 'gradient':
      const g1 = fill1.gradient
      const g2 = fill2.gradient
      if (!g1 || !g2) return false
      if (g1.type !== g2.type) return false
      if (g1.angle !== g2.angle) return false
      for (let i = 0; i < g1.steps.length; i++) {
        if (g1.steps.length !== g2.steps.length) return false
        if (!isColorEqual(g1.steps[i].color, g2.steps[i].color)) return false
        if (g1.steps[i].position !== g2.steps[i].position) return false
      }
      return true
    case 'image':
      const i1 = fill1.image
      const i2 = fill2.image
      if (!i1 || !i2) return false
      return _.isEqual(i1, i2)
  }
}

export function getRectangle(node: ReadOnlyNode): Rectangle {
  return {
    x: node.getBaseAttribute('x'),
    y: node.getBaseAttribute('y'),
    w: node.getBaseAttribute('w'),
    h: node.getBaseAttribute('h'),
  }
}

export function getBoundingRectangle(node: ReadOnlyNode): Rectangle {
  return {
    x: node.getBaseAttribute('boundingBox.x'),
    y: node.getBaseAttribute('boundingBox.y'),
    w: node.getBaseAttribute('boundingBox.w'),
    h: node.getBaseAttribute('boundingBox.h'),
  }
}

export function getClippedRectangle(node: ReadOnlyNode): Rectangle {
  const nodeRect = getRectangle(node)
  const nodeBoundingRect = getBoundingRectangle(node)

  const maxLeft = Math.max(nodeRect.x, nodeBoundingRect.x)
  const maxTop = Math.max(nodeRect.y, nodeBoundingRect.y)
  const minBottom = Math.min(
    nodeRect.y + nodeRect.h,
    nodeBoundingRect.y + nodeBoundingRect.h
  )
  const minRight = Math.min(
    nodeRect.x + nodeRect.w,
    nodeBoundingRect.x + nodeBoundingRect.w
  )

  return {
    x: maxLeft,
    y: maxTop,
    w: minRight - maxLeft,
    h: minBottom - maxTop,
  }
}

export function gradientToCss(
  gradient: AttributeGradient,
  overrideAngle?: number
): string {
  const angle =
    overrideAngle !== undefined ? overrideAngle : gradient.angle ?? 0
  const cssAngle = (angle + 180) % 360

  const gradientSteps = gradient.steps
    .map((step) => `#${rgbaToHex(step.color)} ${step.position}%`)
    .join(', ')

  return `linear-gradient(${cssAngle}deg, ${gradientSteps})`
}

export function canFrameFillPage(
  node: ReadOnlyNode,
  parent: ReadOnlyNode
): boolean {
  if (parent.getBaseAttribute('type') !== 'page') return false
  if (node.getBaseAttribute('type') !== 'frame') return false
  if (!isAutolayout(parent)) return false
  if (!isAutolayoutChild(node, parent)) return false
  if (parent.getStyleAttribute('autolayout.direction') !== 'column')
    return false
  return true
}
