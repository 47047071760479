export type Themes = 'light' | 'dark'

export type ThemeColorKey =
  | 'canvasBackground'
  | 'gridlines'
  | 'smallGridlines'
  | 'panelBackground'
  | 'menuBackground'
  | 'inverseBackground'
  | 'inverseBackgroundHover'
  | 'dropdownBackground'
  | 'overlayBackground'
  | 'panelTint'
  | 'panelBorder'
  | 'popupShadow'
  | 'inputHighlight'
  | 'inputTint'
  | 'inputTintNeutral'
  | 'grayInputTint'
  | 'inputHighlightTint1'
  | 'inputHighlightTint2'
  | 'text1'
  | 'text2'
  | 'inverseText1'
  | 'inverseText2'
  | 'component'
  | 'alignment'
  | 'alignmentText'
  | 'bubbleBackground'
  | 'drawingElement'
  | 'frameTitle'
  | 'frameTitleHighlight'
  | 'componentTitle'
  | 'componentTitleHighlight'
  | 'editingTextBorder'
  | 'warning'
  | 'toastSuccess'
  | 'toastError'
  | 'toastInfo'
  | 'overrideParent'
  | 'overrideTint'
  | 'overridePanel'

const lightModeColors: {
  [key in ThemeColorKey]: string
} = {
  canvasBackground: 'rgba(221, 221, 221, 1)',
  gridlines: 'rgba(206, 206, 206, 1)',
  smallGridlines: 'rgba(128, 128, 128, 0.2)',
  panelBackground: 'rgba(255, 255, 255, 1)',
  menuBackground: 'rgba(244, 244, 244, 1)',
  inverseBackground: 'rgba(17, 17, 17, 1)',
  inverseBackgroundHover: 'rgba(0, 83, 119, 1)',
  dropdownBackground: 'rgba(255, 255, 255, 1)',
  overlayBackground: 'rgba(255, 255, 255, 1)',
  panelTint: 'rgba(0, 0, 0, 0.05)',
  panelBorder: 'rgba(204, 204, 204, 1)',
  popupShadow: 'rgba(0, 0, 0, 0.25)',
  inputHighlight: 'rgba(0, 173, 248, 1)',
  inputTint: 'rgba(0, 173, 248, 0.15)',
  inputTintNeutral: 'rgba(0, 0, 0, 0.10)',
  grayInputTint: 'rgba(0, 0, 0, 0.1)',
  inputHighlightTint1: 'rgba(0, 173, 248, 0.2)',
  inputHighlightTint2: 'rgba(0, 173, 248, 0.1)',
  text1: 'rgba(26, 26, 26, 1)',
  text2: 'rgba(136, 136, 136, 1)',
  inverseText1: 'rgba(255, 255, 255, 1)',
  inverseText2: 'rgba(170, 170, 170, 1)',
  component: 'rgba(251, 91, 158, 1)',
  alignment: 'rgba(255, 0, 0, 1)',
  alignmentText: 'rgba(255, 255, 255, 1)',
  bubbleBackground: 'rgba(255, 255, 255, 1)',
  drawingElement: 'rgba(179, 179, 179, 1)',
  frameTitle: 'rgba(17, 17, 17, 0.7)',
  frameTitleHighlight: 'rgba(0, 149, 212, 1)',
  componentTitle: 'rgba(251, 91, 158, 0.7)',
  componentTitleHighlight: 'rgba(251, 91, 158, 1)',
  editingTextBorder: 'rgba(241, 45, 255, 1)',
  warning: 'rgba(189, 91, 0, 1)',
  toastSuccess: 'rgba(220, 255, 220, 1)',
  toastError: 'rgba(255, 220, 220, 1)',
  toastInfo: 'rgba(220, 240, 255, 1)',
  overrideParent: 'rgba(152, 21, 255, 1)',
  overrideTint: 'rgba(152, 21, 255, 0.1)',
  overridePanel: 'rgba(251, 246, 255, 1)',
}

const darkModeColors: {
  [key in ThemeColorKey]: string
} = {
  canvasBackground: 'rgba(56, 56, 56, 1)',
  gridlines: 'rgba(62, 62, 62, 1)',
  smallGridlines: 'rgba(128, 128, 128, 0.2)',
  panelBackground: 'rgba(17, 17, 17, 1)',
  menuBackground: 'rgba(24, 24, 24, 1)',
  inverseBackground: 'rgba(255, 255, 255, 1)',
  inverseBackgroundHover: 'rgba(142, 221, 255, 1)',
  dropdownBackground: 'rgba(17, 17, 17, 1)',
  overlayBackground: 'rgba(17, 17, 17, 1)',
  panelTint: 'rgba(255, 255, 255, 0.05)',
  panelBorder: 'rgba(96, 96, 96, 1)',
  popupShadow: 'rgba(0, 0, 0, 0.25)',
  inputHighlight: 'rgba(0, 173, 248, 1)',
  inputTint: 'rgba(0, 173, 248, 0.15)',
  inputTintNeutral: 'rgba(255, 255, 255, 0.10)',
  grayInputTint: 'rgba(255, 255, 255, 0.1)',
  inputHighlightTint1: 'rgba(0, 173, 248, 0.2)',
  inputHighlightTint2: 'rgba(0, 173, 248, 0.1)',
  text1: 'rgba(255, 255, 255, 1)',
  text2: 'rgba(170, 170, 170, 1)',
  inverseText1: 'rgba(26, 26, 26, 1)',
  inverseText2: 'rgba(136, 136, 136, 1)',
  component: 'rgba(251, 91, 158, 1)',
  alignment: 'rgba(255, 0, 0, 1)',
  alignmentText: 'rgba(255, 255, 255, 1)',
  bubbleBackground: 'rgba(255, 255, 255, 1)',
  drawingElement: 'rgba(179, 179, 179, 1)',
  frameTitle: 'rgba(255, 255, 255, 0.7)',
  frameTitleHighlight: 'rgba(45, 192, 255, 1)',
  componentTitle: 'rgba(251, 91, 158, 0.7)',
  componentTitleHighlight: 'rgba(251, 91, 158, 1)',
  editingTextBorder: 'rgba(241, 45, 255, 1)',
  warning: 'rgba(255, 249, 115, 1)',
  toastSuccess: 'rgba(36, 63, 36, 1)',
  toastError: 'rgba(63, 36, 36, 1)',
  toastInfo: 'rgba(36, 36, 63, 1)',
  overrideParent: 'rgba(248, 168, 255, 1)',
  overrideTint: 'rgba(248, 168, 255, 0.2)',
  overridePanel: 'rgba(46, 38, 47, 1)',
}

export const themes = {
  light: lightModeColors,
  dark: darkModeColors,
}

export function DesignColor(key: ThemeColorKey): string {
  const mode = getActiveColorTheme()
  switch (mode) {
    case 'light':
      return lightModeColors[key]
    case 'dark':
      return darkModeColors[key]
  }
}

export function getActiveColorTheme(): Themes {
  if (
    typeof localStorage === 'undefined' ||
    typeof localStorage.getItem !== 'function'
  ) {
    return 'light'
  }

  const savedValue = localStorage.getItem('colorTheme')
  if (savedValue === null) {
    localStorage.setItem('colorTheme', 'light')
    return 'light'
  }
  return savedValue as Themes
}
