import { IconKey } from 'assets/iconLibrary'
import { DesignColor } from 'themes'
import Icon from '../Components/Icon/Icon/Icon'
import { useState } from 'react'
import { FontWeight } from 'application/text'

interface MenuButtonProps {
  label: string
  iconKey?: IconKey
  width?: string
  fontColor?: string
  fontWeight?: FontWeight

  onClick: () => void
}

export default function MenuButton({
  label,
  iconKey,
  width,
  fontColor,
  fontWeight,
  onClick,
}: MenuButtonProps) {
  const [hover, setHover] = useState(false)

  const borderColor = hover
    ? DesignColor('inputHighlight')
    : DesignColor('panelBorder')

  return (
    <button
      style={{
        width: width || 'min-content',
        padding: '8px 12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        background: 'none',
        border: `1px solid ${borderColor}`,
        color: fontColor || DesignColor('text1'),
        fontSize: '12px',
        fontWeight: fontWeight || 'normal',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      {iconKey && (
        <Icon icon={iconKey} size={16} color={DesignColor('text1')} />
      )}
      {label}
    </button>
  )
}
