import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import { AttributeType } from 'application/attributes'

interface LayerIconProps {
  type: AttributeType
  componentDescendent: boolean

  hidden: boolean
}

export default function LayerIcon({
  type,
  componentDescendent,
  hidden,
}: LayerIconProps) {
  return (
    <div
      style={{
        width: 24,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        opacity: !hidden ? 1 : 0.4,
      }}
    >
      <Icon
        icon={getIcon(type)}
        size={16}
        color={getIconColor(componentDescendent)}
      />
    </div>
  )
}

function getIcon(type: AttributeType): IconKey {
  switch (type) {
    case 'page':
      return 'Page'
    case 'frame':
      return 'Frame'
    case 'rectangle':
      return 'Rectangle'
    case 'ellipse':
      return 'Ellipse'
    case 'text':
      return 'Text'
    case 'image':
      return 'Image'
    default:
      return 'Frame'
  }
}

function getIconColor(componentDescendent: boolean): string {
  return DesignColor(componentDescendent ? 'component' : 'text1')
}
