interface LayerPaddingBlockProps {
  depth: number
}

export default function LayerPaddingBlock({ depth }: LayerPaddingBlockProps) {
  return (
    <div
      style={{
        height: 32,
        width: getWidth(depth),
        flexShrink: 0,
      }}
    />
  )
}

function getWidth(depth: number) {
  if (depth === 0) return 0
  return (depth - 1) * 24
}
