import { WriteDocument } from 'application/document'
import { RuleValidator, Violation } from '../types'
import { isDynamicSizeAuto } from 'application/attributes'

export class SizeRulesValidator implements RuleValidator {
  private validators: ((
    nodeId: string,
    document: WriteDocument
  ) => Violation[])[]

  constructor() {
    this.validators = [
      this.checkDynamicSizeOnCanvas,
      this.checkHugNonAutolayout,
    ]
  }

  validate(nodeId: string, document: WriteDocument): Violation[] {
    const violations: Violation[] = []

    for (const validator of this.validators) {
      const nodeViolations = validator(nodeId, document)
      violations.push(...nodeViolations)
    }

    return violations
  }

  private checkHugNonAutolayout = (
    nodeId: string,
    document: WriteDocument
  ): Violation[] => {
    const node = document.getNode(nodeId)
    if (node === undefined) return []

    if (
      node.getStyleAttribute('autolayout.mode') !== 'flex' &&
      node.getBaseAttribute('type') !== 'text' &&
      (node.getStyleAttribute('size.w.auto') === 'hug' ||
        node.getStyleAttribute('size.h.auto') === 'hug')
    ) {
      return [
        {
          type: 'hug_non_autolayout',
          metadata: { nodeId: node.getId() },
        },
      ]
    }

    return []
  }

  private checkDynamicSizeOnCanvas = (
    nodeId: string,
    document: WriteDocument
  ): Violation[] => {
    const node = document.getNode(nodeId)
    if (node === undefined) return []

    const parent = document.getParent(node)
    if (parent === undefined) return []
    if (parent.getBaseAttribute('type') !== 'canvas') return []

    if (
      isDynamicSizeAuto(node.getStyleAttribute('size.w.auto')) ||
      isDynamicSizeAuto(node.getStyleAttribute('size.h.auto'))
    ) {
      return [
        {
          type: 'dynamic_size_on_canvas',
          metadata: {
            nodeId: node.getId(),
          },
        },
      ]
    }

    return []
  }
}
