import { Node, NodeMap } from 'application/node'
import { NodeIdMap } from '../types'
import { AttributeAnimationEffect } from 'application/attributes'

export class ReferenceUpdater {
  static update = (snapshot: NodeMap, idMap: NodeIdMap): void => {
    for (const node of Object.values(snapshot)) {
      this.updateChildren(node, idMap)
      this.updateParent(node, idMap)
      this.updateInteractions(node, idMap)
      this.updateAnimations(node, idMap)
    }
  }

  private static updateChildren = (node: Node, idMap: NodeIdMap): void => {
    const children = node.getChildren()
    if (!children) return

    const updatedChildren = children.map((child) => {
      const updated = idMap[child]
      return updated || child
    })
    node.setChildren(updatedChildren)
  }

  private static updateParent = (node: Node, idMap: NodeIdMap): void => {
    const parentId = node.getParent()
    if (!parentId || idMap[parentId] === undefined) return
    node.setParent(idMap[parentId])
  }

  private static updateInteractions = (node: Node, idMap: NodeIdMap): void => {
    const visibility = node.getBaseAttribute('interaction.visibility')
    if (!visibility || visibility.length === 0) return

    const updatedVisibility = visibility.map((interaction) => {
      const updated = idMap[interaction.targetId]
      return updated ? { ...interaction, targetId: updated } : interaction
    })
    node.setBaseAttribute('interaction.visibility', updatedVisibility)
  }

  private static updateAnimations = (node: Node, idMap: NodeIdMap): void => {
    const animations = node.getBaseAttribute('animations')
    if (!animations || animations.length === 0) return

    const updatedAnimations = animations.map((a) => {
      return {
        action: a.action,
        effect: this.updateAnimationEffectTargets(a.effect, idMap),
      }
    })
    node.setBaseAttribute('animations', updatedAnimations)
  }

  private static updateAnimationEffectTargets = (
    effect: AttributeAnimationEffect,
    idMap: NodeIdMap
  ): AttributeAnimationEffect => {
    switch (effect.type) {
      case 'timeline':
        return {
          ...effect,
          events: effect.events.map((e) => {
            switch (e.targetType) {
              case 'target':
                if (!e.targetId) return e
                if (!idMap[e.targetId]) return e
                return {
                  ...e,
                  targetId: idMap[e.targetId],
                }
              default:
                return e
            }
          }),
        }
      default:
        return effect
    }
  }
}
