import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import { useState } from 'react'
import Icon from '../../Icon/Icon/Icon'
import useAction from 'hooks/editor/useAction'

interface TitleIconBlockProps {
  icon: IconKey

  active?: boolean
  canDeactivate?: boolean

  onClick?: () => void
}

export default function TitleIconBlock({
  icon,
  active = false,
  canDeactivate = false,
  onClick,
}: TitleIconBlockProps) {
  const action = useAction()
  const [hover, setHover] = useState(false)

  const boxShadow = getBoxShadow(hover && !action)
  const iconColor = getIconColor(hover && !action, active)

  return (
    <div
      style={{
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        boxShadow,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div
        style={{
          width: 16,
          height: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transform: `rotate(${canDeactivate ? 45 : 0}deg)`,
        }}
      >
        <Icon icon={icon} size={16} color={iconColor} />
      </div>
    </div>
  )
}

function getIconColor(hover: boolean, active: boolean) {
  return hover
    ? DesignColor('inputHighlight')
    : active
    ? DesignColor('text1')
    : DesignColor('text2')
}

function getBoxShadow(hover: boolean) {
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}
