import posthog from 'posthog-js'
import { useEffect } from 'react'

const { REACT_APP_PUBLIC_POSTHOG_KEY, REACT_APP_PUBLIC_POSTHOG_HOST } =
  process.env

export default function usePosthogInit() {
  useEffect(() => {
    if (process.env.REACT_APP_DEV_MODE === 'true') return
    posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY || '', {
      api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
    })
  }, [])
}
