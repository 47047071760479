import {
  Camera,
  CameraUpdateListener,
  CoordinatesConversion,
} from 'application/camera'
import { ReadOnlyDocument } from 'application/document'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import {
  KeyDownListener,
  KeyUpListener,
  MouseMoveListener,
  isCanvasClosest,
} from 'application/browser'
import { Point, Rectangle, encapsulates, intersects } from 'application/shapes'
import {
  ReadOnlyDocumentSelection,
  computeSelectionRectangle,
} from 'application/selection'
import { InteractableNodeFilter } from 'application/action'
import { DistanceLine } from './types'
import { ReadOnlyNode } from 'application/node'
import { DistanceLineTransformer } from './transformer/distanceLines'
import { FontLoaderInterface } from 'application/text'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'

export const hapticDistanceLinesKey = 'distanceLines'

export class HapticDistanceLines
  implements
    ActiveActionListener,
    CameraUpdateListener,
    CanvasUpdateListener,
    MouseMoveListener,
    KeyDownListener,
    KeyUpListener
{
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private interactable: InteractableNodeFilter
  private canvas: Canvas
  private fontLoader: FontLoaderInterface
  private coordinates: CoordinatesConversion
  private calculation: HapticDistanceLineCalculation

  private camera: Camera | null
  private action: Action | null
  private point: Point | null
  private movingTargets: string[]
  private lines: DistanceLine[] | null
  private activated: boolean

  constructor(
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    interactable: InteractableNodeFilter,
    canvas: Canvas,
    fontLoader: FontLoaderInterface,
    coordinates: CoordinatesConversion
  ) {
    this.document = document
    this.selection = selection
    this.interactable = interactable
    this.canvas = canvas
    this.fontLoader = fontLoader
    this.coordinates = coordinates
    this.calculation = new HapticDistanceLineCalculation()

    this.action = null
    this.camera = null
    this.point = null
    this.movingTargets = []
    this.lines = null
    this.activated = false
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  onActiveAction(handler: ActionHandler | null): void {
    if (handler) this.action = handler.getType()
    else this.action = null
    if (this.action !== 'move') this.movingTargets = []
  }

  handleMouseMove = (e: MouseEvent): void => {
    if (!this.canvas.isReady()) return
    if (!isCanvasClosest(e)) {
      this.point = null
    } else {
      this.point = this.coordinates.get(e)
    }
    if (!this.activated) return
    this.computeLines()
    this.render()
  }

  handleKeyDown = (e: KeyboardEvent): void => {
    if (e.altKey) {
      this.activated = true
      this.computeLines()
      this.render()
    }
  }

  handleKeyUp = (e: KeyboardEvent): void => {
    if (e.key === 'Alt') {
      this.activated = false
      this.lines = null
      this.render()
    }
  }

  setDuplicatedTargets = (ids: string[]): void => {
    this.movingTargets = ids
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (!this.lines || this.lines.length === 0 || !this.camera) {
      this.canvas.deleteHaptic(hapticDistanceLinesKey)
    } else {
      this.canvas.setHaptic(
        hapticDistanceLinesKey,
        DistanceLineTransformer.transform(
          this.canvas.getContext(),
          this.fontLoader,
          this.lines,
          this.camera
        )
      )
    }
  }

  private getPrimaryTargets = (): ReadOnlyNode[] => {
    if (this.action === 'move' && this.movingTargets.length > 0) {
      return this.movingTargets
        .map((id) => this.document.getNode(id))
        .filter((n) => n) as ReadOnlyNode[]
    }

    return this.selection.getSelected()
  }

  private computeLines = (): void => {
    const primary = this.getPrimaryTargets()
    const secondary = this.getSecondaryTargets()
    this.lines = this.calculation.compute(primary, secondary)
  }

  private getSecondaryTargets = (): ReadOnlyNode[] => {
    if (!this.point) return []

    const selected = this.selection.getSelected()
    if (this.action === 'move' && this.movingTargets.length > 0) return selected

    const nodeId = this.interactable.nodeAtPoint(this.point, true, false)
    if (!nodeId) return []

    const node = this.document.getNode(nodeId)
    if (!node) return []

    if (selected.some((s) => s.getId() === nodeId)) return []

    return [node]
  }
}

type LineFunction = (
  r1: Rectangle,
  r2: Rectangle,
  lines: DistanceLine[]
) => void

type PointFunction = (r1: Rectangle, r2: Rectangle) => number
type BoundaryFunction = (r1: Rectangle, r2: Rectangle) => boolean

type Edge = 'top' | 'bottom' | 'left' | 'right' | 'centerV' | 'centerH'
type Direction = 'h' | 'v'

class HapticDistanceLineCalculation {
  compute(primary: ReadOnlyNode[], secondary: ReadOnlyNode[]): DistanceLine[] {
    const lines: DistanceLine[] = []

    const primaryRectangle = computeSelectionRectangle(primary)
    if (!primaryRectangle) return lines

    const secondaryRectangle = computeSelectionRectangle(secondary)
    if (!secondaryRectangle) return lines

    const areOverlapping = intersects(primaryRectangle, secondaryRectangle)

    const functions = areOverlapping
      ? this.getOverlappingFunctions()
      : this.getDisjointFunctions()

    functions.forEach((f) => f(primaryRectangle, secondaryRectangle, lines))

    return lines
  }

  private getOverlappingFunctions = (): LineFunction[] => {
    const functions: LineFunction[] = []

    this.edgeOverlapFunctions.forEach(([p1, p2, c, b, d]) =>
      functions.push((r1, r2, lines) =>
        this.addLine(r1, r2, p1, p2, c, b, d, 'solid', true, lines)
      )
    )

    functions.push((r1, r2, lines) => this.addBoxes(r1, lines))
    functions.push((r1, r2, lines) => this.addBoxes(r2, lines))

    return functions
  }

  private getDisjointFunctions = (): LineFunction[] => {
    const functions: LineFunction[] = []

    this.edgeInsideFunctions.forEach(([p1, p2, c, b, d]) =>
      functions.push((r1, r2, lines) =>
        this.addLine(r1, r2, p1, p2, c, b, d, 'solid', true, lines)
      )
    )

    this.edgeOutsideFunctions.forEach(([p1, p2, c, b, d]) =>
      functions.push((r1, r2, lines) =>
        this.addLine(r1, r2, p1, p2, c, b, d, 'solid', true, lines)
      )
    )

    this.cornerInsideFunctions.forEach(([p1, p2, c, b, d]) =>
      functions.push((r1, r2, lines) =>
        this.addLine(r1, r2, p1, p2, c, b, d, 'dashed', false, lines)
      )
    )

    this.cornerOutsideFunctions.forEach(([p1, p2, c, b, d]) =>
      functions.push((r1, r2, lines) =>
        this.addLine(r1, r2, p1, p2, c, b, d, 'dashed', false, lines)
      )
    )

    functions.push((r1, r2, lines) => this.addBoxes(r1, lines))
    functions.push((r1, r2, lines) => this.addBoxes(r2, lines))

    return functions
  }

  private addLine = (
    r1: Rectangle,
    r2: Rectangle,
    p1Function: PointFunction,
    p2Function: PointFunction,
    counterFunction: PointFunction,
    boundaryFunction: BoundaryFunction,
    direction: Direction,
    type: 'solid' | 'dashed',
    addDistance: boolean,
    lines: DistanceLine[]
  ): void => {
    if (!boundaryFunction(r1, r2)) return

    const p1 = p1Function(r1, r2)
    const p2 = p2Function(r1, r2)
    const counter = counterFunction(r1, r2)
    const thickness = 1
    const display = addDistance ? Math.round(Math.abs(p1 - p2)) : undefined

    lines.push({
      line: { p1, p2, counter, direction, thickness },
      type: type,
      display: display,
    })
  }

  private addBoxes = (r1: Rectangle, lines: DistanceLine[]): void => {
    lines.push({
      line: {
        p1: r1.x,
        p2: r1.x + r1.w,
        counter: r1.y,
        direction: 'h',
        thickness: 1,
      },
      type: 'solid',
    })

    lines.push({
      line: {
        p1: r1.y,
        p2: r1.y + r1.h,
        counter: r1.x,
        direction: 'v',
        thickness: 1,
      },
      type: 'solid',
    })

    lines.push({
      line: {
        p1: r1.x,
        p2: r1.x + r1.w,
        counter: r1.y + r1.h,
        direction: 'h',
        thickness: 1,
      },
      type: 'solid',
    })

    lines.push({
      line: {
        p1: r1.y,
        p2: r1.y + r1.h,
        counter: r1.x + r1.w,
        direction: 'v',
        thickness: 1,
      },
      type: 'solid',
    })
  }

  private getPoint = (r: Rectangle, point: Edge): number => {
    switch (point) {
      case 'top':
        return r.y
      case 'bottom':
        return r.y + r.h
      case 'left':
        return r.x
      case 'right':
        return r.x + r.w
      case 'centerV':
        return r.y + r.h / 2
      case 'centerH':
        return r.x + r.w / 2
    }
  }

  private edgeOverlapFunctions: [
    PointFunction,
    PointFunction,
    PointFunction,
    BoundaryFunction,
    Direction
  ][] = [
    // Top to Top
    [
      (r1, r2) => this.getPoint(r1, 'top'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) => {
        const r1Left = this.getPoint(r1, 'left')
        const r1Right = this.getPoint(r1, 'right')
        const r2Left = this.getPoint(r2, 'left')
        const r2Right = this.getPoint(r2, 'right')
        if (encapsulates(r1, r2)) {
          return this.getPoint(r2, 'centerH')
        } else if (encapsulates(r2, r1)) {
          return this.getPoint(r1, 'centerH')
        }
        return (Math.max(r1Left, r2Left) + Math.min(r1Right, r2Right)) / 2
      },
      (r1, r2) => this.getPoint(r1, 'top') !== this.getPoint(r2, 'bottom'),
      'v',
    ],
    // Bottom to Bottom
    [
      (r1, r2) => this.getPoint(r1, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) => {
        const r1Left = this.getPoint(r1, 'left')
        const r1Right = this.getPoint(r1, 'right')
        const r2Left = this.getPoint(r2, 'left')
        const r2Right = this.getPoint(r2, 'right')
        if (encapsulates(r1, r2)) {
          return this.getPoint(r2, 'centerH')
        } else if (encapsulates(r2, r1)) {
          return this.getPoint(r1, 'centerH')
        }
        return (Math.max(r1Left, r2Left) + Math.min(r1Right, r2Right)) / 2
      },
      (r1, r2) => this.getPoint(r1, 'bottom') !== this.getPoint(r2, 'top'),
      'v',
    ],
    // Left to Left
    [
      (r1, r2) => this.getPoint(r1, 'left'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) => {
        const r1Top = this.getPoint(r1, 'top')
        const r1Bottom = this.getPoint(r1, 'bottom')
        const r2Top = this.getPoint(r2, 'top')
        const r2Bottom = this.getPoint(r2, 'bottom')
        if (encapsulates(r1, r2)) {
          return this.getPoint(r2, 'centerV')
        } else if (encapsulates(r2, r1)) {
          return this.getPoint(r1, 'centerV')
        }
        return (Math.max(r1Top, r2Top) + Math.min(r1Bottom, r2Bottom)) / 2
      },
      (r1, r2) => this.getPoint(r1, 'left') !== this.getPoint(r2, 'right'),
      'h',
    ],
    // Right to Right
    [
      (r1, r2) => this.getPoint(r1, 'right'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) => {
        const r1Top = this.getPoint(r1, 'top')
        const r1Bottom = this.getPoint(r1, 'bottom')
        const r2Top = this.getPoint(r2, 'top')
        const r2Bottom = this.getPoint(r2, 'bottom')
        if (encapsulates(r1, r2)) {
          return this.getPoint(r2, 'centerV')
        } else if (encapsulates(r2, r1)) {
          return this.getPoint(r1, 'centerV')
        }
        return (Math.max(r1Top, r2Top) + Math.min(r1Bottom, r2Bottom)) / 2
      },
      (r1, r2) => this.getPoint(r1, 'right') !== this.getPoint(r2, 'left'),
      'h',
    ],
  ]

  private edgeInsideFunctions: [
    PointFunction,
    PointFunction,
    PointFunction,
    BoundaryFunction,
    Direction
  ][] = [
    // Top to Top
    [
      (r1, r2) => this.getPoint(r1, 'top'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) =>
        this.getPoint(r1, 'top') > this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'top') <= this.getPoint(r2, 'bottom') &&
        (this.getPoint(r1, 'left') > this.getPoint(r2, 'right') ||
          this.getPoint(r1, 'right') < this.getPoint(r2, 'left')),
      'v',
    ],
    // Bottom to Bottom
    [
      (r1, r2) => this.getPoint(r1, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) =>
        this.getPoint(r1, 'bottom') >= this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'bottom') < this.getPoint(r2, 'bottom') &&
        (this.getPoint(r1, 'left') > this.getPoint(r2, 'right') ||
          this.getPoint(r1, 'right') < this.getPoint(r2, 'left')),
      'v',
    ],
    // Left to Left
    [
      (r1, r2) => this.getPoint(r1, 'left'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) =>
        this.getPoint(r1, 'left') > this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'left') <= this.getPoint(r2, 'right') &&
        (this.getPoint(r1, 'top') > this.getPoint(r2, 'bottom') ||
          this.getPoint(r1, 'bottom') < this.getPoint(r2, 'top')),
      'h',
    ],
    // Right to Right
    [
      (r1, r2) => this.getPoint(r1, 'right'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) =>
        this.getPoint(r1, 'right') >= this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'right') < this.getPoint(r2, 'right') &&
        (this.getPoint(r1, 'top') > this.getPoint(r2, 'bottom') ||
          this.getPoint(r1, 'bottom') < this.getPoint(r2, 'top')),
      'h',
    ],
  ]

  private edgeOutsideFunctions: [
    PointFunction,
    PointFunction,
    PointFunction,
    BoundaryFunction,
    Direction
  ][] = [
    // Top to Bottom
    [
      (r1, r2) => this.getPoint(r1, 'top'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) => this.getPoint(r1, 'top') > this.getPoint(r2, 'bottom'),
      'v',
    ],
    // Bottom to Top
    [
      (r1, r2) => this.getPoint(r1, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) => this.getPoint(r1, 'bottom') < this.getPoint(r2, 'top'),
      'v',
    ],
    // Left to Right
    [
      (r1, r2) => this.getPoint(r1, 'left'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) => this.getPoint(r1, 'left') > this.getPoint(r2, 'right'),
      'h',
    ],
    // Right to Left
    [
      (r1, r2) => this.getPoint(r1, 'right'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) => this.getPoint(r1, 'right') < this.getPoint(r2, 'left'),
      'h',
    ],
  ]

  private cornerInsideFunctions: [
    PointFunction,
    PointFunction,
    PointFunction,
    BoundaryFunction,
    Direction
  ][] = [
    // Bottom Right Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'right'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) =>
        this.getPoint(r1, 'bottom') >= this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'bottom') < this.getPoint(r2, 'bottom') &&
        this.getPoint(r1, 'left') > this.getPoint(r2, 'right'),
      'h',
    ],
    // Bottom Right Vertical
    [
      (r1, r2) => this.getPoint(r1, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) =>
        this.getPoint(r1, 'right') >= this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'right') < this.getPoint(r2, 'right') &&
        this.getPoint(r1, 'top') > this.getPoint(r2, 'bottom'),
      'v',
    ],
    // Top Right Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'right'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) =>
        this.getPoint(r1, 'top') > this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'top') <= this.getPoint(r2, 'bottom') &&
        this.getPoint(r1, 'left') > this.getPoint(r2, 'right'),
      'h',
    ],
    // Top Right Vertical
    [
      (r1, r2) => this.getPoint(r1, 'top'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) =>
        this.getPoint(r1, 'right') >= this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'right') < this.getPoint(r2, 'right') &&
        this.getPoint(r1, 'bottom') < this.getPoint(r2, 'top'),
      'v',
    ],
    // Bottom Left Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'left'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) =>
        this.getPoint(r1, 'bottom') >= this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'bottom') < this.getPoint(r2, 'bottom') &&
        this.getPoint(r1, 'right') < this.getPoint(r2, 'left'),
      'h',
    ],
    // Bottom Left Vertical
    [
      (r1, r2) => this.getPoint(r1, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) =>
        this.getPoint(r1, 'left') <= this.getPoint(r2, 'right') &&
        this.getPoint(r1, 'left') > this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'top') > this.getPoint(r2, 'bottom'),
      'v',
    ],
    // Top Left Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'left'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) =>
        this.getPoint(r1, 'top') > this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'top') <= this.getPoint(r2, 'bottom') &&
        this.getPoint(r1, 'right') < this.getPoint(r2, 'left'),
      'h',
    ],
    // Top Left Vertical
    [
      (r1, r2) => this.getPoint(r1, 'top'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) =>
        this.getPoint(r1, 'left') <= this.getPoint(r2, 'right') &&
        this.getPoint(r1, 'left') > this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'bottom') < this.getPoint(r2, 'top'),
      'v',
    ],
  ]

  private cornerOutsideFunctions: [
    PointFunction,
    PointFunction,
    PointFunction,
    BoundaryFunction,
    Direction
  ][] = [
    // Bottom Right Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) =>
        this.getPoint(r1, 'top') > this.getPoint(r2, 'bottom') &&
        this.getPoint(r1, 'centerH') >= this.getPoint(r2, 'right'),
      'h',
    ],
    // Bottom Right Vertical
    [
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) =>
        this.getPoint(r1, 'left') > this.getPoint(r2, 'right') &&
        this.getPoint(r1, 'centerV') >= this.getPoint(r2, 'bottom'),
      'v',
    ],
    // Top Right Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) =>
        this.getPoint(r1, 'bottom') < this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'centerH') >= this.getPoint(r2, 'right'),
      'h',
    ],
    // Top Right Vertical
    [
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) => this.getPoint(r2, 'right'),
      (r1, r2) =>
        this.getPoint(r1, 'left') > this.getPoint(r2, 'right') &&
        this.getPoint(r1, 'centerV') <= this.getPoint(r2, 'top'),
      'v',
    ],
    // Bottom Left Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) =>
        this.getPoint(r1, 'top') > this.getPoint(r2, 'bottom') &&
        this.getPoint(r1, 'centerH') <= this.getPoint(r2, 'left'),
      'h',
    ],
    // Bottom Left Vertical
    [
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) => this.getPoint(r2, 'bottom'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) =>
        this.getPoint(r1, 'right') < this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'centerV') >= this.getPoint(r2, 'bottom'),
      'v',
    ],
    // Top Left Horizontal
    [
      (r1, r2) => this.getPoint(r1, 'centerH'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) =>
        this.getPoint(r1, 'bottom') < this.getPoint(r2, 'top') &&
        this.getPoint(r1, 'centerH') <= this.getPoint(r2, 'left'),
      'h',
    ],
    // Top Left Vertical
    [
      (r1, r2) => this.getPoint(r1, 'centerV'),
      (r1, r2) => this.getPoint(r2, 'top'),
      (r1, r2) => this.getPoint(r2, 'left'),
      (r1, r2) =>
        this.getPoint(r1, 'right') < this.getPoint(r2, 'left') &&
        this.getPoint(r1, 'centerV') <= this.getPoint(r2, 'top'),
      'v',
    ],
  ]
}
