import { DesignColor } from 'themes'

export default function TemplateDivider() {
  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        padding: '8px 16px',
        boxSizing: 'border-box',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      <div
        style={{
          width: 80,
          height: 1,
          background: DesignColor('panelBorder'),
        }}
      />
    </div>
  )
}
