import { Project, ProjectWebsiteInfo } from 'application/service'
import { panelShadow } from 'components/Library/Containers/constants'
import { DesignColor } from 'themes'
import ProjectCardActionButton from './ProjectCardActionButton'
import ProjectCardRenamePopup from './ProjectCardRenamePopup'
import { useEffect, useRef, useState } from 'react'
import ProjectCardDeletePopup from './ProjectCardDeletePopup'
import { ProjectGridSortMode } from '../../ProjectGridControls/ProjectSortButton'
import useHovered from 'hooks/ui/useHovered'
import useBackendService from 'hooks/services/useBackendService'

interface ProjectCardProps {
  project: Project
  sortMode: ProjectGridSortMode

  openProject: () => void
  manageProject: () => void
  renameProject: (name: string) => void
  deleteProject: () => void
}

export default function ProjectCard({
  project,
  sortMode,
  openProject,
  manageProject,
  renameProject,
  deleteProject,
}: ProjectCardProps) {
  const backendService = useBackendService()

  const cardRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: cardRef })

  const [info, setInfo] = useState<ProjectWebsiteInfo | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [renameOpen, setRenameOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const lastViewedTime = getLastViewedTime(project.id)

  const getDateInfo = () => {
    if (sortMode === 'dateCreated') {
      return `Date Created ${new Date(project.createdAt).toLocaleDateString(
        'en-US',
        {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }
      )}`
    } else {
      return `Last Viewed ${
        lastViewedTime
          ? new Date(lastViewedTime).toLocaleDateString('en-US', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })
          : ''
      }`
    }
  }

  useEffect(() => {
    backendService
      .getProjectWebsiteInfo(project.id)
      .then((info) => setInfo(info))
      .catch(() => {})
  }, [project.id, backendService])

  return (
    <>
      <div
        ref={cardRef}
        style={{
          height: 'auto',
          backgroundColor: DesignColor('panelBackground'),
          boxShadow: [
            panelShadow,
            `inset 0 0 0 1px ${
              hovered || renameOpen || deleteOpen || menuOpen
                ? DesignColor('inputHighlight')
                : DesignColor('panelBorder')
            }`,
          ].join(', '),
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => {
          if (renameOpen || deleteOpen || menuOpen) return
          openProject()
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '100%',
            padding: '16px 16px 0 16px',
            boxSizing: 'border-box',
            aspectRatio: '16 / 9',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              background: DesignColor('canvasBackground'),
            }}
          ></div>
        </div>
        <div
          style={{
            width: '100%',
            padding: '16px',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <p
              style={{
                fontSize: '12px',
                color: DesignColor('text1'),
              }}
            >
              {project.name}
            </p>
            <p
              style={{
                fontSize: '12px',
                color: DesignColor('text2'),
              }}
            >
              {getDateInfo()}
            </p>
          </div>
          <ProjectCardActionButton
            premium={info?.premium || false}
            cardHovered={hovered || renameOpen || deleteOpen || menuOpen}
            open={menuOpen}
            setOpen={(o) => setMenuOpen(o)}
            openRenamePopup={() => setRenameOpen(true)}
            openDeletePopup={() => setDeleteOpen(true)}
            manageProject={manageProject}
          />
          <ProjectCardRenamePopup
            open={renameOpen}
            close={() => setRenameOpen(false)}
            rename={(name) => {
              renameProject(name)
              setRenameOpen(false)
            }}
          />
          <ProjectCardDeletePopup
            open={deleteOpen}
            name={project.name}
            close={() => setDeleteOpen(false)}
            deleteProject={() => {
              deleteProject()
              setDeleteOpen(false)
            }}
          />
        </div>
      </div>
    </>
  )
}

function getLastViewedTime(projectId: string): Date | undefined {
  const lastViewedProjects = localStorage.getItem('lastViewedProjects')
  if (!lastViewedProjects) return undefined

  const lastViewedProjectsMap = JSON.parse(lastViewedProjects)
  return new Date(lastViewedProjectsMap[projectId])
}
