import TextRow from 'components/Library/Components/Text/TextRow/TextRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'

export default function MixedSelector() {
  return (
    <PanelSection>
      <TextRow width={'fill'} mode={'label2'}>
        You can only multi-edit elements with matching breakpoints and states.
      </TextRow>
    </PanelSection>
  )
}
