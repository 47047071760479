import Dropdown from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import _ from 'lodash'
import FaviconRow from './FaviconRow'
import SocialImageRow from './SocialImageRow'
import useToast from 'hooks/editor/useToast'

interface WebsiteSettingsProps {
  domain: string
  status: string

  homepageId: string
  homepageOptions: {
    id: string
    name: string
  }[]
  setHomepage: (id: string) => void

  faviconUrl: string
  setFavicon: (url: string) => void

  socialImageUrl: string
  setSocialImage: (url: string) => void

  close: () => void
}

export default function GeneralSettings({
  domain,
  status,
  homepageId,
  homepageOptions,
  setHomepage,
  faviconUrl,
  setFavicon,
  socialImageUrl,
  setSocialImage,
  close,
}: WebsiteSettingsProps) {
  const toast = useToast()

  return (
    <>
      <PanelSection width={'fill'}>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="title">
            General Settings
          </TextBlock>
          <IconBlock icon="X" onClick={close} />
        </PanelRow>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={96} mode="label2">
            Domain:
          </TextBlock>
          <TextBlock width={'fill'} mode="label1">
            {domain}
          </TextBlock>
          <IconBlock
            icon="Copy"
            onClick={() => {
              navigator.clipboard.writeText(domain)
              toast('Domain copied to clipboard', 'info')
            }}
          />
        </PanelRow>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={96} mode="label2">
            Status:
          </TextBlock>
          <TextBlock width={'fill'} mode="label1">
            {_.capitalize(status)}
          </TextBlock>
          {status === 'live' && (
            <IconBlock
              icon="Navigate"
              onClick={() => window.open(domain, '_blank')}
            />
          )}
        </PanelRow>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={96} mode="label2">
            Home Page:
          </TextBlock>
          <Dropdown
            width={192}
            selected={homepageId}
            select={(id) => setHomepage(id)}
            options={homepageOptions.map((option) => ({
              value: option.id,
              text: option.name,
              icon: 'Page',
            }))}
            hasIcon={true}
            empty="Select a page"
            emptyIcon="Page"
          />
        </PanelRow>
      </PanelSection>
      <PanelSection width={'fill'}>
        <div
          style={{
            width: '100%',
            height: 'fit-content',
            padding: '0px 8px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <FaviconRow faviconUrl={faviconUrl} setFavicon={setFavicon} />
          <SocialImageRow
            socialImageUrl={socialImageUrl}
            setSocialImage={setSocialImage}
          />
        </div>
      </PanelSection>
    </>
  )
}
