import { useEffect, useRef, useState } from 'react'
import { DesignColor } from 'themes'

interface LargeTextInputBlockProps {
  value: string
  prefix?: string
  setValue: (value: string) => void

  height?: 96 | 200 | 'hug'

  wrap?: boolean

  placeholder?: string
}

export default function LargeTextInputBlock({
  value,
  prefix,
  setValue,
  height = 96,
  wrap = false,
  placeholder,
}: LargeTextInputBlockProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [localValue, setLocalValue] = useState(value)

  const handleStartEdit = (e: React.FocusEvent<HTMLInputElement>) => {
    if (prefix && e.target.value !== '' && !e.target.value.startsWith(prefix)) {
      setLocalValue(prefix + e.target.value)
      e.target.setSelectionRange(e.target.value.length, e.target.value.length)
    }
  }

  const handleEndEdit = () => {
    setValue(localValue)
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && inputRef.current) {
      e.preventDefault()
      inputRef.current.blur()
    }
  }

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return (
    <div
      style={{
        width: '100%',
        height: height === 'hug' ? 'fit-content' : height,
        flexShrink: 0,
        padding: '4px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {height === 'hug' ? (
        <input
          ref={inputRef}
          type="text"
          spellCheck="false"
          value={localValue}
          onFocus={(e) => handleStartEdit(e)}
          onBlur={handleEndEdit}
          onChange={(e) => {
            if (
              (prefix && e.target.value.startsWith(prefix)) ||
              e.target.value === ''
            ) {
              setLocalValue(e.target.value)
            } else {
              setLocalValue(prefix ? prefix + e.target.value : e.target.value)
            }
          }}
          onKeyUp={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            e.stopPropagation()
            handleKeyDown(e)
          }}
          onPaste={(e) => e.stopPropagation()}
          placeholder={placeholder}
          style={{
            width: '100%',
            padding: 8,
            margin: 0,
            boxSizing: 'border-box',
            fontSize: 11,
            fontFamily: 'Inter',
            color: DesignColor('text1'),
            borderRadius: 4,
            border: 'none',
            resize: 'none',
            outline: 'none',
            background: DesignColor('panelTint'),
            whiteSpace: wrap ? 'pre-wrap' : 'nowrap',
          }}
        />
      ) : (
        <textarea
          spellCheck="false"
          value={localValue}
          onChange={(e) => setLocalValue(e.target.value)}
          onKeyUp={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            e.stopPropagation()
            handleKeyDown(e)
          }}
          onBlur={handleEndEdit}
          onPaste={(e) => e.stopPropagation()}
          placeholder={placeholder}
          style={{
            width: '100%',
            height: '100%',
            padding: 8,
            margin: 0,
            boxSizing: 'border-box',
            fontSize: 11,
            fontFamily: 'Inter',
            color: DesignColor('text1'),
            borderRadius: 4,
            border: 'none',
            resize: 'none',
            outline: 'none',
            background: DesignColor('panelTint'),
            overflowX: 'scroll',
            overflowY: 'scroll',
            whiteSpace: wrap ? 'pre-wrap' : 'nowrap',
          }}
        />
      )}
    </div>
  )
}
