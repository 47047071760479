import { Material, Texture, VertexBuffer } from 'application/render'
import { Camera } from 'application/camera'
import { mat3 } from 'gl-matrix'
import { Color, glClearColors, rgbaToWebgl } from 'application/color'
import { MaterialType, shaderMap } from '../renderables/shaderMap'
import { WebglTransfer } from '../renderables/webglTransfer'
import { Rectangle } from 'application/shapes'

const MaterialsWithZoom = [MaterialType.rectangleBorder, MaterialType.icon]
const MaterialsWithDpr = [MaterialType.rectangleBorder]
const MaterialsWithTexelSize = [
  MaterialType.rectangleBlur,
  MaterialType.ellipseOuterShadow,
  MaterialType.ellipseInnerShadow,
]

export enum BlendMode {
  'default',
  'shadow',
  'jitter',
  'clear',
}

export type DrawingTarget = 'temp1' | 'temp2' | number

type SceneTextures = {
  [key in DrawingTarget]: Texture
}

export class Context {
  private canvas: HTMLCanvasElement | null
  private gl: WebGL2RenderingContext | null
  private fbo: WebGLFramebuffer | null
  private materials: { [key in MaterialType]: Material } | null
  private textures: { [key: string]: Texture }
  private sceneTextures: SceneTextures | null
  private sceneTransferrer: WebglTransfer | null
  private camera: Camera
  private w: number
  private h: number
  private dpr: number
  private pendingTextureRequests: { [key: string]: boolean }

  constructor() {
    this.canvas = null
    this.gl = null
    this.fbo = null
    this.materials = null
    this.textures = {}
    this.sceneTextures = null
    this.sceneTransferrer = null
    this.camera = { x: 0, y: 0, z: 1 }
    this.w = 0
    this.h = 0
    this.dpr = 0
    this.pendingTextureRequests = {}
  }

  init = (canvas: HTMLCanvasElement) => {
    this.canvas = canvas

    this.gl = this.createGl()
    this.fbo = this.createFbo()
    this.sceneTextures = this.createScenes()
    this.materials = this.createMaterials()

    const { width, height } = canvas.getBoundingClientRect()
    const dpr = window.devicePixelRatio
    this.updateCanvasSize(width, height, dpr)

    this.sceneTransferrer = new WebglTransfer(this, {
      source: 0,
    })
    this.sceneTransferrer.init()
  }

  getGl = (): WebGL2RenderingContext => {
    if (!this.gl) throw new Error('WebGL context not initialized')
    return this.gl
  }

  getSize = (): { w: number; h: number } => {
    return { w: this.w, h: this.h }
  }

  getCamera = (): Camera => {
    return this.camera
  }

  getMaterial = (type: MaterialType): Material => {
    if (!this.materials) throw new Error('WebGL context not initialized')
    return this.materials[type]
  }

  getTexture = (src: string): Texture | undefined => {
    return this.textures[src]
  }

  getSceneTexture = (key: DrawingTarget): Texture => {
    if (!this.sceneTextures) throw new Error('WebGL context not initialized')
    const texture = this.sceneTextures[key]
    if (!texture) throw new Error(`Texture ${key} not found`)
    return texture
  }

  clear = (color?: Color) => {
    if (color) this.setClearColor(color)
    const gl = this.getGl()
    gl.clear(gl.COLOR_BUFFER_BIT)
  }

  clearScene = (target: DrawingTarget) => {
    if (!this.sceneTextures) return
    this.setDrawingTarget(target)
    if (target === 0) {
      this.setClearColor(glClearColors.default)
    } else {
      this.setClearColor(glClearColors.transparent)
    }
    this.clear()
    this.setDrawingTarget(0)
  }

  clearAllScenes = () => {
    if (!this.sceneTextures) return
    for (const key of Object.keys(this.sceneTextures)) {
      this.setDrawingTarget(key as DrawingTarget)
      this.setClearColor(glClearColors.transparent)
      this.clear()
    }
    this.setDrawingTarget(0)
    this.setClearColor(glClearColors.default)
    this.clear()
  }

  drawToCanvas = () => {
    if (!this.gl || !this.sceneTextures || !this.sceneTransferrer) return
    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null)
    this.sceneTransferrer.draw()
  }

  transferScene = (
    source: DrawingTarget,
    target: DrawingTarget,
    opacity: number = 100,
    boundingBox: Rectangle | undefined = undefined
  ) => {
    if (!this.sceneTransferrer) return
    this.sceneTransferrer.setSource(source)
    this.setDrawingTarget(target)
    if (boundingBox) {
      this.sceneTransferrer.updateRenderData(boundingBox)
      this.sceneTransferrer.draw(target, opacity / 100)
      this.sceneTransferrer.updateRenderData()
    } else {
      this.sceneTransferrer.draw(target, opacity / 100)
    }
    this.sceneTransferrer.setSource(0)
  }

  createMaterial = (sourceVs: string, sourceFs: string): Material => {
    return new Material(this, sourceVs, sourceFs)
  }

  createImageTexture = (src: string): Promise<Texture | undefined> => {
    if (this.pendingTextureRequests[src]) {
      return Promise.resolve(this.textures[src])
    }

    this.pendingTextureRequests[src] = true

    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = `${src}?nocache=${new Date().getTime()}`
      image.crossOrigin = 'anonymous'
      image.onload = () => {
        const texture = new Texture(this, this.w, this.h, this.dpr, image)
        this.textures[src] = texture
        resolve(texture)
      }
      image.onerror = reject
    })
  }

  createVertexBuffer = (data: Float32Array, step: number): VertexBuffer => {
    return new VertexBuffer(this, data, step)
  }

  setDrawingTarget = (target: DrawingTarget) => {
    if (!this.gl || !this.sceneTextures) return
    if (
      typeof target === 'number' &&
      this.sceneTextures[target] === undefined
    ) {
      this.sceneTextures[target] = new Texture(this, this.w, this.h, this.dpr)
    }
    const texture = this.sceneTextures[target]

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.fbo)
    this.gl.framebufferTexture2D(
      this.gl.FRAMEBUFFER,
      this.gl.COLOR_ATTACHMENT0,
      this.gl.TEXTURE_2D,
      texture.getTexture(),
      0
    )
  }

  setCamera = (camera: Camera) => {
    if (this.gl === null) return
    this.camera = camera
    this.updatePerspectiveUniforms()
  }

  setClearColor = (color: Color) => {
    const gl = this.getGl()
    const c = rgbaToWebgl(color)
    gl.clearColor(c[0], c[1], c[2], c[3])
  }

  setBlendMode = (mode: BlendMode) => {
    const gl = this.getGl()
    switch (mode) {
      case BlendMode.default:
        gl.blendFuncSeparate(
          gl.SRC_ALPHA,
          gl.ONE_MINUS_SRC_ALPHA,
          gl.ONE,
          gl.ONE_MINUS_SRC_ALPHA
        )
        break
      case BlendMode.shadow:
        gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA)
        break
      case BlendMode.jitter:
        gl.blendFuncSeparate(gl.ONE, gl.ZERO, gl.ONE, gl.ONE)
        break
      case BlendMode.clear:
        gl.blendFunc(gl.ZERO, gl.ZERO)
        break
    }
  }

  updateCanvasSize = (width: number, height: number, dpr: number) => {
    if (!this.canvas || !this.gl || !this.sceneTextures) return

    this.canvas.width = width * dpr
    this.canvas.height = height * dpr

    this.w = width
    this.h = height
    this.dpr = dpr

    this.gl.viewport(0, 0, this.canvas.width, this.canvas.height)
    this.updatePerspectiveUniforms()

    for (const texture of Object.values(this.sceneTextures)) {
      if (texture) texture.resize(this.w, this.h, this.dpr)
    }
  }

  cleanup = () => {
    if (!this.gl || !this.materials || !this.sceneTextures) return
    for (const texture of Object.values(this.textures)) {
      texture.cleanup()
    }
    for (const texture of Object.values(this.sceneTextures)) {
      texture.cleanup()
    }
  }

  private updatePerspectiveUniforms = () => {
    if (!this.materials) throw new Error('WebGL context not initialized')

    const matrix = cameraToWebGLMatrix(this.camera, this.w, this.h)

    for (const material of Object.values(this.materials)) {
      material.setUniform('uMatrix', matrix)
    }
    for (const materialType of MaterialsWithZoom) {
      const material = this.getMaterial(materialType)
      material.setUniform('uZoom', this.camera.z)
    }
    for (const materialType of MaterialsWithDpr) {
      const material = this.getMaterial(materialType)
      material.setUniform('uDpr', window.devicePixelRatio)
    }
    for (const materialType of MaterialsWithTexelSize) {
      const material = this.getMaterial(materialType)
      material.setUniform('uTexelSize', this.getTexelSize())
    }

    if (this.sceneTransferrer) this.sceneTransferrer.updateRenderData()
  }

  private getTexelSize = (): number[] => {
    const dpr = window.devicePixelRatio
    const texelWidth = 2 / ((this.w * dpr) / this.camera.z)
    const texelHeight = 2 / ((this.h * dpr) / this.camera.z)

    return [texelWidth, texelHeight]
  }

  private createGl = (): WebGL2RenderingContext => {
    if (!this.canvas) throw new Error('Canvas not initialized')

    const gl = this.canvas.getContext('webgl2')
    if (gl === null) throw new Error('Could not initialize WebGL2 context')
    this.gl = gl

    gl.enable(gl.BLEND)
    this.setBlendMode(BlendMode.default)

    this.setClearColor(glClearColors.default)
    this.clear()

    return gl
  }

  private createFbo = (): WebGLFramebuffer => {
    const fbo = this.gl?.createFramebuffer()
    if (!fbo) throw new Error('Could not create framebuffer')
    return fbo
  }

  private createScenes = (): SceneTextures => {
    return {
      temp1: new Texture(this, this.w, this.h, this.dpr),
      temp2: new Texture(this, this.w, this.h, this.dpr),
    }
  }

  private createMaterials = (): { [key in MaterialType]: Material } => {
    let materials: Partial<{ [key in MaterialType]: Material }> = {}
    Object.keys(MaterialType).forEach((key) => {
      const shader = shaderMap[key as MaterialType]
      materials[key as MaterialType] = this.createMaterial(shader.vs, shader.fs)
    })

    if (!Object.values(MaterialType).every((key) => key in materials)) {
      throw new Error('Not all materials have been initialized properly')
    }

    return materials as { [key in MaterialType]: Material }
  }
}

function cameraToWebGLMatrix(camera: Camera, w: number, h: number): mat3 {
  const { x, y, z } = camera

  // prettier-ignore
  const zoom = mat3.fromValues(
      z, 0, 0,
      0, z, 0,
      0, 0, 1,
    );

  // prettier-ignore
  const translate = mat3.fromValues(
      1, 0, 0,
      0, 1, 0,
      -x, y, 1
    );

  // prettier-ignore
  const canvas = mat3.fromValues(
      2 / w, 0, 0,
      0, -2 / h, 0,
      -1, 1, 1
    );

  const transform = mat3.create()

  mat3.multiply(transform, zoom, translate)
  mat3.multiply(transform, canvas, transform)

  return transform
}
