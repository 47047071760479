import PanelBlock from '../PanelBlock/PanelBlock'

interface IconSetBlockProps {
  width?: 96 | 120 | 216
  align?: 'center' | 'left' | 'right'

  children?: React.ReactNode
}

export default function IconSetBlock({
  width = 96,
  align = 'left',
  children,
}: IconSetBlockProps) {
  return (
    <PanelBlock width={width} align={align} paddingL={4} paddingR={8}>
      {children}
    </PanelBlock>
  )
}
