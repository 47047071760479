import { StyleMap } from 'application/attributes'
import { truncate } from 'application/math'
import { ReadOnlyNode } from 'application/node'

export function getAdjustedPositionModes(
  node: ReadOnlyNode
): Partial<StyleMap> {
  const positionMode = node.getStyleAttribute('position.mode')
  const topMode = node.getStyleAttribute('position.top.auto')
  const leftMode = node.getStyleAttribute('position.left.auto')
  const bottomMode = node.getStyleAttribute('position.bottom.auto')
  const rightMode = node.getStyleAttribute('position.right.auto')
  return {
    'position.mode': positionMode,
    'position.top.auto': topMode,
    'position.left.auto': leftMode,
    'position.bottom.auto': bottomMode,
    'position.right.auto': rightMode,
  }
}

export function getAdjustedTop(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  top: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentTop = parent.getBaseAttribute('y')
  const parentHeight = parent.getBaseAttribute('h')
  const topMode = node.getStyleAttribute('position.top.auto')

  switch (topMode) {
    case 'fixed':
      update['position.top'] = truncate(top - parentTop)
      break
    case 'percent':
      if (parentHeight === 0) break
      update['position.top.percent'] = truncate(
        ((top - parentTop) / parentHeight) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedLeft(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  left: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentLeft = parent.getBaseAttribute('x')
  const parentWidth = parent.getBaseAttribute('w')
  const leftMode = node.getStyleAttribute('position.left.auto')

  switch (leftMode) {
    case 'fixed':
      update['position.left'] = truncate(left - parentLeft)
      break
    case 'percent':
      if (parentWidth === 0) break
      update['position.left.percent'] = truncate(
        ((left - parentLeft) / parentWidth) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedBottom(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  bottom: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentTop = parent.getBaseAttribute('y')
  const parentHeight = parent.getBaseAttribute('h')
  const bottomMode = node.getStyleAttribute('position.bottom.auto')

  switch (bottomMode) {
    case 'fixed':
      update['position.bottom'] = truncate(parentTop + parentHeight - bottom)
      break
    case 'percent':
      if (parentHeight === 0) break
      update['position.bottom.percent'] = truncate(
        100 - ((bottom - parentTop) / parentHeight) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedRight(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  right: number
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentLeft = parent.getBaseAttribute('x')
  const parentWidth = parent.getBaseAttribute('w')
  const rightMode = node.getStyleAttribute('position.right.auto')

  switch (rightMode) {
    case 'fixed':
      update['position.right'] = truncate(parentLeft + parentWidth - right)
      break
    case 'percent':
      if (parentWidth === 0) break
      update['position.right.percent'] = truncate(
        100 - ((right - parentLeft) / parentWidth) * 100,
        8
      )
      break
  }

  return update
}

export function getAdjustedSizeModes(node: ReadOnlyNode): Partial<StyleMap> {
  const widthMode = node.getStyleAttribute('size.w.auto')
  const heightMode = node.getStyleAttribute('size.h.auto')
  return {
    'size.w.auto': widthMode,
    'size.h.auto': heightMode,
  }
}

export function getAdjustedWidth(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  width: number,
  checkEqual: boolean = true
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentWidth = parent.getBaseAttribute('w')
  const widthMode = node.getStyleAttribute('size.w.auto')

  switch (widthMode) {
    case 'fixed':
      if (node.getStyleAttribute('size.w') === width && checkEqual) break
      update['size.w'] = truncate(width)
      break
    case 'percent':
      if (parentWidth === 0) break
      const currentPercent = node.getStyleAttribute('size.w.percent')
      const newPercent = truncate((width / parentWidth) * 100, 8)
      if (currentPercent !== newPercent || !checkEqual) {
        update['size.w.percent'] = newPercent
      }
      break
  }

  return update
}

export function getAdjustedHeight(
  node: ReadOnlyNode,
  parent: ReadOnlyNode,
  height: number,
  checkEqual: boolean = true
): Partial<StyleMap> {
  const update: Partial<StyleMap> = {}

  const parentHeight = parent.getBaseAttribute('h')
  const heightMode = node.getStyleAttribute('size.h.auto')

  switch (heightMode) {
    case 'fixed':
      if (node.getStyleAttribute('size.h') === height && checkEqual) break
      update['size.h'] = truncate(height)
      break
    case 'percent':
      if (parentHeight === 0) break
      const currentPercent = node.getStyleAttribute('size.h.percent')
      const newPercent = truncate((height / parentHeight) * 100, 8)
      if (currentPercent !== newPercent || !checkEqual) {
        update['size.h.percent'] = newPercent
      }
  }

  return update
}
