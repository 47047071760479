import { ActionHandler } from '../types'
import { CommandHandler } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { DragCanvasAction } from './action'
import { DraggingCanvasLine } from './line'

export class DragCanvasActionFactory {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private line: DraggingCanvasLine

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    line: DraggingCanvasLine
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.selection = selection
    this.line = line
  }

  create = (): ActionHandler => {
    return new DragCanvasAction(
      this.commandHandler,
      this.document,
      this.selection,
      this.line
    )
  }
}
