import { DesignColor } from 'themes'
import { useEffect, useRef, useState } from 'react'

interface ToolbarUnitInputProps {
  value: number
  setValue: (value: number) => void
  unit: string
}

export default function ToolbarUnitInput({
  value,
  setValue,
  unit,
}: ToolbarUnitInputProps) {
  const [editing, setEditing] = useState(false)
  const [hover, setHover] = useState(false)

  const [localValue, setLocalValue] = useState<string>(value.toString())
  const [select, setSelect] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const textColor = getTextColor(editing, hover)
  const background = getBackground(editing, hover)
  const boxShadow = getBoxShadow(editing)

  const handleBlur = () => {
    const parsed = parseValue(value, localValue)
    setValue(parsed)
    setEditing(false)
  }

  const handleFocus = () => {
    setLocalValue(value.toString())
    setEditing(true)
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (!select && inputRef.current) {
      e.preventDefault()
      inputRef.current.select()
      setSelect(true)
      setEditing(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') inputRef.current?.blur()
  }

  useEffect(() => {
    if (editing) return
    setLocalValue(value.toString())
    setSelect(false)
  }, [editing, value])

  return (
    <input
      ref={inputRef}
      type="text"
      value={editing ? localValue : value + unit}
      onChange={(e) => setLocalValue(e.target.value)}
      onClick={(e) => e.stopPropagation()}
      onMouseUp={handleMouseUp}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: 68,
        height: 48,
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
        textAlign: 'center',
        border: 'none',
        outline: 'none',
        fontFamily: 'Inter',
        fontWeight: 'regular',
        fontSize: 11,
        color: textColor,
        cursor: 'default',
        background,
        boxShadow,
      }}
    />
  )
}

function getTextColor(editing: boolean, hover: boolean) {
  if (editing) return DesignColor('text1')
  if (hover) return DesignColor('inputHighlight')
  return DesignColor('text1')
}

function getBackground(editing: boolean, hover: boolean) {
  if (editing || hover) return DesignColor('inputTint')
  return 'none'
}

function getBoxShadow(editing: boolean) {
  return editing
    ? `inset 0px -1px 0px 0px ${DesignColor('inputHighlight')}`
    : 'none'
}

function parseValue(value: number, localValue: string): number {
  const parsed = parseFloat(localValue)
  if (isNaN(parsed)) return value
  return parsed
}
