import {
  AttributeCursorType,
  AttributeType,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type CursorPanelKeys = 'cursor.mode' | 'cursor.style'

type CursorPanelAttributes = Pick<MultiselectStyleMap, CursorPanelKeys> | null

export interface CursorPanelState {
  attributes: CursorPanelAttributes
  mode: 'mixed' | 'add' | 'remove' | 'disabled'
}

export interface CursorPanelHandlers {
  addCursor: () => void
  removeCursor: () => void
  setStyle: (style: AttributeCursorType) => void
  clearMixed: () => void
}

export class CursorPanel extends StyleAttributePanel<
  CursorPanelState,
  CursorPanelHandlers,
  CursorPanelKeys
> {
  getSettings(): CursorPanelState {
    return {
      attributes: this.attributes,
      mode: this.getMode(),
    }
  }

  getHandlers(): CursorPanelHandlers {
    return {
      addCursor: this.addCursor,
      removeCursor: this.removeTransition,
      setStyle: this.setStyle,
      clearMixed: this.clearMixed,
    }
  }

  private getMode = (): 'mixed' | 'add' | 'remove' | 'disabled' => {
    const nodes = this.getNodes()

    let hadCursor = false
    let hasNoCursor = false
    for (const node of nodes) {
      const mode = node.getStyleAttribute('cursor.mode')
      if (mode !== undefined && mode !== 'none') hadCursor = true
      else hasNoCursor = true
    }

    if (hadCursor && hasNoCursor) return 'mixed'
    if (hadCursor) return 'remove'
    if (hasNoCursor) return 'add'
    return 'disabled'
  }

  private addCursor = (): void => {
    this.setMulti({
      'cursor.mode': 'display',
      'cursor.style': 'auto',
    })
    this.commit()
  }

  private removeTransition = (): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.setOne(node.getId(), {
        'cursor.mode': 'none',
        'cursor.style': undefined,
      })
    }
    this.commit()
  }

  private setStyle = (style: AttributeCursorType): void => {
    this.setMulti({
      'cursor.mode': 'display',
      'cursor.style': style,
    })
  }

  private clearMixed = (): void => {
    const nodes = this.getNodes()
    let cursor: AttributeCursorType | undefined = undefined
    for (const node of nodes) {
      const style = node.getStyleAttribute('cursor.style')
      if (style === undefined) continue
      cursor = style
      break
    }
    if (cursor === undefined) return

    this.setMulti({
      'cursor.mode': 'display',
      'cursor.style': cursor,
    })
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 20
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'rectangle',
  'ellipse',
  'text',
  'image',
]
