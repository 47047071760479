import {
  AttributePositionMode,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { NodeReparentAction } from 'application/action'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyDocument } from 'application/document'
import { NodeAttributesAction } from 'application/action/attributes'
import { CommandHandler } from 'application/client'
import { warningKey } from 'assets/warnings'
import { ReadOnlyNode } from 'application/node'

type PositionModePanelKeys = 'position.mode'

type PositionModePanelAttributes = Pick<
  MultiselectStyleMap,
  PositionModePanelKeys
> | null

export interface PositionModePanelState {
  attributes: PositionModePanelAttributes
  disabled: boolean
  warning?: warningKey
}

export interface PositionModePanelHandlers {
  setAuto: (value: AttributePositionMode) => void
}

export class PositionModePanel extends StyleAttributePanel<
  PositionModePanelState,
  PositionModePanelHandlers,
  PositionModePanelKeys
> {
  private reparentAction: NodeReparentAction

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    reparentAction: NodeReparentAction
  ) {
    super(commandHandler, action, document, documentSelection)
    this.reparentAction = reparentAction
  }

  getSettings(): PositionModePanelState {
    return {
      attributes: this.attributes,
      disabled: this.getDisabled(),
      warning: this.getWarning(),
    }
  }

  getHandlers(): PositionModePanelHandlers {
    return {
      setAuto: this.setAuto,
    }
  }

  private getDisabled = (): boolean => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return true

    for (let i = 0; i < pairs.length; i++) {
      const parent = pairs[i][1]
      if (!parent) continue
      if (parent.getBaseAttribute('type') !== 'canvas') return false
    }

    return true
  }

  private setAuto = (value: AttributePositionMode): void => {
    if (this.attributes && value === this.attributes['position.mode']) return

    const nodes = this.getNodes()
    switch (value) {
      case 'auto':
      case 'absolute':
      case 'sticky':
        this.setMulti({ 'position.mode': value })
        break
      case 'fixed':
        for (const node of nodes) {
          this.setOne(node.getId(), { 'position.mode': 'fixed' })
          this.reparentToPageOrCanvas(node.getId())
        }
        break
    }
  }

  private reparentToPageOrCanvas = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const parent = this.document.getParent(node)
    if (!parent) return
    if (['page', 'canvas'].includes(parent.getBaseAttribute('type'))) return

    const ancestors = this.document.getAncestors(node)
    if (ancestors.length === 0) return

    const pageOrCanvas = ancestors.find(
      (ancestor) =>
        ancestor.getBaseAttribute('type') === 'page' ||
        ancestor.getBaseAttribute('type') === 'canvas'
    )
    if (!pageOrCanvas) return

    this.reparentAction.reparentToNode([id], pageOrCanvas.getId(), 0)
  }

  private getWarning = (): warningKey | undefined => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    for (const node of nodes) {
      if (node.getStyleAttribute('position.mode') !== 'sticky') continue
      if (this.hasClipAncestor(node)) return 'Sticky'
    }
  }

  private hasClipAncestor = (node: ReadOnlyNode): boolean => {
    const ancestors = this.document.getAncestors(node)
    for (const ancestor of ancestors) {
      if (ancestor.getBaseAttribute('type') === 'canvas') return false
      if (ancestor.getStyleAttribute('clip')) return true
    }
    return false
  }
}
