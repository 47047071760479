import Text from 'components/Library/Text/Text'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'

interface TemplateTabProps {
  display: string

  select: () => void
  selected: boolean
}

export default function TemplateTab({
  display,
  select,
  selected,
}: TemplateTabProps) {
  const tabRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: tabRef })

  const boxShadow = getBoxShadow(hovered || false)

  return (
    <div
      ref={tabRef}
      style={{
        width: '100%',
        height: 'fit-content',
        padding: '8px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        cursor: 'pointer',
        background: selected ? DesignColor('inputTintNeutral') : 'transparent',
        boxShadow: boxShadow,
      }}
      onClick={select}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Text
        styles={{ cursor: 'pointer', size: 12 }}
        color={selected ? DesignColor('text1') : DesignColor('text2')}
      >
        {display}
      </Text>
    </div>
  )
}

function getBoxShadow(hover: boolean) {
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}
