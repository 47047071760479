import TextBlock from '../../Text/TextBlock/TextBlock'
import { AttributeAnimationTriggerType } from 'application/attributes'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import IconBlock from '../../IconBlock/IconBlock/IconBlock'
import { DesignColor } from 'themes'
import useToast from 'hooks/editor/useToast'

interface AnimationRowProps {
  type: AttributeAnimationTriggerType
  onOpen: () => void
  onDelete: () => void
  onCopy: () => void
}

export default function AnimationRow({
  type,
  onOpen,
  onDelete,
  onCopy,
}: AnimationRowProps) {
  const toast = useToast()

  const panelRef = useRef<HTMLDivElement>(null)
  const deleteButtonRef = useRef<HTMLDivElement>(null)
  const copyButtonRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered({ ref: panelRef })

  const isHovered = hovered || false

  return (
    <div
      ref={panelRef}
      style={{
        width: '100%',
        height: 32,
        padding: '0px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        boxShadow: hovered
          ? `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
          : 'none',
      }}
      onClick={(e) => {
        if (deleteButtonRef.current?.contains(e.target as Node)) return
        if (copyButtonRef.current?.contains(e.target as Node)) return
        onOpen()
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <TextBlock width={168} icon={'Lightning'} mode={'label1'}>
        {getDisplayText(type)}
      </TextBlock>
      {isHovered && (
        <IconBlock
          buttonRef={copyButtonRef}
          onClick={() => {
            onCopy()
            toast(`Copied animation`, 'success')
          }}
          icon={'Copy'}
        />
      )}
      {isHovered && (
        <IconBlock buttonRef={deleteButtonRef} onClick={onDelete} icon={'X'} />
      )}
    </div>
  )
}

function getDisplayText(type: AttributeAnimationTriggerType) {
  switch (type) {
    case 'click':
      return 'Click'
    case 'hover-in':
      return 'Hover'
    case 'hover-out':
      return 'Hover Out'
    case 'scroll-in':
      return 'Scroll Into View'
    case 'scroll-out':
      return 'Scroll Out Of View'
    case 'load':
      return 'Page Load'
  }
}
