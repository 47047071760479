import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import TitleIconBlock from 'components/Library/Components/IconBlock/TitleIconBlock/TitleIconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function FilterBlurSettings() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getFilterBlurPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings) return <></>

  const { radius, mode } = settings
  const { setRadius, slideRadius, addFilter, removeFilter } = handlers
  const { hasOverride, sectionHasOverride } = overrides.handlers
  if (mode === 'disabled') return <></>

  const handleAdd = () => {
    if (mode === 'add') addFilter()
    else if (mode === 'remove') removeFilter()
  }

  return (
    <PanelSection>
      <PanelRow>
        <OverrideReset section={'filters'}>
          <TitleButtonBlock
            title="Background Blur"
            active={mode === 'remove'}
            hasOverride={sectionHasOverride('filters')}
            onClick={handleAdd}
          />
        </OverrideReset>
        <TitleIconBlock
          icon={'Plus'}
          active={mode === 'remove'}
          canDeactivate={mode === 'remove'}
          onClick={handleAdd}
        />
      </PanelRow>
      {mode === 'remove' && (
        <PanelRow>
          <OverrideReset keys={['filter.blur.radius', 'filter.mode']}>
            <NumberBlock
              icon="Blur"
              tooltipKey="BackgroundBlurRadius"
              value={radius}
              setValue={setRadius}
              increment={(v) => slideRadius(v)}
              decrement={(v) => slideRadius(-v)}
              min={0}
              max={100}
              hasOverride={hasOverride(['filter.blur.radius', 'filter.mode'])}
            />
          </OverrideReset>
        </PanelRow>
      )}
    </PanelSection>
  )
}
