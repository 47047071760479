import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import Text from 'components/Library/Text/Text'
import ButtonIcon from 'components/Library/Components/Icon/ButtonIcon/ButtonIcon'

interface DropdownRowProps {
  width: number

  icon?: IconKey
  title: string

  selected: boolean
  hover: boolean

  onMouseEnter: () => void
  onMouseLeave: () => void
  onClick: () => void
}

export default function DropdownRow({
  width,
  icon,
  title,
  selected,
  hover,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: DropdownRowProps) {
  return (
    <div
      style={{
        width: width,
        height: 24,
        padding: '0px 8px 0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {icon && <ButtonIcon icon={icon} color={DesignColor('text2')} />}
      <Text color={getTextColor(hover, selected)}>{title}</Text>
    </div>
  )
}

function getTextColor(hovered: boolean, select: boolean) {
  if (hovered) return DesignColor('inputHighlight')
  return DesignColor('text1')
}
