import { WebsiteSettingsPage } from 'application/service'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import LargeTextInputBlock from 'components/Library/Components/Text/LargeTextInputBlock/LargeTextInputBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'

interface PageSettingsProps {
  pageId: string
  name: string

  settings: WebsiteSettingsPage
  setPageSettings: (pageId: string, settings: WebsiteSettingsPage) => void

  close: () => void
}

export default function PageSettings({
  pageId,
  name,
  settings,
  setPageSettings,
  close,
}: PageSettingsProps) {
  return (
    <>
      <PanelSection width={'fill'}>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="title">
            {`Page Settings - ${name}`}
          </TextBlock>
          <IconBlock icon="X" onClick={close} />
        </PanelRow>
        <PanelRow width={'fill'}>
          <TextBlock width={'fill'} mode={'label1'}>
            Tab Title
          </TextBlock>
        </PanelRow>
        <LargeTextInputBlock
          height={'hug'}
          value={settings.title}
          setValue={(value) =>
            setPageSettings(pageId, { ...settings, title: value })
          }
          placeholder={name}
        />
        <PanelRow width={'fill'}>
          <TextBlock width={'fill'} mode={'label1'}>
            URL
          </TextBlock>
        </PanelRow>
        <LargeTextInputBlock
          height={'hug'}
          value={settings.slug}
          prefix={'/'}
          setValue={(value) =>
            setPageSettings(pageId, { ...settings, slug: value })
          }
          placeholder={'/' + dashify(name)}
        />
        <PanelRow width={'fill'}>
          <TextBlock width={'fill'} mode={'label1'}>
            Page Description
          </TextBlock>
        </PanelRow>
        <LargeTextInputBlock
          height={96}
          wrap={true}
          value={settings.description}
          setValue={(value) =>
            setPageSettings(pageId, { ...settings, description: value })
          }
          placeholder={
            'Page descriptions are shown on search engines, social media previews, and used for SEO. Recommended length is 50-160 characters.'
          }
        />
      </PanelSection>
    </>
  )
}

function dashify(str: string) {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
}
