import { useState, useRef } from 'react'
import { DesignColor } from 'themes'
import { panelShadow } from 'components/Library/Containers/constants'
import useClosePopup from 'hooks/ui/useClosePopup'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'

interface MenuDrawerPopupContainerProps {
  open: boolean
  children: React.ReactNode
  sourceRef: React.RefObject<HTMLDivElement>

  setOpen: (open: boolean) => void
  direction?: 'left' | 'right'
}

export function MenuDrawerPopupContainer({
  open,
  children,
  sourceRef,
  setOpen,
  direction = 'left',
}: MenuDrawerPopupContainerProps) {
  const popupRef = useRef<HTMLDivElement>(null)

  useClosePopup({
    ref: popupRef,
    close: () => setOpen(false),
    exceptionRef: sourceRef,
  })

  const rect = sourceRef.current?.getBoundingClientRect()
  if (!open || !rect) return <></>
  const left = direction === 'right' ? rect.x + rect.width : rect.x
  const transform = direction === 'right' ? 'translateX(-100%)' : 'none'

  return (
    <Portal>
      <div
        ref={popupRef}
        className="popup"
        style={{
          position: 'absolute',
          top: `${rect.top}px`,
          left: `${left}px`,
          transform: transform,
          width: `${rect.width}px`,
          minWidth: '120px',
          backgroundColor: DesignColor('panelBackground'),
          border: `1px solid ${DesignColor('panelBorder')}`,
          boxShadow: panelShadow,
          zIndex: 1,
          padding: '4px 0px',
        }}
      >
        {children}
      </div>
    </Portal>
  )
}

interface MenuDrawerPopupButtonProps {
  label: string
  onClick: () => void
}

export function MenuDrawerPopupButton({
  label,
  onClick,
}: MenuDrawerPopupButtonProps) {
  const [hover, setHover] = useState(false)

  return (
    <button
      style={{
        width: '100%',
        height: '24px',
        padding: '4px 10px',
        background: hover ? DesignColor('grayInputTint') : 'none',
        border: 'none',
        color: DesignColor('text1'),
        fontSize: 12,
        fontFamily: 'inter',
        textAlign: 'left',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {label}
    </button>
  )
}
