import { MultiselectStyleMap, isAutolayoutChild } from 'application/attributes'
import { truncate } from 'application/math'
import { ReadOnlyNode } from 'application/node'
import { AttributePanel } from './attributePanel'

type PositionPanelKeys =
  | 'position.top'
  | 'position.left'
  | 'position.right'
  | 'position.bottom'
  | 'position.top.auto'
  | 'position.left.auto'
  | 'position.right.auto'
  | 'position.bottom.auto'
  | 'position.top.percent'
  | 'position.left.percent'
  | 'position.right.percent'
  | 'position.bottom.percent'
  | 'position.mode'

export type PositionPanelAttributes = Pick<
  MultiselectStyleMap,
  PositionPanelKeys
> | null

export type PositionPanelSide = 'top' | 'left' | 'bottom' | 'right'

export interface PositionPanelState {
  x: 'Mixed' | number
  y: 'Mixed' | number
  attributes: PositionPanelAttributes
  disabled: boolean
  displayMode: 'Mixed' | 'canvas' | 'css'
}

export interface PositionPanelHandlers {
  setPosition: (value: number, side: PositionPanelSide) => void
  slidePosition: (value: number, side: PositionPanelSide) => void
  setPositionUnit: (unit: 'percent' | 'fixed', side: PositionPanelSide) => void
  removePosition: (side: PositionPanelSide) => void
  collapsePosition: () => void
}

export class PositionPanel extends AttributePanel<
  PositionPanelState,
  PositionPanelHandlers,
  PositionPanelKeys,
  never
> {
  getSettings(): PositionPanelState {
    return {
      x: this.getBaseValue('x'),
      y: this.getBaseValue('y'),
      attributes: this.styleAttributes,
      disabled: this.getPositionDisabled(),
      displayMode: this.getPositionDisplayMode(),
    }
  }

  getHandlers(): PositionPanelHandlers {
    return {
      removePosition: this.removePosition,
      setPositionUnit: this.setPositionUnit,
      setPosition: this.setPosition,
      slidePosition: this.slidePosition,
      collapsePosition: this.collapsePosition,
    }
  }

  private getBaseValue = (mode: 'x' | 'y'): 'Mixed' | number => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return 'Mixed'

    const values = pairs.map(([node, parent]) => {
      if (!parent) return node.getBaseAttribute(mode)
      return this.computePositionPixels(
        node,
        parent,
        mode === 'x' ? 'left' : 'top'
      )
    })
    const first = values[0]
    if (values.every((v) => v === first)) return first
    return 'Mixed'
  }

  private getPositionDisabled = (): boolean => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return true

    for (let i = 0; i < pairs.length; i++) {
      const child = pairs[i][0]
      const parent = pairs[i][1]
      if (!parent) continue
      if (
        isAutolayoutChild(child, parent) &&
        child.getStyleAttribute('position.mode') !== 'sticky'
      ) {
        return true
      }
    }

    return false
  }

  private getPositionDisplayMode = (): 'Mixed' | 'canvas' | 'css' => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return 'Mixed'

    const modes = pairs.map(([node, parent]) =>
      this.getNodeDisplayMode(node, parent)
    )
    const first = modes[0]
    if (modes.every((m) => m === first)) return first
    return 'Mixed'
  }

  private setPosition = (value: number, side: PositionPanelSide): void => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return

    for (const [node, parent] of pairs) {
      const displayMode = this.getNodeDisplayMode(node, parent)
      switch (displayMode) {
        case 'canvas':
          switch (side) {
            case 'top':
              this.setOne(node.getId(), { y: value }, {})
              break
            case 'left':
              this.setOne(node.getId(), { x: value }, {})
              break
          }
          break
        case 'css':
          const mode = node.getStyleAttribute(`position.${side}.auto`)
          switch (mode) {
            case 'percent':
              this.setOne(
                node.getId(),
                {},
                { [`position.${side}.percent`]: value }
              )
              break
            case 'fixed':
              this.setOne(node.getId(), {}, { [`position.${side}`]: value })
              break
            case 'none':
            case undefined:
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: value,
                  [`position.${side}.auto`]: 'fixed',
                }
              )
          }
          break
      }
    }
  }

  private setPositionUnit = (
    unit: 'percent' | 'fixed',
    side: PositionPanelSide
  ): void => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return

    for (const p of pairs) {
      const node = p[0]
      const parent = p[1]
      if (!parent) continue

      const displayMode = this.getNodeDisplayMode(node, parent)
      switch (displayMode) {
        case 'css':
          const opposite = this.isOppositeActive(side, node)
          const axis = side === 'top' || side === 'bottom' ? 'h' : 'w'
          if (opposite) {
            this.setOne(
              node.getId(),
              {},
              {
                [`size.${axis}.auto`]: 'fixed',
                [`size.${axis}`]: node.getBaseAttribute(axis),
              }
            )
          }

          switch (unit) {
            case 'fixed':
              const value = this.computePositionPixels(node, parent, side)
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: value,
                  [`position.${side}.percent`]: undefined,
                  [`position.${side}.auto`]: 'fixed',
                }
              )
              break
            case 'percent':
              const percent = this.computePositionPercent(node, parent, side)
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: undefined,
                  [`position.${side}.percent`]: percent,
                  [`position.${side}.auto`]: 'percent',
                }
              )
          }
      }
    }

    this.commit()
  }

  private slidePosition = (value: number, side: PositionPanelSide): void => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return

    for (const [node, parent] of pairs) {
      const displayMode = this.getNodeDisplayMode(node, parent)
      switch (displayMode) {
        case 'canvas':
          switch (side) {
            case 'top':
              this.setOne(
                node.getId(),
                { y: node.getBaseAttribute('y') + value },
                {}
              )
              break
            case 'left':
              this.setOne(
                node.getId(),
                { x: node.getBaseAttribute('x') + value },
                {}
              )
              break
          }
          break
        case 'css':
          const mode = node.getStyleAttribute(`position.${side}.auto`)
          switch (mode) {
            case 'percent':
              const percent =
                node.getStyleAttribute(`position.${side}.percent`) || 0
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}.percent`]: percent + value,
                }
              )
              break
            case 'fixed':
              const fixed = node.getStyleAttribute(`position.${side}`) || 0
              this.setOne(
                node.getId(),
                {},
                {
                  [`position.${side}`]: fixed + value,
                }
              )
              break
          }
      }
    }
  }

  private removePosition = (side: PositionPanelSide): void => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return

    for (const [node, parent] of pairs) {
      const displayMode = this.getNodeDisplayMode(node, parent)
      switch (displayMode) {
        case 'css':
          switch (side) {
            case 'top':
              const hasBottom =
                node.getStyleAttribute('position.bottom.auto') !== 'none'
              if (!hasBottom) break
              this.setOne(
                node.getId(),
                {},
                {
                  'position.top': undefined,
                  'position.top.percent': undefined,
                  'position.top.auto': 'none',
                }
              )
              break
            case 'left':
              const hasRight =
                node.getStyleAttribute('position.right.auto') !== 'none'
              if (!hasRight) break
              this.setOne(
                node.getId(),
                {},
                {
                  'position.left': undefined,
                  'position.left.percent': undefined,
                  'position.left.auto': 'none',
                }
              )
              break
            case 'right':
              const hasLeft =
                node.getStyleAttribute('position.left.auto') !== 'none'
              if (!hasLeft) break
              this.setOne(
                node.getId(),
                {},
                {
                  'position.right': undefined,
                  'position.right.percent': undefined,
                  'position.right.auto': 'none',
                }
              )
              break
            case 'bottom':
              const hasTop =
                node.getStyleAttribute('position.top.auto') !== 'none'
              if (!hasTop) break
              this.setOne(
                node.getId(),
                {},
                {
                  'position.bottom': undefined,
                  'position.bottom.percent': undefined,
                  'position.bottom.auto': 'none',
                }
              )
              break
          }
      }
    }

    this.commit()
  }

  private collapsePosition = (): void => {
    for (const pairs of this.getNodesAndParents()) {
      const node = pairs[0]
      const parent = pairs[1]
      if (!parent) continue

      const displayMode = this.getNodeDisplayMode(node, parent)
      switch (displayMode) {
        case 'css':
          let topMode = node.getStyleAttribute('position.top.auto')
          let leftMode = node.getStyleAttribute('position.left.auto')
          let top = node.getStyleAttribute('position.top')
          let left = node.getStyleAttribute('position.left')
          let topPercent = node.getStyleAttribute('position.top.percent')
          let leftPercent = node.getStyleAttribute('position.left.percent')

          if (topMode === 'percent') {
            topPercent = this.computePositionPercent(node, parent, 'top')
          } else {
            topMode = 'fixed'
            top = this.computePositionPixels(node, parent, 'top')
          }

          if (leftMode === 'percent') {
            leftPercent = this.computePositionPercent(node, parent, 'left')
          } else {
            leftMode = 'fixed'
            left = this.computePositionPixels(node, parent, 'left')
          }

          this.setOne(
            node.getId(),
            {},
            {
              'position.top': top,
              'position.left': left,
              'position.top.percent': topPercent,
              'position.left.percent': leftPercent,
              'position.top.auto': topMode,
              'position.left.auto': leftMode,
              'position.bottom': undefined,
              'position.right': undefined,
              'position.bottom.percent': undefined,
              'position.right.percent': undefined,
              'position.bottom.auto': 'none',
              'position.right.auto': 'none',
            }
          )
          break
      }
    }

    this.commit()
  }

  private computePositionPixels = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    mode: 'top' | 'left' | 'bottom' | 'right'
  ): number => {
    switch (mode) {
      case 'top':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('y')
        return truncate(
          node.getBaseAttribute('y') - parent.getBaseAttribute('y')
        )
      case 'left':
        if (parent.getBaseAttribute('type') === 'canvas')
          return node.getBaseAttribute('x')
        return truncate(
          node.getBaseAttribute('x') - parent.getBaseAttribute('x')
        )
      case 'bottom':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        return truncate(
          parent.getBaseAttribute('y') +
            parent.getBaseAttribute('h') -
            node.getBaseAttribute('y') -
            node.getBaseAttribute('h')
        )
      case 'right':
        if (parent.getBaseAttribute('type') === 'canvas') return 0
        return truncate(
          parent.getBaseAttribute('x') +
            parent.getBaseAttribute('w') -
            node.getBaseAttribute('x') -
            node.getBaseAttribute('w')
        )
    }
  }

  private computePositionPercent = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode,
    mode: 'top' | 'left' | 'bottom' | 'right'
  ): number => {
    if (node.getBaseAttribute('type') === 'canvas') return 0
    const parentSize = ['top', 'bottom'].includes(mode)
      ? parent.getBaseAttribute('h')
      : parent.getBaseAttribute('w')
    return truncate(
      (this.computePositionPixels(node, parent, mode) / parentSize) * 100,
      1
    )
  }

  private isOppositeActive = (
    side: PositionPanelSide,
    node: ReadOnlyNode
  ): boolean => {
    switch (side) {
      case 'top':
        return node.getStyleAttribute('position.bottom.auto') !== 'none'
      case 'left':
        return node.getStyleAttribute('position.right.auto') !== 'none'
      case 'bottom':
        return node.getStyleAttribute('position.top.auto') !== 'none'
      case 'right':
        return node.getStyleAttribute('position.left.auto') !== 'none'
    }
  }

  private getNodeDisplayMode = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ): 'canvas' | 'css' => {
    if (!parent) return 'canvas'
    if (parent.getBaseAttribute('type') === 'canvas') return 'canvas'
    if (
      isAutolayoutChild(node, parent) &&
      node.getStyleAttribute('position.mode') !== 'sticky'
    ) {
      return 'canvas'
    }
    return 'css'
  }
}
