import { WriteDocument } from 'application/document'
import { PasteSnapshot } from '../../types'
import { Point, Rectangle, intersects } from 'application/shapes'
import { Node } from 'application/node'
import { PastePositionHandler } from '../paste'
import { moveTree, setAttributes } from './utils'

export class PasteAtAvailableCanvasPosition implements PastePositionHandler {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  paste = (snapshot: PasteSnapshot): void => {
    for (const id of snapshot.ids) {
      const node = snapshot.nodes[id]
      if (!node) continue

      const parent = this.document.getParent(node)
      if (!parent || parent.getBaseAttribute('type') !== 'canvas') continue

      const siblings = parent.getChildren()
      if (!siblings) continue

      const childRect = this.getRectangle(node)

      const point = this.moveToAvailableSpace(childRect, siblings)
      const xDelta = node.getBaseAttribute('x') - point.x
      const yDelta = node.getBaseAttribute('y') - point.y

      setAttributes(
        node,
        {
          'position.top.auto': 'fixed',
          'position.left.auto': 'fixed',
          'position.top': point.y,
          'position.left': point.x,
        },
        {
          x: point.x,
          y: point.y,
        }
      )

      moveTree(node, snapshot, xDelta, yDelta)
    }
  }

  private getRectangle = (node: Node): Rectangle => {
    return {
      x: node.getBaseAttribute('x'),
      y: node.getBaseAttribute('y'),
      w: node.getBaseAttribute('w'),
      h: node.getBaseAttribute('h'),
    }
  }

  private moveToAvailableSpace = (
    child: Rectangle,
    siblingIds: string[]
  ): Point => {
    const siblings = siblingIds
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as Node[]

    const siblingRects = siblings
      .map((sibling) => this.getRectangle(sibling))
      .sort((a, b) => a.x - b.x)

    const movedChild = { ...child }
    for (const rect of siblingRects) {
      if (intersects(movedChild, rect)) movedChild.x = rect.x + rect.w + 20
    }

    return movedChild
  }
}
