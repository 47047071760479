import { Color } from 'application/color'
import { ReadOnlyNode } from 'application/node'
import {
  Context,
  Renderable,
  WebglEllipseBorder,
  WebglEllipseImage,
  WebglEllipseImageData,
  WebglEllipseInnerShadow,
  WebglEllipseOuterShadow,
  WebglRectangle,
  WebglRectangleBorder,
  WebglRectangleData,
  WebglRectangleImage,
  WebglRectangleImageData,
  WebglRectangleInnerShadow,
  WebglRectangleOuterShadow,
  WebglText,
} from 'application/render'
import {
  WebglEllipse,
  WebglEllipseData,
} from 'application/render/renderables/webglEllipse'
import { WebglRectangleBlur } from 'application/render/renderables/webglRectangleBlur'
import { Rectangle } from 'application/shapes'
import { FontLoaderInterface, TextContent } from 'application/text'

export function createRenderBounds(node: ReadOnlyNode): Rectangle | undefined {
  const x = node.getBaseAttribute('renderBox.x')
  const y = node.getBaseAttribute('renderBox.y')
  const w = node.getBaseAttribute('renderBox.w')
  const h = node.getBaseAttribute('renderBox.h')
  if (
    x === undefined ||
    y === undefined ||
    w === undefined ||
    h === undefined
  ) {
    return undefined
  }
  return { x, y, w, h }
}

export function createRectangleFill(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined
): Renderable[] {
  const rectangles: Renderable[] = []

  node.getStyleAttribute('fills')?.forEach((fill) => {
    switch (fill.type) {
      case 'color':
      case 'gradient':
        const rectangleData: WebglRectangleData = {
          ...getRectangle(node),
          fill: fill,
          rounding: getRounding(node),
          bb: getBoundingBox(node),
          bbRounding: getRounding(parent),
        }
        const rectangleRenderable = new WebglRectangle(context, rectangleData)
        rectangleRenderable.init()
        rectangles.push(rectangleRenderable)
        break
      case 'image':
        if (fill.image === undefined) break
        const imageData: WebglRectangleImageData = {
          ...getRectangle(node),
          src: fill.image.src,
          resize: fill.image.resize,
          originalSize: {
            w: fill.image['originalSize.w'],
            h: fill.image['originalSize.h'],
          },
          rounding: getRounding(node),
          bb: getBoundingBox(node),
          bbRounding: getRounding(parent),
        }
        const imageRenderable = new WebglRectangleImage(context, imageData)
        imageRenderable.init()
        rectangles.push(imageRenderable)
        break
    }
  })

  return rectangles
}

export function createRectangleImage(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined
): Renderable[] {
  const src = node.getStyleAttribute('image.src')
  if (src === undefined) return []

  const data: WebglRectangleImageData = {
    ...getRectangle(node),
    src: src,
    resize: node.getStyleAttribute('image.resize') || 'fill',
    originalSize: {
      w: node.getStyleAttribute('image.originalSize.w') || 0,
      h: node.getStyleAttribute('image.originalSize.h') || 0,
    },
    rounding: getRounding(node),
    bb: getBoundingBox(node),
    bbRounding: getRounding(parent),
  }
  const renderable = new WebglRectangleImage(context, data)
  renderable.init()

  return [renderable]
}

export function createRectangleBorder(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined
): Renderable[] {
  const color = getBorderColor(node)
  if (color === undefined) return []

  const mode = node.getStyleAttribute('border.mode')
  if (mode === 'none') return []

  const data = {
    ...getRectangle(node),
    color: color,
    widths: getBorderWidths(node),
    rounding: getRounding(node),
    bb: getBoundingBox(node),
    bbRounding: getRounding(parent),
  }
  const renderable = new WebglRectangleBorder(context, data)
  renderable.init()

  return [renderable]
}

export function createRectangleShadows(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined,
  mode: 'inner' | 'outer'
): Renderable[] {
  const shadows: Renderable[] = []

  node.getStyleAttribute('shadows')?.forEach((shadow) => {
    if (shadow.mode !== mode) return
    const sourceRect = getRectangle(node)
    const data = {
      x: sourceRect.x + shadow.x,
      y: sourceRect.y + shadow.y,
      w: sourceRect.w,
      h: sourceRect.h,
      color: shadow.color,
      blur: shadow.blur,
      spread: shadow.spread,
      sourceRect: sourceRect,
      rounding: getRounding(node),
      bb: getBoundingBox(node),
      bbRounding: getRounding(parent),
    }
    const renderable =
      mode === 'outer'
        ? new WebglRectangleOuterShadow(context, data)
        : new WebglRectangleInnerShadow(context, data)
    renderable.init()
    shadows.push(renderable)
  })

  return shadows
}

export function createRectangleBlur(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined
): Renderable[] {
  const mode = node.getStyleAttribute('filter.mode')
  if (mode === 'none') return []

  const radius = node.getStyleAttribute('filter.blur.radius')
  if (radius === undefined) return []

  const data = {
    ...getRectangle(node),
    blur: radius,
    rounding: getRounding(node),
    bb: getBoundingBox(node),
    bbRounding: getRounding(parent),
  }
  const renderable = new WebglRectangleBlur(context, data)
  renderable.init()

  return [renderable]
}

export function createEllipseFill(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined
): Renderable[] {
  const ellipses: Renderable[] = []

  node.getStyleAttribute('fills')?.forEach((fill) => {
    switch (fill.type) {
      case 'color':
      case 'gradient':
        const ellipseData: WebglEllipseData = {
          ...getRectangle(node),
          fill: fill,
          bb: getBoundingBox(node),
          bbRounding: getRounding(parent),
        }
        const ellipseRenderable = new WebglEllipse(context, ellipseData)
        ellipseRenderable.init()
        ellipses.push(ellipseRenderable)
        break
      case 'image':
        if (fill.image === undefined) break
        const imageData: WebglEllipseImageData = {
          ...getRectangle(node),
          src: fill.image.src,
          resize: fill.image.resize,
          originalSize: {
            w: fill.image['originalSize.w'],
            h: fill.image['originalSize.h'],
          },
          bb: getBoundingBox(node),
          bbRounding: getRounding(parent),
        }
        const imageRenderable = new WebglEllipseImage(context, imageData)
        imageRenderable.init()
        ellipses.push(imageRenderable)
        break
    }
  })

  return ellipses
}

export function createEllipseImage(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined
): Renderable[] {
  const src = node.getStyleAttribute('image.src')
  if (src === undefined) return []

  const data: WebglRectangleImageData = {
    ...getRectangle(node),
    src: src,
    resize: node.getStyleAttribute('image.resize') || 'fill',
    originalSize: {
      w: node.getStyleAttribute('image.originalSize.w') || 0,
      h: node.getStyleAttribute('image.originalSize.h') || 0,
    },
    rounding: getRounding(node),
    bb: getBoundingBox(node),
    bbRounding: getRounding(parent),
  }
  const renderable = new WebglEllipseImage(context, data)
  renderable.init()

  return [renderable]
}

export function createEllipseBorder(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined
): Renderable[] {
  const color = getBorderColor(node)
  if (color === undefined) return []
  const data = {
    ...getRectangle(node),
    color: color,
    width: getBorderWidths(node).t,
    bb: getBoundingBox(node),
    bbRounding: getRounding(parent),
  }
  const renderable = new WebglEllipseBorder(context, data)
  renderable.init()

  return [renderable]
}

export function createEllipseShadows(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined,
  mode: 'inner' | 'outer'
): Renderable[] {
  const shadows: Renderable[] = []

  node.getStyleAttribute('shadows')?.forEach((shadow) => {
    if (shadow.mode !== mode) return
    const sourceRect = getRectangle(node)
    const data = {
      x: sourceRect.x + shadow.x,
      y: sourceRect.y + shadow.y,
      w: sourceRect.w,
      h: sourceRect.h,
      color: shadow.color,
      blur: shadow.blur,
      spread: shadow.spread,
      sourceRect: sourceRect,
      bb: getBoundingBox(node),
      bbRounding: getRounding(parent),
    }
    const renderable =
      mode === 'outer'
        ? new WebglEllipseOuterShadow(context, data)
        : new WebglEllipseInnerShadow(context, data)
    renderable.init()
    shadows.push(renderable)
  })

  return shadows
}

export function createTextFill(
  context: Context,
  node: ReadOnlyNode,
  parent: ReadOnlyNode | undefined,
  fontLoader: FontLoaderInterface
): Renderable {
  const data = {
    ...getRectangle(node),
    text: getTextContent(node),
    bb: getBoundingBox(node),
    bbRounding: getRounding(parent),
  }
  const renderable = new WebglText(context, data, fontLoader)
  renderable.init()

  return renderable
}

function getRectangle(node: ReadOnlyNode): Rectangle {
  return {
    x: node.getBaseAttribute('x'),
    y: node.getBaseAttribute('y'),
    w: node.getBaseAttribute('w'),
    h: node.getBaseAttribute('h'),
  }
}

function getRounding(node: ReadOnlyNode | undefined):
  | {
      tl: number
      tr: number
      br: number
      bl: number
    }
  | undefined {
  if (node === undefined) return undefined
  return {
    tl: node.getStyleAttribute('rounding.topLeft') || 0,
    tr: node.getStyleAttribute('rounding.topRight') || 0,
    br: node.getStyleAttribute('rounding.bottomRight') || 0,
    bl: node.getStyleAttribute('rounding.bottomLeft') || 0,
  }
}

function getBorderColor(node: ReadOnlyNode): Color | undefined {
  return node.getStyleAttribute('border.color')
}

function getBorderWidths(node: ReadOnlyNode): {
  t: number
  r: number
  b: number
  l: number
} {
  switch (node.getStyleAttribute('border.side') || 'all') {
    case 'top':
      return { t: node.getStyleAttribute('border.top') || 0, r: 0, b: 0, l: 0 }
    case 'right':
      return {
        t: 0,
        r: node.getStyleAttribute('border.right') || 0,
        b: 0,
        l: 0,
      }
    case 'bottom':
      return {
        t: 0,
        r: 0,
        b: node.getStyleAttribute('border.bottom') || 0,
        l: 0,
      }
    case 'left':
      return { t: 0, r: 0, b: 0, l: node.getStyleAttribute('border.left') || 0 }
    case 'all':
    case 'custom':
      return {
        t: node.getStyleAttribute('border.top') || 0,
        r: node.getStyleAttribute('border.right') || 0,
        b: node.getStyleAttribute('border.bottom') || 0,
        l: node.getStyleAttribute('border.left') || 0,
      }
  }
}

function getBoundingBox(node: ReadOnlyNode): Rectangle {
  return {
    x: node.getBaseAttribute('boundingBox.x'),
    y: node.getBaseAttribute('boundingBox.y'),
    w: node.getBaseAttribute('boundingBox.w'),
    h: node.getBaseAttribute('boundingBox.h'),
  }
}

function getTextContent(node: ReadOnlyNode): TextContent {
  const textContent: TextContent = {
    content: '',
    contentStyles: [],
    styles: {
      fill: { type: 'color', color: { r: 0, g: 0, b: 0, a: 0 } },
      fontFamily: 'inter',
      fontWeight: 'regular',
      fontSize: 0,
      align: 'left',
      letterSpacing: 0,
      lineHeight: 0,
      italic: false,
      underline: false,
      link: null,
    },
    styleOverrides: {},
    lineTypes: [],
  }

  const content = node.getBaseAttribute('text.content')
  const fontFamily = node.getStyleAttribute('text.font.family')
  const fontWeight = node.getStyleAttribute('text.font.weight')
  const fontSize = node.getStyleAttribute('text.font.size')
  const fill = node.getStyleAttribute('text.color')
  const align = node.getStyleAttribute('text.align')
  const letterSpacing = node.getStyleAttribute('text.letterSpacing')
  const lineHeight = node.getStyleAttribute('text.lineHeight')
  const italic = node.getStyleAttribute('text.italic')
  const underline = node.getStyleAttribute('text.underline')
  const link = null

  if (
    content === undefined ||
    fontFamily === undefined ||
    fontWeight === undefined ||
    fontSize === undefined ||
    fill === undefined ||
    align === undefined ||
    letterSpacing === undefined ||
    lineHeight === undefined ||
    italic === undefined ||
    underline === undefined ||
    link === undefined
  ) {
    return textContent
  }

  textContent.content = content
  textContent.contentStyles = Array(content.length).fill(0)
  textContent.styles = {
    fontFamily,
    fontWeight,
    fontSize,
    fill,
    align,
    letterSpacing,
    lineHeight,
    italic,
    underline,
    link,
  }

  return textContent
}
