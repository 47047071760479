import { AttributeType } from 'application/attributes'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'
import Icon from '../../Icon/Icon/Icon'
import Text from 'components/Library/Text/Text'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import useSelected from 'hooks/editor/useSelected'
import { IconKey } from 'assets/iconLibrary'

interface TimelineEffectProps {
  eventId: string

  self: boolean
  id: string | undefined

  selected: boolean
  select: () => void
}

export default function TimelineEvent({
  eventId,
  self,
  id,
  selected,
  select,
}: TimelineEffectProps) {
  const selectedNodes = useSelected()
  const selectedNode = selectedNodes[0]
  const { settings } = usePanelHelper(editor.getUI().getDetailsPanel())

  const name = getName(id, self, settings?.names || {})
  const type = getType(
    id,
    self,
    selectedNode?.getBaseAttribute('type'),
    settings?.types || {}
  )

  const ref = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered({ ref })

  return (
    <div
      ref={ref}
      data-event-id={eventId}
      className={'timelineEvent'}
      style={{
        width: 168,
        height: 24,
        padding: '4px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        borderRadius: 4,
        boxShadow:
          hovered || selected
            ? `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
            : 'none',
        backgroundColor: selected ? DesignColor('inputTint') : 'transparent',
      }}
      onClick={select}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Icon
        icon={getIconForType(type)}
        size={16}
        color={DesignColor(selected || hovered ? 'text1' : 'text2')}
      />
      <Text color={DesignColor(selected || hovered ? 'text1' : 'text2')}>
        {name}
      </Text>
    </div>
  )
}

function getIconForType(type: AttributeType | undefined): IconKey {
  switch (type) {
    case 'frame':
      return 'Frame'
    case 'text':
      return 'Text'
    case 'image':
      return 'Image'
    case 'page':
      return 'Page'
    case 'ellipse':
      return 'Ellipse'
    case 'rectangle':
      return 'Rectangle'
    default:
      return 'Frame'
  }
}

function getName(
  id: string | undefined,
  self: boolean,
  names: { [key: string]: string }
): string {
  if (self) return 'Self'
  if (!id) return 'Select Target'
  return names[id] || 'Select Target'
}

function getType(
  id: string | undefined,
  self: boolean,
  selectedType: AttributeType | undefined,
  types: { [key: string]: AttributeType }
): AttributeType | undefined {
  if (self) return selectedType
  if (!id) return undefined
  return types[id]
}
