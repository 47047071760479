import { truncate } from 'application/math'
import ToolbarUnitInput from 'components/Library/Components/Toolbar/ToolbarUnitInput/ToolbarUnitInput'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function PreviewZoomInput() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPreviewPanel()
  )
  if (!settings || settings.fullscreen) return <></>

  const { zoom } = settings
  const { setZoom } = handlers

  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        right: 124,
        zIndex: 2,
      }}
      width={'hug'}
      height={48}
      paddingH={10}
      direction="row"
    >
      <Tooltip tooltipKey="Zoom" direction="center">
        <ToolbarUnitInput
          value={truncate(zoom * 100, 1)}
          setValue={(value: number) => setZoom(value / 100)}
          unit="%"
        />
      </Tooltip>
    </PanelContainer>
  )
}
