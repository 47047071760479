import { LayoutDependencyMode } from '../types'
import { Node } from 'application/node'
import { SizeEngineCalculator } from './types'
import { State, attributesToTextEditorState } from 'application/textEditor'
import {
  TextAttributes,
  areTextAttributesEqual,
  getTextAttributes,
} from 'application/attributes'
import { ceil } from 'application/math'
import { Shaper } from 'application/text'
import { ReadOnlyDocument } from 'application/document'

export class TextSizeCalculator implements SizeEngineCalculator {
  private shaper: Shaper

  constructor(shaper: Shaper) {
    this.shaper = shaper
  }

  update(
    node: Node,
    _: LayoutDependencyMode,
    initialDocument: ReadOnlyDocument
  ): void {
    if (node.getBaseAttribute('type') !== 'text') return

    const textAttributes = getTextAttributes(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!textAttributes) return
    if (!this.hasUpdates(node.getId(), textAttributes, initialDocument)) return

    const state = attributesToTextEditorState(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!state) return

    const width = node.getStyleAttribute('size.w.auto') === 'hug'
    const height = node.getStyleAttribute('size.h.auto') === 'hug'

    if (width && height) {
      this.computeHugBoth(node, state)
    } else if (height) {
      this.computeHugHeight(node, state)
    }
  }

  private computeHugHeight(node: Node, state: State): void {
    const width = node.getBaseAttribute('w')
    const shapedText = this.shaper.getShapedText(state.content, width)
    if (!shapedText) return

    node.setBaseAttribute('h', ceil(shapedText.h, 0))
  }

  private computeHugBoth(node: Node, state: State): void {
    const shapedText = this.shaper.getShapedText(state.content)
    if (!shapedText) return

    node.setBaseAttribute('w', ceil(shapedText.w, 0))
    node.setBaseAttribute('h', ceil(shapedText.h, 0))
  }

  private hasUpdates(
    id: string,
    textAttributes: TextAttributes,
    initialDocument: ReadOnlyDocument
  ): boolean {
    const initialNode = initialDocument.getNode(id)
    if (!initialNode) return true

    const initialTextAttributes = getTextAttributes(
      initialNode.getBaseAttributes(),
      initialNode.getStyleAttributes()
    )

    if (!initialTextAttributes || !textAttributes) return true
    return !areTextAttributesEqual(initialTextAttributes, textAttributes)
  }
}
