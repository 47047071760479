import { DesignColor } from 'themes'
import { Camera } from 'application/camera'
import { Window } from '../types'
import {
  Context,
  Renderable,
  WebglEllipseBorder,
  WebglLine,
  WebglLineData,
  WebglRectangle,
} from 'application/render'
import { WebglRectangleBorder } from 'application/render/renderables/webglRectangleBorder'
import { rgbaStringToRgba } from 'application/color'
import { Rectangle } from 'application/shapes'

type bubblePosition = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

const bubblePositionList: bubblePosition[] = [
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
]

const bubbleSize = 8

export class WindowTransformer {
  static transform = (
    context: Context,
    windows: Window[],
    camera: Camera
  ): Renderable[] => {
    const renderables: Renderable[] = []
    for (const window of windows) {
      const windowFill = this.createWindowFill(context, window)
      if (windowFill) renderables.push(windowFill)

      if (window.dashed) {
        const dashedWindow = this.createDashedWindow(context, window, camera)
        renderables.push(dashedWindow)
      } else {
        const windowBorder = this.createWindowBorder(context, window, camera)
        renderables.push(windowBorder)
      }

      if (window.shape === 'circle') {
        const ellipseBorder = this.createEllipseBorder(context, window, camera)
        renderables.push(ellipseBorder)
      }

      if (this.areBubblesAllowed(window, camera)) {
        for (const position of bubblePositionList) {
          const bubbleRect = this.createBubbleRect(window, position, camera)
          const bubbleFill = this.createBubbleFill(context, bubbleRect)
          const bubbleBorder = this.createBubbleBorder(
            context,
            bubbleRect,
            window.border,
            camera
          )
          renderables.push(bubbleFill)
          renderables.push(bubbleBorder)
        }
      }
    }

    return renderables
  }

  private static createWindowBorder(
    context: Context,
    window: Window,
    camera: Camera
  ): WebglRectangleBorder {
    const width = window.borderWidth / camera.z
    const renderable = new WebglRectangleBorder(context, {
      ...window.rectangle,
      color: rgbaStringToRgba(window.border),
      widths: { t: width, r: width, b: width, l: width },
    })
    renderable.init()
    return renderable
  }

  private static createDashedWindow(
    context: Context,
    window: Window,
    camera: Camera
  ): WebglLine {
    const width = window.borderWidth / camera.z
    const lines: WebglLineData = {
      lines: [
        {
          start: { x: window.rectangle.x, y: window.rectangle.y },
          end: {
            x: window.rectangle.x + window.rectangle.w,
            y: window.rectangle.y,
          },
        },
        {
          start: {
            x: window.rectangle.x + window.rectangle.w,
            y: window.rectangle.y,
          },
          end: {
            x: window.rectangle.x + window.rectangle.w,
            y: window.rectangle.y + window.rectangle.h,
          },
        },
        {
          start: {
            x: window.rectangle.x + window.rectangle.w,
            y: window.rectangle.y + window.rectangle.h,
          },
          end: {
            x: window.rectangle.x,
            y: window.rectangle.y + window.rectangle.h,
          },
        },
        {
          start: {
            x: window.rectangle.x,
            y: window.rectangle.y + window.rectangle.h,
          },
          end: { x: window.rectangle.x, y: window.rectangle.y },
        },
      ],
      thickness: width,
      color: rgbaStringToRgba(window.border),
      dashed: true,
      dashLength: Math.max(2 / camera.z, 2),
    }
    const renderable = new WebglLine(context, lines)
    renderable.init()
    return renderable
  }

  private static createEllipseBorder(
    context: Context,
    window: Window,
    camera: Camera
  ): WebglEllipseBorder {
    const width = window.borderWidth / camera.z
    const renderable = new WebglEllipseBorder(context, {
      ...window.rectangle,
      color: rgbaStringToRgba(window.border),
      width: width,
    })
    renderable.init()
    return renderable
  }

  private static createWindowFill(
    context: Context,
    window: Window
  ): WebglRectangle | null {
    if (!window.fill) return null
    const renderable = new WebglRectangle(context, {
      ...window.rectangle,
      fill: {
        type: 'color',
        color: rgbaStringToRgba(window.fill),
      },
    })
    renderable.init()
    return renderable
  }

  private static createBubbleFill(
    context: Context,
    rect: Rectangle
  ): WebglRectangle {
    const renderable = new WebglRectangle(context, {
      ...rect,
      fill: {
        type: 'color',
        color: rgbaStringToRgba(DesignColor('bubbleBackground')),
      },
    })
    renderable.init()

    return renderable
  }

  private static createBubbleBorder(
    context: Context,
    rect: Rectangle,
    color: string,
    camera: Camera
  ): WebglRectangleBorder {
    const borderWidth = 1 / camera.z
    const renderable = new WebglRectangleBorder(context, {
      ...rect,
      color: rgbaStringToRgba(color),
      widths: {
        t: borderWidth,
        r: borderWidth,
        b: borderWidth,
        l: borderWidth,
      },
    })
    renderable.init()

    return renderable
  }

  private static createBubbleRect(
    window: Window,
    position: bubblePosition,
    camera: Camera
  ): Rectangle {
    const { x, y, w, h } = window.rectangle
    const adjustedSize = bubbleSize / camera.z
    const bubbleRect = { x: 0, y: 0, w: adjustedSize, h: adjustedSize }
    switch (position) {
      case 'topLeft':
        bubbleRect.x = x - adjustedSize / 2
        bubbleRect.y = y - adjustedSize / 2
        break
      case 'topRight':
        bubbleRect.x = x + w - adjustedSize / 2
        bubbleRect.y = y - adjustedSize / 2
        break
      case 'bottomLeft':
        bubbleRect.x = x - adjustedSize / 2
        bubbleRect.y = y + h - adjustedSize / 2
        break
      case 'bottomRight':
        bubbleRect.x = x + w - adjustedSize / 2
        bubbleRect.y = y + h - adjustedSize / 2
        break
    }

    return bubbleRect
  }

  private static areBubblesAllowed(window: Window, camera: Camera): boolean {
    if (!window.bubbles) return false
    const { w, h } = window.rectangle
    const minSize = bubbleSize
    return w * camera.z >= minSize && h * camera.z >= minSize
  }
}
