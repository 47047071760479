import { AttributeCursorType } from 'application/attributes'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import TitleIconBlock from 'components/Library/Components/IconBlock/TitleIconBlock/TitleIconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function CursorSettings() {
  const { settings, handlers } = usePanelHelper(editor.getUI().getCursorPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings) return <></>

  const { attributes, mode } = settings
  const { addCursor, removeCursor, setStyle, clearMixed } = handlers
  const { hasOverride, sectionHasOverride } = overrides.handlers
  if (mode === 'disabled' || attributes === null) return <></>

  const { 'cursor.style': style } = attributes

  const handleAdd = () => {
    if (mode === 'mixed') clearMixed()
    else if (mode === 'add') addCursor()
    else if (mode === 'remove') removeCursor()
  }

  return (
    <PanelSection>
      <PanelRow>
        <OverrideReset section={'cursor'}>
          <TitleButtonBlock
            title={'Cursor'}
            active={mode === 'remove' || mode === 'mixed'}
            hasOverride={sectionHasOverride('cursor')}
            onClick={handleAdd}
          />
        </OverrideReset>
        <TitleIconBlock
          icon={'Plus'}
          active={mode === 'remove'}
          canDeactivate={mode === 'remove'}
          onClick={handleAdd}
        />
      </PanelRow>
      {mode === 'mixed' && (
        <PanelRow>
          <TextBlock width={216} mode={'label2'}>
            {'Click + to clear mixed transitions'}
          </TextBlock>
        </PanelRow>
      )}
      {mode === 'remove' && (
        <PanelRow>
          <OverrideReset keys={['cursor.mode', 'cursor.style']}>
            <Dropdown
              width={96}
              tooltipKey={'CursorMode'}
              mixed={style === 'Mixed'}
              selected={style}
              select={(v) => {
                if (v === 'Mixed') return
                if (v === undefined) return
                setStyle(v)
              }}
              options={cursorOptions}
              commit={true}
              hasOverride={hasOverride(['cursor.style', 'cursor.mode'])}
            />
          </OverrideReset>
        </PanelRow>
      )}
    </PanelSection>
  )
}

const cursorOptions: dropdownOption<AttributeCursorType>[] = [
  {
    value: 'auto',
    text: 'Auto',
  },
  {
    value: 'pointer',
    text: 'Pointer',
  },
  {
    value: 'cancel',
    text: 'Not Allowed',
  },
]
