import { HTTPService } from './http'
import {
  ProjectDocumentVersion,
  Project,
  ProjectDocument,
  RequestProjectUpdate,
  ProjectAsset,
  UnsplashResponse,
  TemplateInfo,
  TemplateData,
  ProjectWebsiteInfo,
  ProjectWebsiteInfoUpdate,
  ProjectFontInfo,
  ProjectFontDetailsUpdate,
} from './types'

interface AuthService {
  getToken(): string
}

export class BackendService {
  private httpService: HTTPService
  private authService: AuthService

  constructor(httpService: HTTPService, authService: AuthService) {
    this.httpService = httpService
    this.authService = authService
  }

  public getProject = (id: string): Promise<Project | null> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<Project | null>(
      `/api/project/${id}`,
      'GET',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public getProjects = (): Promise<Project[]> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<Project[]>(
      '/api/project',
      'GET',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public getProjectWebsiteInfo = (
    id: string
  ): Promise<ProjectWebsiteInfo | null> => {
    return this.httpService.request<ProjectWebsiteInfo | null>(
      `/api/project/website/${id}`,
      'GET'
    )
  }

  public updateProjectWebsiteInfo = (
    id: string,
    update: ProjectWebsiteInfoUpdate
  ): Promise<ProjectWebsiteInfo | null> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<ProjectWebsiteInfo>(
      `/api/project/website/${id}`,
      'PUT',
      update,
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public validateProjectDomain = (id: string): Promise<boolean> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<boolean>(
      `/api/dns/validate/${id}`,
      'GET',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public getDocument = (id: string): Promise<ProjectDocument | null> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<ProjectDocument | null>(
      `/api/document/${id}`,
      'GET',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public getDocumentVersion = (
    id: string
  ): Promise<ProjectDocumentVersion | null> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<ProjectDocumentVersion | null>(
      `/api/document/version/${id}`,
      'GET',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public createProject = (): Promise<Project> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request(
      '/api/project',
      'POST',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public updateProject = (
    id: string,
    update: RequestProjectUpdate
  ): Promise<Project> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<Project>(
      `/api/project/${id}`,
      'PUT',
      update,
      {
        Authorization: `Bearer ${bearerToken}`,
      }
    )
  }

  public deleteProject = (id: string) => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request(
      `/api/project/${id}`,
      'DELETE',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public publishProject = (projectId: string, pages: string[]) => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request(
      '/api/website/publish',
      'POST',
      { projectId, pages },
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public startCheckoutSession = (
    projectId: string,
    annual: boolean
  ): Promise<{ sessionId: string }> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<{ sessionId: string }>(
      `/api/payment/checkout/${projectId}?annual=${annual}`,
      'POST',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public startManageSession = (): Promise<{ sessionUrl: string }> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<{ sessionUrl: string }>(
      '/api/payment/manage',
      'POST',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public getUnsplashImages = (
    query: string,
    page: number
  ): Promise<UnsplashResponse> => {
    return this.httpService.request<UnsplashResponse>(
      `/api/unsplash?query=${query}&page=${page}`,
      'GET'
    )
  }

  public createAsset = (
    projectId: string,
    id: string,
    file: File
  ): Promise<ProjectAsset | null> => {
    const bearerToken = this.authService.getToken()

    const data = new FormData()
    data.append('id', id)
    data.append('file', file)
    data.append('type', file.type)

    return this.httpService.request<ProjectAsset | null>(
      `/api/project/${projectId}/asset`,
      'POST',
      data,
      {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'multipart/form-data',
      }
    )
  }

  public getFonts = (projectId: string): Promise<ProjectFontInfo[]> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<ProjectFontInfo[]>(
      `/api/project/${projectId}/font`,
      'GET',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public uploadFont = (
    projectId: string,
    id: string,
    name: string,
    file: File
  ): Promise<ProjectFontInfo> => {
    const bearerToken = this.authService.getToken()

    const data = new FormData()
    data.append('id', id)
    data.append('file', file)
    data.append('name', file.name)
    data.append('type', file.type)

    return this.httpService.request<ProjectFontInfo>(
      `/api/project/${projectId}/font`,
      'POST',
      data,
      {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'multipart/form-data',
      }
    )
  }

  public updateFontInfo = (
    projectId: string,
    fontId: string,
    data: ProjectFontDetailsUpdate
  ): Promise<ProjectFontInfo> => {
    const bearerToken = this.authService.getToken()

    return this.httpService.request<ProjectFontInfo>(
      `/api/project/${projectId}/font/${fontId}/info`,
      'PUT',
      data,
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public updateFontFile = (
    projectId: string,
    fontId: string,
    file: File
  ): Promise<ProjectFontInfo> => {
    const bearerToken = this.authService.getToken()

    const data = new FormData()
    data.append('id', fontId)
    data.append('file', file)
    data.append('name', file.name)
    data.append('type', file.type)

    return this.httpService.request<ProjectFontInfo>(
      `/api/project/${projectId}/font/${fontId}/file`,
      'PUT',
      data,
      {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'multipart/form-data',
      }
    )
  }

  public deleteFont = (
    projectId: string,
    fontId: string
  ): Promise<ProjectFontInfo> => {
    const bearerToken = this.authService.getToken()

    return this.httpService.request<ProjectFontInfo>(
      `/api/project/${projectId}/font/${fontId}`,
      'DELETE',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public getTemplates = (): Promise<TemplateInfo[]> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<TemplateInfo[]>(
      `/api/templates`,
      'GET',
      {},
      { Authorization: `Bearer ${bearerToken}` }
    )
  }

  public getTemplateData = (template: TemplateInfo): Promise<TemplateData> => {
    const bearerToken = this.authService.getToken()
    return this.httpService.request<TemplateData>(
      `/api/templates/data?url=${template.documentUrl}`,
      'GET',
      { url: template.documentUrl },
      { Authorization: `Bearer ${bearerToken}` }
    )
  }
}
