import { WriteDocument } from 'application/document'
import { Node } from 'application/node'
import { PositionEngineCalculator } from './types'
import { Rectangle } from 'application/shapes'

export class BoundingBoxCalculator implements PositionEngineCalculator {
  update(node: Node, document: WriteDocument): void {
    const boundingBox = this.computeBoundingBox(node, document)
    node.setBaseAttribute('boundingBox.x', boundingBox.x)
    node.setBaseAttribute('boundingBox.y', boundingBox.y)
    node.setBaseAttribute('boundingBox.w', boundingBox.w)
    node.setBaseAttribute('boundingBox.h', boundingBox.h)
  }

  private computeBoundingBox(node: Node, document: WriteDocument): Rectangle {
    const parent = this.findClipParent(node, document)
    if (!parent || ['canvas', 'root'].includes(node.getBaseAttribute('type'))) {
      return defaultCanvasBox
    }

    const top = Math.max(
      parent.getBaseAttribute('y'),
      parent.getBaseAttribute('boundingBox.y')
    )
    const left = Math.max(
      parent.getBaseAttribute('x'),
      parent.getBaseAttribute('boundingBox.x')
    )
    const bottom = Math.min(
      parent.getBaseAttribute('y') + parent.getBaseAttribute('h'),
      parent.getBaseAttribute('boundingBox.y') +
        parent.getBaseAttribute('boundingBox.h')
    )
    const right = Math.min(
      parent.getBaseAttribute('x') + parent.getBaseAttribute('w'),
      parent.getBaseAttribute('boundingBox.x') +
        parent.getBaseAttribute('boundingBox.w')
    )
    return {
      x: left,
      y: top,
      w: right - left,
      h: bottom - top,
    }
  }

  private findClipParent(
    node: Node,
    document: WriteDocument
  ): Node | undefined {
    return document
      .getAncestors(node)
      .find(
        (a) =>
          a.getStyleAttribute('clip') &&
          !['canvas', 'root'].includes(a.getBaseAttribute('type'))
      )
  }
}

const defaultCanvasBox: Rectangle = {
  x: -100_000,
  y: -100_000,
  w: 200_000,
  h: 200_000,
}
