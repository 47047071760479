import { DesignColor } from 'themes'

interface TimelineEffectBlockProps {
  children?: React.ReactNode
}

export default function TimelineEffectBlock({
  children,
}: TimelineEffectBlockProps) {
  return (
    <div
      style={{
        width: 168,
        height: 'fit-content',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        boxShadow: `inset 0px 0px 0px 1px ${DesignColor('panelBorder')}`,
        borderRadius: 4,
      }}
    >
      {children}
    </div>
  )
}
