import { ReadOnlyDocument } from 'application/document'
import {
  ProjectFontDetailsUpdate,
  ProjectFontInfo,
  ProjectWebsiteInfo,
  ProjectWebsiteInfoUpdate,
  WebsiteSettingsPage,
} from 'application/service'
import { BackendService } from 'application/service/backend'
import { FontDataMap, FontLoaderInterface } from 'application/text'
import { EditorProjectService } from 'editor/project/project'
import { v4 } from 'uuid'

export type WebsitePanelSettings = {
  domain: string
  defaultDomain: string

  validated: boolean
  premium: boolean
  premiumDomain?: string

  status: 'live' | 'offline' | 'unpublished' | ''
  hasPublished: boolean
  publishPages: { id: string; name: string; checked: boolean }[]

  faviconUrl: string
  socialImageUrl: string

  homepageId: string
  homepageOptions: { id: string; name: string }[]

  fonts: ProjectFontInfo[]

  pageSettings: { [key: string]: WebsiteSettingsPage }

  ip1: string
  ip2: string
  fileserver: string
}

export type WebsitePanelHandlers = {
  load: () => Promise<boolean>
  stop: () => void
  publish: () => Promise<void>
  togglePage: (id: string) => void

  setHomepage: (pageId: string) => Promise<void>
  setFavicon: (url: string) => Promise<void>
  setSocialImage: (url: string) => Promise<void>
  setPageInfo: (pageId: string, page: WebsiteSettingsPage) => Promise<void>
  setPremiumDomain: (domain: string) => void

  uploadFont: (file: File) => Promise<ProjectFontInfo | null>
  updateFontInfo: (id: string, info: ProjectFontDetailsUpdate) => Promise<void>
  updateFontFile: (id: string, file: File) => Promise<void>
  deleteFont: (id: string) => Promise<void>

  startCheckout: (annual: boolean) => Promise<{ sessionId: string }>
  startManage: () => Promise<{ sessionUrl: string }>
}

export type WebsiteListener = (settings: WebsitePanelSettings) => void

type CheckedState = { [id: string]: boolean }

export class WebsitePanel {
  private document: ReadOnlyDocument
  private backendService: BackendService
  private editorProjectService: EditorProjectService
  private fontLoader: FontLoaderInterface
  private fontDataMap: FontDataMap

  private listeners: { [key: string]: WebsiteListener }

  private projectWebsiteInfo: ProjectWebsiteInfo | null = null
  private status: 'live' | 'offline' | 'unpublished' | '' = ''
  private validated: boolean = false
  private refreshInterval: NodeJS.Timeout | null = null

  private projectFontInfo: ProjectFontInfo[] = []

  constructor(
    document: ReadOnlyDocument,
    backendService: BackendService,
    editorProjectService: EditorProjectService,
    fontLoader: FontLoaderInterface,
    fontMap: FontDataMap
  ) {
    this.document = document
    this.backendService = backendService
    this.editorProjectService = editorProjectService
    this.fontLoader = fontLoader
    this.fontDataMap = fontMap

    this.listeners = {}
  }

  getSettings = (): WebsitePanelSettings => {
    return {
      domain: this.formatUrl(this.getLiveDomain()),
      defaultDomain: this.getDefaultDomain(),
      validated: this.validated,
      premium: this.projectWebsiteInfo?.premium || false,
      premiumDomain: this.projectWebsiteInfo?.premiumDomain || undefined,
      status: this.status,
      hasPublished: this.getHasPublished(),
      publishPages: this.getPublishPages(),
      faviconUrl: this.projectWebsiteInfo?.settings.faviconUrl || '',
      socialImageUrl: this.projectWebsiteInfo?.settings.socialImageUrl || '',
      homepageId: this.projectWebsiteInfo?.settings.homepageId || '',
      homepageOptions: this.getHomepageOptions(),
      fonts: this.projectFontInfo,
      pageSettings: this.getPageSettings(),
      ip1: this.getIP1(),
      ip2: this.getIP2(),
      fileserver: this.getFileserver(),
    }
  }

  getHandlers = (): WebsitePanelHandlers => {
    return {
      load: this.load,
      stop: this.stop,
      publish: this.publish,
      togglePage: this.togglePage,
      setHomepage: this.setHomepage,
      setFavicon: this.setFavicon,
      setSocialImage: this.setSocialImage,
      setPremiumDomain: this.setPremiumDomain,
      setPageInfo: this.setPageInfo,
      uploadFont: this.uploadFont,
      updateFontInfo: this.updateFontInfo,
      updateFontFile: this.updateFontFile,
      deleteFont: this.deleteFont,
      startCheckout: this.startCheckout,
      startManage: this.startManage,
    }
  }

  subscribe(key: string, listener: WebsiteListener): void {
    this.listeners[key] = listener
    listener(this.getSettings())
  }

  unsubscribe(key: string): void {
    delete this.listeners[key]
  }

  private getPublishPages = (): {
    id: string
    name: string
    checked: boolean
  }[] => {
    const checkedState = this.getCheckedState()
    const pages = this.document.getNodes().filter((node) => {
      return node.getBaseAttribute('type') === 'page'
    })

    return pages.map((page) => {
      const checked = checkedState[page.getId()]
      return {
        id: page.getId(),
        name: page.getBaseAttribute('name'),
        checked: checked === undefined ? true : checked,
      }
    })
  }

  private load = async (): Promise<boolean> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return false

    await this.loadWebsiteInfo()
    await this.loadFonts()

    this.start()
    return true
  }

  private loadWebsiteInfo = async (): Promise<void> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return

    await this.backendService.getProjectWebsiteInfo(projectId).then((info) => {
      this.projectWebsiteInfo = info
      this.notifyListeners()
    })
  }

  private loadFonts = async (): Promise<void> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return

    await this.backendService.getFonts(projectId).then((fonts) => {
      this.projectFontInfo = fonts
      this.notifyListeners()
    })
  }

  private start = (): void => {
    this.stop()
    this.refreshStatus()
    this.refreshValidated()
    this.refreshInterval = setInterval(() => {
      this.refreshStatus()
      this.refreshValidated()
    }, 5000)
  }

  private stop = (): void => {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  }

  private togglePage = (id: string): void => {
    const checkedState = this.getCheckedState()
    const checked = checkedState[id]

    checkedState[id] = checked === undefined ? false : !checked
    for (const checkedId in checkedState) {
      if (checkedId === id) continue
      const node = this.document.getNode(checkedId)
      if (!node) delete checkedState[checkedId]
    }

    this.saveCheckedState(checkedState)

    this.notifyListeners()
  }

  private publish = async (): Promise<void> => {
    const checkedState = this.getCheckedState()
    const pages = this.document
      .getNodes()
      .filter((n) => n.getBaseAttribute('type') === 'page')
      .filter((n) => checkedState[n.getId()] !== false)

    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return

    await this.backendService.publishProject(
      projectId,
      pages.map((p) => p.getId())
    )

    this.saveHasPublished()
    this.start()
    this.notifyListeners()
  }

  private setHomepage = async (pageId: string): Promise<void> => {
    if (!this.projectWebsiteInfo) return
    await this.updateWebsiteSettings({
      settings: {
        ...this.projectWebsiteInfo.settings,
        homepageId: pageId,
      },
    })
  }

  private setFavicon = async (url: string): Promise<void> => {
    if (!this.projectWebsiteInfo) return
    await this.updateWebsiteSettings({
      settings: {
        ...this.projectWebsiteInfo.settings,
        faviconUrl: url,
      },
    })
  }

  private setSocialImage = async (url: string): Promise<void> => {
    if (!this.projectWebsiteInfo) return
    await this.updateWebsiteSettings({
      settings: {
        ...this.projectWebsiteInfo.settings,
        socialImageUrl: url,
      },
    })
  }

  private setPremiumDomain = async (domain: string): Promise<void> => {
    if (!this.projectWebsiteInfo) return
    if (domain === '') this.validated = false
    await this.updateWebsiteSettings({
      premiumDomain: domain,
    })
    this.notifyListeners()
  }

  private setPageInfo = async (
    pageId: string,
    page: WebsiteSettingsPage
  ): Promise<void> => {
    if (!this.projectWebsiteInfo) return
    await this.updateWebsiteSettings({
      settings: {
        ...this.projectWebsiteInfo.settings,
        pages: {
          ...this.projectWebsiteInfo.settings.pages,
          [pageId]: page,
        },
      },
    })
  }

  private uploadFont = async (file: File): Promise<ProjectFontInfo | null> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return null

    const fontId = v4().replace(/-/g, '').slice(0, 12)
    const name = file.name.split('.')[0]
    const uploaded = await this.backendService.uploadFont(
      projectId,
      fontId,
      name,
      file
    )
    await this.loadFonts()

    await this.fontLoader.loadExternalFont(
      uploaded.family,
      uploaded.weight,
      uploaded.fileUrl,
      uploaded.msdfJsonUrl,
      uploaded.msdfPngUrl
    )
    this.fontDataMap.addProjectFont(uploaded)

    return uploaded
  }

  private updateFontInfo = async (
    id: string,
    update: ProjectFontDetailsUpdate
  ): Promise<void> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return

    const current = this.projectFontInfo.find((f) => f.id === id)
    if (!current) return

    const updated = await this.backendService.updateFontInfo(
      projectId,
      id,
      update
    )
    await this.loadFonts()

    await this.fontLoader.loadExternalFont(
      updated.family,
      updated.weight,
      updated.fileUrl,
      updated.msdfJsonUrl,
      updated.msdfPngUrl
    )
    this.fontDataMap.removeProjectFont(current)
    this.fontDataMap.addProjectFont(updated)
  }

  private updateFontFile = async (id: string, file: File): Promise<void> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return

    const current = this.projectFontInfo.find((f) => f.id === id)
    if (!current) return

    const updated = await this.backendService.updateFontFile(
      projectId,
      id,
      file
    )
    await this.loadFonts()

    await this.fontLoader.loadExternalFont(
      updated.family,
      updated.weight,
      updated.fileUrl,
      updated.msdfJsonUrl,
      updated.msdfPngUrl
    )
    this.fontDataMap.removeProjectFont(current)
    this.fontDataMap.addProjectFont(updated)
  }

  private deleteFont = async (id: string): Promise<void> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return

    const deleted = this.projectFontInfo.find((f) => f.id === id)
    if (!deleted) return

    await this.backendService.deleteFont(projectId, id)
    await this.loadFonts()

    this.fontDataMap.removeProjectFont(deleted)
  }

  private updateWebsiteSettings = async (
    update: ProjectWebsiteInfoUpdate
  ): Promise<void> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId || !this.projectWebsiteInfo) return

    this.projectWebsiteInfo = { ...this.projectWebsiteInfo, ...update }
    this.notifyListeners()

    await this.backendService.updateProjectWebsiteInfo(projectId, update)
    await this.loadWebsiteInfo()
  }

  private startCheckout = async (
    annual: boolean
  ): Promise<{ sessionId: string }> => {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId) return { sessionId: '' }

    return this.backendService.startCheckoutSession(projectId, annual)
  }

  private startManage = async (): Promise<{ sessionUrl: string }> => {
    return this.backendService.startManageSession()
  }

  private refreshStatus(): void {
    if (!this.projectWebsiteInfo) return

    const url = this.formatUrl(this.getLiveDomain())
    fetch(url)
      .then((response) => {
        this.status = response.ok ? 'live' : 'offline'
        this.notifyListeners()
      })
      .catch(() => {
        if (!this.getHasPublished()) {
          this.status = 'unpublished'
          this.stop()
        } else {
          this.status = 'offline'
        }
        this.notifyListeners()
      })
  }

  private refreshValidated(): void {
    const projectId = this.editorProjectService.getProjectId()
    if (!projectId || !this.projectWebsiteInfo?.premiumDomain) return
    if (this.validated) return

    try {
      this.backendService.validateProjectDomain(projectId).then((validated) => {
        this.validated = validated
        this.notifyListeners()
      })
    } catch (e) {}
  }

  private getLiveDomain(): string {
    if (!this.projectWebsiteInfo) return ''
    if (this.projectWebsiteInfo.premiumDomain) {
      return this.projectWebsiteInfo.premiumDomain
    } else {
      return this.getDefaultDomain()
    }
  }

  private getDefaultDomain(): string {
    if (!this.projectWebsiteInfo) return ''
    if (process.env.REACT_APP_WEBSITE_PREFIX !== undefined) {
      return `${process.env.REACT_APP_WEBSITE_PREFIX}/${this.projectWebsiteInfo.domain}.${process.env.REACT_APP_WEBSITE_SUFFIX}`
    } else {
      return `${this.projectWebsiteInfo.domain}.${process.env.REACT_APP_WEBSITE_SUFFIX}`
    }
  }

  private formatUrl(url: string): string {
    if (process.env.REACT_APP_WEBSITE_HTTPS === 'false') {
      return `http://${url}`
    }
    return `https://${url}`
  }

  private getHomepageOptions(): { id: string; name: string }[] {
    return this.document
      .getNodes()
      .filter((n) => n.getBaseAttribute('type') === 'page')
      .map((node) => {
        return {
          id: node.getId(),
          name: node.getBaseAttribute('name'),
        }
      })
  }

  private getPageSettings(): { [key: string]: WebsiteSettingsPage } {
    const websiteSettings = this.projectWebsiteInfo?.settings
    if (!websiteSettings) return {}

    const pageSettings: { [key: string]: WebsiteSettingsPage } = {}
    const pages = this.document.getNodes().filter((node) => {
      return node.getBaseAttribute('type') === 'page'
    })

    for (const page of pages) {
      if (websiteSettings.pages[page.getId()]) {
        pageSettings[page.getId()] = websiteSettings.pages[page.getId()]
      } else {
        pageSettings[page.getId()] = {
          id: page.getId(),
          title: '',
          slug: '',
          description: '',
        }
      }
    }

    return pageSettings
  }

  private getIP1(): string {
    return process.env.REACT_APP_FILESERVER_IP_1 || ''
  }

  private getIP2(): string {
    return process.env.REACT_APP_FILESERVER_IP_2 || ''
  }

  private getFileserver(): string {
    return process.env.REACT_APP_FILESERVER_ADDRESS || ''
  }

  private getCheckedState(): CheckedState {
    const state = localStorage.getItem('checkedPages')
    return state ? JSON.parse(state) : {}
  }

  private saveCheckedState(state: CheckedState): void {
    localStorage.setItem('checkedPages', JSON.stringify(state))
  }

  private getHasPublished(): boolean {
    return localStorage.getItem('hasPublished') === 'true'
  }

  private saveHasPublished(): void {
    localStorage.setItem('hasPublished', 'true')
  }

  private notifyListeners(): void {
    const settings = this.getSettings()
    for (const key in this.listeners) {
      this.listeners[key](settings)
    }
  }
}
