import { canFrameFillPage } from 'application/attributes'
import { PostReparentRule } from '../types'
import { ReadOnlyDocument } from 'application/document'
import { Command } from 'application/client'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class FillPageRule implements PostReparentRule {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument

  constructor(commandHandler: CommandHandler, document: ReadOnlyDocument) {
    this.commandHandler = commandHandler
    this.document = document
  }

  postReparent = (ids: string[], parentId: string | null): void => {
    if (!parentId) return

    const parent = this.document.getNode(parentId)
    if (!parent) return
    if (parent.getBaseAttribute('type') !== 'page') return

    const commands: Command[] = []
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue
      if (!canFrameFillPage(node, parent)) continue
      commands.push({
        type: 'setNodeAttribute',
        params: {
          id: node.getId(),
          base: {},
          style: { 'size.w.auto': 'fill' },
        },
      })
    }

    this.commandHandler.handle(commands)
  }
}
