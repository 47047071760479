import { CommandHandler, SetChildOrder } from 'application/client'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  Done,
  NotDone,
} from '../types'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { getById, getClosestByClass } from 'application/browser'
import { canAttributeInsertInto, isContainerType } from 'application/attributes'
import { DraggingLayersLine } from './line'
import { ReadOnlyNode } from 'application/node'
import { Point } from 'application/shapes'
import { NodeReparentAction, NodeSelectionAction } from 'application/action'

type LayerPosition = 'top' | 'bottom' | 'inside'
type LayerInsertPosition = { parentId: string; index: number }

export class DragLayersAction implements ActionHandler {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private reparent: NodeReparentAction
  private selection: NodeSelectionAction
  private line: DraggingLayersLine

  private startPoint: Point | null
  private started: boolean
  private lastTarget: string | null
  private lastPosition: LayerPosition | null

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    reparent: NodeReparentAction,
    selection: NodeSelectionAction,
    line: DraggingLayersLine
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.documentSelection = documentSelection
    this.reparent = reparent
    this.selection = selection
    this.line = line

    this.startPoint = null
    this.started = false
    this.lastTarget = null
    this.lastPosition = null
  }

  getType = (): Action => {
    return 'dragLayers'
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    const point = { x: e.clientX, y: e.clientY }
    if (!this.startPoint) {
      this.startPoint = point
      return NotDone
    }

    if (!this.isPastDeltaThreshold(this.startPoint, point) && !this.started) {
      return NotDone
    } else {
      this.started = true
    }

    const id = this.getLayerAtPoint(e)

    if (!id) return NotDone

    const position = this.getLayerTargetPosition(e, id)
    if (!position) return NotDone

    this.lastPosition = position
    this.lastTarget = id
    this.line.set({ id, position })

    return NotDone
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    this.line.set(null)

    const point = { x: e.clientX, y: e.clientY }
    if (
      !this.startPoint ||
      !this.isPastDeltaThreshold(this.startPoint, point)
    ) {
      if (e.shiftKey || e.ctrlKey || e.metaKey) return Done

      const selected = this.documentSelection.getSelected()
      if (selected.length === 0) return Done

      const targetId = this.getLayerAtPoint(e)
      if (!targetId) return Done
      if (!selected.some((n) => n.getId() === targetId)) return Done

      this.selection.selectNodes([targetId], true)
      this.commandHandler.handle({ type: 'commit' })

      return Done
    }

    const targetId = this.getLayerAtPoint(e) || this.lastTarget
    if (!targetId) return Done

    const position =
      this.getLayerTargetPosition(e, targetId) || this.lastPosition
    if (!position) return Done

    this.moveNodes(targetId, position)
    this.commandHandler.handle({ type: 'commit' })

    return Done
  }

  private getLayerAtPoint = (e: MouseEvent): string | null => {
    const target = getClosestByClass(e, 'layer-row')
    if (!target) return null

    const layerId = target.getAttribute('data-layer-id')
    return layerId || null
  }

  private getLayerTargetPosition = (
    e: MouseEvent,
    layerId: string
  ): LayerPosition | null => {
    const target = getById(`layer-row-${layerId}`)
    if (!target) return null

    const node = this.document.getNode(layerId)
    if (!node) return null

    const selected = this.documentSelection.getSelected()
    if (selected.length === 0) return null

    const allDescendants = selected.flatMap((n) => {
      return this.document.getDescendants(n)
    })
    if (allDescendants.some((n) => n.getId() === node.getId())) return null

    const isContainer = isContainerType(node.getBaseAttribute('type'))
    const rectangle = target.getBoundingClientRect()

    if (isContainer && !selected.some((n) => n.getId() === node.getId())) {
      const third = rectangle.height / 3
      const y = rectangle.top + third
      if (e.clientY < y) return 'top'
      if (e.clientY > y + third) return 'bottom'
      return 'inside'
    } else {
      const half = rectangle.height / 2
      const y = rectangle.top + half
      if (e.clientY < y) return 'top'
      return 'bottom'
    }
  }

  private moveNodes = (targetId: string, position: LayerPosition): void => {
    const insertPostion = this.getParentAndIndex(targetId, position)
    if (!insertPostion) return

    for (const node of this.documentSelection.getSelected()) {
      if (node.getParent() === insertPostion.parentId) {
        this.reorderChildren(node, insertPostion)
      } else {
        const { parentId, index } = insertPostion

        const parent = this.document.getNode(parentId)
        if (!parent) continue

        if (
          !canAttributeInsertInto(
            node.getStyleAttributes(),
            node.getBaseAttribute('type'),
            parent.getBaseAttribute('type')
          )
        ) {
          continue
        }

        this.reparent.reparentToNode([node.getId()], parentId, index)
      }
    }
  }

  private reorderChildren = (
    node: ReadOnlyNode,
    insertPosition: LayerInsertPosition
  ): void => {
    const { parentId, index } = insertPosition

    const currentParentId = node.getParent()
    if (!currentParentId) return

    if (parentId === currentParentId) {
      const currentParent = this.document.getNode(currentParentId)
      if (!currentParent) return

      const children = currentParent.getChildren()
      if (!children) return

      const nodeIndex = children.indexOf(node.getId())
      if (nodeIndex === -1) return

      const newOrder = children.filter((id) => id !== node.getId())
      newOrder.splice(nodeIndex > index ? index : index - 1, 0, node.getId())

      const command = this.buildReorderCommand(currentParentId, newOrder)
      this.commandHandler.handle(command)
    }
  }

  private getParentAndIndex = (
    id: string,
    position: LayerPosition
  ): LayerInsertPosition | null => {
    if (position === 'inside') return { parentId: id, index: 0 }

    const node = this.document.getNode(id)
    if (!node) return null

    const parent = this.document.getParent(node)
    if (!parent) return null

    const children = parent.getChildren()
    if (!children) return null

    const index = children.indexOf(id)
    if (index === -1) return null

    return {
      parentId: parent.getId(),
      index: position === 'top' ? index : index + 1,
    }
  }

  private buildReorderCommand = (
    parentId: string,
    children: string[]
  ): SetChildOrder => {
    return {
      type: 'setChildOrder',
      params: { parentId: parentId, children: children },
    }
  }

  private isPastDeltaThreshold = (start: Point, current: Point): boolean => {
    return Math.abs(current.y - start.y) > 20
  }
}
