import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import ClipBlock from './ClipBlock/ClipBlock'
import { IconKey } from 'assets/iconLibrary'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'

export default function OpacityRow() {
  const opacityPanel = usePanelHelper(editor.getUI().getOpacityPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!opacityPanel.settings || !opacityPanel.settings.attributes) return <></>
  if (!overrides.settings) return <></>

  const { setOpacity, setHidden, slideOpacity } = opacityPanel.handlers
  const { hasOverride } = overrides.handlers
  const { opacity, hidden } = opacityPanel.settings.attributes

  const handleToggleVisibility = () => {
    hidden === 'Mixed' ? setHidden(true) : setHidden(!hidden)
  }

  return (
    <PanelRow>
      <OverrideReset keys={['opacity']}>
        <NumberBlock
          icon="Opacity"
          tooltipKey="Opacity"
          value={opacity}
          setValue={setOpacity}
          increment={(v) => slideOpacity(v)}
          decrement={(v) => slideOpacity(-v)}
          min={0}
          max={100}
          unit="%"
          presets={opacityPresets}
          hasOverride={hasOverride(['opacity'])}
        />
      </OverrideReset>
      <ClipBlock />
      <OverrideReset keys={['hidden']}>
        <IconBlock
          icon={getHiddenIcon(hidden || false)}
          tooltipKey="Visibility"
          tooltipDirection="left"
          selected={hidden === true}
          hasOverride={hasOverride(['hidden'])}
          onClick={handleToggleVisibility}
        />
      </OverrideReset>
    </PanelRow>
  )
}

function getHiddenIcon(hidden: 'Mixed' | boolean): IconKey {
  if (hidden === true) return 'VisibleFalse'
  return 'VisibleTrue'
}

const opacityPresets = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0]
