import { DesignColor } from 'themes'
import PreviewContent from './PreviewContent/PreviewContent'
import LeftTopbar from './LeftTopbar/LeftTopbar'
import PreviewZoomInput from './PreviewZoomInput/PreviewZoomInput'
import RightTopbar from './RightTopbar/RightTopbar'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function Preview() {
  const { settings } = usePanelHelper(editor.getUI().getPreviewPanel())
  if (!settings) return <></>

  return (
    <div
      style={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: DesignColor('canvasBackground'),
        zIndex: settings.open ? (settings.fullscreen ? 3 : 2) : -1,
      }}
    >
      <PreviewContent />
      <LeftTopbar />
      <RightTopbar />
      <PreviewZoomInput />
    </div>
  )
}
