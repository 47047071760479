import CanvasRowButton from 'components/Library/Components/Button/CanvasRowButton/CanvasRowButton'
import { MouseEvent } from 'react'
import CanvasDraggingLine from './CanvasDraggingLine'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function Canvas() {
  const { settings, handlers } = usePanelHelper(editor.getUI().getCanvasPanel())
  if (!settings) return <></>

  const { canvases, line } = settings
  const { setEditing, setName, select, startDrag } = handlers

  const onMouseDown = (e: MouseEvent, key: string) => {
    if (e.button !== 0 || e.ctrlKey) return
    if (e.detail > 1) return
    select(key)
    startDrag()
  }

  return (
    <div
      id={'canvases'}
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        paddingBottom: 32,
      }}
    >
      {canvases.map((c) => {
        return (
          <CanvasRowButton
            key={c.id}
            id={c.id}
            name={c.name}
            editing={c.editing}
            setEditing={(e) => setEditing(c.id, e)}
            setName={(e) => setName(c.id, e)}
            active={c.selected}
            onMouseDown={(e) => onMouseDown(e, c.id)}
          />
        )
      })}
      <CanvasDraggingLine line={line} />
    </div>
  )
}
