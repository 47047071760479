import {
  AttributeType,
  BaseMap,
  MultiselectBaseMap,
} from 'application/attributes'
import { ReadOnlyNode } from 'application/node'
import { BaseAttributePanel } from '../baseAttributePanel'

type LinkPanelKey =
  | 'link.mode'
  | 'link.url'
  | 'link.newTab'
  | 'link.email'
  | 'link.subject'
  | 'link.page'
  | 'link.node'
  | 'link.scroll.position'
  | 'link.scroll.smooth'

type LinkPanelAttributes = Pick<MultiselectBaseMap, LinkPanelKey> | null

export interface LinkPanelState {
  allowed: boolean
  pageTargets: string[]
  nodeTargets: string[]
  attributes: LinkPanelAttributes
}

export interface LinkPanelHandlers {
  addLink: () => void
  removeLink: () => void
  setMode: (mode: 'page' | 'url' | 'email' | 'scroll') => void
  setUrl: (url: string) => void
  setNewTab: (newTab: boolean) => void
  setEmail: (email: string) => void
  setSubject: (subject: string) => void
  setPage: (pageId: string) => void
  setNode: (nodeId: string) => void
  setScrollPosition: (position: 'top' | 'bottom' | 'center') => void
  setScrollSmooth: (smooth: boolean) => void
}

export class InteractionLinkPanel extends BaseAttributePanel<
  LinkPanelState,
  LinkPanelHandlers,
  LinkPanelKey
> {
  getSettings(): LinkPanelState {
    return {
      allowed: this.getAllowed(),
      pageTargets: this.getPageTargets(),
      nodeTargets: this.getNodeTargets(),
      attributes: this.attributes,
    }
  }

  getHandlers(): LinkPanelHandlers {
    return {
      addLink: this.addLink,
      removeLink: this.removeLink,
      setMode: this.setMode,
      setNewTab: this.setNewTab,
      setUrl: this.setUrl,
      setEmail: this.setEmail,
      setSubject: this.setSubject,
      setPage: this.setPage,
      setNode: this.setNode,
      setScrollPosition: this.setScrollPosition,
      setScrollSmooth: this.setScrollSmooth,
    }
  }

  private getAllowed = (): boolean => {
    return this.getNodes().length > 0
  }

  private addLink = (): void => {
    this.setMulti({ 'link.mode': 'page', 'link.page': '' })
    this.commit()
  }

  private removeLink = (): void => {
    this.setMulti({
      'link.mode': undefined,
      'link.url': undefined,
      'link.newTab': undefined,
      'link.email': undefined,
      'link.subject': undefined,
      'link.page': undefined,
      'link.node': undefined,
      'link.scroll.position': undefined,
      'link.scroll.smooth': undefined,
    })
    this.commit()
  }

  private setMode = (mode: 'page' | 'url' | 'email' | 'scroll'): void => {
    this.setMultiWithFilter(this.getDefaultsForMode(mode))
    this.commit()
  }

  private setNewTab = (newTab: boolean): void => {
    this.setMultiWithFilter({ 'link.newTab': newTab })
    this.commit()
  }

  private setUrl = (url: string): void => {
    this.setMultiWithFilter({ 'link.url': url })
  }

  private setEmail = (email: string): void => {
    this.setMultiWithFilter({ 'link.email': email })
  }

  private setSubject = (subject: string): void => {
    this.setMultiWithFilter({ 'link.subject': subject })
  }

  private setPage = (pageId: string): void => {
    this.setMultiWithFilter({ 'link.page': pageId })
    this.commit()
  }

  private setNode = (nodeId: string): void => {
    this.setMultiWithFilter({ 'link.node': nodeId })
    this.commit()
  }

  private setScrollPosition = (position: 'top' | 'bottom' | 'center'): void => {
    this.setMultiWithFilter({ 'link.scroll.position': position })
    this.commit()
  }

  private setScrollSmooth = (smooth: boolean): void => {
    this.setMultiWithFilter({ 'link.scroll.smooth': smooth })
    this.commit()
  }

  private getDefaultsForMode = (
    mode: 'page' | 'url' | 'email' | 'scroll'
  ): Partial<BaseMap> => {
    switch (mode) {
      case 'page':
        return { 'link.mode': 'page' }
      case 'url':
        return { 'link.mode': 'url' }
      case 'email':
        return { 'link.mode': 'email' }
      case 'scroll':
        return {
          'link.mode': 'scroll',
          'link.scroll.position': 'top',
          'link.scroll.smooth': true,
        }
    }
  }

  private setMultiWithFilter = (attributes: Partial<BaseMap>): void => {
    const nodes = this.getNodes().filter(
      (node) => node.getBaseAttribute('link.mode') !== undefined
    )
    for (const node of nodes) {
      this.setOne(node.getId(), attributes)
    }
  }

  private getPageTargets = (): string[] => {
    const root = this.document.getRoot()
    if (!root) return []

    const canvases = root.getChildren()
    if (!canvases) return []

    return canvases.flatMap((canvasId) => {
      const canvas = this.document.getNode(canvasId)
      if (!canvas) return []

      const children = canvas.getChildren()
      if (!children) return []

      return children
        .map((childId) => this.document.getNode(childId))
        .filter((child) => child?.getBaseAttribute('type') === 'page')
        .map((page) => page!.getId())
    })
  }

  private getNodeTargets = (): string[] => {
    const nodes = this.getNodes()
    const ids = nodes.map((node) => node.getId())

    return nodes.flatMap((node) => {
      const ancestors = this.document.getAncestors(node)
      const pageParent = ancestors.find(
        (ancestor) => ancestor.getBaseAttribute('type') === 'page'
      )
      if (!pageParent) return []

      const descendants = this.document.getDescendants(pageParent)
      return descendants
        .map((descendant) => descendant.getId())
        .filter((id) => !ids.includes(id))
    })
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'rectangle',
  'ellipse',
  'text',
  'image',
]
