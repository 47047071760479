import { UINodeActions } from './types'
import { CommandHandler } from 'application/client'
import { AttributesAction } from 'editor/action/node/attributes'
import { AddAutolayoutAction } from 'editor/action/node/autolayout'
import { GroupAction } from 'editor/action/node/group'
import { RearrangeAction } from 'editor/action/node/rearrange'

export class NodeActions implements UINodeActions {
  private commandHandler: CommandHandler
  private attributes: AttributesAction
  private autolayoutAction: AddAutolayoutAction
  private groupAction: GroupAction
  private rearrange: RearrangeAction

  constructor(
    commandHandler: CommandHandler,
    attributes: AttributesAction,
    autolayout: AddAutolayoutAction,
    group: GroupAction,
    rearrange: RearrangeAction
  ) {
    this.commandHandler = commandHandler
    this.attributes = attributes
    this.autolayoutAction = autolayout
    this.groupAction = group
    this.rearrange = rearrange
  }

  copy = (): void => {
    this.commandHandler.handle({
      type: 'copySelectedNodes',
      params: { clipboard: false },
    })
  }

  copyAttributes = (): void => {
    this.commandHandler.handle({ type: 'copySelectedNodeAttributes' })
  }

  cut = (): void => {
    this.commandHandler.handle({ type: 'cutSelectedNodes' })
    this.commandHandler.handle({ type: 'commit' })
  }

  paste = (): void => {
    this.commandHandler.handle({ type: 'pasteNodes' })
    this.commandHandler.handle({ type: 'commit' })
  }

  pasteAttributes = (): void => {
    this.commandHandler.handle({ type: 'pasteNodeAttributes' })
    this.commandHandler.handle({ type: 'commit' })
  }

  duplicate = (): void => {
    this.commandHandler.handle({
      type: 'duplicateSelectedNodes',
      params: { addOffset: true },
    })
    this.commandHandler.handle({ type: 'commit' })
  }

  replace = (): void => {
    this.commandHandler.handle({ type: 'replaceSelectedNodes' })
    this.commandHandler.handle({ type: 'commit' })
  }

  group = (): void => {
    this.groupAction.group()
  }

  ungroup = (): void => {
    this.groupAction.ungroup()
  }

  undo = (): void => {
    this.commandHandler.handle({ type: 'undo' })
  }

  redo = (): void => {
    this.commandHandler.handle({ type: 'redo' })
  }

  autolayout = (): void => {
    this.autolayoutAction.addAutolayout()
  }

  toggleHidden = (): void => {
    this.attributes.toggleHidden()
  }

  toggleLocked = (): void => {
    this.attributes.toggleLocked()
  }

  moveToFront = (): void => {
    this.rearrange.moveToFront()
  }

  moveToBack = (): void => {
    this.rearrange.moveToBack()
  }
}
