import { useRef, useState } from 'react'
import Text from 'components/Library/Text/Text'
import { targetInputWidth } from 'components/Library/Containers/constants'
import { IconKey } from 'assets/iconLibrary'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import useHovered from 'hooks/ui/useHovered'
import Tooltip from '../../Tooltip/Tooltip'
import Icon from '../../Icon/Icon/Icon'
import { DesignColor } from 'themes'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { tooltipDirection, tooltipKey } from 'assets/tooltips'
import useCommit from 'hooks/editor/useCommit'

interface TargetInputProps {
  width?: targetInputWidth

  icon: IconKey
  tooltipKey?: tooltipKey
  tooltipDirection?: tooltipDirection

  id: string

  options: string[]
  onSelect(option: string | null): void

  disabled?: boolean
  placeholder?: string

  commit?: boolean
}

export default function TargetInput({
  width = 168,
  icon,
  tooltipKey,
  tooltipDirection,
  id,
  options,
  onSelect,
  disabled = false,
  placeholder,
  commit,
}: TargetInputProps) {
  const commitUpdate = useCommit()
  const { target } = useActionInitiator()

  const { settings } = usePanelHelper(editor.getUI().getDetailsPanel())
  const name = settings?.names[id] ?? ''

  const inputRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: inputRef })
  const [hoverIcon, setHoverIcon] = useState(false)
  const [active, setActive] = useState(false)

  const activeIcon = getIcon(icon, active, hovered || false)
  const iconColor = getIconColor(active, hoverIcon)
  const textContent = getTextContent(name, active, placeholder)
  const textColor = getTextColor(name, active)
  const opacity = getOpacity(disabled)
  const background = getBackground(active, disabled)
  const boxShadow = getBoxShadow(active, hovered || false, disabled)

  const handleTarget = () => {
    if (disabled) return
    setActive(true)
    target({
      filter: (id: string) => {
        return options.includes(id)
      },
      callback: (o: string | null) => {
        setActive(false)
        onSelect(o)
        if (commit) commitUpdate()
      },
    })
  }

  return (
    <TooltipWrapper
      tooltipKey={tooltipKey}
      tooltipDirection={tooltipDirection}
      tooltipString={name}
    >
      <div
        ref={inputRef}
        style={{
          width: width,
          height: 24,
          paddingLeft: 4,
          paddingRight: 8,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: 4,
          borderRadius: 4,
          opacity,
          background,
          boxShadow,
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleTarget}
      >
        <TargetButton
          icon={activeIcon}
          color={iconColor}
          setHover={setHoverIcon}
        />
        <ContentContainer hovered={hovered || false}>
          <Text maxWidth={width - 48} color={textColor}>
            {textContent}
          </Text>
        </ContentContainer>
      </div>
    </TooltipWrapper>
  )
}

function TooltipWrapper({
  tooltipKey,
  tooltipDirection,
  tooltipString,
  children,
}: {
  tooltipKey?: tooltipKey
  tooltipDirection?: tooltipDirection
  tooltipString?: string
  children?: React.ReactNode
}) {
  if (tooltipKey || tooltipString) {
    return (
      <Tooltip
        tooltipKey={tooltipKey}
        direction={tooltipDirection || 'left'}
        tooltipString={tooltipString}
      >
        {children}
      </Tooltip>
    )
  } else {
    return <>{children}</>
  }
}

function TargetButton({
  icon,
  color,
  setHover,
}: {
  icon: IconKey
  color: string
  setHover: (hover: boolean) => void
}) {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon size={16} icon={icon} color={color} />
    </div>
  )
}

function ContentContainer({
  children,
  hovered,
}: {
  children?: React.ReactNode
  hovered: boolean
}) {
  return (
    <div
      style={{
        width: '100%',
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: hovered ? 'space-between' : 'start',
      }}
    >
      {children}
    </div>
  )
}

function getIcon(icon: IconKey, active: boolean, hover: boolean) {
  return active || hover ? 'Target' : icon
}

function getIconColor(active: boolean, hover: boolean) {
  return active || hover ? DesignColor('inputHighlight') : DesignColor('text2')
}

function getTextContent(
  name: string | null,
  active: boolean,
  placeholder?: string
) {
  if (active) return placeholder ? placeholder : `Select`
  if (name) return name
  if (placeholder) return placeholder
  return `Select`
}

function getTextColor(name: string | null, active: boolean) {
  if (active || name) return DesignColor('text1')
  return DesignColor('text2')
}

function getOpacity(disabled: boolean) {
  return disabled ? 0.4 : 1
}

function getBackground(active: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (active) return DesignColor('inputTint')
  return 'none'
}

function getBoxShadow(active: boolean, hover: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (active || hover)
    return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
}
