import { HashingService } from './hash'
import { HTTPService } from './http'
import {
  RequestLogin,
  RequestUserCreate,
  RequestUserUpdate,
  AuthToken,
  UserInfo,
  RequestReset,
} from './types'

interface AuthService {
  setToken(token: string): void
  getToken(): string
}

export class UserService {
  private httpService: HTTPService
  private authService: AuthService
  private hashingService: HashingService

  constructor(
    httpService: HTTPService,
    authService: AuthService,
    hashingService: HashingService
  ) {
    this.httpService = httpService
    this.authService = authService
    this.hashingService = hashingService
  }

  /**
   * Logs in the user.
   * @throws {AxiosError} Throws an error if the login request fails.
   */
  async login(request: RequestLogin): Promise<void> {
    const hash = await this.hashingService.hash(request.password)
    return this.httpService
      .request<AuthToken>('/api/auth/login', 'POST', {
        email: request.email,
        password: hash,
      })
      .then((response: AuthToken) => {
        this.authService.setToken(response.token)
      })
  }

  async reset(request: RequestReset): Promise<void> {
    return this.httpService.request<void>('/api/auth/reset', 'POST', request)
  }

  async createAccount(request: RequestUserCreate): Promise<void> {
    const hash = await this.hashingService.hash(request.password)
    return this.httpService
      .request<AuthToken>('/api/user', 'POST', {
        ...request,
        password: hash,
      })
      .then((response: AuthToken) => {
        this.authService.setToken(response.token)
      })
  }

  async getUserInfo(): Promise<UserInfo> {
    const token = this.authService.getToken()
    return this.httpService.request<UserInfo>(
      '/api/user',
      'GET',
      {},
      { Authorization: `Bearer ${token}` }
    )
  }

  async updateUserInfo(request: RequestUserUpdate): Promise<UserInfo> {
    const token = this.authService.getToken()
    return this.httpService.request<UserInfo>('/api/user', 'PUT', request, {
      Authorization: `Bearer ${token}`,
    })
  }

  async updateUserPassword(
    token: string,
    email: string,
    password: string
  ): Promise<void> {
    const hash = await this.hashingService.hash(password)
    await this.httpService.request<void>(
      '/api/user/password',
      'PUT',
      { password: hash },
      { Authorization: `Bearer ${token}` }
    )

    return this.httpService
      .request<AuthToken>('/api/auth/login', 'POST', {
        email: email,
        password: hash,
      })
      .then((response: AuthToken) => {
        this.authService.setToken(response.token)
      })
  }
}
