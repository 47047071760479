import {
  KeyDownListener,
  KeyUpListener,
  MouseDownListener,
  MouseMoveListener,
  MouseUpListener,
} from 'application/browser'
import { ActiveAction } from './active'
import { ActionCoordinator } from './coordinator'
import { EditorCursor } from 'editor/cursor/cursor'

export class ActionService
  implements
    MouseUpListener,
    MouseDownListener,
    MouseMoveListener,
    KeyDownListener,
    KeyUpListener
{
  private activeAction: ActiveAction
  private coordinator: ActionCoordinator
  private cursor: EditorCursor

  constructor(
    activeAction: ActiveAction,
    coordinator: ActionCoordinator,
    cursor: EditorCursor
  ) {
    this.activeAction = activeAction
    this.coordinator = coordinator
    this.cursor = cursor
  }

  handleMouseDown = (e: MouseEvent): void => {
    const handler = this.activeAction.get()
    if (handler !== null && handler.onMouseDown) {
      const result = handler.onMouseDown(e)
      if (result.done) this.activeAction.reset()
      if (result.nextAction) this.coordinator.nextAction(result.nextAction)
      else if (result.passthrough) this.coordinator.mouseDown(e)
      this.setCursor()
    } else if (handler === null) {
      this.coordinator.mouseDown(e)
    }
  }

  handleMouseMove = (e: MouseEvent): void => {
    const handler = this.activeAction.get()
    if (handler !== null && handler.onMouseMove) {
      const result = handler.onMouseMove(e)
      if (result.done) this.activeAction.reset()
      if (result.nextAction) this.coordinator.nextAction(result.nextAction)
      else if (result.passthrough) this.coordinator.mouseMove(e)
      this.setCursor()
    } else if (handler === null) {
      this.coordinator.mouseMove(e)
    }
  }

  handleMouseUp = (e: MouseEvent): void => {
    const handler = this.activeAction.get()
    if (handler !== null && handler.onMouseUp) {
      const result = handler.onMouseUp(e)
      if (result.done) this.activeAction.reset()
      if (result.nextAction) this.coordinator.nextAction(result.nextAction)
      else if (result.passthrough) this.coordinator.mouseUp(e)
      this.setCursor()
    } else if (handler === null) {
      this.coordinator.mouseUp(e)
    }
  }

  handleKeyDown = (e: KeyboardEvent): void => {
    const handler = this.activeAction.get()
    if (handler !== null && handler.onKeyDown) {
      const result = handler.onKeyDown(e)
      if (result.done) this.activeAction.reset()
      if (result.nextAction) this.coordinator.nextAction(result.nextAction)
      else if (result.passthrough) this.coordinator.keyDown(e)
      this.setCursor()
    } else if (handler === null) {
      this.coordinator.keyDown(e)
    }
  }

  handleKeyUp = (e: KeyboardEvent): void => {
    const handler = this.activeAction.get()
    if (handler !== null && handler.onKeyUp) {
      const result = handler.onKeyUp(e)
      if (result.done) this.activeAction.reset()
      if (result.nextAction) this.coordinator.nextAction(result.nextAction)
      else if (result.passthrough) this.coordinator.keyUp()
      this.setCursor()
    } else if (handler === null) {
      this.coordinator.keyUp()
    }
  }

  handlePaste = (e: ClipboardEvent): void => {
    const handler = this.activeAction.get()
    if (handler !== null && handler.onPaste) {
      const result = handler.onPaste(e)
      if (result.done) this.activeAction.reset()
      if (result.nextAction) this.coordinator.nextAction(result.nextAction)
      else if (result.passthrough) this.coordinator.paste(e)
      this.setCursor()
    } else if (handler === null) {
      this.coordinator.paste(e)
    }
  }

  handleDrop = (e: DragEvent): void => {
    const handler = this.activeAction.get()
    if (handler !== null && handler.onDrop) {
      const result = handler.onDrop(e)
      if (result.done) this.activeAction.reset()
      if (result.nextAction) this.coordinator.nextAction(result.nextAction)
      else if (result.passthrough) this.coordinator.drop(e)
      this.setCursor()
    } else if (handler === null) {
      this.coordinator.drop(e)
    }
  }

  private setCursor = (): void => {
    const actionHandler = this.activeAction.get()
    if (actionHandler && actionHandler.getCursor) {
      this.cursor.set(actionHandler.getCursor())
    }
  }
}
