import DropdownDrawerContainer from 'components/Library/Containers/DropdownDrawerContainer/DropdownDrawerContainer'
import AnimationEffectPopupRow from './AnimationEffectPopupRow'
import { useRef, useState } from 'react'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import { panelPosition } from 'components/Library/Containers/constants'
import useClosePopup from 'hooks/ui/useClosePopup'

export type AnimationEffectPopupOption<T> = {
  value: T
  text: string
}

interface AnimationEffectPopupProps<T> {
  close: () => void

  panelRef: React.RefObject<HTMLDivElement>

  options: AnimationEffectPopupOption<T>[]
  select: (value: T) => void
}

export default function AnimationEffectPopup<T>({
  close,
  panelRef,
  options,
  select,
}: AnimationEffectPopupProps<T>) {
  const drawerRef = useRef<HTMLDivElement>(null)
  const [hoveredRow, setHoveredRow] = useState<number>(-1)
  useClosePopup({
    ref: drawerRef,
    close: close,
    ignorePopup: false,
  })

  const position = getPosition(panelRef)

  return (
    <Portal>
      <DropdownDrawerContainer
        drawerRef={drawerRef}
        width={96}
        position={position}
        onMouseLeave={() => setHoveredRow(-1)}
      >
        {options.map((option, index) => (
          <AnimationEffectPopupRow
            key={index}
            width={96}
            title={option.text}
            hovered={hoveredRow === index}
            setHovered={(h) => (h ? setHoveredRow(index) : setHoveredRow(-1))}
            onClick={() => select(option.value)}
          />
        ))}
      </DropdownDrawerContainer>
    </Portal>
  )
}

function getPosition(
  dropdownRef: React.RefObject<HTMLDivElement>
): panelPosition {
  if (!dropdownRef.current) return { top: 0, left: 0 }
  const boundingBox = dropdownRef.current.getBoundingClientRect()
  return {
    position: 'absolute',
    top: boundingBox.top + 8,
    right: 16,
  }
}
