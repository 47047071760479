import { ActionHandler } from '../types'
import { CommandHandler } from 'application/client'
import {
  NodeMoveAction,
  NodeReparentAction,
  OffsetMap,
  ParentMap,
} from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { MoveAction } from './action'
import { Point, Rectangle, pointInRectangle } from 'application/shapes'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { HapticAutolayoutChildLine } from 'editor/haptic/autolayoutChildLine'
import { MoveHapticAutolayoutLineCalculator } from './line'
import { PointAlignmentCalculator } from 'application/action'
import { AlignmentLinesCalculator } from 'application/action'
import { HapticAlignment } from 'editor/haptic/alignment'
import { HapticDistanceLines } from 'editor/haptic/distanceLines'
import { HapticNewParentWindow } from 'editor/haptic/newParentWindow'
import { AlignmentGapsCalculator } from 'application/action/alignment/gaps'
import { ReadOnlyDocument } from 'application/document'
import { HapticAutolayoutChildWindows } from 'editor/haptic/autolayoutChildWindows'
import { getClippedRectangle } from 'application/attributes'
import _ from 'lodash'
import { NodeAttributesAction } from 'application/action/attributes'

export class MoveActionFactory {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private commandHandler: CommandHandler
  private move: NodeMoveAction
  private reparent: NodeReparentAction
  private attributes: NodeAttributesAction
  private coordinates: CoordinatesConversion
  private alignmentLinesCalculator: AlignmentLinesCalculator
  private alignmentGapsCalculator: AlignmentGapsCalculator
  private alignmentCalculator: PointAlignmentCalculator

  private hapticAlignment: HapticAlignment
  private hapticAutolayoutChildren: HapticAutolayoutChildWindows
  private hapticLine: HapticAutolayoutChildLine
  private hapticDistance: HapticDistanceLines
  private hapticNewParent: HapticNewParentWindow
  private lineComputer: MoveHapticAutolayoutLineCalculator

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    commandHandler: CommandHandler,
    move: NodeMoveAction,
    reparent: NodeReparentAction,
    attributes: NodeAttributesAction,
    coordinates: CoordinatesConversion,
    alignmentLinesCalculator: AlignmentLinesCalculator,
    alignmentGapsCalculator: AlignmentGapsCalculator,
    align: PointAlignmentCalculator,

    hapticAlignment: HapticAlignment,
    hapticAutolayoutChildren: HapticAutolayoutChildWindows,
    hapticLine: HapticAutolayoutChildLine,
    hapticDistance: HapticDistanceLines,
    hapticNewParent: HapticNewParentWindow,
    lineComputer: MoveHapticAutolayoutLineCalculator
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.commandHandler = commandHandler
    this.move = move
    this.reparent = reparent
    this.attributes = attributes
    this.coordinates = coordinates
    this.alignmentLinesCalculator = alignmentLinesCalculator
    this.alignmentGapsCalculator = alignmentGapsCalculator
    this.alignmentCalculator = align

    this.hapticAlignment = hapticAlignment
    this.hapticAutolayoutChildren = hapticAutolayoutChildren
    this.hapticLine = hapticLine
    this.hapticDistance = hapticDistance
    this.hapticNewParent = hapticNewParent
    this.lineComputer = lineComputer
  }

  create = (point: Point, duplicate: boolean): ActionHandler => {
    const duplicatedIds = duplicate ? this.duplicate() : []
    this.hapticDistance.setDuplicatedTargets(duplicatedIds)

    return new MoveAction(
      this.document,
      this.documentSelection,
      this.commandHandler,
      this.move,
      this.reparent,
      this.attributes,
      this.coordinates,
      this.alignmentCalculator,
      this.alignmentLinesCalculator,
      this.alignmentGapsCalculator,
      this.hapticAlignment,
      this.hapticAutolayoutChildren,
      this.hapticLine,
      this.hapticNewParent,
      this.lineComputer,
      this.createOffsetMap(point),
      this.createParentMap(),
      this.createOffsetWindow(point),
      this.getParentIds(),
      [...duplicatedIds, ...this.getFilteredParentIds(point)]
    )
  }

  private createOffsetMap = (point: Point): OffsetMap => {
    const nodes = this.documentSelection.getSelected()

    const offsetMap: OffsetMap = {}
    for (const node of nodes) {
      const x = node.getBaseAttribute('x') || 0
      const y = node.getBaseAttribute('y') || 0
      offsetMap[node.getId()] = {
        x: x - point.x,
        y: y - point.y,
      }
    }
    return offsetMap
  }

  private createParentMap = (): ParentMap => {
    const nodes = this.documentSelection.getSelected()

    const parentMap: ParentMap = {}
    for (const node of nodes) {
      parentMap[node.getId()] = node.getParent()
    }
    return parentMap
  }

  private createOffsetWindow = (point: Point): Rectangle => {
    let xMin = Infinity
    let yMin = Infinity
    let xMax = -Infinity
    let yMax = -Infinity

    const nodes = this.documentSelection.getSelected()
    for (const node of nodes) {
      const x = node.getBaseAttribute('x')
      const y = node.getBaseAttribute('y')
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      xMin = Math.min(xMin, x)
      yMin = Math.min(yMin, y)
      xMax = Math.max(xMax, x + w)
      yMax = Math.max(yMax, y + h)
    }

    return {
      x: xMin - point.x,
      y: yMin - point.y,
      w: xMax - xMin,
      h: yMax - yMin,
    }
  }

  private duplicate = (): string[] => {
    const ids = this.documentSelection.getSelected().map((node) => node.getId())
    this.commandHandler.handle({
      type: 'duplicateSelectedNodes',
      params: { addOffset: false },
    })
    return ids
  }

  private getParentIds = (): string[] => {
    return _.uniq(
      this.documentSelection
        .getSelected()
        .map((node) => node.getParent())
        .filter((id) => id) as string[]
    )
  }

  private getFilteredParentIds = (point: Point): string[] => {
    const parentIds = this.documentSelection
      .getSelected()
      .map((node) => node.getParent())
      .filter((id) => id) as string[]
    if (parentIds.length === 0) return []
    if (!parentIds.every((id) => id === parentIds[0])) return []

    const parent = this.document.getNode(parentIds[0])
    if (!parent) return []

    const parentChain = this.document.getAncestors(parent)
    const parentChainIds = parentChain.map((node) => node.getId())

    const rectangle = getClippedRectangle(parent)
    return !pointInRectangle(point, rectangle) ? parentChainIds : []
  }
}
