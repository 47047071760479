import ToolbarTextButtonLarge from 'components/Library/Components/Toolbar/ToolbarTextButtonLarge/ToolbarTextButtonLarge'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function ExitPreviewButton() {
  const { handlers } = usePanelHelper(editor.getUI().getPreviewPanel())

  return (
    <ToolbarTextButtonLarge
      text="Exit Preview"
      onClick={() => handlers.setOpen(false)}
    />
  )
}
