import {
  AttributeFill,
  AttributeSizeAuto,
  AttributeTextAlign,
  StyleMap,
  getTextAttributes,
} from 'application/attributes'
import { StyleAttributePanel } from '../styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import {
  TextPanel,
  TextPanelAttributes,
  TextPanelHandlers,
  TextPanelKeys,
  TextPanelState,
} from './interface'
import _ from 'lodash'
import {
  Style,
  Styles,
  TextEditorStyles,
  attributesToTextEditorState,
  textEditorStateToAttributes,
} from 'application/textEditor'
import { FontKey, FontWeight, ReadOnlyFontDataMap } from 'application/text'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CommandHandler } from 'application/client'

export class TextPanelDefault
  extends StyleAttributePanel<TextPanelState, TextPanelHandlers, TextPanelKeys>
  implements TextPanel
{
  private fontMap: ReadOnlyFontDataMap
  private styles: Styles

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    fontMap: ReadOnlyFontDataMap
  ) {
    super(commandHandler, action, document, documentSelection)
    this.fontMap = fontMap
    this.styles = new TextEditorStyles(fontMap)
  }

  getSettings(): TextPanelState {
    return {
      attributes: this.getAttributes(),
    }
  }

  getHandlers(): TextPanelHandlers {
    return {
      getFontData: this.fontMap.getFontData,
      setFontFamily: this.setFontFamily,
      setFontWeight: this.setFontWeight,
      setFontSize: this.setFontSize,
      slideFontSize: this.slideFontSize,
      setColor: this.setColor,
      clearMixedColor: this.clearMixedColor,
      setLetterSpacing: this.setLetterSpacing,
      slideLetterSpacing: this.slideLetterSpacing,
      setLineHeight: this.setLineHeight,
      slideLineHeight: this.slideLineHeight,
      setAlignment: this.setAlignment,
      setSizeAuto: this.setSizeAuto,
    }
  }

  private getAttributes = (): TextPanelAttributes => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return null

    const attributes = this.getNodeAttributes(nodes[0])
    if (!attributes) return null

    for (let i = 1; i < nodes.length; i++) {
      const nodeAttributes = this.getNodeAttributes(nodes[i])
      if (!nodeAttributes) return null

      for (const key in attributes) {
        const typedKey = key as TextPanelKeys
        if (attributes[typedKey] === 'Mixed') continue
        if (_.isEqual(attributes[typedKey], nodeAttributes[typedKey])) continue
        attributes[typedKey] = 'Mixed'
      }
    }

    return attributes
  }

  private setFontFamily = (fontFamily: FontKey): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { fontFamily })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private setFontWeight = (fontWeight: FontWeight): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { fontWeight })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private setFontSize = (fontSize: number): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { fontSize })
      this.setOne(node.getId(), attributes)
    }
  }

  private slideFontSize = (fontSize: number): void => {
    for (const node of this.getNodes()) {
      const current = this.getNodeAttributes(node)
      if (!current) return

      const currentFontSize = current['text.font.size']
      if (currentFontSize === undefined || currentFontSize === 'Mixed') return
      const attributes = this.setStyles(node, {
        fontSize: Math.max(currentFontSize + fontSize, 1),
      })
      this.setOne(node.getId(), attributes)
    }
  }

  private setColor = (fill: AttributeFill): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { fill })
      this.setOne(node.getId(), attributes)
    }
  }

  private clearMixedColor = (): void => {
    let firstColor: AttributeFill | undefined = undefined
    for (const node of this.getNodes()) {
      const current = this.getNodeAttributes(node)
      if (!current) return
      const color = current['text.color']
      if (color === undefined || color === 'Mixed') continue
      firstColor = color
      break
    }
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { fill: firstColor })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private setLetterSpacing = (letterSpacing: number): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { letterSpacing })
      this.setOne(node.getId(), attributes)
    }
  }

  private slideLetterSpacing = (letterSpacing: number): void => {
    for (const node of this.getNodes()) {
      const current = this.getNodeAttributes(node)
      if (!current) return

      const currentValue = current['text.letterSpacing']
      if (currentValue === undefined || currentValue === 'Mixed') return
      const attributes = this.setStyles(node, {
        letterSpacing: Math.max(currentValue + letterSpacing, -100),
      })
      this.setOne(node.getId(), attributes)
    }
  }

  private setLineHeight = (lineHeight: number): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { lineHeight })
      this.setOne(node.getId(), attributes)
    }
  }

  private slideLineHeight = (lineHeight: number): void => {
    for (const node of this.getNodes()) {
      const current = this.getNodeAttributes(node)
      if (!current) return

      const currentValue = current['text.lineHeight']
      if (currentValue === undefined || currentValue === 'Mixed') return
      const attributes = this.setStyles(node, {
        lineHeight: Math.max(currentValue + lineHeight, -100),
      })
      this.setOne(node.getId(), attributes)
    }
  }

  private setAlignment = (align: AttributeTextAlign): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles(node, { align })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private setSizeAuto = (w: AttributeSizeAuto, h: AttributeSizeAuto): void => {
    for (const node of this.getNodes()) {
      const size: Partial<StyleMap> = {
        'size.w.auto': w,
        'size.h.auto': h,
      }
      switch (w) {
        case 'fixed':
          size['size.w'] = node.getBaseAttribute('w')
          break
        case 'hug':
          size['size.w'] = undefined
          break
      }
      switch (h) {
        case 'fixed':
          size['size.h'] = node.getBaseAttribute('h')
          break
        case 'hug':
          size['size.h'] = undefined
          break
      }
      this.setOne(node.getId(), size)
    }
    this.commit()
  }

  private getNodeAttributes = (
    node: ReadOnlyNode
  ): TextPanelAttributes | null => {
    return getTextAttributes(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
  }

  private setStyles = (
    node: ReadOnlyNode,
    styles: Partial<Style>
  ): Partial<StyleMap> => {
    const state = attributesToTextEditorState(
      node.getBaseAttributes(),
      node.getStyleAttributes()
    )
    if (!state) return {}

    this.styles.applyAll(state.content, styles)

    const { style } = textEditorStateToAttributes(state)
    const styleMap: Partial<StyleMap> = {}
    if (styles.align !== undefined) {
      styleMap['text.align'] = style['text.align']
    }
    if (styles.fill !== undefined) {
      styleMap['text.color'] = style['text.color']
    }
    if (styles.fontFamily !== undefined) {
      styleMap['text.font.family'] = style['text.font.family']
      styleMap['text.font.weight'] = style['text.font.weight']
    }
    if (styles.fontWeight !== undefined) {
      styleMap['text.font.weight'] = style['text.font.weight']
    }
    if (styles.fontSize !== undefined) {
      styleMap['text.font.size'] = style['text.font.size']
    }
    if (styles.letterSpacing !== undefined) {
      styleMap['text.letterSpacing'] = style['text.letterSpacing']
    }
    if (styles.lineHeight !== undefined) {
      styleMap['text.lineHeight'] = style['text.lineHeight']
    }
    return styleMap
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node) => node.getBaseAttribute('type') === 'text'
  }
}
