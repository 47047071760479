import { AttributeType } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type FilterBlurPanelKeys = 'filter.blur.radius'

export interface FilterBlurPanelState {
  radius: number | 'Mixed'
  mode: 'add' | 'remove' | 'disabled'
}

export interface FilterBlurPanelHandlers {
  addFilter: () => void
  removeFilter: () => void
  setRadius: (value: number) => void
  slideRadius: (value: number) => void
}

export class FilterBlurPanel extends StyleAttributePanel<
  FilterBlurPanelState,
  FilterBlurPanelHandlers,
  FilterBlurPanelKeys
> {
  getSettings(): FilterBlurPanelState {
    return {
      radius: this.getRadius(),
      mode: this.getMode(),
    }
  }

  getHandlers(): FilterBlurPanelHandlers {
    return {
      addFilter: this.addFilter,
      removeFilter: this.removeFilter,
      setRadius: this.setRadius,
      slideRadius: this.slideRadius,
    }
  }

  private getRadius = (): number | 'Mixed' => {
    const nodes = this.getNodes().filter(this.nodeHasFilterBlur)
    if (nodes.length === 0) return 'Mixed'

    const radius = nodes[0].getStyleAttribute('filter.blur.radius')
    if (radius === undefined) return 'Mixed'

    for (const node of nodes) {
      if (node.getStyleAttribute('filter.blur.radius') !== radius)
        return 'Mixed'
    }

    return radius
  }

  private getMode = (): 'add' | 'remove' | 'disabled' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'disabled'

    const filtered = nodes.filter(this.nodeHasFilterBlur)
    return filtered.length === 0 ? 'add' : 'remove'
  }

  private addFilter = (): void => {
    this.setMulti({
      'filter.mode': 'display',
      'filter.blur.radius': 5,
    })
    this.commit()
  }

  private removeFilter = (): void => {
    const nodes = this.getNodes().filter(this.nodeHasFilterBlur)
    for (const node of nodes) {
      this.setOne(node.getId(), {
        'filter.mode': 'none',
        'filter.blur.radius': undefined,
      })
    }
    this.commit()
  }

  private setRadius = (value: number): void => {
    const nodes = this.getNodes().filter(this.nodeHasFilterBlur)
    for (const node of nodes) {
      this.setOne(node.getId(), { 'filter.blur.radius': value })
    }
  }

  private slideRadius = (value: number): void => {
    const nodes = this.getNodes().filter(this.nodeHasFilterBlur)
    for (const node of nodes) {
      this.slideOne(node.getId(), 'filter.blur.radius', value)
    }
  }

  private nodeHasFilterBlur = (node: ReadOnlyNode): boolean => {
    return node.getStyleAttribute('filter.mode') === 'display'
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 20
  }
}

const allowedTypes: AttributeType[] = ['frame', 'rectangle', 'ellipse']
