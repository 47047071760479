import {
  AttributeAutolayoutAlign,
  AttributeAutolayoutDirection,
  AttributeType,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { AddAutolayoutAction } from 'editor/action/node/autolayout'
import { CommandHandler } from 'application/client'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'

type AutolayoutPanelKeys =
  | 'autolayout.direction'
  | 'autolayout.align.main'
  | 'autolayout.align.counter'
  | 'autolayout.align.counter'
  | 'autolayout.gap'
  | 'padding.left'
  | 'padding.right'
  | 'padding.top'
  | 'padding.bottom'

type AutolayoutPanelAttributes = Pick<
  MultiselectStyleMap,
  AutolayoutPanelKeys
> | null

export type PaddingMode = 'left' | 'right' | 'top' | 'bottom'

export interface AutolayoutPanelState {
  attributes: AutolayoutPanelAttributes
  mode: 'add' | 'remove' | 'disabled'
}

export interface AutolayoutPanelHandlers {
  activate: () => void
  deactivate: () => void
  setDirection: (value: AttributeAutolayoutDirection) => void
  setCounter: (value: AttributeAutolayoutAlign) => void
  setAlign: (
    main: AttributeAutolayoutAlign,
    counter: AttributeAutolayoutAlign
  ) => void
  setGap: (value: number) => void
  slideGap: (value: number) => void
  setPadding: (value: number, mode: PaddingMode, split: boolean) => void
  collapsePadding: () => void
  slidePadding: (value: number, mode: PaddingMode, split: boolean) => void
}

export class AutolayoutPanel extends StyleAttributePanel<
  AutolayoutPanelState,
  AutolayoutPanelHandlers,
  AutolayoutPanelKeys
> {
  private addAutolayoutAction: AddAutolayoutAction

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    addAutolayoutAction: AddAutolayoutAction
  ) {
    super(commandHandler, action, document, documentSelection)
    this.addAutolayoutAction = addAutolayoutAction
  }

  getSettings(): AutolayoutPanelState {
    return {
      attributes: this.attributes,
      mode: this.getMode(),
    }
  }

  getHandlers(): AutolayoutPanelHandlers {
    return {
      activate: this.activate,
      deactivate: this.deactivate,
      setDirection: this.setDirection,
      setCounter: this.setCounter,
      setAlign: this.setAlign,
      setGap: this.setGap,
      slideGap: this.slideGap,
      setPadding: this.setPadding,
      collapsePadding: this.collapsePadding,
      slidePadding: this.slidePadding,
    }
  }

  private activate = (): void => {
    this.addAutolayoutAction.addAutolayout()
  }

  private deactivate = (): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      if (!allowedTypes.includes(node.getBaseAttribute('type'))) continue
      this.setOne(node.getId(), { 'autolayout.mode': 'none' })
    }

    this.commit()
  }

  private setDirection = (value: AttributeAutolayoutDirection): void => {
    const nodes = this.getNodes()

    for (const node of nodes) {
      const direction = node.getStyleAttribute('autolayout.direction')
      if (direction === value) return

      const main = node.getStyleAttribute('autolayout.align.main')
      const counter = node.getStyleAttribute('autolayout.align.counter')

      if (
        counter === 'spaced' ||
        (value === 'wrap' && direction === 'row') ||
        (value === 'row' && direction === 'wrap')
      ) {
        this.setOne(node.getId(), {
          'autolayout.direction': value,
        })
      } else {
        this.setOne(node.getId(), {
          'autolayout.direction': value,
          'autolayout.align.main': counter,
          'autolayout.align.counter': main,
        })
      }
    }

    this.commit()
  }

  private setCounter = (value: AttributeAutolayoutAlign): void => {
    this.setMulti({ 'autolayout.align.counter': value })
    this.commit()
  }

  private setAlign = (
    main: AttributeAutolayoutAlign,
    counter: AttributeAutolayoutAlign
  ): void => {
    this.setMulti({
      'autolayout.align.main': main,
      'autolayout.align.counter': counter,
    })
    this.commit()
  }

  private setGap = (value: number): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      const gap = node.getStyleAttribute('autolayout.gap')
      if (gap === value) return
      this.setOne(node.getId(), { 'autolayout.gap': value })
      if (node.getStyleAttribute('autolayout.align.counter') === 'spaced') {
        this.setOne(node.getId(), { 'autolayout.align.counter': 'center' })
      }
    }
  }

  private slideGap = (value: number): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.slideOne(node.getId(), 'autolayout.gap', value)
      if (node.getStyleAttribute('autolayout.align.counter') === 'spaced') {
        this.setOne(node.getId(), { 'autolayout.align.counter': 'center' })
      }
    }
  }

  private setPadding = (
    value: number,
    mode: PaddingMode,
    split: boolean
  ): void => {
    const nodes = this.getNodes()

    for (const node of nodes) {
      let left = node.getStyleAttribute('padding.left') || 0
      let right = node.getStyleAttribute('padding.right') || 0
      let top = node.getStyleAttribute('padding.top') || 0
      let bottom = node.getStyleAttribute('padding.bottom') || 0

      switch (mode) {
        case 'left':
          left = value
          break
        case 'right':
          right = value
          break
        case 'top':
          top = value
          break
        case 'bottom':
          bottom = value
          break
      }

      this.setOne(node.getId(), {
        'padding.left': left,
        'padding.right': split ? right : left,
        'padding.top': top,
        'padding.bottom': split ? bottom : top,
      })
    }
  }

  private collapsePadding = (): void => {
    const nodes = this.getNodes()

    for (const node of nodes) {
      const left = node.getStyleAttribute('padding.left') || 0
      const right = node.getStyleAttribute('padding.right') || 0
      const top = node.getStyleAttribute('padding.top') || 0
      const bottom = node.getStyleAttribute('padding.bottom') || 0
      const maxH = Math.max(left, right)
      const maxV = Math.max(top, bottom)

      this.setOne(node.getId(), {
        'padding.left': maxH,
        'padding.right': maxH,
        'padding.top': maxV,
        'padding.bottom': maxV,
      })
    }

    this.commit()
  }

  private slidePadding = (
    value: number,
    mode: 'left' | 'right' | 'top' | 'bottom',
    split: boolean
  ): void => {
    if (split) {
      this.slideMulti(`padding.${mode}`, value)
    } else {
      switch (mode) {
        case 'top':
          this.slideMulti('padding.top', value)
          this.slideMulti('padding.bottom', value)
          break
        case 'left':
          this.slideMulti('padding.left', value)
          this.slideMulti('padding.right', value)
          break
      }
    }
  }

  private getMode = (): 'add' | 'remove' | 'disabled' => {
    const nodes = this.getNodes()
    const allowed = nodes.filter((n) =>
      allowedTypes.includes(n.getBaseAttribute('type'))
    )
    if (allowed.length !== nodes.length) {
      return nodes.length >= 2 ? 'add' : 'disabled'
    }
    for (const node of allowed) {
      if (node.getStyleAttribute('autolayout.mode') !== 'flex') return 'add'
    }
    return 'remove'
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 1_000
  }
}

const allowedTypes: AttributeType[] = ['page', 'frame']
