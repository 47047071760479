import RightClickRowButton from 'components/Library/Components/Button/RightClickRowButton/RightClickRowButton'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { getHotkeyPrefix } from '../Elements/ElementsRightClickMenu'

interface EffectsRightClickMenuProps {
  eventId: string
  close: () => void
}

export default function EffectsRightClickMenu({
  eventId,
  close,
}: EffectsRightClickMenuProps) {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getInteractionAnimationEffectPanel()
  )
  if (!settings) return <></>

  return (
    <PanelSection width={'hug'} bottom={true}>
      <RightClickRowButton
        text={'Delete'}
        subtext={'⌫'}
        onClick={() => {
          handlers.deleteEvent(eventId)
          close()
        }}
      />
      <RightClickRowButton
        text={'Duplicate'}
        subtext={getHotkeyPrefix() + 'D'}
        onClick={() => {
          handlers.duplicateEvent(eventId)
          close()
        }}
      />
    </PanelSection>
  )
}
