import { NodeAttributesAction } from 'application/action/attributes'
import {
  MultiselectStyleMap,
  SelectorName,
  StyleMap,
} from 'application/attributes'
import { Command, CommandHandler } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { AttributeStoreListener } from 'editor/attributes/types'
import _ from 'lodash'

export type PanelListener<T> = (settings: T) => void

export abstract class StyleAttributePanel<S, H, A extends keyof StyleMap>
  implements AttributeStoreListener
{
  private action: NodeAttributesAction
  private commandHandler: CommandHandler
  protected document: ReadOnlyDocument
  protected documentSelection: ReadOnlyDocumentSelection

  protected attributes: Pick<MultiselectStyleMap, A> | null

  protected listeners: { [key: string]: PanelListener<S> }
  protected state: S

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection
  ) {
    this.commandHandler = commandHandler
    this.action = action
    this.document = document
    this.documentSelection = documentSelection

    this.attributes = null

    this.listeners = {}
    this.state = {} as S
  }

  getSettings(): S {
    return {} as S
  }

  getHandlers(): H {
    return {} as H
  }

  subscribe(key: string, listener: PanelListener<S>): void {
    this.listeners[key] = listener
    listener(this.getSettings())
  }

  unsubscribe(key: string): void {
    delete this.listeners[key]
  }

  onAttributes(): void {
    this.attributes = this.getMultiselect(this.getNodes())
    this.notifyListeners()
  }

  protected setOne(
    id: string,
    attributes: Partial<StyleMap>,
    selector?: SelectorName
  ): void {
    this.action.setStyleAttributes([id], attributes, selector)
  }

  protected setMulti = (
    attributes: Partial<StyleMap>,
    selector?: SelectorName
  ): void => {
    const ids = this.getNodes().map((node) => node.getId())
    this.action.setStyleAttributes(ids, attributes, selector)
  }

  protected slideOne = (
    id: string,
    key: keyof StyleMap,
    value: number
  ): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const current = node.getStyleAttribute(key)
    if (typeof current !== 'number') return

    const min = this.getSlideMin(key)
    const max = this.getSlideMax(key)
    const newValue = Math.min(max, Math.max(min, current + value))
    this.setOne(id, { [key]: newValue })
  }

  protected slideMulti = (key: keyof StyleMap, value: number): void => {
    this.getNodes().forEach((n) => this.slideOne(n.getId(), key, value))
  }

  protected getSlideMin = (key: keyof StyleMap): number => {
    switch (key) {
      default:
        return 0
    }
  }

  protected getSlideMax = (key: keyof StyleMap): number => {
    switch (key) {
      default:
        return 100
    }
  }

  protected applyCommand = (command: Command): void => {
    this.commandHandler.handle(command)
  }

  protected commit = (): void => {
    this.commandHandler.handle({ type: 'commit' })
  }

  protected notifyListeners = (): void => {
    for (const key in this.listeners) {
      this.listeners[key](this.getSettings())
    }
  }

  protected getNodes = (): ReadOnlyNode[] => {
    const unfilteredPairs = this.getUnfilteredPairs()
    const predicate = this.getNodeFilterPredicate()

    return unfilteredPairs
      .filter(([node, parent]) => predicate(node, parent))
      .map(([node]) => node)
  }

  protected getNodesAndParents(): [ReadOnlyNode, ReadOnlyNode | null][] {
    const unfilteredPairs = this.getUnfilteredPairs()
    const predicate = this.getNodeFilterPredicate()

    return unfilteredPairs.filter(([node, parent]) => predicate(node, parent))
  }

  protected getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return () => true
  }

  private getUnfilteredPairs = (): [ReadOnlyNode, ReadOnlyNode | null][] => {
    const nodes = this.documentSelection.getSelected()
    if (nodes.length === 0) return []

    const pairs: [ReadOnlyNode, ReadOnlyNode | null][] = []
    for (const node of nodes) {
      const parent = this.document.getParent(node)
      pairs.push([node, parent || null])
    }

    return pairs
  }

  private getMultiselect = (
    nodes: ReadOnlyNode[]
  ): Pick<MultiselectStyleMap, A> | null => {
    if (nodes.length === 0) return null

    const map: Partial<Pick<MultiselectStyleMap, A>> = {}

    for (const node of nodes) {
      for (const key of Object.keys(node.getStyleAttributes()) as A[]) {
        const typedKey = key as A
        map[typedKey] = node.getStyleAttribute(typedKey)
      }
    }

    for (let i = 0; i < nodes.length; i++) {
      for (const key in map) {
        const typedKey = key as A
        if (!_.isEqual(map[typedKey], nodes[i].getStyleAttribute(typedKey))) {
          map[typedKey] = 'Mixed'
        }
      }
    }

    return map as Pick<MultiselectStyleMap, A>
  }
}
