import { AttributeType, MultiselectStyleMap } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type ClipContentPanelKeys = 'clip'

type ClipContentPanelAttributes = Pick<
  MultiselectStyleMap,
  ClipContentPanelKeys
> | null

export interface ClipContentPanelState {
  attributes: ClipContentPanelAttributes
}

export interface ClipContentPanelHandlers {
  setClip: (value: boolean) => void
}

export class ClipContentPanel extends StyleAttributePanel<
  ClipContentPanelState,
  ClipContentPanelHandlers,
  ClipContentPanelKeys
> {
  getSettings(): ClipContentPanelState {
    return {
      attributes: this.attributes,
    }
  }

  getHandlers(): ClipContentPanelHandlers {
    return {
      setClip: this.setClip,
    }
  }

  private setClip = (value: boolean): void => {
    this.setMulti({ clip: value })
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = ['frame', 'page']
