import {
  AttributeAnimationEffectEvent,
  AttributeAnimationEffectStyleMap,
} from 'application/attributes'
import TitleRow from 'components/Library/Components/Button/TitleRow/TitleRow'
import { dropdownOption } from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import AnimationEffectPopup, {
  AnimationEffectPopupOption,
} from 'components/Library/Components/Inputs/AnimationEffectPopup/AnimationEffectPopup'
import ColorInput from 'components/Library/Components/Inputs/ColorInput/ColorInput'
import DropdownNumberBlock from 'components/Library/Components/NumberBlock/DropdownNumberBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { EffectEventStyleOption } from 'editor/ui/interactions/interactionAnimationEffectPanel'
import { useRef, useState } from 'react'

interface EffectsProps {
  addStyle: (key: string, style: EffectEventStyleOption) => void
  removeStyle: (key: string, style: EffectEventStyleOption) => void
  getStyleOptions: (key: string) => EffectEventStyleOption[]

  event: AttributeAnimationEffectEvent
  updateEvent: (event: AttributeAnimationEffectEvent) => void
}

export default function EventEffects({
  addStyle,
  removeStyle,
  getStyleOptions,
  event,
  updateEvent,
}: EffectsProps) {
  const panelRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [hovered, setHovered] = useState<string | null>(null)

  const { style } = event

  const hasX = style['transform.x.unit'] !== undefined
  const hasY = style['transform.y.unit'] !== undefined
  const hasScale = style['transform.scale'] !== undefined
  const hasRotate = style['transform.rotate'] !== undefined
  const hasOpacity = style['opacity'] !== undefined
  const hasBackground = style['background.color'] !== undefined
  const hasBorder = style['border.color'] !== undefined
  const hasText = style['text.color'] !== undefined
  const hasAny = Object.keys(style).length > 0

  const xUnit = style['transform.x.unit'] || 'px'
  const yUnit = style['transform.y.unit'] || 'px'
  const x =
    xUnit === 'px'
      ? style['transform.x.pixel'] || 0
      : style['transform.x.percent'] || 0
  const y =
    yUnit === 'px'
      ? style['transform.y.pixel'] || 0
      : style['transform.y.percent'] || 0
  const scale =
    style['transform.scale'] === undefined ? 1 : style['transform.scale']
  const rotate =
    style['transform.rotate'] === undefined ? 0 : style['transform.rotate']
  const opacity = style['opacity'] === undefined ? 1 : style['opacity']
  const background = style['background.color'] || { r: 0, g: 0, b: 0, a: 1 }
  const border = style['border.color'] || { r: 0, g: 0, b: 0, a: 1 }
  const text = style['text.color'] || { r: 0, g: 0, b: 0, a: 1 }
  const options = getEffectPopupOptions(getStyleOptions(event.key))

  const updateStyle = (style: Partial<AttributeAnimationEffectStyleMap>) => {
    updateEvent({
      ...event,
      style: { ...event.style, ...style },
    })
  }

  const updateTransformUnit = (mode: 'x' | 'y', unit: 'px' | '%') => {
    switch (unit) {
      case 'px':
        updateStyle({
          [`transform.${mode}.unit`]: unit,
          [`transform.${mode}.pixel`]: 0,
        })
        break
      case '%':
        updateStyle({
          [`transform.${mode}.unit`]: unit,
          [`transform.${mode}.percent`]: 0,
        })
        break
    }
  }

  const updateTransformMove = (mode: 'x' | 'y', value: number) => {
    switch (mode) {
      case 'x':
        switch (xUnit) {
          case 'px':
            updateStyle({ 'transform.x.pixel': value })
            break
          case '%':
            updateStyle({ 'transform.x.percent': value })
            break
        }
        break
      case 'y':
        switch (yUnit) {
          case 'px':
            updateStyle({ 'transform.y.pixel': value })
            break
          case '%':
            updateStyle({ 'transform.y.percent': value })
            break
        }
        break
    }
  }

  return (
    <PanelSection sectionRef={panelRef}>
      <TitleRow
        title={'Effects'}
        active={hasAny}
        canActivate={options.length > 0}
        canDeactivate={false}
        sectionRef={panelRef}
        onClickRow={() => {
          if (hasAny) return
          setOpen(true)
        }}
        onClickIcon={() => setOpen(true)}
      />
      {(hasX || hasY) && (
        <PanelRow
          onMouseEnter={() => setHovered('move')}
          onMouseLeave={() => setHovered(null)}
        >
          <DropdownNumberBlock
            icon={'TransformX'}
            tooltipKey={'AnimationTransformX'}
            value={x}
            setValue={(v) => updateTransformMove('x', v)}
            increment={(v) => updateTransformMove('x', x + v)}
            decrement={(v) => updateTransformMove('x', x - v)}
            min={-10_000}
            max={10_000}
            unit={xUnit === '%' ? '%' : undefined}
            selectedOption={xUnit}
            dropdownOptions={transformUnitOptions}
            selectOption={(v) => updateTransformUnit('x', v)}
          />
          <DropdownNumberBlock
            icon={'TransformY'}
            tooltipKey={'AnimationTransformY'}
            value={y}
            setValue={(v) => updateTransformMove('y', v)}
            increment={(v) => updateTransformMove('y', y + v)}
            decrement={(v) => updateTransformMove('y', y - v)}
            min={-10_000}
            max={10_000}
            unit={yUnit === '%' ? '%' : undefined}
            selectedOption={yUnit}
            dropdownOptions={transformUnitOptions}
            selectOption={(v) => updateTransformUnit('y', v)}
          />
          {hovered === 'move' && (
            <IconBlock
              icon={'X'}
              onClick={() => removeStyle(event.key, 'move')}
            />
          )}
        </PanelRow>
      )}
      {hasScale && (
        <PanelRow
          onMouseEnter={() => setHovered('scale')}
          onMouseLeave={() => setHovered(null)}
        >
          <NumberBlock
            icon={'TransformScale'}
            tooltipKey={'AnimationScale'}
            value={scale * 100}
            setValue={(v) => updateStyle({ 'transform.scale': v / 100 })}
            increment={(v) =>
              updateStyle({ 'transform.scale': scale + v / 100 })
            }
            decrement={(v) =>
              updateStyle({ 'transform.scale': scale - v / 100 })
            }
            unit={'%'}
          />
          <PanelBlock />
          {hovered === 'scale' && (
            <IconBlock
              icon={'X'}
              onClick={() => removeStyle(event.key, 'scale')}
            />
          )}
        </PanelRow>
      )}
      {hasRotate && (
        <PanelRow
          onMouseEnter={() => setHovered('rotate')}
          onMouseLeave={() => setHovered(null)}
        >
          <NumberBlock
            icon={'TransformRotate'}
            tooltipKey={'AnimationRotation'}
            value={rotate}
            setValue={(v) => updateStyle({ 'transform.rotate': v })}
            increment={(v) => updateStyle({ 'transform.rotate': rotate + v })}
            decrement={(v) => updateStyle({ 'transform.rotate': rotate - v })}
            unit={'°'}
          />
          <PanelBlock />
          {hovered === 'rotate' && (
            <IconBlock
              icon={'X'}
              onClick={() => removeStyle(event.key, 'rotate')}
            />
          )}
        </PanelRow>
      )}
      {hasOpacity && (
        <PanelRow
          onMouseEnter={() => setHovered('opacity')}
          onMouseLeave={() => setHovered(null)}
        >
          <NumberBlock
            icon={'Opacity'}
            tooltipKey={'AnimationOpacity'}
            value={opacity}
            setValue={(v) => updateStyle({ opacity: v })}
            increment={(v) => updateStyle({ opacity: opacity + v })}
            decrement={(v) => updateStyle({ opacity: opacity - v })}
            unit={'%'}
            min={0}
          />
          <PanelBlock />
          {hovered === 'opacity' && (
            <IconBlock
              icon={'X'}
              onClick={() => removeStyle(event.key, 'opacity')}
            />
          )}
        </PanelRow>
      )}
      {hasBackground && (
        <PanelRow
          onMouseEnter={() => setHovered('background')}
          onMouseLeave={() => setHovered(null)}
        >
          <ColorInput
            color={background}
            updateColor={(color) => updateStyle({ 'background.color': color })}
            opacity={false}
          />
          <TextBlock width={96} mode={'label2'}>
            Background
          </TextBlock>
          {hovered === 'background' && (
            <IconBlock
              icon={'X'}
              onClick={() => removeStyle(event.key, 'background')}
            />
          )}
        </PanelRow>
      )}
      {hasBorder && (
        <PanelRow
          onMouseEnter={() => setHovered('border')}
          onMouseLeave={() => setHovered(null)}
        >
          <ColorInput
            color={border}
            updateColor={(color) => updateStyle({ 'border.color': color })}
            opacity={false}
          />
          <TextBlock width={96} mode={'label2'}>
            Border
          </TextBlock>
          {hovered === 'border' && (
            <IconBlock
              icon={'X'}
              onClick={() => removeStyle(event.key, 'border')}
            />
          )}
        </PanelRow>
      )}
      {hasText && (
        <PanelRow
          onMouseEnter={() => setHovered('text')}
          onMouseLeave={() => setHovered(null)}
        >
          <ColorInput
            color={text}
            updateColor={(color) => updateStyle({ 'text.color': color })}
            opacity={false}
          />
          <TextBlock width={96} mode={'label2'}>
            Text
          </TextBlock>
          {hovered === 'text' && (
            <IconBlock
              icon={'X'}
              onClick={() => removeStyle(event.key, 'text')}
            />
          )}
        </PanelRow>
      )}
      {open && (
        <AnimationEffectPopup
          close={() => setOpen(false)}
          options={options}
          panelRef={panelRef}
          select={(v) => {
            addStyle(event.key, v)
            setOpen(false)
          }}
        />
      )}
    </PanelSection>
  )
}

const transformUnitOptions: dropdownOption<'px' | '%'>[] = [
  {
    text: 'px',
    value: 'px',
  },
  {
    text: '%',
    value: '%',
  },
]

function getEffectPopupOptions(
  options: EffectEventStyleOption[]
): AnimationEffectPopupOption<EffectEventStyleOption>[] {
  return options.map((o) => {
    switch (o) {
      case 'move':
        return { value: 'move', text: 'Move' }
      case 'scale':
        return { value: 'scale', text: 'Scale' }
      case 'rotate':
        return { value: 'rotate', text: 'Rotate' }
      case 'opacity':
        return { value: 'opacity', text: 'Opacity' }
      case 'background':
        return { value: 'background', text: 'Background' }
      case 'border':
        return { value: 'border', text: 'Border Color' }
      default:
        return { value: 'text', text: 'Text Color' }
    }
  })
}
