import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { useState } from 'react'
import TitleButtonBlock from '../TitleButtonBlock/TitleButtonBlock'
import TitleIconBlock from '../../IconBlock/TitleIconBlock/TitleIconBlock'
import { tooltipKey } from 'assets/tooltips'
import { OverrideSectionKey } from 'editor/ui/styleOverrides'
import OverrideReset from '../../Inputs/OverrideReset/OverrideReset'
import useAction from 'hooks/editor/useAction'

interface TitleRowProps {
  title: string
  tooltipKey?: tooltipKey

  active?: boolean
  canActivate?: boolean
  canDeactivate?: boolean

  onClickRow?: () => void
  onClickIcon?: () => void
  onSelectDesign?: (id: string) => void

  sectionRef: React.RefObject<HTMLDivElement>

  overrideSectionKey?: OverrideSectionKey
  hasOverride?: boolean
}

export default function TitleRow({
  title,
  tooltipKey,
  active = false,
  canActivate = true,
  canDeactivate = false,
  onClickRow,
  onClickIcon,
  overrideSectionKey,
  hasOverride,
}: TitleRowProps) {
  const action = useAction()
  const [hover, setHover] = useState(false)

  return (
    <PanelRow
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {overrideSectionKey ? (
        <OverrideReset section={overrideSectionKey}>
          <TitleButtonBlock
            width={192}
            title={title}
            tooltipKey={tooltipKey}
            active={active || (hover && !action)}
            onClick={onClickRow}
            hasOverride={hasOverride}
          />
        </OverrideReset>
      ) : (
        <TitleButtonBlock
          width={192}
          title={title}
          tooltipKey={tooltipKey}
          active={active || (hover && !action)}
          onClick={onClickRow}
          hasOverride={hasOverride}
        />
      )}
      {(canActivate || canDeactivate) && (
        <TitleIconBlock
          icon={'Plus'}
          active={active || (hover && !action)}
          canDeactivate={canDeactivate}
          onClick={onClickIcon}
        />
      )}
    </PanelRow>
  )
}
