import { DesignColor } from 'themes'
import { Camera } from 'application/camera'
import { rgbaStringToRgba } from 'application/color'
import {
  Context,
  Renderable,
  WebglRectangle,
  WebglRectangleData,
  WebglText,
  WebglTextData,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import { FontLoaderInterface, TextContent, TextShaper } from 'application/text'
import { truncate } from 'application/math'

const labelPaddingV = 2
const labbelPaddingH = 4
const labelRounding = 4
const labelOffset = 8

export class WindowSizeDisplayTransformer {
  static transform = (
    context: Context,
    fontLoader: FontLoaderInterface,
    window: Rectangle,
    camera: Camera
  ): Renderable[] => {
    if (!this.isDisplayAllowed(window, camera)) return []
    return this.getLabel(context, fontLoader, camera, window)
  }

  private static getLabel = (
    context: Context,
    fontLoader: FontLoaderInterface,
    camera: Camera,
    window: Rectangle
  ): Renderable[] => {
    const labelText = this.getLabelText(window.w, window.h, camera.z)
    const rectangle = this.getLabelRectangle(
      window,
      camera.z,
      labelText,
      new TextShaper(fontLoader)
    )
    if (!rectangle) return []

    const labelTextData: WebglTextData = {
      ...rectangle,
      bb: rectangle,
      text: labelText,
    }

    const text = new WebglText(context, labelTextData, fontLoader)
    text.init()

    const backgroundRectangle = {
      x: rectangle.x - labbelPaddingH / camera.z,
      y: rectangle.y - labelPaddingV / camera.z,
      w: rectangle.w + (labbelPaddingH * 2) / camera.z,
      h: rectangle.h + (labelPaddingV * 2) / camera.z,
    }
    const labelBackground: WebglRectangleData = {
      ...backgroundRectangle,
      bb: backgroundRectangle,
      fill: {
        type: 'color',
        color: rgbaStringToRgba(DesignColor('inputHighlight')),
      },
      rounding: {
        tl: labelRounding / camera.z,
        tr: labelRounding / camera.z,
        br: labelRounding / camera.z,
        bl: labelRounding / camera.z,
      },
    }

    const background = new WebglRectangle(context, labelBackground)
    background.init()

    return [background, text]
  }

  private static getLabelRectangle = (
    window: Rectangle,
    z: number,
    textContent: TextContent,
    textShaper: TextShaper
  ): Rectangle | null => {
    const shapedText = textShaper.getShapedText(textContent)
    if (!shapedText) return null

    return {
      x: window.x + window.w / 2 - shapedText.w / 2,
      y: window.y + window.h + labelOffset / z,
      w: shapedText.w,
      h: shapedText.h,
    }
  }

  private static getLabelText = (
    w: number,
    h: number,
    z: number
  ): TextContent => {
    const displayText = `${truncate(w)} x ${truncate(h)}`
    return {
      content: displayText,
      contentStyles: new Array(displayText.length).fill(0),
      styles: {
        fontFamily: 'inter',
        fontWeight: 'medium',
        fontSize: 12 / z,
        align: 'left',
        fill: {
          type: 'color',
          color: rgbaStringToRgba(DesignColor('alignmentText')),
        },
        letterSpacing: 0,
        lineHeight: 0,
        italic: false,
        underline: false,
        link: null,
      },
      styleOverrides: {},
      lineTypes: [],
    }
  }

  private static isDisplayAllowed(window: Rectangle, camera: Camera): boolean {
    const { w, h } = window
    return w * camera.z >= 8 && h * camera.z >= 8
  }
}
