import {
  AttributeAnimation,
  AttributeAnimationTriggerType,
  AttributeType,
  MultiselectBaseMap,
  isInteractable,
} from 'application/attributes'
import { ReadOnlyNode } from 'application/node'
import { BaseAttributePanel } from '../baseAttributePanel'

type InteractionAnimationPanelKeys = 'animations'

type InteractionAnimationPanelAttributes = Pick<
  MultiselectBaseMap,
  InteractionAnimationPanelKeys
> | null

export interface InteractionAnimationPanelState {
  allowed: boolean
  options: string[]
  triggers: AttributeAnimationTriggerType[]
  attributes: InteractionAnimationPanelAttributes
}

export interface InteractionAnimationPanelHandlers {
  add: (type: AttributeAnimationTriggerType) => void
  remove: (index: number) => void
  update: (index: number, value: AttributeAnimation) => void
  copy: (index: number) => void
}

export class InteractionAnimationPanel extends BaseAttributePanel<
  InteractionAnimationPanelState,
  InteractionAnimationPanelHandlers,
  InteractionAnimationPanelKeys
> {
  getSettings(): InteractionAnimationPanelState {
    return {
      allowed: this.getAllowed(),
      options: this.getOptions(),
      triggers: this.getTriggers(),
      attributes: this.attributes,
    }
  }

  getHandlers(): InteractionAnimationPanelHandlers {
    return {
      add: this.add,
      remove: this.remove,
      update: this.update,
      copy: this.copy,
    }
  }

  private getAllowed = (): boolean => {
    return this.getNodes().length === 1
  }

  private getOptions = (): string[] => {
    return this.document
      .getNodes()
      .filter(
        (n) =>
          allowedTypes.includes(n.getBaseAttribute('type')) && isInteractable(n)
      )
      .map((n) => n.getId())
  }

  private getTriggers = (): AttributeAnimationTriggerType[] => {
    const nodes = this.getNodes()
    if (nodes.length !== 1) return []

    switch (nodes[0].getBaseAttribute('type')) {
      case 'page':
        return ['load']
      default:
        return [
          'click',
          'hover-in',
          'hover-out',
          'scroll-in',
          'scroll-out',
          'load',
        ]
    }
  }

  private add = (trigger: AttributeAnimationTriggerType): void => {
    if (this.attributes === null) return
    if (!this.getAllowed()) return

    const animations = this.attributes.animations
    if (animations === 'Mixed') return

    this.setMulti({
      animations: [...(animations || []), this.createNewAnimation(trigger)],
    })
    this.commit()
  }

  private remove = (index: number): void => {
    if (this.attributes === null) return
    if (!this.getAllowed()) return

    const animations = this.attributes.animations
    if (animations === undefined || animations === 'Mixed') return

    this.setMulti({
      animations: animations.filter((_, i) => i !== index),
    })
    this.commit()
  }

  private update = (index: number, value: AttributeAnimation): void => {
    if (this.attributes === null) return
    if (!this.getAllowed()) return

    const animations = this.attributes.animations
    if (animations === undefined || animations === 'Mixed') return

    this.setMulti({
      animations: animations.map((a, i) => (i === index ? value : a)),
    })
  }

  private copy = (index: number): void => {
    this.applyCommand({ type: 'copySelectedNodeAnimation', params: { index } })
  }

  private createNewAnimation = (
    trigger: AttributeAnimationTriggerType
  ): AttributeAnimation => {
    return {
      action: { trigger: trigger },
      effect: {
        type: 'timeline',
        loop: false,
        events: [
          {
            key: 'e_1',
            start: false,
            targetType: 'self',
            startTime: 0,
            duration: 300,
            easing: 'ease-in-out',
            style: {},
          },
        ],
      },
    }
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (n, _) => allowedTypes.includes(n.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'rectangle',
  'ellipse',
  'text',
  'image',
  'page',
]
