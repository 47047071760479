import { DesignColor } from 'themes'
import Icon from '../Icon/Icon/Icon'
import { useEffect, useRef, useState } from 'react'
import useHovered from 'hooks/ui/useHovered'

interface TemplatesSearchProps {
  value: string
  setValue: (value: string) => void

  close(): void
}

export default function TemplatesSearch({
  value,
  setValue,
  close,
}: TemplatesSearchProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)

  const [editing, setEditing] = useState(false)
  const containerHover = useHovered<boolean>({ ref: containerRef })
  const iconHover = useHovered<boolean>({ ref: iconRef })

  useEffect(() => {
    if (editing) inputRef.current?.select()
  }, [editing])

  return (
    <div
      style={{
        width: '100%',
        height: 48,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 24px',
        boxSizing: 'border-box',
        boxShadow: `
        inset 0 1px 0 0 ${DesignColor('panelBorder')}, 
        inset 0 -1px 0 0 ${DesignColor('panelBorder')}, 
        inset -1px 0 0 0 ${DesignColor('panelBorder')}
        `,
        background: DesignColor('panelBackground'),
      }}
    >
      <div
        ref={containerRef}
        style={{
          width: 300,
          height: 'fit-content',
          padding: '8px 10px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          gap: 8,
          boxShadow:
            editing || containerHover.hovered || false
              ? `inset 0 0 0 1px ${DesignColor('inputHighlight')}`
              : 'none',
        }}
        onMouseEnter={() => containerHover.setHovered(true)}
        onMouseLeave={() => containerHover.setHovered(false)}
        onClick={() => inputRef.current?.focus()}
      >
        <Icon icon="Search" size={16} color={DesignColor('text2')} />
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          onKeyUp={(e) => e.stopPropagation()}
          onFocus={() => setEditing(true)}
          onBlur={() => setEditing(false)}
          style={{
            width: '100%',
            height: 'fit-content',
            boxSizing: 'border-box',
            padding: 0,
            fontSize: 12,
            color: DesignColor('text1'),
            border: 'none',
            outline: 'none',
            background: 'transparent',
          }}
          placeholder="Search"
        />
      </div>
      <div
        ref={iconRef}
        style={{
          width: 24,
          height: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          borderRadius: 4,
          boxShadow: iconHover.hovered
            ? `inset 0 0 0 1px ${DesignColor('inputHighlight')}`
            : 'none',
        }}
        onClick={close}
        onMouseEnter={() => iconHover.setHovered(true)}
        onMouseLeave={() => iconHover.setHovered(false)}
      >
        <Icon
          icon="X"
          size={24}
          color={DesignColor(iconHover.hovered ? 'inputHighlight' : 'text2')}
        />
      </div>
    </div>
  )
}
