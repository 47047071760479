import Text from 'components/Library/Text/Text'
import { DesignColor } from 'themes'

export default function TemplatesTitle() {
  return (
    <div
      style={{
        width: '100%',
        height: 48,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        padding: '0 16px',
        boxSizing: 'border-box',
        boxShadow: `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
      }}
    >
      <Text styles={{ size: 12, weight: 'bold' }}>Templates</Text>
    </div>
  )
}
