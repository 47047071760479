import { DesignColor } from 'themes'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import Text from 'components/Library/Text/Text'
import useCommit from 'hooks/editor/useCommit'

interface CanvasRowButtonProps {
  id: string

  editing: boolean
  setEditing: (value: boolean) => void

  name: string
  setName: (value: string) => void

  active: boolean

  onMouseDown?: (e: MouseEvent) => void
  onMouseUp?: (e: MouseEvent) => void
}

export default function CanvasRowButton({
  id,
  editing,
  setEditing,
  name,
  setName,
  active,
  onMouseDown,
  onMouseUp,
}: CanvasRowButtonProps) {
  const commit = useCommit()
  const inputRef = useRef<HTMLInputElement>(null)
  const [hovered, setHovered] = useState(false)
  const [localName, setLocalName] = useState(name)

  const backgroundColor = getBackgroundColor(active, editing)
  const boxShadow = getBoxShadow(hovered, editing)

  const handleDoneEditing = () => {
    setName(localName)
    setEditing(false)
    commit()
  }

  const handleStartEditing = () => {
    setEditing(true)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      e.preventDefault()
      handleDoneEditing()
    }
  }

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
      inputRef.current?.select()
    }
  }, [editing])

  useEffect(() => {
    if (editing) return
    setLocalName(name)
  }, [editing, name])

  return (
    <div
      data-canvas-id={id}
      id={`canvas-row-${id}`}
      className={`canvas-row`}
      style={{
        position: 'relative',
        width: '100%',
        height: 32,
        padding: '0px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        backgroundColor,
        boxShadow,
      }}
      onDoubleClick={handleStartEditing}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {editing ? (
        <input
          ref={inputRef}
          type="text"
          value={localName}
          onChange={(e) => setLocalName(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          onKeyDown={handleKeyDown}
          onBlur={handleDoneEditing}
          style={{
            flex: 1,
            height: '100%',
            boxSizing: 'border-box',
            padding: 0,
            margin: 0,
            border: 'none',
            outline: 'none',
            background: 'transparent',
            color: DesignColor('text1'),
            fontFamily: 'Inter',
            fontWeight: 'regular',
            fontSize: 11,
          }}
        />
      ) : (
        <Text>{name}</Text>
      )}
    </div>
  )
}

function getBackgroundColor(active: boolean, editing: boolean): string {
  if (active && !editing) {
    return DesignColor('inputTint')
  }
  return 'transparent'
}

function getBoxShadow(hovered: boolean, renaming: boolean): string {
  if (!hovered && !renaming) return 'none'
  return `inset 0 0 0 1px ${DesignColor('inputHighlight')}`
}
