import { ReadOnlyDocument } from 'application/document'
import { Canvas } from './types'
import {
  ClientUpdateListener,
  CommandHandler,
  Update,
} from 'application/client'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ActionInitiator } from 'editor/action/initiator'
import { CreateAction } from 'editor/action/node/create'
import { NodeDeleteAction, NodeSelectionAction } from 'application/action'
import {
  DraggingCanvasLineListener,
  DraggingLine,
} from 'editor/action/dragCanvas/line'

export type CanvasPanelSettings = {
  canvases: Canvas[]
  line: DraggingLine | null
}

export type LayersPanelHanders = {
  createCanvas: () => void
  deleteCanvas: (id: string) => void
  select: (id: string) => void
  setName: (id: string, name: string) => void
  setEditing: (id: string, editing: boolean) => void
  startDrag: () => void
}

export type CanvasListener = (settings: CanvasPanelSettings) => void

export class CanvasPanel
  implements ClientUpdateListener, DraggingCanvasLineListener
{
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private actionInitiator: ActionInitiator
  private createAction: CreateAction
  private selectionAction: NodeSelectionAction
  private deleteAction: NodeDeleteAction
  private commandHandler: CommandHandler

  private line: DraggingLine | null
  private editing: string | null

  private listeners: { [key: string]: CanvasListener }
  private canvases: Canvas[]

  constructor(
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    actionInitiator: ActionInitiator,
    createAction: CreateAction,
    selectionAction: NodeSelectionAction,
    deleteAction: NodeDeleteAction,
    commandHandler: CommandHandler
  ) {
    this.document = document
    this.selection = selection
    this.actionInitiator = actionInitiator
    this.createAction = createAction
    this.selectionAction = selectionAction
    this.deleteAction = deleteAction
    this.commandHandler = commandHandler

    this.line = null
    this.editing = null

    this.listeners = {}
    this.canvases = this.generateCanvases()
  }

  getSettings = (): CanvasPanelSettings => {
    return {
      canvases: this.canvases,
      line: this.line,
    }
  }

  getHandlers = (): LayersPanelHanders => {
    return {
      createCanvas: this.createCanvas,
      deleteCanvas: this.deleteCanvas,
      select: this.select,
      startDrag: this.startDrag,
      setName: this.setName,
      setEditing: this.setEditing,
    }
  }

  getTypes = (): Update['type'][] => {
    return ['node_updated', 'selected_canvas', 'initialize']
  }

  onUpdate = () => {
    this.canvases = this.generateCanvases()
    this.notifyListeners()
  }

  onDraggingLine = (line: DraggingLine | null): void => {
    this.line = line
    this.notifyListeners()
  }

  subscribe(key: string, listener: CanvasListener): void {
    this.listeners[key] = listener
    listener(this.getSettings())
  }

  unsubscribe(key: string): void {
    delete this.listeners[key]
  }

  private createCanvas = (): void => {
    const id = this.createAction.createCanvas()
    this.setEditing(id, true)
  }

  private deleteCanvas = (id: string): void => {
    const selectedCanvas = this.selection.getSelectedCanvas()
    if (selectedCanvas === id) {
      const next = this.getNextCanvasId()
      if (!next) return
      this.selectionAction.selectNodes([], true)
      this.selectionAction.selectCanvas(next)
    }

    this.deleteAction.delete([id])
    this.commandHandler.handle({ type: 'commit' })
  }

  private select = (id: string): void => {
    this.selectionAction.selectNodes([], true)
    this.selectionAction.selectCanvas(id)
    this.commandHandler.handle({ type: 'commit' })
  }

  private startDrag = (): void => {
    this.actionInitiator.dragCanvas()
  }

  private setName = (id: string, name: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    this.commandHandler.handle({
      type: 'setNodeAttribute',
      params: { id: id, base: { name }, style: {} },
    })
  }

  private setEditing = (id: string, editing: boolean): void => {
    this.editing = editing ? id : null
    this.actionInitiator.editInput(editing)
    this.canvases = this.generateCanvases()
    this.notifyListeners()
  }

  private notifyListeners = (): void => {
    const settings = this.getSettings()
    for (const key in this.listeners) {
      this.listeners[key](settings)
    }
  }

  private generateCanvases = (): Canvas[] => {
    const canvases: Canvas[] = []
    const root = this.document.getNode('root')
    if (!root) return canvases

    const children = root.getChildren()
    if (!children) return canvases

    for (const id of children) {
      const canvas = this.document.getNode(id)
      if (!canvas) continue

      canvases.push({
        id: id,
        name: canvas.getBaseAttribute('name') || 'Canvas',
        selected: this.selection.getSelectedCanvas() === id,
        editing: this.editing === id,
      })
    }

    return canvases
  }

  private getNextCanvasId = (): string | null => {
    const selectedCanvas = this.selection.getSelectedCanvas()
    const root = this.document.getNode('root')
    if (!root) return null

    const children = root.getChildren()
    if (!children) return null

    const next = children.find((id) => id !== selectedCanvas)
    return next || null
  }
}
