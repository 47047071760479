import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import WebglCanvas from './WebglCanvas/WebglCanvas'
import useLoadFonts from 'hooks/admin/useLoadFonts'
import RightSidepanel from './RightSidepanel/RightSidepanel'
import RightClickMenu from './RightClickMenu/RightClickMenu'
import RightTopbar from './RightTopbar/RightTopbar'
import LeftToolbar from './LeftToolbar/LeftToolbar'
import LeftTopbar from './LeftTopbar/LeftTopbar'
import LeftSidepanel from './LeftSidepanel/LeftSidepanel'
import Preview from './Preview/Preview'
import PortalRoot from './PortalRoot/PortalRoot'
import DevTools from './DevTools/DevTools'
import SplashScreen from './SplashScreen/SplashScreen'
import useSplashScreenTimeout from 'hooks/ui/useSplashScreenTimeout'
import { editor, recreateEditor } from 'index'
import useRedirectLoggedOut from 'hooks/admin/useRedirectLoggedOut'
import useRedirectNoProject from 'hooks/admin/useRedirectNoProject'
import useSetProject from 'hooks/services/useSetProject'
import useWebsocket from 'hooks/admin/useWebsocket'
import { activeProjectAtom } from '../Projects/Projects'
import ZoomInput from './ZoomInput/ZoomInput'
import IntercomMenu from 'components/Library/Support/IntercomMenu'

export const SIDEBAR_TOP = 72
export const SIDEBAR_OFFSET = 8

export default function Editor() {
  useRedirectLoggedOut()
  useRedirectNoProject()
  useWebsocket()
  useLoadFonts()

  const { timeout, fade } = useSplashScreenTimeout()

  const activeProject = useRecoilValue(activeProjectAtom)
  const setProject = useSetProject()

  const [loaded, setLoaded] = useState(false)
  const [fading, setFading] = useState(false)

  document.documentElement.classList.add('hideScrollbar')

  useEffect(() => {
    editor.init()
    if (activeProject) setProject(activeProject.id)
    setTimeout(() => {
      setFading(true)
    }, fade)
    setTimeout(() => {
      setLoaded(true)
    }, timeout)

    return () => {
      editor.cleanup()
      recreateEditor()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        userSelect: 'none',
      }}
    >
      <IntercomMenu />
      <PortalRoot />
      <WebglCanvas />
      {(loaded || fading) && (
        <>
          <LeftTopbar />
          <LeftSidepanel />
          <LeftToolbar />
          <RightTopbar />
          <RightSidepanel />
          <ZoomInput />
          <RightClickMenu />
          <DevTools />
          <Preview />
        </>
      )}
      {!loaded && <SplashScreen fading={fading} />}
    </div>
  )
}
