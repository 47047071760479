import { AlignMode, TextContent, TextStyle, TextStyleOverrides } from '../types'
import { ShapedCharacter, ShapedRow, ShapedText } from './types'
import { FontLoaderInterface } from 'application/text'

export interface Shaper {
  getShapedText: (state: TextContent, width?: number) => ShapedText
}

export class TextShaper implements Shaper {
  private fontLoader: FontLoaderInterface

  constructor(fontLoader: FontLoaderInterface) {
    this.fontLoader = fontLoader
  }

  getShapedText = (state: TextContent, width?: number): ShapedText => {
    const { content, contentStyles, styleOverrides } = state

    let index = 0
    let paragraph = 0
    let x = 0
    let y = 0
    let maxWidth = width
    const rows: ShapedRow[] = []

    while (index < content.length) {
      const { row, nextIndex, nextParagraph } = this.getNextRow(
        index,
        x,
        y,
        paragraph,
        maxWidth,
        content,
        contentStyles,
        styleOverrides,
        state.styles
      )
      if (row.characters.length === 0) break

      row.w = row.characters.reduce((acc, char) => acc + char.w, 0)
      row.h = row.characters.reduce((acc, char) => Math.max(acc, char.h), 0)

      this.updateYOffsets(
        row,
        content,
        contentStyles,
        styleOverrides,
        state.styles
      )

      rows.push(row)
      index = nextIndex
      x = 0
      y += row.h
      if (nextParagraph) paragraph++
    }

    if (rows.length === 0) {
      rows.push({
        w: 0,
        h: this.getDefaultHeight(state.styles),
        paragraph: 0,
        characters: [],
      })
    } else if (content[index - 1] === '\n') {
      rows.push({
        w: 0,
        h: rows[rows.length - 1].h,
        paragraph: paragraph,
        characters: [],
      })
    }

    return {
      rows: this.updateRowsForAlignment(rows, state.styles.align, maxWidth),
      w: rows.reduce((acc, row) => Math.max(acc, row.w), 0),
      h: rows.reduce((acc, row) => acc + row.h, 0),
    }
  }

  private getNextRow(
    index: number,
    startX: number,
    startY: number,
    paragraph: number,
    maxWidth: number | undefined,
    content: string,
    contentStyles: number[],
    styleOverrides: TextStyleOverrides,
    style: TextStyle
  ): { row: ShapedRow; nextIndex: number; nextParagraph: boolean } {
    const row: ShapedRow = {
      w: 0,
      h: 0,
      paragraph: paragraph,
      characters: [],
    }

    let x = startX
    let y = startY
    let unfinished: ShapedCharacter[] = []
    let unfinishedIndex = index
    let finishedIndex = index
    let paragraphEnd = false

    while (unfinishedIndex < content.length) {
      const charStyleIndex = contentStyles[unfinishedIndex]
      const charStyle = {
        ...style,
        ...styleOverrides[charStyleIndex],
      }

      const shapedChar = this.getCharBox(content, unfinishedIndex, charStyle)
      if (!shapedChar) break

      unfinishedIndex++

      shapedChar.x = x
      shapedChar.y = y
      shapedChar.index = unfinishedIndex

      if (shapedChar.newline) {
        shapedChar.w = 0
        row.characters.push(...unfinished, shapedChar)
        unfinished = []
        finishedIndex = unfinishedIndex
        paragraphEnd = true
      } else if (shapedChar.space) {
        row.characters.push(...unfinished, shapedChar)
        unfinished = []
        finishedIndex = unfinishedIndex
        x += shapedChar.w
      } else {
        unfinished.push(shapedChar)
        x += shapedChar.w
      }

      if (
        (maxWidth && x > maxWidth && row.characters.length > 0) ||
        paragraphEnd
      ) {
        return {
          row: row,
          nextIndex: finishedIndex,
          nextParagraph: paragraphEnd,
        }
      }
    }

    row.characters.push(...unfinished)
    finishedIndex = unfinishedIndex

    return {
      row: row,
      nextIndex: finishedIndex,
      nextParagraph: paragraphEnd,
    }
  }

  private getCharBox(
    content: string,
    index: number,
    style: TextStyle
  ): ShapedCharacter | null {
    const { char, nextChar } = this.getChar(index, content)
    const { fontFamily, fontWeight, fontSize, letterSpacing, lineHeight } =
      style

    const font = this.fontLoader.getFont(fontFamily, fontWeight)
    if (!font) return null

    const charGlyph = font.charToGlyph(char)
    if (!charGlyph) return null

    const nextCharGlyph = font.charToGlyph(nextChar)
    if (!nextCharGlyph) return null

    const scale = fontSize / font.unitsPerEm
    const kerning = font.getKerningValue(charGlyph, nextCharGlyph)

    const width = (charGlyph.advanceWidth! + kerning) * scale + letterSpacing
    const height =
      (font.ascender! - font.descender! + font.tables.hhea.lineGap) * scale +
      lineHeight
    const yOffset = (font.ascender! - charGlyph.yMax!) * scale + lineHeight / 2

    return {
      x: 0,
      y: 0,
      w: width,
      h: height,
      index: 0,
      yOffset: yOffset,
      space: content[index] === ' ',
      newline: content[index] === '\n',
    }
  }

  private getChar(
    index: number,
    content: string
  ): { char: string; nextChar: string } {
    const char =
      content[index] !== undefined && content[index] !== '\n'
        ? content[index]
        : ' '
    const nextChar =
      content[index + 1] !== undefined && content[index + 1] !== '\n'
        ? content[index + 1]
        : ' '
    return { char, nextChar }
  }

  private getDefaultHeight(style: TextStyle): number {
    const { fontFamily, fontWeight, fontSize, lineHeight } = style

    const font = this.fontLoader.getFont(fontFamily, fontWeight)
    if (!font) return 0

    const scale = fontSize / font.unitsPerEm
    return (
      (font.ascender! - font.descender! + font.tables.hhea.lineGap) * scale +
      lineHeight
    )
  }

  private updateRowsForAlignment(
    rows: ShapedRow[],
    alignment: AlignMode,
    width?: number
  ): ShapedRow[] {
    const maxWidth = width || Math.max(...rows.map((r) => r.w))
    return rows.map((row) => {
      const rowWidth = row.w
      const targetWidth = width || maxWidth
      let x = 0
      switch (alignment) {
        case 'left':
          x = 0
          break
        case 'right':
          x = targetWidth - rowWidth
          break
        case 'center':
          x = (targetWidth - rowWidth) / 2
          break
      }
      return {
        ...row,
        characters: row.characters.map((char) => {
          const newX = x + char.x
          return {
            ...char,
            x: newX,
          }
        }),
      }
    })
  }

  private updateYOffsets(
    row: ShapedRow,
    content: string,
    contentStyles: number[],
    styleOverrides: TextStyleOverrides,
    style: TextStyle
  ): ShapedRow {
    let tallestCharIndex = 0
    let tallestCharHeight = 0

    for (let i = 0; i < row.characters.length; i++) {
      const char = row.characters[i]
      if (char.h > tallestCharHeight) {
        tallestCharHeight = char.h
        tallestCharIndex = i
      }
    }

    const tallestChar = row.characters[tallestCharIndex]
    const tallestCharStyleIndex = contentStyles[tallestChar.index - 1]
    const tallestCharStyle = {
      ...style,
      ...styleOverrides[tallestCharStyleIndex],
    }
    const tallestFont = this.fontLoader.getFont(
      tallestCharStyle.fontFamily,
      tallestCharStyle.fontWeight
    )
    if (!tallestFont) return row
    const tallestCharScale = tallestCharStyle.fontSize / tallestFont.unitsPerEm
    const tallestLineGap = tallestFont.tables.hhea.lineGap
    const gapAboveBaseline =
      (tallestFont.ascender + tallestLineGap / 2) * tallestCharScale

    for (let i = 0; i < row.characters.length; i++) {
      const char = row.characters[i]
      if (char.h < tallestCharHeight) {
        const charStyleIndex = contentStyles[char.index - 1]
        const charStyle = {
          ...style,
          ...styleOverrides[charStyleIndex],
        }
        const charContent =
          content[char.index - 1] !== undefined &&
          content[char.index - 1] !== '\n'
            ? content[char.index - 1]
            : ' '
        const { fontFamily, fontWeight } = charStyle

        const font = this.fontLoader.getFont(fontFamily, fontWeight)
        if (!font) continue

        const charGlyph = font.charToGlyph(charContent)
        if (!charGlyph) continue

        const scale = charStyle.fontSize / font.unitsPerEm

        const glyphYMax = charGlyph.yMax! * scale
        char.yOffset =
          gapAboveBaseline - glyphYMax + tallestCharStyle.lineHeight / 2
      }
    }
    return row
  }
}
