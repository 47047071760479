import { activeProjectAtom } from 'components/Pages/Projects/Projects'
import useBackendService from 'hooks/services/useBackendService'
import { ChangeEvent, DragEvent } from 'react'
import { useRecoilValue } from 'recoil'
import { v4 } from 'uuid'

export type UploadedImage = {
  url: string
  size: { w: number; h: number }
}

const allowedTypes = [
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
  'image/gif',
  'image/avif',
]

export default function useUploadImage() {
  const activeProject = useRecoilValue(activeProjectAtom)
  const backendService = useBackendService()

  return async (
    callback: (uploadedImage: UploadedImage) => Promise<void>,
    changeEvent: ChangeEvent<HTMLInputElement> | undefined,
    dragEvent: DragEvent<HTMLDivElement> | undefined
  ) => {
    if (!activeProject) return
    if (!changeEvent && !dragEvent) return

    const file = changeEvent
      ? getEventFile(changeEvent)
      : dragEvent
      ? getFile(dragEvent)
      : undefined

    if (file && allowedTypes.includes(file.type)) {
      getImageSize(file)
        .then(async ({ file, w, h }) => {
          const response = await backendService.createAsset(
            activeProject.id,
            v4(),
            file
          )
          if (response) {
            await callback({ url: response.url, size: { w, h } })
          }
        })
        .catch((error) => {
          console.error('Image resizing failed:', error)
        })
    }
  }
}

function getImageSize(
  file: File
): Promise<{ file: File; w: number; h: number }> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = URL.createObjectURL(file)

    image.onload = () => {
      let width = image.width
      let height = image.height

      resolve({ file, w: width, h: height })
    }

    image.onerror = () => reject(new Error('Failed to load image.'))
  })
}

function getFile(e: React.DragEvent<HTMLDivElement>): File | undefined {
  if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
    return e.dataTransfer.files[0]
  }
}

function getEventFile(e: ChangeEvent<HTMLInputElement>): File | undefined {
  if (e.target.files && e.target.files.length > 0) {
    return e.target.files[0]
  }
}
