import { WriteDocument } from 'application/document'
import {
  Violation,
  ViolationDynamicSizeOnCanvas,
  ViolationRemediator,
} from '../types'

export class SizeViolationRemediator implements ViolationRemediator {
  canRemediate(type: Violation['type']): boolean {
    switch (type) {
      case 'dynamic_size_on_canvas':
        return true
      case 'hug_non_autolayout':
        return true
      default:
        return false
    }
  }

  remediate(violation: Violation, document: WriteDocument): void {
    switch (violation.type) {
      case 'dynamic_size_on_canvas':
        this.remediateDynamicSizeOnCanvas(violation, document)
        break
      case 'hug_non_autolayout':
        this.remediateHugNonAutolayout(violation, document)
        break
    }
  }

  private remediateDynamicSizeOnCanvas = (
    violation: ViolationDynamicSizeOnCanvas,
    document: WriteDocument
  ): void => {
    const node = document.getNode(violation.metadata.nodeId)
    if (node === undefined) return

    const h = node.getBaseAttribute('h')
    const w = node.getBaseAttribute('w')

    node.setStyleAttribute('size.w', w)
    node.setStyleAttribute('size.h', h)
    node.setStyleAttribute('size.w.auto', 'fixed')
    node.setStyleAttribute('size.h.auto', 'fixed')
  }

  private remediateHugNonAutolayout = (
    violation: Violation,
    document: WriteDocument
  ): void => {
    const node = document.getNode(violation.metadata.nodeId)
    if (node === undefined) return
    if (node.getStyleAttribute('autolayout.mode') === 'flex') return

    if (node.getStyleAttribute('size.w.auto') === 'hug') {
      node.setStyleAttribute('size.w.auto', 'fixed')
      node.setStyleAttribute('size.w', node.getBaseAttribute('w'))
    }

    if (node.getStyleAttribute('size.h.auto') === 'hug') {
      node.setStyleAttribute('size.h.auto', 'fixed')
      node.setStyleAttribute('size.h', node.getBaseAttribute('h'))
    }
  }
}
