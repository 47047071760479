import { ReadOnlyDocument } from 'application/document'

export interface IdGenerator {
  next(): string
}

export class NodeIdGenerator implements IdGenerator {
  private document: ReadOnlyDocument
  private max = 0

  constructor(document: ReadOnlyDocument) {
    this.document = document
  }

  next(): string {
    this.findMax()
    this.max++
    return this.max.toString()
  }

  private findMax(): void {
    this.max = this.document.getNodes().reduce((max, node) => {
      const id = parseInt(node.getId())
      return id > max ? id : max
    }, this.max)
  }
}
