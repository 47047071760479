import { Node, NodeFactory } from 'application/node'
import { Command, CommandHandler, InitializeDocument } from './types'
import { ClientDocumentHelper } from '../document/helper'
import { DocumentSelection } from 'application/selection'
import { ProjectDocument } from 'application/service'
import { UpdateHandler } from '../update/update'
import { ClientTransactionHandler } from '../transaction'

export class InitializeCommandHandler implements CommandHandler {
  private transaction: ClientTransactionHandler
  private updateHandler: UpdateHandler
  private nodeFactory: NodeFactory
  private documentHelper: ClientDocumentHelper
  private documentSelection: DocumentSelection

  constructor(
    transaction: ClientTransactionHandler,
    updateHandler: UpdateHandler,
    nodeFactory: NodeFactory,
    documentHelper: ClientDocumentHelper,
    documentSelection: DocumentSelection
  ) {
    this.transaction = transaction
    this.updateHandler = updateHandler
    this.nodeFactory = nodeFactory
    this.documentHelper = documentHelper
    this.documentSelection = documentSelection
  }

  handle = (command: Command): void => {
    switch (command.type) {
      case 'initializeDocument':
        this.initializeDocument(command)
        return
    }
  }

  private initializeDocument = (command: InitializeDocument): void => {
    const projectDocument = command.params.document
    const nodeMap: { [key: string]: Node } = {}
    for (const key of Object.keys(projectDocument.data)) {
      const node = this.nodeFactory.createLoadedNode(projectDocument.data[key])
      nodeMap[key] = node
    }

    this.documentHelper.setNodeMap(nodeMap)
    const firstCanvas = this.getFirstCanvas(projectDocument)
    if (firstCanvas) this.documentSelection.selectCanvas(firstCanvas)

    this.transaction.clear()
    this.updateHandler.clear()
    this.updateHandler.addUpdate({
      type: 'initialize',
      data: { documentId: projectDocument.id },
    })
  }

  private getFirstCanvas = (
    projectDocument: ProjectDocument
  ): string | null => {
    const root = projectDocument.data['root']
    if (!root) return null

    const children = root.children
    if (!children || children.length === 0) return null

    return children[0]
  }
}
