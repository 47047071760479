import { Project } from 'application/service'
import { useState } from 'react'
import ProjectGridControls from './ProjectGridControls/ProjectGridControls'
import { ProjectGridSortMode } from './ProjectGridControls/ProjectSortButton'
import useBackendService from 'hooks/services/useBackendService'
import { useRecoilState } from 'recoil'
import { projectListAtom } from 'components/Pages/Projects/Projects'
import ProjectGrid from './ProjectGrid/ProjectGrid'
import ProjectGridEmpty from './ProjectGridEmpty'
import ProjectGridEmptySearch from './ProjectGridEmptySearch'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import useToast from 'hooks/editor/useToast'

export default function ProjectBody() {
  const backendService = useBackendService()
  const { toEditor } = useNavigatePage()
  const toast = useToast()

  const [projects, setProjects] = useRecoilState(projectListAtom)
  const [searchString, setSearchString] = useState('')
  const [sortMode, setSortMode] =
    useState<ProjectGridSortMode>('recentlyViewed')

  const sortedProjects = sortProjects(projects, searchString, sortMode)

  const handleCreateNewProject = async () => {
    try {
      const project = await backendService.createProject()
      const projects = await backendService.getProjects()
      setProjects(projects)
      await toEditor(project.id)
      toast('Project created', 'success')
    } catch (e) {
      console.error('Error creating project', e)
      toast('Unable to create project, you are currently offline.', 'error')
      return
    }
  }

  const handleRenameProject = async (projectId: string, name: string) => {
    try {
      await backendService.updateProject(projectId, { name })
      const projects = await backendService.getProjects()
      setProjects(projects)
    } catch (e) {
      console.error('Error renaming project', e)
      toast('Unable to rename project, you are currently offline.', 'error')
      return
    }
  }

  const handleDeleteProject = async (projectId: string) => {
    try {
      await backendService.deleteProject(projectId)
      const projects = await backendService.getProjects()
      setProjects(projects)
    } catch (e) {
      console.error('Error deleting project', e)
      toast('Unable to delete project, you are currently offline.', 'error')
      return
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <ProjectGridControls
        searchString={searchString}
        sortMode={sortMode}
        setSearchString={setSearchString}
        setSortMode={setSortMode}
        createProject={handleCreateNewProject}
      />
      {sortedProjects.length === 0 && !searchString && (
        <ProjectGridEmpty createProject={handleCreateNewProject} />
      )}
      {sortedProjects.length === 0 && searchString && (
        <ProjectGridEmptySearch setSearchString={setSearchString} />
      )}
      {sortedProjects.length > 0 && (
        <ProjectGrid
          sortMode={sortMode}
          sortedProjects={sortedProjects}
          renameProject={handleRenameProject}
          deleteProject={handleDeleteProject}
        />
      )}
    </div>
  )
}

function sortProjects(
  projects: Project[],
  searchString: string,
  sortMode: ProjectGridSortMode
) {
  return projects
    .filter((project) =>
      project.name.toLowerCase().includes(searchString.toLowerCase())
    )
    .sort((a, b) => {
      switch (sortMode) {
        case 'dateCreated':
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
        case 'alphabetical':
          return a.name.localeCompare(b.name)
        case 'recentlyViewed':
          return getLastViewedTime(b.id) - getLastViewedTime(a.id)
        default:
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          )
      }
    })
}

function getLastViewedTime(projectId: string): number {
  const lastViewedProjects = localStorage.getItem('lastViewedProjects')
  if (!lastViewedProjects) return 0

  const lastViewedProjectsMap = JSON.parse(lastViewedProjects)
  return lastViewedProjectsMap[projectId] || 0
}
