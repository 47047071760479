import { ReadOnlyNode } from 'application/node'
import { NodeTitle } from './types'
import { DesignColor } from 'themes'
import { CameraService } from 'application/camera'
import { Rectangle } from 'application/shapes'
import { ShapedText, Shaper, TextContent } from 'application/text'
import { Color, rgbaStringToRgba } from 'application/color'
import {
  TitleIconSize,
  TitlePadding,
} from 'editor/haptic/transformer/nodeTitles'

export class NodeTitleGenerator {
  private textShaper: Shaper
  private cameraService: CameraService

  constructor(textShaper: Shaper, cameraService: CameraService) {
    this.textShaper = textShaper
    this.cameraService = cameraService
  }

  generateNodeTitle = (
    node: ReadOnlyNode,
    selected: boolean
  ): NodeTitle | null => {
    const color = rgbaStringToRgba(this.getColor(selected))
    const content = this.createTextContent(node.getBaseAttribute('name'), color)
    const shapedName = this.textShaper.getShapedText(content)
    if (!shapedName) return null

    const rectangle = this.getRectangle(node, shapedName)

    return {
      id: node.getId(),
      name: node.getBaseAttribute('name'),
      type: node.getBaseAttribute('type'),
      rectangle: rectangle,
      shapedTitle: shapedName,
      text: content,
    }
  }

  private createTextContent = (name: string, color: Color): TextContent => {
    return {
      content: name,
      contentStyles: new Array(name.length).fill(0),
      styles: {
        fontFamily: 'inter',
        fontWeight: 'regular',
        fontSize: 12 / this.cameraService.getCamera().z,
        align: 'left',
        fill: { type: 'color', color: color },
        letterSpacing: 0,
        lineHeight: 0,
        italic: false,
        underline: false,
        link: null,
      },
      styleOverrides: {},
      lineTypes: ['none'],
    }
  }

  private getRectangle = (
    node: ReadOnlyNode,
    shapedName: ShapedText
  ): Rectangle => {
    const z = this.cameraService.getCamera().z
    const x = node.getBaseAttribute('x')
    const y = node.getBaseAttribute('y')
    const w = node.getBaseAttribute('w')

    return {
      y: y - (TitleIconSize + TitlePadding) / z,
      x: x,
      w: Math.min(w, (TitleIconSize + TitlePadding) / z + shapedName.w),
      h: (TitleIconSize + TitlePadding) / z,
    }
  }

  private getColor = (selected: boolean): string => {
    if (selected) {
      return DesignColor('frameTitleHighlight')
    } else {
      return DesignColor('frameTitle')
    }
  }
}
