import Icon from 'components/Library/Components/Icon/Icon/Icon'
import { useRef, useState } from 'react'
import { DesignColor } from 'themes'
import {
  MenuDrawerPopupButton,
  MenuDrawerPopupContainer,
} from '../../../Menus/MenuDrawerPopup'

interface ProjectSortButtonProps {
  sortMode: ProjectGridSortMode
  setSortMode: (mode: ProjectGridSortMode) => void
}

export default function ProjectSortButton({
  sortMode,
  setSortMode,
}: ProjectSortButtonProps) {
  const [hover, setHover] = useState(false)
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div
        ref={buttonRef}
        style={{
          display: 'flex',
          padding: '8px 10px',
          background: 'none',
          border: `1px solid ${
            hover ? DesignColor('inputHighlight') : 'transparent'
          }`,
          borderRadius: 0,
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <p
          style={{
            fontSize: 12,
            fontFamily: 'inter',
            color: DesignColor('text1'),
            alignSelf: 'center',
          }}
        >
          {options.find((option) => option.value === sortMode)?.label}
        </p>
        <Icon icon="DropdownArrowDown" size={16} color={DesignColor('text2')} />
      </div>
      <MenuDrawerPopupContainer
        open={open}
        setOpen={setOpen}
        sourceRef={buttonRef}
      >
        {options.map((option) => (
          <MenuDrawerPopupButton
            key={option.value}
            label={option.label}
            onClick={() => {
              setSortMode(option.value)
              setOpen(false)
            }}
          />
        ))}
      </MenuDrawerPopupContainer>
    </>
  )
}

export type ProjectGridSortMode =
  | 'recentlyViewed'
  | 'dateCreated'
  | 'alphabetical'

const options = [
  {
    label: 'Recently Viewed',
    value: 'recentlyViewed' as ProjectGridSortMode,
  },
  {
    label: 'Date Created',
    value: 'dateCreated' as ProjectGridSortMode,
  },
  {
    label: 'Alphabetical',
    value: 'alphabetical' as ProjectGridSortMode,
  },
]
