import { ReadOnlyNode } from 'application/node'
import { ClientUpdateListener, Command, Update } from 'application/client'
import {
  doesContainerAllowOverrides,
  getOverrideContainer,
  getOverrideMode,
  getWidthForMode,
} from 'application/breakpoint/utils'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { SelectorBreakpoint, SelectorPseudo } from 'application/attributes'

export type BreakpointHasOverrides = {
  [key in SelectorBreakpoint]: boolean
}

export type PseudoHasOverrides = {
  [key in SelectorPseudo]: boolean
}

export type BreakpointModePanelState = {
  breakpoint: 'Mixed' | SelectorBreakpoint
  pseudo: 'Mixed' | SelectorPseudo
  options: SelectorBreakpoint[]
  breakpointHasOverrides: BreakpointHasOverrides
  pseudoHasOverrides: PseudoHasOverrides
}

export type BreakpointModePanelHandlers = {
  setMode: (mode: SelectorBreakpoint) => void
  setPseudo: (pseudo: SelectorPseudo) => void
}

type PanelListeners = (settings: BreakpointModePanelState) => void

type CommandHandler = {
  handle: (command: Command | Command[]) => void
}

const Breakpoints: SelectorBreakpoint[] = [
  'default',
  'tablet',
  'landscape',
  'mobile',
]

const Pseudos: SelectorPseudo[] = ['hover']

export class BreakpointModePanel implements ClientUpdateListener {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private listeners: { [key: string]: PanelListeners } = {}

  private editingText: boolean = false

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.documentSelection = documentSelection
  }

  getSettings(): BreakpointModePanelState {
    return {
      breakpoint: this.getBreakpoint(),
      pseudo: this.getPseudo(),
      options: this.getOptions(),
      breakpointHasOverrides: this.getBreakpointHasOverrides(),
      pseudoHasOverrides: this.getPseudoHasOverrides(),
    }
  }

  getHandlers(): BreakpointModePanelHandlers {
    return {
      setMode: this.setBreakpoint,
      setPseudo: this.setPseudo,
    }
  }

  getTypes = (): Update['type'][] => {
    return ['selection', 'node_updated', 'edit_text']
  }

  onUpdate = (updates: Update[]): void => {
    let selectionUpdated = false
    let selectedNodeUpdated = false
    for (const update of updates) {
      switch (update.type) {
        case 'selection':
          selectionUpdated = true
          break
        case 'node_updated':
          if (this.isSelected(update.data.id)) selectedNodeUpdated = true
          break
        case 'edit_text':
          this.editingText = update.data.id !== null
          break
      }
    }
    if (!selectionUpdated && !selectedNodeUpdated) return

    this.notifyListeners()
  }

  subscribe = (key: string, listener: PanelListeners): void => {
    this.listeners[key] = listener
  }

  unsubscribe = (key: string): void => {
    delete this.listeners[key]
  }

  private getBreakpoint = (): 'Mixed' | SelectorBreakpoint => {
    const nodes = this.documentSelection.getSelected()
    if (nodes.length === 0) return 'default'

    const modes = nodes.map((node) => getOverrideMode(node, this.document))
    const first = modes[0]
    if (modes.every((mode) => mode === first)) return first
    return 'Mixed'
  }

  private getPseudo = (): 'Mixed' | SelectorPseudo => {
    const nodes = this.documentSelection.getSelected()
    if (nodes.length === 0) return 'Mixed'

    const pseudoModes = nodes.map((node) => node.getActivePseudo())
    const first = pseudoModes[0]
    if (pseudoModes.every((mode) => mode === first)) return first
    return 'Mixed'
  }

  private getOptions = (): SelectorBreakpoint[] => {
    const containers = this.getOverrideContainers()
    if (
      containers.every((c) => doesContainerAllowOverrides(c, this.document))
    ) {
      return ['default', 'tablet', 'landscape', 'mobile']
    } else {
      return ['default']
    }
  }

  private getBreakpointHasOverrides = (): BreakpointHasOverrides => {
    const hasOverrides: BreakpointHasOverrides = {
      default: false,
      tablet: false,
      landscape: false,
      mobile: false,
    }

    const nodes = this.getNodes()
    if (nodes.length === 0) return hasOverrides

    for (const node of nodes) {
      for (const mode of Breakpoints) {
        if (mode === 'default') continue
        const selector = node.getSelectors().find((s) => s.name === mode)
        if (!selector) continue
        if (Object.keys(selector.styles).length > 0) {
          hasOverrides[mode] = true
        }
      }
    }

    return hasOverrides
  }

  private getPseudoHasOverrides = (): PseudoHasOverrides => {
    const hasOverrides: PseudoHasOverrides = {
      none: false,
      hover: false,
    }

    const nodes = this.getNodes()
    if (nodes.length === 0) return hasOverrides

    for (const node of nodes) {
      for (const pseudo of Pseudos as SelectorPseudo[]) {
        const selector = node
          .getSelectors()
          .find((s) => s.name.includes(pseudo))
        if (!selector) continue
        if (Object.keys(selector.styles).length > 0) {
          hasOverrides[pseudo] = true
        }
      }
    }

    return hasOverrides
  }

  private setBreakpoint = (mode: SelectorBreakpoint): void => {
    if (this.editingText) {
      this.applyCommand({ type: 'stopEditText' })
      this.applyCommand({ type: 'commit' })
    }
    const overrideContainers = this.getOverrideContainers()
    const commands: Command[] = []
    for (const container of overrideContainers) {
      commands.push(...this.buildCommandsForContainer(mode, container))
    }
    this.applyCommand(commands)
    this.commit()
    this.notifyListeners()
  }

  private setPseudo = (pseudo: SelectorPseudo): void => {
    if (this.editingText) {
      this.applyCommand({ type: 'stopEditText' })
      this.applyCommand({ type: 'commit' })
    }

    const mode = this.getBreakpoint()
    if (mode === 'Mixed') return

    const commands: Command[] = []
    for (const node of this.getNodes()) {
      commands.push({
        type: 'setNodeSelector',
        params: { id: node.getId(), pseudo: pseudo },
      })
    }

    this.applyCommand(commands)
    this.commit()
    this.notifyListeners()
  }

  private getOverrideContainers = (): ReadOnlyNode[] => {
    const nodes = this.getNodes()
    const containers = nodes
      .map((node) => getOverrideContainer(node, this.document))
      .filter((c) => c) as ReadOnlyNode[]

    const ids = new Set(containers.map((c) => c.getId()))
    return Array.from(ids)
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private isSelected = (id: string): boolean => {
    const selected = this.documentSelection.getSelected()
    return selected.some((node) => node.getId() === id)
  }

  private buildCommandsForContainer = (
    mode: SelectorBreakpoint,
    container: ReadOnlyNode
  ): Command[] => {
    const descendants = this.document.getDescendants(container)
    const commands: Command[] = []
    commands.push({
      type: 'setNodeSelector',
      params: { id: container.getId(), breakpoint: mode },
    })
    for (const descendant of descendants) {
      commands.push({
        type: 'setNodeSelector',
        params: { id: descendant.getId(), breakpoint: mode },
      })
    }
    if (container.getBaseAttribute('type') === 'page') {
      const width = getWidthForMode(mode)
      commands.push({
        type: 'setNodeAttribute',
        params: {
          id: container.getId(),
          base: {},
          style: { 'size.w.auto': 'fixed', 'size.w': width },
        },
      })
    }
    return commands
  }

  private getNodes = (): ReadOnlyNode[] => {
    const unfilteredPairs = this.getUnfilteredPairs()
    return unfilteredPairs.map(([node]) => node)
  }

  private getUnfilteredPairs = (): [ReadOnlyNode, ReadOnlyNode | null][] => {
    const nodes = this.documentSelection.getSelected()
    if (nodes.length === 0) return []

    const pairs: [ReadOnlyNode, ReadOnlyNode | null][] = []
    for (const node of nodes) {
      const parent = this.document.getParent(node)
      pairs.push([node, parent || null])
    }

    return pairs
  }

  private commit = (): void => {
    this.commandHandler.handle({ type: 'commit' })
  }

  private applyCommand = (command: Command | Command[]): void => {
    this.commandHandler.handle(command)
  }

  private notifyListeners = (): void => {
    for (const key in this.listeners) {
      this.listeners[key](this.getSettings())
    }
  }
}
