import { WriteDocument } from 'application/document'
import { PasteSnapshot } from '../../types'
import { PasteTargetHandler } from '../paste'
import { DocumentSelection } from 'application/selection'
import { PostPasteRule } from './types'

export class PasteOnCanvas implements PasteTargetHandler {
  private document: WriteDocument
  private documentSelection: DocumentSelection
  private insertionRules: PostPasteRule[]

  constructor(
    document: WriteDocument,
    documentSelection: DocumentSelection,
    insertionRules: PostPasteRule[]
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.insertionRules = insertionRules
  }

  paste = (snapshot: PasteSnapshot): void => {
    for (const id of snapshot.ids) {
      this.addChild(id, snapshot)
    }
  }

  private addChild = (id: string, snapshot: PasteSnapshot): void => {
    const node = snapshot.nodes[id]
    if (!node) return

    const parentId = this.documentSelection.getSelectedCanvas()
    if (!parentId) return

    const parent = this.document.getNode(parentId)
    if (!parent) return

    node.setParent(parentId)
    parent.addChild(id, 0)

    for (const rule of this.insertionRules) {
      rule.postInsert(node, parent)
    }
  }
}
