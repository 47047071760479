import { WriteDocument } from 'application/document'
import {
  LayoutDependencyGraphFactory,
  LayoutDependencyNode,
  LayoutEngineCalculator,
} from '../types'
import { getNonDependentNodes, removeNode } from '../dependency/utils'
import { PositionEngineCalculator } from './types'

export class PositionLayoutEngine implements LayoutEngineCalculator {
  private graphFactory: LayoutDependencyGraphFactory
  private positionCalculators: PositionEngineCalculator[]

  constructor(
    graphFactory: LayoutDependencyGraphFactory,
    calculators: PositionEngineCalculator[]
  ) {
    this.graphFactory = graphFactory
    this.positionCalculators = calculators
  }

  layout(nodes: Set<string>, document: WriteDocument): void {
    const graph = this.graphFactory.create(nodes, document)
    let terminalNodes = getNonDependentNodes(graph)
    while (terminalNodes.length > 0) {
      for (const terminalNode of terminalNodes) {
        this.recomputePosition(terminalNode, document)
        removeNode(terminalNode, graph)
      }
      terminalNodes = getNonDependentNodes(graph)
    }
  }

  private recomputePosition = (
    layoutNode: LayoutDependencyNode,
    document: WriteDocument
  ): void => {
    const node = document.getNode(layoutNode.documentNodeId)
    if (!node) return

    for (const calculator of this.positionCalculators) {
      calculator.update(node, document)
    }
  }
}
