import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import TitleRow from 'components/Library/Components/Button/TitleRow/TitleRow'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TargetInput from 'components/Library/Components/Inputs/TargetInput/TargetInput'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'
import IconSetBlock from 'components/Library/Containers/IconSetBlock/IconSetBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useRef } from 'react'

export default function LinkSettings() {
  const sectionRef = useRef<HTMLDivElement>(null)
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getInteractionLinkPanel()
  )
  if (!settings || !settings.allowed || !settings.attributes) return <></>

  const {
    addLink,
    removeLink,
    setMode,
    setUrl,
    setNewTab,
    setEmail,
    setSubject,
    setPage,
    setNode,
    setScrollSmooth,
    setScrollPosition,
  } = handlers

  const { pageTargets, nodeTargets, attributes } = settings
  const {
    'link.mode': mode,
    'link.url': url,
    'link.newTab': newTab,
    'link.email': email,
    'link.subject': subject,
    'link.page': pageId,
    'link.node': nodeId,
    'link.scroll.position': scrollPosition,
    'link.scroll.smooth': smooth,
  } = attributes

  const handleClickRow = () => {
    if (!mode) addLink()
  }

  const handleClickIcon = () => {
    mode ? removeLink() : addLink()
  }

  return (
    <PanelSection sectionRef={sectionRef}>
      <TitleRow
        title={'Link'}
        tooltipKey={'InteractionsLink'}
        active={mode !== undefined}
        canDeactivate={mode !== undefined}
        sectionRef={sectionRef}
        onClickRow={handleClickRow}
        onClickIcon={handleClickIcon}
      />
      {mode !== undefined && (
        <PanelRow>
          <IconSetBlock>
            {linkModes.map((m) => (
              <IconBlock
                key={m}
                icon={modeToIcon(m)}
                tooltipKey={modeToTooltipKey(m)}
                selected={m === mode}
                onClick={() => setMode(m)}
              />
            ))}
          </IconSetBlock>
        </PanelRow>
      )}
      {mode === 'page' && (
        <>
          <PanelRow>
            <TargetInput
              icon={'Target'}
              tooltipKey={'InteractionsLinkPageTarget'}
              tooltipDirection={'center'}
              width={192}
              id={pageId || ''}
              options={pageTargets}
              placeholder={'Select a Page'}
              onSelect={(v) => {
                if (v === null) return
                setPage(v)
              }}
            />
          </PanelRow>
          <PanelRow>
            <ButtonBlock
              icon={newTab ? 'CheckboxChecked' : 'CheckboxUnchecked'}
              onClick={() => setNewTab(!newTab)}
              text={'New Tab'}
            />
          </PanelRow>
        </>
      )}
      {mode === 'url' && (
        <>
          <PanelRow>
            <TextInputBlock
              icon={'Link'}
              tooltipKey={'InteractionsLinkURL'}
              width={216}
              value={url || ''}
              setValue={setUrl}
              placeholder={'Enter URL'}
              commit={true}
            />
          </PanelRow>
          <PanelRow>
            <ButtonBlock
              icon={newTab ? 'CheckboxChecked' : 'CheckboxUnchecked'}
              onClick={() => setNewTab(!newTab)}
              text={'New Tab'}
            />
          </PanelRow>
        </>
      )}
      {mode === 'email' && (
        <>
          <PanelRow>
            <TextInputBlock
              width={216}
              value={email || ''}
              setValue={setEmail}
              placeholder={'Email (example@gmail.com)'}
              commit={true}
            />
          </PanelRow>
          <PanelRow>
            <TextInputBlock
              width={216}
              value={subject || ''}
              setValue={setSubject}
              placeholder={`Subject (You've got mail)`}
              commit={true}
            />
          </PanelRow>
        </>
      )}
      {mode === 'scroll' && (
        <>
          <PanelRow>
            <TargetInput
              icon={'Target'}
              tooltipKey={'InteractionsLinkScrollTarget'}
              tooltipDirection={'center'}
              width={192}
              id={nodeId || ''}
              options={nodeTargets}
              placeholder={'Select an Element'}
              onSelect={(v) => {
                if (v === null) return
                setNode(v)
              }}
            />
          </PanelRow>
          <PanelRow>
            <ButtonBlock
              icon={smooth ? 'CheckboxChecked' : 'CheckboxUnchecked'}
              text={'Smooth'}
              onClick={() => setScrollSmooth(!smooth)}
            />
            <IconSetBlock>
              {scrollPositionModes.map((p) => (
                <IconBlock
                  key={p}
                  icon={scrollPositionToIcon(p)}
                  tooltipKey={scrollPositionToTooltipKey(p)}
                  selected={p === scrollPosition}
                  onClick={() => setScrollPosition(p)}
                />
              ))}
            </IconSetBlock>
          </PanelRow>
        </>
      )}
    </PanelSection>
  )
}

const linkModes: ('page' | 'url' | 'email' | 'scroll')[] = [
  'page',
  'url',
  'scroll',
  'email',
]

function modeToIcon(mode: string): IconKey {
  switch (mode) {
    case 'page':
      return 'Page'
    case 'url':
      return 'Link'
    case 'scroll':
      return 'ScrollLink'
    case 'email':
      return 'Email'
    default:
      return 'Page'
  }
}

function modeToTooltipKey(mode: string): tooltipKey {
  switch (mode) {
    case 'page':
      return 'InteractionsLinkModePage'
    case 'url':
      return 'InteractionsLinkModeURL'
    case 'scroll':
      return 'InteractionsLinkModeScroll'
    case 'email':
      return 'InteractionsLinkModeEmail'
    default:
      return 'InteractionsLinkModePage'
  }
}

const scrollPositionModes: ('top' | 'bottom' | 'center')[] = [
  'top',
  'center',
  'bottom',
]

const scrollPositionToIcon = (position: string): IconKey => {
  switch (position) {
    case 'top':
      return 'TopOfPage'
    case 'center':
      return 'MiddleOfPage'
    case 'bottom':
      return 'BottomOfPage'
    default:
      return 'TopOfPage'
  }
}

const scrollPositionToTooltipKey = (position: string): tooltipKey => {
  switch (position) {
    case 'top':
      return 'InteractionsLinkScrollTop'
    case 'center':
      return 'InteractionsLinkScrollCenter'
    case 'bottom':
      return 'InteractionsLinkScrollBottom'
    default:
      return 'InteractionsLinkScrollTop'
  }
}
