import { WSCommandDocumentUpdate, isHeartbeatAck } from 'application/service'
import { WebsocketService } from 'application/service/websocket'

type DisconnectListener = () => void

const isDevMode = process.env.REACT_APP_DEV_MODE === 'true'
const interval = isDevMode ? 10_000 : 1_000
const timeout = isDevMode ? 60_000 : 5_000

export class EditorWebsocketService {
  private service: WebsocketService

  private listeners: { [key: string]: DisconnectListener }
  private heartbeatInterval: NodeJS.Timeout | null
  private lastHeartbeatAck: number | null
  private failedMessages: number

  constructor(service: WebsocketService) {
    this.service = service
    this.addListeners()

    this.listeners = {}
    this.heartbeatInterval = null
    this.lastHeartbeatAck = null
    this.failedMessages = 0
  }

  open = (): void => {
    this.service.open()
  }

  subscribe = (key: string, listener: DisconnectListener): void => {
    this.listeners[key] = listener
  }

  unsubscribe = (key: string): void => {
    delete this.listeners[key]
  }

  sendDocumentUpdate = (command: WSCommandDocumentUpdate): void => {
    const result = this.service.sendCommand(command)
    if (!result) {
      this.failedMessages++
      this.failedMessageCheck()
    } else {
      this.failedMessages = 0
    }
  }

  private addListeners = (): void => {
    this.service.addConnectFailedListener('ws_service', this.onConnectError)
    this.service.addOpenListener('ws_service', this.onOpen)
    this.service.addCloseListener('ws_service', this.onClose)
    this.service.addMessageListener('ws_service', this.onMessage)
  }

  private sendHeartbeat = (): void => {
    const result = this.service.sendCommand({
      type: 'heartbeat',
      token: '',
      data: {
        timestamp: Date.now(),
      },
    })
    if (!result) {
      this.failedMessages++
      this.failedMessageCheck()
    } else {
      this.failedMessages = 0
    }
  }

  private onConnectError = (): void => {
    this.stopHeartbeat()
    this.notifyDisconnect()
  }

  private onOpen = (): void => {
    this.startHeartbeat()
  }

  private onClose = (): void => {
    if (isDevMode) return
    this.stopHeartbeat()
    this.notifyDisconnect()
  }

  private onMessage = (data?: object): void => {
    if (!data) return
    const isHeartbeat = isHeartbeatAck(data)
    if (isHeartbeat) {
      console.debug('[WS] Heartbeat received')
      this.lastHeartbeatAck = Date.now()
    }
  }

  private startHeartbeat = (): void => {
    this.lastHeartbeatAck = Date.now()
    this.heartbeatRoutine()
  }

  private stopHeartbeat = (): void => {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
  }

  private heartbeatRoutine = (): void => {
    if (!this.lastHeartbeatAck) return
    if (Date.now() - this.lastHeartbeatAck > timeout) {
      this.stopHeartbeat()
      this.service.close()
      this.notifyDisconnect()
      return
    }
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
    this.sendHeartbeat()
    this.heartbeatInterval = setInterval(this.heartbeatRoutine, interval)
  }

  private failedMessageCheck = (): void => {
    if (this.failedMessages >= 3) {
      this.stopHeartbeat()
      this.service.close()
      this.notifyDisconnect()
    }
  }

  private notifyDisconnect = (): void => {
    for (const key in this.listeners) {
      this.listeners[key]()
    }
  }
}
