import { TemplateInfo } from 'application/service'
import TemplateCard from './TemplateCard'

interface TemplatesDisplayProps {
  templates: TemplateInfo[]

  select(template: TemplateInfo): void
  copy(template: TemplateInfo): void
}

export default function TemplatesDisplay({
  templates,
  select,
  copy,
}: TemplatesDisplayProps) {
  return (
    <div
      style={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridAutoRows: 'auto',
        padding: '24px',
        boxSizing: 'border-box',
        gap: 24,
        flexWrap: 'wrap',
        overflowY: 'scroll',
      }}
    >
      {templates.map((template, index) => (
        <TemplateCard
          key={`${template.name}-${index}`}
          template={template}
          select={select}
          copy={copy}
        />
      ))}
    </div>
  )
}
