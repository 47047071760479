import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import LinkSettings from './LinkSettings/LinkSettings'
import VisibilitySettings from './InteractionVisibilitySettings/InteractionVisiblitySettings'
import AnimationSettings from './AnimationSettings/AnimationSettings'

export default function InteractionSettings() {
  return (
    <ScrollContainer>
      <LinkSettings />
      <VisibilitySettings />
      <AnimationSettings />
      <SidepanelBottom />
    </ScrollContainer>
  )
}

function SidepanelBottom() {
  return (
    <div
      style={{
        width: 232,
        height: 100,
      }}
    />
  )
}
