import useClosePopup from 'hooks/ui/useClosePopup'
import { useRef } from 'react'
import { dropdownOption } from './MenuDropdown'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import { DesignColor } from 'themes'
import { panelShadow } from 'components/Library/Containers/constants'
import MenuDropdownDrawerRow from './MenuDropdownDrawerRow'

interface MenuDropdownDrawerProps<T> {
  open: boolean
  sourceRef: React.RefObject<HTMLDivElement>
  options: dropdownOption<T>[]

  close: () => void
  setSelected: (value: T) => void
}

export default function MenuDropdownDrawer<T>({
  open,
  sourceRef,
  options,
  close,
  setSelected,
}: MenuDropdownDrawerProps<T>) {
  const popupRef = useRef<HTMLDivElement>(null)

  useClosePopup({
    ref: popupRef,
    close: close,
    exceptionRef: sourceRef,
  })

  const rect = sourceRef.current?.getBoundingClientRect()

  if (!open || !rect) return <></>

  return (
    <Portal>
      <div
        ref={popupRef}
        style={{
          position: 'absolute',
          top: rect.top,
          left: rect.left,
          width: rect.width,
          background: DesignColor('panelBackground'),
          border: `1px solid ${DesignColor('inputHighlight')}`,
          boxShadow: panelShadow,
          zIndex: 2,
        }}
      >
        {options.map((option) => (
          <MenuDropdownDrawerRow
            key={option.label}
            option={option}
            setSelected={setSelected}
            close={close}
          />
        ))}
      </div>
    </Portal>
  )
}
