import { AttributeInteractionVisibility } from 'application/attributes'
import TitleRow from 'components/Library/Components/Button/TitleRow/TitleRow'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TargetInput from 'components/Library/Components/Inputs/TargetInput/TargetInput'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useRef, useState } from 'react'

export default function VisibilitySettings() {
  const sectionRef = useRef<HTMLDivElement>(null)
  const [hoveredRow, setHoveredRow] = useState<number>(-1)

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getInteractionVisibilityPanel()
  )
  if (!settings || !settings.allowed || !settings.attributes) return <></>

  const { add, remove, update, clearMixed } = handlers

  const { attributes, options, allowed } = settings
  const { 'interaction.visibility': interactions } = attributes

  const handleClickRow = () => {
    if (interactions === undefined || interactions.length === 0) add()
  }

  const handleClickIcon = () => {
    interactions === 'Mixed' ? clearMixed() : add()
  }

  if (!allowed) return <></>

  return (
    <PanelSection sectionRef={sectionRef}>
      <TitleRow
        title={'Show / Hide'}
        tooltipKey={'InteractionsShowHide'}
        active={interactions !== undefined && interactions.length > 0}
        canActivate={true}
        canDeactivate={false}
        sectionRef={sectionRef}
        onClickRow={handleClickRow}
        onClickIcon={handleClickIcon}
      />
      {interactions === 'Mixed' && (
        <PanelRow>
          <TextBlock width={192} mode={'label2'}>
            Click + to clear mixed
          </TextBlock>
        </PanelRow>
      )}
      {interactions !== 'Mixed' && interactions !== undefined && (
        <>
          {interactions.map((i, index) => (
            <PanelRow
              key={index}
              onMouseEnter={() => setHoveredRow(index)}
              onMouseLeave={() => setHoveredRow(-1)}
            >
              <Dropdown
                selected={i.mode}
                options={modeOptions}
                hasIcon={true}
                select={(v) => {
                  setHoveredRow(-1)
                  update(index, { ...i, mode: v })
                }}
                commit={true}
              />
              <TargetInput
                icon="Target"
                width={96}
                id={i.targetId}
                options={options}
                onSelect={(v) => {
                  if (v === null) return
                  update(index, { ...i, targetId: v })
                }}
                commit={true}
              />
              {hoveredRow === index && (
                <IconBlock
                  icon="X"
                  onClick={() => {
                    remove(index)
                    if (index === interactions.length - 1) setHoveredRow(-1)
                  }}
                />
              )}
            </PanelRow>
          ))}
        </>
      )}
    </PanelSection>
  )
}

const modeOptions: dropdownOption<AttributeInteractionVisibility['mode']>[] = [
  {
    text: 'Toggle',
    value: 'toggle',
    icon: 'VisibleTrue',
  },
  {
    text: 'Show',
    value: 'show',
    icon: 'VisibleTrue',
  },
  {
    text: 'Hide',
    value: 'hide',
    icon: 'VisibleFalse',
  },
]
