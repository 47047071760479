import { panelShadow } from 'components/Library/Containers/constants'
import MenuButton from 'components/Library/Menus/MenuButton'
import { DesignColor } from 'themes'

interface ProjectGridEmptySearchProps {
  setSearchString: (searchString: string) => void
}

export default function ProjectGridEmptySearch({
  setSearchString,
}: ProjectGridEmptySearchProps) {
  return (
    <div
      style={{
        height: '400px',
        margin: '0px 24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        background: DesignColor('panelBackground'),
        boxShadow: [
          panelShadow,
          `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
        ].join(', '),
      }}
    >
      <h2
        style={{
          fontSize: '16px',
          fontWeight: 400,
          color: DesignColor('text1'),
        }}
      >
        <span>No search results found</span>
      </h2>
      <MenuButton
        iconKey="X"
        label="Clear Search"
        onClick={() => setSearchString('')}
      />
    </div>
  )
}
