import { DocumentSelection } from 'application/selection'
import { Copy } from '../action/copy/copy'
import { Paste } from '../action/paste/paste'
import { PastePositionHandlerFactory } from '../action/paste/position/factory'
import { PasteTargetHandlerFactory } from '../action/paste/target/factory'
import { Command, CommandHandler } from './types'
import { ClientDocumentHelper } from '../document/helper'
import { CopySnapshot } from '../action/types'
import { ProjectDocumentNodeMap } from 'application/service'
import { Node } from 'application/node'
import { Point } from 'application/shapes'
import { CopyAttributes } from '../action/copyAttributes/copyAttributes'
import { PasteAttributes } from '../action/pasteAttributes/pasteAttributes'
import { CopyAnimation } from '../action/copyAnimation/copyAnimation'
import { PasteAnimation } from '../action/pasteAnimation/pasteAnimation'

export class NodeActionCommandHandler implements CommandHandler {
  private documentHelper: ClientDocumentHelper
  private documentSelection: DocumentSelection
  private copy: Copy
  private copyAttributes: CopyAttributes
  private copyAnimation: CopyAnimation
  private paste: Paste
  private pasteAttributes: PasteAttributes
  private pasteAnimation: PasteAnimation
  private pasteTargetFactory: PasteTargetHandlerFactory
  private pastePositionFactory: PastePositionHandlerFactory

  constructor(
    documentHelper: ClientDocumentHelper,
    documentSelection: DocumentSelection,
    copy: Copy,
    copyAttributes: CopyAttributes,
    copyAnimation: CopyAnimation,
    paste: Paste,
    pasteAttributes: PasteAttributes,
    pasteAnimation: PasteAnimation,
    pasteTargetFactory: PasteTargetHandlerFactory,
    pastePositionFactory: PastePositionHandlerFactory
  ) {
    this.documentSelection = documentSelection
    this.documentHelper = documentHelper
    this.copy = copy
    this.copyAttributes = copyAttributes
    this.copyAnimation = copyAnimation
    this.paste = paste
    this.pasteAttributes = pasteAttributes
    this.pasteAnimation = pasteAnimation
    this.pasteTargetFactory = pasteTargetFactory
    this.pastePositionFactory = pastePositionFactory
  }

  handle = (command: Command): void => {
    switch (command.type) {
      case 'copySelectedNodes':
        this.handleCopy(command.params.clipboard)
        break
      case 'copyOutsideDocumentNodes':
        this.handleCopyOutsideDocumentNodes(
          command.params.ids,
          command.params.nodes
        )
        break
      case 'copySelectedNodeAttributes':
        this.handleCopyAttributes()
        break
      case 'copySelectedNodeAnimation':
        this.handleCopyAnimation(command.params.index)
        break
      case 'cutSelectedNodes':
        this.handleCut()
        break
      case 'pasteNodes':
        this.handlePaste()
        break
      case 'pasteNodeAttributes':
        this.handlePasteAttributes()
        break
      case 'pasteOutsideDocumentNodes':
        this.handlePasteOutsideDocumentNodes(
          command.params.ids,
          command.params.nodes
        )
        break
      case 'duplicateSelectedNodes':
        this.handleDuplicate(command.params.addOffset)
        break
      case 'replaceSelectedNodes':
        this.handleReplace()
        break
    }
  }

  private handleCopy = (clipboard: boolean): void => {
    const selected = this.documentSelection.getSelected()
    if (selected.length === 0) return

    this.copy.saveSnapshot()
    this.copyAnimation.clearSnapshot()
    this.copyAttributes.clearSnapshot()

    if (clipboard) {
      this.addCopySnapshotToClipboard(this.copy.getSnapshot())
    } else {
      this.clearClipboard()
    }
  }

  private handleCopyAttributes = (): void => {
    const selected = this.documentSelection.getSelected()
    if (selected.length === 0) return

    this.copy.clearSnapshot()
    this.copyAnimation.clearSnapshot()
    this.copyAttributes.saveSnapshot()
  }

  private handleCopyAnimation = (index: number): void => {
    this.copy.clearSnapshot()
    this.copyAttributes.clearSnapshot()
    this.copyAnimation.saveSnapshot(index)
  }

  private handleCopyOutsideDocumentNodes = (
    ids: string[],
    nodes: ProjectDocumentNodeMap
  ): void => {
    const snapshot = this.projectNodeMapToCopySnapshot(ids, nodes)
    this.copy.setSnapshot(snapshot)
  }

  private handleCut = (): void => {
    const selected = this.documentSelection.getSelected()
    if (selected.length === 0) return

    this.copy.saveSnapshot()

    this.documentSelection.select([], true)

    for (const node of selected) {
      this.documentHelper.deleteNode(node.getId())
    }

    this.clearClipboard()
  }

  private handlePaste = (): void => {
    const animationSnapshot = this.copyAnimation.getSavedSnapshot()
    if (animationSnapshot) {
      this.pasteAnimation.pasteAnimation(animationSnapshot)
      return
    }

    const attributeSnapshot = this.copyAttributes.getSavedSnapshot()
    if (attributeSnapshot) {
      this.pasteAttributes.pasteAttributes(attributeSnapshot)
      return
    }

    const nodeSnapshot = this.copy.getSavedSnapshot()
    if (!nodeSnapshot) return

    const targets = this.pasteTargetFactory.createPaste(nodeSnapshot)
    const position = this.pastePositionFactory.createPaste(nodeSnapshot)

    this.documentSelection.select([], true)

    for (const target of targets) {
      this.paste.paste(nodeSnapshot, target, position)
    }
  }

  private handlePasteAttributes = (): void => {
    const snapshot = this.copyAttributes.getSavedSnapshot()
    if (!snapshot) return

    this.pasteAttributes.pasteAttributes(snapshot)
  }

  private handlePasteOutsideDocumentNodes = (
    ids: string[],
    nodes: ProjectDocumentNodeMap
  ): void => {
    const snapshot = this.projectNodeMapToCopySnapshot(ids, nodes)

    const targets = this.pasteTargetFactory.createOutsidePaste()
    const position = this.pastePositionFactory.createPaste(
      snapshot,
      false,
      true
    )

    this.documentSelection.select([], true)

    for (const target of targets) {
      this.paste.paste(snapshot, target, position, false)
    }
  }

  private handleDuplicate = (addOffset: boolean): void => {
    const snapshot = this.copy.getSnapshot()
    if (!snapshot) return

    const targets = this.pasteTargetFactory.createPaste(snapshot)
    const position = this.pastePositionFactory.createPaste(snapshot, addOffset)

    this.documentSelection.select([], true)

    for (const target of targets) {
      this.paste.paste(snapshot, target, position)
    }
  }

  private handleReplace = (): void => {
    const selected = this.documentSelection.getSelected()
    if (selected.length === 0) return

    const snapshot = this.copy.getSavedSnapshot()
    if (!snapshot) return

    this.documentSelection.select([], true)

    for (const node of selected) {
      const target = this.pasteTargetFactory.createReplace(node)
      const position = this.pastePositionFactory.createReplace(node)
      this.documentHelper.deleteNode(node.getId())
      this.paste.paste(snapshot, target, position)
    }
  }

  private projectNodeMapToCopySnapshot = (
    ids: string[],
    projectNodes: ProjectDocumentNodeMap
  ): CopySnapshot => {
    const canvasId = this.documentSelection.getSelectedCanvas()
    const nodes: { [key: string]: Node } = {}
    const canvasChild: { [key: string]: boolean } = {}
    const outsideParent: { [key: string]: boolean } = {}
    const offsets: { [key: string]: Point } = {}
    const indexes: { [key: string]: number } = {}

    for (const id of Object.keys(projectNodes)) {
      const node = this.documentHelper.createNodeFromProjectNode(
        projectNodes[id]
      )
      nodes[id] = node
    }

    for (const id of ids) {
      canvasChild[id] = true
      outsideParent[id] = false
      offsets[id] = { x: 0, y: 0 }
      indexes[id] = 0
    }

    return {
      ids,
      canvasId,
      nodes,
      canvasChild,
      outsideParent,
      offsets,
      indexes,
    }
  }

  private addCopySnapshotToClipboard = (snapshot: CopySnapshot): void => {
    const nodeMap: ProjectDocumentNodeMap = {}
    for (const key of Object.keys(snapshot.nodes)) {
      const node = this.documentHelper.createProjectNodeFromNode(
        snapshot.nodes[key]
      )
      nodeMap[key] = node
    }
    navigator.clipboard.writeText(
      JSON.stringify({
        schema: this.getSchemaVersion(),
        ids: snapshot.ids,
        nodes: nodeMap,
      })
    )
  }

  private clearClipboard = (): void => {
    navigator.clipboard.writeText('')
  }

  private getSchemaVersion = (): number => {
    return process.env.REACT_APP_DOCUMENT_SCHEMA_VERSION
      ? parseInt(process.env.REACT_APP_DOCUMENT_SCHEMA_VERSION)
      : 0
  }
}
