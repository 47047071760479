import { ReadOnlyDocumentSelection } from 'application/selection'
import { ClientTextEditor } from '../textEditor/textEditor'
import { Command, CommandHandler } from './types'

export class TextEditorCommandHandler implements CommandHandler {
  private clientTextEditor: ClientTextEditor
  private documentSelection: ReadOnlyDocumentSelection

  constructor(
    clientTextEditor: ClientTextEditor,
    documentSelection: ReadOnlyDocumentSelection
  ) {
    this.clientTextEditor = clientTextEditor
    this.documentSelection = documentSelection
  }

  handle = (command: Command): void => {
    switch (command.type) {
      case 'editText':
        const id = this.getSelectedTextNode()
        if (id) this.clientTextEditor.setEditing(id)
        return
      case 'stopEditText':
        this.clientTextEditor.setEditing(null)
        return
      case 'editTextCommand':
        if (command.params.command.type === 'history') return
        if (command.params.command.type === 'none') return
        this.clientTextEditor.handleCommand(command.params.command)
        return
    }
  }

  private getSelectedTextNode = (): string | null => {
    const selection = this.documentSelection.getSelected()
    if (!selection) return null
    if (selection.length !== 1) return null

    const node = selection[0]
    if (node.getBaseAttribute('type') !== 'text') return null

    return node.getId()
  }
}
