import { ActionHandler } from '../types'
import { CommandHandler } from 'application/client'
import { NodeCreateAction, NodeSelectionAction } from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { DrawAction } from './action'
import { AttributeType } from 'application/attributes'
import { HapticDrawingWindow } from 'editor/haptic/drawingWindow'

export class DrawActionFactory {
  private commandHandler: CommandHandler
  private createAction: NodeCreateAction
  private select: NodeSelectionAction
  private haptic: HapticDrawingWindow
  private coordinates: CoordinatesConversion

  constructor(
    commandHandler: CommandHandler,
    createAction: NodeCreateAction,
    select: NodeSelectionAction,
    haptic: HapticDrawingWindow,
    coordinates: CoordinatesConversion
  ) {
    this.commandHandler = commandHandler
    this.createAction = createAction
    this.select = select
    this.haptic = haptic
    this.coordinates = coordinates
  }

  create = (type: AttributeType, hotkey: boolean = false): ActionHandler => {
    return new DrawAction(
      this.commandHandler,
      this.createAction,
      this.select,
      this.coordinates,
      this.haptic,
      type,
      hotkey
    )
  }
}
