import { AutolayoutAttributes } from 'application/attributes'
import { Node } from 'application/node'

export function splitChildrenIntoRows(
  attributes: AutolayoutAttributes,
  children: Node[]
): Node[][] {
  const { w, paddingLeft, paddingRight, gap } = attributes

  const rows: Node[][] = [[]]
  let rowIndex = 0
  let rowWidth = 0
  for (const child of children) {
    const childW = child.getBaseAttribute('w')
    const addedGap = rows[rowIndex].length > 0 ? gap : 0
    if (rowWidth + addedGap + childW > w - paddingLeft - paddingRight) {
      if (rows[rowIndex].length === 0) {
        rows[rowIndex].push(child)
        rows[rowIndex + 1] = []
        rowIndex++
        rowWidth = 0
      } else {
        rows[rowIndex + 1] = [child]
        rowIndex++
        rowWidth = childW
      }
    } else {
      rows[rowIndex].push(child)
      rowWidth += addedGap + childW
    }
  }

  if (rows[rows.length - 1].length === 0) rows.pop()

  return rows
}
