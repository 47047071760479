import RightClickRowButton from 'components/Library/Components/Button/RightClickRowButton/RightClickRowButton'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

interface CanvasesRightClickMenuProps {
  canvasId: string
  close: () => void
}

export default function CanvasesRightClickMenu({
  canvasId,
  close,
}: CanvasesRightClickMenuProps) {
  const { settings, handlers } = usePanelHelper(editor.getUI().getCanvasPanel())
  if (!settings) return <></>

  const { canvases } = settings
  const { setEditing, deleteCanvas } = handlers

  return (
    <PanelSection bottom={true}>
      <RightClickRowButton
        text={'Rename Canvas'}
        onClick={() => {
          setEditing(canvasId, true)
          close()
        }}
      />
      {canvases.length > 1 && (
        <RightClickRowButton
          text={'Delete Canvas'}
          onClick={() => {
            deleteCanvas(canvasId)
            close()
          }}
        />
      )}
    </PanelSection>
  )
}
