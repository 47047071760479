import AlignmentSettings from './Alignment/AlignmentSettings'
import AutolayoutSettings from './Autolayout/AutolayoutSettings'
import BackgroundSettings from './Background/BackgroundSettings'
import FilterBlurSettings from './FilterBlur/FilterBlurSettings'
import BorderSettings from './Border/BorderSettings'
import GeneralSettings from './General/GeneralSettings'
import ImageSettings from './Image/ImageSettings'
import SelectionColors from './SelectionColors/SelectionColors'
import ShadowSettings from './Shadow/ShadowSettings'
import TextSettings from './Text/TextSettings'
import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import TransitionSettings from './Transition/TransitionSettings'
import useMixedSelectors from 'hooks/editor/useMixedSelectors'
import MixedSelector from './MixedSelector/MixedSelector'
import CursorSettings from './Cursor/CursorSettings'

export default function StyleSettings() {
  const mixed = useMixedSelectors()

  return (
    <ScrollContainer>
      {mixed && <MixedSelector />}
      {!mixed && (
        <>
          <AlignmentSettings />
          <GeneralSettings />
          <BackgroundSettings />
          <TextSettings />
          <ImageSettings />
          <BorderSettings />
          <AutolayoutSettings />
          <ShadowSettings />
          <FilterBlurSettings />
          <TransitionSettings />
          <CursorSettings />
          <SelectionColors />
          <SidepanelBottom />
        </>
      )}
    </ScrollContainer>
  )
}

function SidepanelBottom() {
  return (
    <div
      style={{
        width: 232,
        height: 100,
      }}
    />
  )
}
