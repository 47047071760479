import { NodeSelectionAction } from 'application/action'
import { NodeWrapAction } from 'application/action/wrap'
import { canAttributeInsertInto, isContainerType } from 'application/attributes'
import { CommandHandler } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import {
  ReadOnlyDocumentSelection,
  computeSelectionRectangle,
} from 'application/selection'
import { Rectangle } from 'application/shapes'

export class GroupAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private selectionAction: NodeSelectionAction
  private wrap: NodeWrapAction

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    selectionAction: NodeSelectionAction,
    wrap: NodeWrapAction
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.selection = selection
    this.selectionAction = selectionAction
    this.wrap = wrap
  }

  group = (): void => {
    const selected = this.selection.getSelected()
    if (selected.length === 0) return

    const filtered = selected.filter((n) =>
      canAttributeInsertInto(
        n.getStyleAttributes(),
        n.getBaseAttribute('type'),
        'frame'
      )
    )
    const filteredIds = filtered.map((n) => n.getId())
    if (filtered.length === 0) return

    const wrapperId = this.wrap.wrap(filteredIds, 'frame')
    if (!wrapperId) return

    this.addGroupAttributes(wrapperId, filtered)
    this.selectionAction.selectNodes([wrapperId], true)
    this.commit()
  }

  ungroup = (): void => {
    const selected = this.selection.getSelected()
    if (selected.length === 0) return

    this.selectionAction.selectNodes([], true)

    for (const node of selected) {
      if (
        !isContainerType(node.getBaseAttribute('type')) ||
        node.getBaseAttribute('type') === 'page'
      ) {
        this.selectionAction.selectNodes([node.getId()], false)
      } else {
        const unwrapped = this.wrap.unwrap(node.getId())
        this.selectionAction.selectNodes(unwrapped, false)
      }
    }

    this.commit()
  }

  private addGroupAttributes = (
    nodeId: string,
    selected: ReadOnlyNode[]
  ): void => {
    const node = this.document.getNode(nodeId)
    if (!node) return

    const window = this.getWindow(selected)
    this.commandHandler.handle({
      type: 'setNodeAttribute',
      params: {
        id: nodeId,
        base: { h: window.h, w: window.w },
        style: { fills: [] },
        selector: 'default',
      },
    })
  }

  private commit = (): void => {
    this.commandHandler.handle({ type: 'commit' })
  }

  private getWindow = (nodes: ReadOnlyNode[]): Rectangle => {
    const window = computeSelectionRectangle(nodes)
    if (!window) return { x: 0, y: 0, w: 0, h: 0 }
    return window
  }
}
