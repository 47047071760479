import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import FillInputRow from 'components/Library/Components/Inputs/FillInputRow/FillInputRow'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useState } from 'react'

export default function SelectionColors() {
  const [expanded, setExpanded] = useState(false)

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getSelectionColorsPanel()
  )
  if (!settings) return <></>

  const { active, selection } = settings
  const { updateFill, startUpdate, endUpdate } = handlers
  if (!active || selection.length === 0) return <></>

  return (
    <PanelSection>
      <PanelRow>
        <TitleButtonBlock title="Selection Colors" active={true} />
      </PanelRow>
      {selection.slice(0, expanded ? selection.length : 3).map((c, i) => (
        <FillInputRow
          key={i}
          fill={c.fill}
          modes={['color', 'gradient']}
          updateFill={(f) => updateFill(c.fill, f, c.ids)}
          startUpdate={startUpdate}
          endUpdate={endUpdate}
        />
      ))}
      {!expanded && selection.length > 3 && (
        <PanelRow>
          <ButtonBlock
            icon={'DropdownArrowDown'}
            text={`+${selection.length - 3} colors`}
            onClick={() => setExpanded(true)}
          />
        </PanelRow>
      )}
    </PanelSection>
  )
}
