import {
  AttributeTransitionTiming,
  AttributeType,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type TransitionPanelKeys = 'transition.mode' | 'transition.duration'

export interface TransitionPanelState {
  duration: 'Mixed' | number
  timing: 'Mixed' | AttributeTransitionTiming
  mode: 'mixed' | 'add' | 'remove' | 'disabled'
}

export interface TransitionPanelHandlers {
  addTransition: () => void
  removeTransition: () => void
  clearMixed: () => void
  setTiming: (value: AttributeTransitionTiming) => void
  setDuration: (value: number) => void
  slideDuration: (value: number) => void
}

export class TransitionPanel extends StyleAttributePanel<
  TransitionPanelState,
  TransitionPanelHandlers,
  TransitionPanelKeys
> {
  getSettings(): TransitionPanelState {
    return {
      duration: this.getDuration(),
      timing: this.getTiming(),
      mode: this.getMode(),
    }
  }

  getHandlers(): TransitionPanelHandlers {
    return {
      addTransition: this.addTransition,
      removeTransition: this.removeTransition,
      clearMixed: this.clearMixed,
      setTiming: this.setTiming,
      setDuration: this.setDuration,
      slideDuration: this.slideDuration,
    }
  }

  private getDuration = (): number | 'Mixed' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'Mixed'

    const radius = nodes[0].getStyleAttribute('transition.duration')
    if (radius === undefined) return 'Mixed'

    for (const node of nodes) {
      if (node.getStyleAttribute('transition.duration') !== radius)
        return 'Mixed'
    }

    return radius
  }

  private getTiming = (): AttributeTransitionTiming | 'Mixed' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'Mixed'

    const timing = nodes[0].getStyleAttribute('transition.timing')
    if (timing === undefined) return 'Mixed'

    for (const node of nodes) {
      if (node.getStyleAttribute('transition.timing') !== timing) {
        return 'Mixed'
      }
    }

    return timing
  }

  private getMode = (): 'mixed' | 'add' | 'remove' | 'disabled' => {
    const nodes = this.getNodes()

    let hasTransition = false
    let hasNoTransition = false
    for (const node of nodes) {
      const mode = node.getStyleAttribute('transition.mode')
      if (mode === 'all') hasTransition = true
      else hasNoTransition = true
    }

    if (hasTransition && hasNoTransition) return 'mixed'
    if (hasTransition) return 'remove'
    if (hasNoTransition) return 'add'
    return 'disabled'
  }

  private addTransition = (): void => {
    this.setMulti({
      'transition.mode': 'all',
      'transition.timing': 'ease-in-out',
      'transition.duration': 0.3,
    })
    this.commit()
  }

  private removeTransition = (): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.setOne(node.getId(), {
        'transition.mode': 'none',
        'transition.timing': undefined,
        'transition.duration': undefined,
      })
    }
    this.commit()
  }

  private clearMixed = (): void => {
    const nodes = this.getNodes()
    let duration: number | undefined = undefined
    let timing: AttributeTransitionTiming | undefined = undefined
    for (const node of nodes) {
      const nodeDuration = node.getStyleAttribute('transition.duration')
      const nodeTiming = node.getStyleAttribute('transition.timing')
      if (nodeTiming === undefined || nodeDuration === undefined) continue
      duration = nodeDuration
      timing = nodeTiming
      break
    }
    if (duration === undefined || timing === undefined) return

    this.setMulti({
      'transition.mode': 'all',
      'transition.duration': duration,
      'transition.timing': timing,
    })
    this.commit()
  }

  private setTiming = (value: AttributeTransitionTiming): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.setOne(node.getId(), { 'transition.timing': value })
    }
    this.commit()
  }

  private setDuration = (value: number): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.setOne(node.getId(), {
        'transition.duration': Math.max(Math.min(value, 20), 0),
      })
    }
  }

  private slideDuration = (value: number): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      const current = node.getStyleAttribute('transition.duration') || 0
      this.setOne(node.getId(), {
        'transition.duration': Math.max(Math.min(current + value, 20), 0),
      })
    }
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 20
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'rectangle',
  'ellipse',
  'text',
  'image',
]
