import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PreviewButton from './PreviewButton/PreviewButton'
import ToolbarDivider from 'components/Library/Components/Toolbar/ToolbarDivider/ToolbarDivider'
import PublishButton from './PublishButton/PublishButton'
import SettingsButton from './SettingsButton/SettingsButton'

export default function RightTopbar() {
  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}
      width={232}
      height={48}
      paddingH={10}
      gap={8}
      direction="row"
    >
      <SettingsButton />
      <ToolbarDivider />
      <PreviewButton />
      <PublishButton />
    </PanelContainer>
  )
}
