import { NodeSelectionAction } from 'application/action'
import { NodeWrapAction } from 'application/action/wrap'
import {
  BaseMap,
  SelectorName,
  StyleMap,
  canAttributeInsertInto,
  createAutolayoutAttributes,
  isContainerType,
} from 'application/attributes'
import { Command } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import _ from 'lodash'

interface CommandHandler {
  handle: (command: Command | Command[]) => void
}

export class AddAutolayoutAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private selectionAction: NodeSelectionAction
  private wrap: NodeWrapAction

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    selectionAction: NodeSelectionAction,
    wrap: NodeWrapAction
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.selection = selection
    this.selectionAction = selectionAction
    this.wrap = wrap
  }

  addAutolayout = (): void => {
    const selected = this.selection.getSelected()
    if (selected.length === 0) return

    if (this.getShouldAdd(selected)) {
      const selector = this.getSelector(selected[0])
      this.addAutolayoutToContainer(selected, selector)
    } else {
      this.wrapSelected(selected, selected.length === 1)
    }
  }

  private wrapSelected = (selected: ReadOnlyNode[], offset: boolean): void => {
    const filtered = selected.filter((n) => {
      const attrs = n.getStyleAttributes()
      const type = n.getBaseAttribute('type')
      return canAttributeInsertInto(attrs, type, 'frame')
    })

    const filteredIds = filtered.map((n) => n.getId())
    const originalAttrs = this.getOriginalAttributes(filteredIds)

    const wrapperId = this.wrap.wrap(filteredIds, 'frame')
    if (!wrapperId) return

    const wrapper = this.document.getNode(wrapperId)
    if (!wrapper) return

    const x = wrapper.getBaseAttribute('x') - (offset ? 8 : 0)
    const y = wrapper.getBaseAttribute('y') - (offset ? 8 : 0)

    const newAttributes = createAutolayoutAttributes(
      wrapper,
      this.getChildren(wrapper),
      Object.values(originalAttrs),
      true
    )

    const mode = newAttributes['autolayout.direction'] === 'row' ? 'x' : 'y'
    this.sortChildren(wrapper, mode)

    const setWrapperAttributes = this.buildSetAttributesCommand(
      wrapperId,
      { x, y },
      { fills: [], ...newAttributes },
      'default'
    )
    this.commandHandler.handle(setWrapperAttributes)

    const setNodeAttributes = filteredIds.map((id) => {
      return this.buildSetAttributesCommand(
        id,
        {},
        {
          'position.mode': 'auto',
          'size.w': originalAttrs[id]['size.w'],
          'size.h': originalAttrs[id]['size.h'],
          'size.w.auto': originalAttrs[id]['size.w.auto'],
          'size.h.auto': originalAttrs[id]['size.h.auto'],
          'size.w.percent': originalAttrs[id]['size.w.percent'],
          'size.h.percent': originalAttrs[id]['size.h.percent'],
        },
        'default'
      )
    })
    this.commandHandler.handle(setNodeAttributes)

    this.selectionAction.selectNodes([wrapperId], true)
    this.sendCommit()
  }

  private addAutolayoutToContainer = (
    selected: ReadOnlyNode[],
    selector: SelectorName | undefined
  ): void => {
    if (selected.length !== 1) return

    const node = selected[0]
    const addedAttributes = createAutolayoutAttributes(
      node,
      this.getChildren(node)
    )

    const mode = addedAttributes['autolayout.direction'] === 'row' ? 'x' : 'y'
    this.sortChildren(node, mode)

    const setAttributes = this.buildSetAttributesCommand(
      node.getId(),
      {},
      { ...addedAttributes },
      selector
    )
    this.commandHandler.handle(setAttributes)

    this.sendCommit()
  }

  private sortChildren = (node: ReadOnlyNode, mode: 'x' | 'y'): void => {
    const children = node.getChildren()
    if (!children) return

    const sorted = children.slice().sort((a, b) => {
      const aNode = this.document.getNode(a)
      const bNode = this.document.getNode(b)
      if (!aNode || !bNode) return 0

      const aPos = aNode.getBaseAttribute(mode)
      const bPos = bNode.getBaseAttribute(mode)

      return aPos - bPos
    })

    this.commandHandler.handle({
      type: 'setChildOrder',
      params: { parentId: node.getId(), children: sorted },
    })
  }

  private buildSetAttributesCommand = (
    id: string,
    base: Partial<BaseMap>,
    style: Partial<StyleMap>,
    selector: SelectorName | undefined
  ): Command => {
    return {
      type: 'setNodeAttribute',
      params: { id, base, style, selector },
    }
  }

  private sendCommit = (): void => {
    this.commandHandler.handle({ type: 'commit' })
  }

  private getChildren = (node: ReadOnlyNode): ReadOnlyNode[] => {
    const children = node.getChildren() || []
    return children
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private getOriginalAttributes = (
    ids: string[]
  ): { [id: string]: StyleMap } => {
    const attributes: { [id: string]: StyleMap } = {}
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue
      attributes[id] = _.cloneDeep(node.getStyleAttributes())
    }
    return attributes
  }

  private getShouldAdd = (nodes: ReadOnlyNode[]): boolean => {
    return (
      nodes.length === 1 &&
      nodes[0].getStyleAttribute('autolayout.mode') !== 'flex' &&
      isContainerType(nodes[0].getBaseAttribute('type'))
    )
  }

  private getSelector = (node: ReadOnlyNode): SelectorName | undefined => {
    const defaultSelector = node.getDefaultSelector()
    if (!defaultSelector) return 'default'
    if (defaultSelector.styles['autolayout.mode'] !== undefined) {
      return undefined
    }

    const selectors = node.getSelectors()
    if (selectors.length === 0) return 'default'
    if (!selectors.some((s) => s.styles['autolayout.mode'] !== undefined)) {
      return 'default'
    }
  }
}
