import { IconKey } from 'assets/iconLibrary'
import { DesignColor } from 'themes'
import Text, { textStyles } from 'components/Library/Text/Text'
import { dropdownWidth } from 'components/Library/Containers/constants'

interface AnimationEffectPopupRowProps {
  width?: dropdownWidth

  icon?: IconKey
  title: string
  subtitle?: string
  style?: textStyles

  selected?: boolean

  hovered: boolean
  setHovered: (hovered: boolean) => void

  disabled?: boolean

  onClick?: () => void
}

export default function AnimationEffectPopupRow({
  width = 96,
  icon,
  title,
  hovered,
  setHovered,
  onClick,
}: AnimationEffectPopupRowProps) {
  const background = getBackground(hovered)

  return (
    <div
      style={{
        width,
        height: 24,
        padding: `0px ${icon ? 4 : 8}px`,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        borderRadius: 4,
        background: background,
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <Text>{title}</Text>
    </div>
  )
}

function getBackground(hovered: boolean) {
  return hovered ? DesignColor('inputTint') : 'none'
}
