import { DesignColor } from 'themes'
import Icon from '../Components/Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import { ProjectTab } from 'components/Pages/Projects/Projects'
import { useState } from 'react'

interface ProjectSidebarProps {
  activeTab: ProjectTab

  setTab: (tab: ProjectTab) => void
}

export default function ProjectSidebar({
  activeTab,
  setTab,
}: ProjectSidebarProps) {
  return (
    <div
      style={{
        width: '232px',
        height: '100%',
        padding: '24px 16px',
        boxSizing: 'border-box',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        backgroundColor: DesignColor('panelBackground'),
        borderRight: `1px solid ${DesignColor('panelBorder')}`,
      }}
    >
      <ProjectTabButton
        label="Projects"
        icon="Projects"
        selected={activeTab === 'Projects'}
        onClick={() => setTab('Projects')}
      />
      <ProjectTabButton
        label="Settings"
        icon="Settings"
        selected={activeTab === 'Settings'}
        onClick={() => setTab('Settings')}
      />
    </div>
  )
}

interface ProjectTabButtonProps {
  label: string
  icon: IconKey
  selected: boolean

  onClick: () => void
}

function ProjectTabButton({
  label,
  icon,
  selected,
  onClick,
}: ProjectTabButtonProps) {
  const [hovered, setHovered] = useState(false)
  const backgroundColor =
    selected || hovered ? DesignColor('grayInputTint') : 'transparent'
  const textColor = selected ? DesignColor('text1') : DesignColor('text2')
  return (
    <button
      style={{
        width: '100%',
        padding: '8px',
        gap: '8px',
        boxSizing: 'border-box',
        display: 'flex',
        backgroundColor: backgroundColor,
        color: textColor,
        border: 'none',
        cursor: 'pointer',
      }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Icon icon={icon} size={16} color={textColor} />
      {label}
    </button>
  )
}
