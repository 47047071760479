import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import { useState } from 'react'

interface ToolbarIconButtonLargeProps {
  buttonRef?: React.RefObject<HTMLDivElement>

  text: string
  active?: boolean

  onClick?: () => void
}

export default function ToolbarTextButtonLarge({
  buttonRef,
  text,
  active = false,
  onClick,
}: ToolbarIconButtonLargeProps) {
  const [hover, setHover] = useState(false)

  const textColor = getTextColor(hover)
  const background = getBackground(active, hover)
  const boxShadow = getBoxShadow(active)

  return (
    <div
      ref={buttonRef}
      style={{
        height: 48,
        padding: '0px 10px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background,
        boxShadow,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <Text styles={{ size: 11 }} color={textColor}>
        {text}
      </Text>
    </div>
  )
}

function getBackground(active: boolean, hovered: boolean) {
  if (active || hovered) return DesignColor('inputTint')
  return 'none'
}

function getBoxShadow(active: boolean) {
  if (active) return `inset 0px -1px 0px 0px ${DesignColor('inputHighlight')}`
  return 'none'
}

function getTextColor(hover: boolean) {
  return hover ? DesignColor('inputHighlight') : DesignColor('text1')
}
