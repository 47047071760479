import {
  activeProjectAtom,
  projectListAtom,
} from 'components/Pages/Projects/Projects'
import useBackendService from 'hooks/services/useBackendService'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

export default function useNavigatePage() {
  const backendService = useBackendService()
  const navigate = useNavigate()

  const setProjectsState = useSetRecoilState(projectListAtom)
  const setActiveProject = useSetRecoilState(activeProjectAtom)

  const toProjects = async () => {
    try {
      const projects = await backendService.getProjects()
      setProjectsState(projects)
    } catch (e) {
      console.error('Error loading projects', e)
    }
    navigate('/projects')
  }

  const toLogin = () => {
    navigate('/login')
  }

  const toSignUp = () => {
    navigate('/signup')
  }

  const toReset = () => {
    navigate('/reset')
  }

  const toEditor = async (projectId: string) => {
    try {
      const project = await backendService.getProject(projectId)
      setActiveProject(project)
      navigate(`/editor`)
    } catch (e) {
      console.error('Error loading project', e)
      throw e
    }
  }

  return {
    toProjects,
    toLogin,
    toSignUp,
    toReset,
    toEditor,
  }
}
