import { editor } from 'index'
import usePanelHelper from './usePanelHelper'

export default function useMixedSelectors() {
  const breakpointsPanel = usePanelHelper(
    editor.getUI().getBreakpointModePanel()
  )
  if (!breakpointsPanel.settings) return false
  if (breakpointsPanel.settings.breakpoint === 'Mixed') return true
  if (breakpointsPanel.settings.pseudo === 'Mixed') return true
  return false
}
