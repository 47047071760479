import { DesignColor } from 'themes'
import { IconKey } from 'assets/iconLibrary'
import { useRef, useState } from 'react'
import NumberInput from './NumberInput'
import ButtonIcon from '../../Icon/ButtonIcon/ButtonIcon'
import { tooltipKey } from 'assets/tooltips'
import Tooltip from '../../Tooltip/Tooltip'

interface ToolbarNumberBlockProps {
  icon: IconKey
  tooltipKey?: tooltipKey

  value: number
  setValue: (value: number) => void
}

export default function ToolbarNumberBlock({
  icon,
  tooltipKey,
  value,
  setValue,
}: ToolbarNumberBlockProps) {
  const blockRef = useRef<HTMLDivElement>(null)

  const [hover, setHover] = useState(false)
  const [editing, setEditing] = useState(false)

  const Element = (
    <Block
      blockRef={blockRef}
      hover={hover}
      editing={editing}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ButtonIcon icon={icon} color={DesignColor('text2')} />
      <NumberInput
        editing={editing}
        setEditing={setEditing}
        value={value}
        setValue={setValue}
        min={0}
        max={4000}
      />
    </Block>
  )

  if (tooltipKey) {
    return <Tooltip tooltipKey={tooltipKey}>{Element}</Tooltip>
  } else {
    return Element
  }
}

function Block({
  blockRef,
  editing,
  hover,
  children,
  onMouseEnter,
  onMouseLeave,
}: {
  blockRef: React.RefObject<HTMLDivElement>
  editing: boolean
  hover: boolean
  children?: React.ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}) {
  return (
    <div
      ref={blockRef}
      style={{
        width: 72,
        height: 48,
        padding: '0 4px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        boxShadow: editing
          ? `inset 0px -1px 0px 0px ${DesignColor('inputHighlight')}`
          : 'none',
        background: hover || editing ? DesignColor('inputTint') : 'none',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}
