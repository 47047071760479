import Text from 'components/Library/Text/Text'
import { DesignColor } from 'themes'

interface TemplatesNotFoundProps {
  clearSearch: () => void
}

export default function TemplatesNotFound({
  clearSearch,
}: TemplatesNotFoundProps) {
  return (
    <div
      style={{
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        justifyContent: 'start',
        padding: '24px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 264,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 16,
          backgroundColor: DesignColor('panelBackground'),
        }}
      >
        <Text styles={{ size: 16 }}>
          No templates found matching this search.
        </Text>
        <div
          style={{
            width: 'fit-content',
            height: 36,
            padding: '10px 8px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
            cursor: 'pointer',
          }}
        >
          <Text styles={{ size: 14, cursor: 'pointer' }} onClick={clearSearch}>
            Clear Search
          </Text>
        </div>
      </div>
    </div>
  )
}
