import {
  AttributeFill,
  AttributeSizeAuto,
  AttributeTextAlign,
} from 'application/attributes'
import { StyleAttributePanel } from '../styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import {
  TextPanel,
  TextPanelHandlers,
  TextPanelKeys,
  TextPanelState,
} from './interface'
import { State, Style, Styles, TextEditorStyles } from 'application/textEditor'
import {
  ClientUpdateListener,
  CommandHandler,
  Update,
} from 'application/client'
import { FontKey, FontWeight, ReadOnlyFontDataMap } from 'application/text'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'

export class TextPanelTextEditor
  extends StyleAttributePanel<TextPanelState, TextPanelHandlers, TextPanelKeys>
  implements TextPanel, ClientUpdateListener
{
  private textEditorState: State | null = null
  private fontMap: ReadOnlyFontDataMap
  private styles: Styles

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    fontMap: ReadOnlyFontDataMap
  ) {
    super(commandHandler, action, document, documentSelection)
    this.fontMap = fontMap
    this.styles = new TextEditorStyles(fontMap)
  }

  getSettings(): TextPanelState {
    if (!this.textEditorState || this.attributes === null) {
      return {
        attributes: null,
      }
    }

    const style = this.styles.getSelected(
      this.textEditorState.content,
      this.textEditorState.nextCharacter,
      this.textEditorState.selection
    )

    return {
      attributes: {
        'text.font.family': style.selected.fontFamily,
        'text.font.weight': style.selected.fontWeight,
        'text.font.size': style.selected.fontSize,
        'text.letterSpacing': style.selected.letterSpacing,
        'text.lineHeight': style.selected.lineHeight,
        'text.color': style.selected.fill,
        'text.align': style.selected.align,
        'size.w.auto': this.attributes['size.w.auto'],
        'size.h.auto': this.attributes['size.h.auto'],
      },
    }
  }

  getHandlers(): TextPanelHandlers {
    return {
      getFontData: this.fontMap.getFontData,
      setFontFamily: this.setFontFamily,
      setFontWeight: this.setFontWeight,
      setFontSize: this.setFontSize,
      slideFontSize: this.slideFontSize,
      setColor: this.setColor,
      clearMixedColor: this.clearMixedColor,
      setLetterSpacing: this.setLetterSpacing,
      slideLetterSpacing: this.slideLetterSpacing,
      setLineHeight: this.setLineHeight,
      slideLineHeight: this.slideLineHeight,
      setAlignment: this.setAlignment,
      setSizeAuto: this.setSizeAuto,
    }
  }

  getTypes = (): Update['type'][] => {
    return ['edit_text', 'text_editor_state']
  }

  onUpdate = (updates: Update[]): void => {
    for (const u of updates) {
      switch (u.type) {
        case 'edit_text':
          this.notifyListeners()
          break
        case 'text_editor_state':
          if (u.data.state === null) return
          this.textEditorState = u.data.state
          this.notifyListeners()
          break
      }
    }
  }

  private setFontFamily = (value: FontKey): void => {
    this.applyTextStyles({ fontFamily: value })
    this.commit()
  }

  private setFontWeight = (value: FontWeight): void => {
    this.applyTextStyles({ fontWeight: value })
    this.commit()
  }

  private setFontSize = (value: number): void => {
    this.applyTextStyles({ fontSize: value })
  }

  private slideFontSize = (value: number): void => {
    if (!this.textEditorState) return
    const style = this.styles.getSelected(
      this.textEditorState.content,
      this.textEditorState.nextCharacter,
      this.textEditorState.selection
    )
    const currentSize = style.selected.fontSize
    if (!currentSize) return

    if (currentSize === 'Mixed') {
      this.setFontSize(style.defaults.fontSize)
    } else {
      this.setFontSize(Math.max(currentSize + value, 1))
    }
  }

  private setColor = (value: AttributeFill): void => {
    this.applyTextStyles({ fill: value })
  }

  private clearMixedColor = (): void => {}

  private setLetterSpacing = (value: number): void => {
    this.applyTextStyles({ letterSpacing: value })
  }

  private slideLetterSpacing = (value: number): void => {
    if (!this.textEditorState) return
    const style = this.styles.getSelected(
      this.textEditorState.content,
      this.textEditorState.nextCharacter,
      this.textEditorState.selection
    )
    const currentLetterSpacing = style.selected.letterSpacing
    if (!currentLetterSpacing) return

    if (currentLetterSpacing === 'Mixed') {
      this.setLetterSpacing(style.defaults.letterSpacing)
    } else {
      this.setLetterSpacing(Math.max(currentLetterSpacing + value, -100))
    }
  }

  private setLineHeight = (value: number): void => {
    this.applyTextStyles({ lineHeight: value })
  }

  private slideLineHeight = (value: number): void => {
    if (!this.textEditorState) return
    const style = this.styles.getSelected(
      this.textEditorState.content,
      this.textEditorState.nextCharacter,
      this.textEditorState.selection
    )
    const currentLineHeight = style.selected.lineHeight
    if (!currentLineHeight) return

    if (currentLineHeight === 'Mixed') {
      this.setLineHeight(style.defaults.lineHeight)
    } else {
      this.setLineHeight(Math.max(currentLineHeight + value, -100))
    }
  }

  private setAlignment = (value: AttributeTextAlign): void => {
    this.applyTextStyles({ align: value })
    this.commit()
  }

  private setSizeAuto = (w: AttributeSizeAuto, h: AttributeSizeAuto): void => {
    this.setMulti({ 'size.w.auto': w, 'size.h.auto': h })
    this.commit()
  }

  private applyTextStyles = (style: Partial<Style>): void => {
    this.applyCommand({
      type: 'editTextCommand',
      params: { command: { type: 'allStyle', parameters: { style } } },
    })
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node) => node.getBaseAttribute('type') === 'text'
  }
}
