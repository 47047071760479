import { LayoutDependencyMode } from '../types'
import { Node, ReadOnlyNode } from 'application/node'
import { SizeEngineCalculator } from './types'
import { truncate } from 'application/math'
import { minMaxSize } from './utils'

export class FixedSizeCalculator implements SizeEngineCalculator {
  update(node: Node, mode: LayoutDependencyMode): void {
    let width: number | undefined
    let height: number | undefined

    if (
      ['w'].includes(mode) &&
      node.getStyleAttribute('size.w.auto') === 'fixed' &&
      node.getStyleAttribute('size.w') !== undefined &&
      !this.isConstrained('w', node) &&
      !this.isWidthLocked(node)
    ) {
      const current = node.getStyleAttribute('size.w') || 0
      width = truncate(minMaxSize(current, 'w', node), 2)
    }

    if (
      ['h'].includes(mode) &&
      node.getStyleAttribute('size.h.auto') === 'fixed' &&
      node.getStyleAttribute('size.h') !== undefined &&
      !this.isConstrained('h', node) &&
      !this.isHeightLocked(node)
    ) {
      const current = node.getStyleAttribute('size.h') || 0
      height = truncate(minMaxSize(current, 'h', node), 2)
    }

    if (['wh'].includes(mode)) {
      if (
        node.getStyleAttribute('size.w.auto') === 'fixed' &&
        !this.isConstrained('w', node) &&
        node.getStyleAttribute('size.w') !== undefined &&
        !this.isWidthLocked(node)
      ) {
        const current = node.getStyleAttribute('size.w') || 0
        width = truncate(minMaxSize(current, 'w', node), 2)
      }
      if (
        node.getStyleAttribute('size.h.auto') === 'fixed' &&
        !this.isConstrained('h', node) &&
        node.getStyleAttribute('size.h') !== undefined &&
        !this.isHeightLocked(node)
      ) {
        const current = node.getStyleAttribute('size.h') || 0
        height = truncate(minMaxSize(current, 'h', node), 2)
      }
    }

    if (width !== undefined) node.setBaseAttribute('w', width)
    if (height !== undefined) node.setBaseAttribute('h', height)
  }

  private isConstrained = (axis: 'w' | 'h', node: ReadOnlyNode): boolean => {
    switch (axis) {
      case 'w':
        return (
          node.getStyleAttribute('position.left.auto') !== 'none' &&
          node.getStyleAttribute('position.right.auto') !== 'none'
        )
      case 'h':
        return (
          node.getStyleAttribute('position.top.auto') !== 'none' &&
          node.getStyleAttribute('position.bottom.auto') !== 'none'
        )
    }
  }

  private isWidthLocked(node: ReadOnlyNode): boolean {
    return (
      node.getStyleAttribute('size.w.auto') === 'fixed' &&
      ['fill', 'percent'].includes(node.getStyleAttribute('size.h.auto')) &&
      node.getStyleAttribute('size.ratio.mode') === 'fixed'
    )
  }

  private isHeightLocked(node: ReadOnlyNode): boolean {
    return (
      node.getStyleAttribute('size.h.auto') === 'fixed' &&
      ['fill', 'percent'].includes(node.getStyleAttribute('size.w.auto')) &&
      node.getStyleAttribute('size.ratio.mode') === 'fixed'
    )
  }
}
