import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import ColorInput from 'components/Library/Components/Inputs/ColorInput/ColorInput'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TitleIconBlock from 'components/Library/Components/IconBlock/TitleIconBlock/TitleIconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import React, { useRef, useState } from 'react'
import ShadowSettingsPopup from '../../../../../Library/Components/Inputs/ShadowSettingsPopup/ShadowSettingsPopup'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { Color } from 'application/color'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'

export default function ShadowSettings() {
  const sectionRef = useRef<HTMLDivElement>(null)
  const [editingRow, setEditingRow] = useState(-1)

  const buttonRefs: React.RefObject<HTMLDivElement>[] = []

  const { settings, handlers } = usePanelHelper(editor.getUI().getShadowPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings) return <></>

  const { attributes } = settings
  if (!attributes) return <></>

  const { add, remove, update, clearMixed } = handlers
  const { hasOverride, sectionHasOverride } = overrides.handlers

  const shadows = attributes['shadows']
  const active = shadows && (shadows === 'Mixed' || shadows.length > 0)
  const mixed = shadows === 'Mixed'

  const handleAdd = () => {
    mixed ? clearMixed() : add()
  }

  const handleRemoveRow = (index: number) => {
    if (editingRow === index) setEditingRow(-1)
    if (editingRow > index) setEditingRow(editingRow - 1)
    remove(index)
  }

  const handleUpdateColor = (index: number, color: Color) => {
    if (!shadows || shadows === 'Mixed') return
    update(index, { ...shadows[index], color })
  }

  const handleClickRow = () => {
    if (!active) handleAdd()
  }

  return (
    <PanelSection sectionRef={sectionRef}>
      <PanelRow>
        <OverrideReset section={'shadows'}>
          <TitleButtonBlock
            title="Shadows"
            active={active}
            hasOverride={sectionHasOverride('shadows')}
            onClick={handleClickRow}
          />
        </OverrideReset>
        <TitleIconBlock icon={'Plus'} active={active} onClick={handleAdd} />
      </PanelRow>
      {mixed && (
        <PanelRow>
          <TextBlock width={216} mode={'label2'}>
            {'Click + to clear mixed shadows'}
          </TextBlock>
        </PanelRow>
      )}
      {shadows && shadows !== 'Mixed' && (
        <>
          {shadows.map((shadow, i) => (
            <PanelRow key={i}>
              <OverrideReset keys={['shadows']}>
                <IconBlock
                  buttonRef={buttonRefs[i]}
                  icon="Settings"
                  tooltipKey="ShadowSettings"
                  tooltipDirection="left"
                  selected={editingRow === i}
                  onClick={() => setEditingRow(editingRow === i ? -1 : i)}
                  hasOverride={hasOverride(['shadows'])}
                />
              </OverrideReset>
              <OverrideReset keys={['shadows']}>
                <ColorInput
                  color={shadow.color}
                  updateColor={(color) => handleUpdateColor(i, color)}
                  opacity={false}
                  hasOverride={hasOverride(['shadows'])}
                />
              </OverrideReset>
              <PanelBlock width={72} />
              <IconBlock icon="X" onClick={() => handleRemoveRow(i)} />
            </PanelRow>
          ))}
        </>
      )}
      {editingRow !== -1 && shadows && shadows !== 'Mixed' && (
        <ShadowSettingsPopup
          close={() => setEditingRow(-1)}
          shadow={shadows[editingRow]}
          updateShadow={(s) => update(editingRow, s)}
          sectionRef={sectionRef}
          buttonRef={buttonRefs[editingRow]}
        />
      )}
    </PanelSection>
  )
}
