import { ClientUpdateListener, Update } from 'application/client'
import { NodeTitle, NodeTitlesListener } from './types'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyNode } from 'application/node'
import { NodeTitleGenerator } from './generator'
import { CameraUpdateListener } from 'application/camera'
import { FontsLoadedListener } from 'application/text'
import { HighlightListener } from 'editor/highlight/service'

export class NodeTitlesService
  implements
    ClientUpdateListener,
    HighlightListener,
    CameraUpdateListener,
    FontsLoadedListener
{
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private generator: NodeTitleGenerator

  private fontsLoaded: boolean
  private highlighted: string | null

  private listeners: { [key: string]: NodeTitlesListener }
  private titles: { [key: string]: NodeTitle }

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    generator: NodeTitleGenerator
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.generator = generator

    this.fontsLoaded = false
    this.highlighted = null

    this.listeners = {}
    this.titles = {}
  }

  getTypes = (): Update['type'][] => {
    return [
      'node_created',
      'node_deleted',
      'node_updated',
      'selection',
      'selected_canvas',
    ]
  }

  onUpdate = (update: Update[]): void => {
    let found: boolean = false
    for (const u of update) {
      switch (u.type) {
        case 'selection':
          found = true
          break
        case 'node_created':
          if (this.isTitleAllowed(u.data.id)) found = true
          break
        case 'node_updated':
          if (this.titles[u.data.id] !== undefined) found = true
          if (this.isTitleAllowed(u.data.id)) found = true
          break
        case 'node_deleted':
          if (this.titles[u.data.id] !== undefined) found = true
          break
        case 'selected_canvas':
          found = true
          break
      }
    }
    if (found) {
      this.updateTitles()
      this.notifyListeners()
    }
  }

  onCamera = (): void => {
    this.updateTitles()
    this.notifyListeners()
  }

  onHighlight = (id: string | null): void => {
    this.highlighted = id
    this.updateTitles()
    this.notifyListeners()
  }

  onFontsLoaded = (): void => {
    this.fontsLoaded = true
    this.updateTitles()
    this.notifyListeners()
  }

  subscribe = (key: string, listener: NodeTitlesListener): void => {
    this.listeners[key] = listener
    listener.onNodeTitles(Object.values(this.titles))
  }

  unsubscribe = (key: string): void => {
    delete this.listeners[key]
  }

  private notifyListeners = (): void => {
    if (!this.fontsLoaded) return

    for (const key in this.listeners) {
      this.listeners[key].onNodeTitles(Object.values(this.titles))
    }
  }

  private updateTitles = (): void => {
    if (!this.fontsLoaded) return

    const updatedTitles: { [key: string]: NodeTitle } = {}

    const canvas = this.documentSelection.getSelectedCanvas()
    if (!canvas) return

    const canvasNode = this.document.getNode(canvas)
    if (!canvasNode) return

    const children = canvasNode.getChildren()
    if (!children) return

    const filtered = children.filter((id) => this.isTitleAllowed(id))
    for (const id of filtered) {
      const node = this.document.getNode(id)
      if (!node) continue

      const selected = this.isSelected(id)
      const title = this.generator.generateNodeTitle(node, selected)
      if (title) updatedTitles[id] = title
    }

    this.titles = updatedTitles
  }

  private isTitleAllowed = (id: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    if (!this.isTitleType(node)) return false
    if (!this.isCanvasChild(id)) return false
    if (node.getStyleAttribute('hidden')) return false
    return true
  }

  private isTitleType = (node: ReadOnlyNode): boolean => {
    switch (node.getBaseAttribute('type')) {
      case 'frame':
      case 'page':
        return true
      default:
        return false
    }
  }

  private isCanvasChild = (id: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    return this.documentSelection.getSelectedCanvas() === node.getParent()
  }

  private isSelected = (id: string): boolean => {
    return (
      this.documentSelection
        .getSelected()
        .some((selected) => selected.getId() === id) || this.highlighted === id
    )
  }
}
