import { ReadOnlyNode } from 'application/node'
import { StyleAttributePanel } from './styleAttributePanel'
import { isAutolayoutChild } from 'application/attributes'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { AlignNodesAction } from 'editor/action/node/align'
import { ReadOnlyDocument } from 'application/document'
import { NodeAttributesAction } from 'application/action/attributes'
import { CommandHandler } from 'application/client'

export type AlignmentPanelDirection = 'start' | 'center' | 'end'

export type AlignmentPanelAxis = 'h' | 'v'

export interface AlignmentPanelState {
  alignEnabled: boolean
  distributeEnabled: boolean
  distributeMode: AlignmentPanelAxis
}

export interface AlignmentPanelHandlers {
  align: (axis: AlignmentPanelAxis, direction: AlignmentPanelDirection) => void
  distribute: (axis: AlignmentPanelAxis) => void
}

export class AlignmentPanel extends StyleAttributePanel<
  AlignmentPanelState,
  AlignmentPanelHandlers,
  never
> {
  private alignNodesAction: AlignNodesAction

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    alignNodesAction: AlignNodesAction
  ) {
    super(commandHandler, action, document, documentSelection)
    this.alignNodesAction = alignNodesAction
  }

  getSettings(): AlignmentPanelState {
    return {
      alignEnabled: this.isAlignAllowed(),
      distributeEnabled: this.isDistributeAllowed(),
      distributeMode: 'h',
    }
  }

  getHandlers(): AlignmentPanelHandlers {
    return {
      align: this.alignNodesAction.align,
      distribute: this.alignNodesAction.distribute,
    }
  }

  private isAlignAllowed = (): boolean => {
    const pairs = this.getNodesAndParents()
    const canvasChildCount = pairs.filter((p) => {
      return p[1] && p[1].getBaseAttribute('type') === 'canvas'
    })
    return pairs.length >= 2 || pairs.length - canvasChildCount.length > 0
  }

  private isDistributeAllowed = (): boolean => {
    return this.getNodesAndParents().length >= 3
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, parent) => {
      if (!parent) return false
      return !isAutolayoutChild(node, parent)
    }
  }
}
