import { AttributeAnimationTriggerType } from 'application/attributes'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { panelPosition } from 'components/Library/Containers/constants'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import useClosePopup from 'hooks/ui/useClosePopup'
import React, { useRef } from 'react'
import TextBlock from '../../Text/TextBlock/TextBlock'
import TriggerPopupRow from './TriggerPopupRow'

interface AnimationTriggerPopupProps {
  sectionRef: React.RefObject<HTMLDivElement>
  buttonRef: React.RefObject<HTMLDivElement>

  triggers: AttributeAnimationTriggerType[]

  select: (trigger: AttributeAnimationTriggerType) => void
  close: () => void
}

export default function AnimationTriggerPopup({
  sectionRef,
  buttonRef,
  triggers,
  select,
  close,
}: AnimationTriggerPopupProps) {
  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({
    ref: popupRef,
    close: close,
    exceptionRef: buttonRef,
  })

  return (
    <Portal>
      <PanelContainer
        position={getPosition(sectionRef)}
        popup={true}
        panelRef={popupRef}
      >
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode="title">
              Triggers
            </TextBlock>
          </PanelRow>
        </PanelSection>
        <PanelSection bottom={true}>
          {getSections(triggers).map((s) => (
            <React.Fragment key={s.title}>
              <PanelRow>
                <TextBlock width={192} mode={'title'}>
                  {s.title}
                </TextBlock>
              </PanelRow>
              {s.triggers.map((t) => (
                <TriggerPopupRow
                  key={t.type}
                  type={t.type}
                  select={() => select(t.type)}
                />
              ))}
            </React.Fragment>
          ))}
        </PanelSection>
      </PanelContainer>
    </Portal>
  )
}

function getSections(triggers: AttributeAnimationTriggerType[]): {
  title: string
  triggers: { text: string; type: AttributeAnimationTriggerType }[]
}[] {
  return sections
    .map((s) => ({
      title: s.title,
      triggers: s.triggers.filter((t) => triggers.includes(t.type)),
    }))
    .filter((s) => s.triggers.length > 0)
}

const sections: {
  title: string
  triggers: { text: string; type: AttributeAnimationTriggerType }[]
}[] = [
  {
    title: 'Click',
    triggers: [{ text: 'Click', type: 'click' }],
  },
  {
    title: 'Hover',
    triggers: [
      { text: 'Hover', type: 'hover-in' },
      { text: 'Hover Out', type: 'hover-out' },
    ],
  },
  {
    title: 'Scroll',
    triggers: [
      { text: 'Scroll Into View', type: 'scroll-in' },
      { text: 'Scroll Out Of View', type: 'scroll-out' },
    ],
  },
  {
    title: 'Page',
    triggers: [{ text: 'Page Load', type: 'load' }],
  },
]

function getPosition(panelRef: React.RefObject<HTMLElement>): panelPosition {
  if (!panelRef.current) return { top: 0, right: 0 }
  const rect = panelRef.current.getBoundingClientRect()
  return {
    position: 'absolute',
    top: rect.top,
    right: 248,
  }
}
