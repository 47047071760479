import { WriteDocument } from 'application/document'
import {
  DocumentUpdateListener,
  ReadOnlyDocument,
} from 'application/document/types'
import { HistoryActionListener } from 'application/history'
import { LayoutEngine } from 'application/layout'
import { Node, NodeUpdateListener } from 'application/node'

export class ClientLayoutEngine
  implements NodeUpdateListener, DocumentUpdateListener, HistoryActionListener
{
  private document: WriteDocument
  private layoutEngine: LayoutEngine
  private historyActionInProgress = false
  private initialDocument: ReadOnlyDocument
  private dirtyNodes: Set<string> = new Set()

  constructor(document: WriteDocument, layoutEngine: LayoutEngine) {
    this.document = document
    this.layoutEngine = layoutEngine
    this.historyActionInProgress = false
    this.initialDocument = document.cloneReadOnly()
    this.dirtyNodes = new Set()
  }

  layout = (): void => {
    this.layoutEngine.computeLayout(
      this.initialDocument,
      this.document,
      this.dirtyNodes
    )
    this.dirtyNodes.clear()
    this.initialDocument = this.document.cloneReadOnly()
  }

  onBaseAttribute = (id: string): void => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(id)
  }

  onStyleAttribute = (id: string): void => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(id)
  }

  onActiveBreakpoint = (id: string): void => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(id)
  }

  onActivePseudo = (id: string): void => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(id)
  }

  onChildren = (id: string): void => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(id)
  }

  onParent = (id: string) => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(id)
  }

  onNodeAdded = (node: Node): void => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(node.getId())
  }

  onNodeDeleted = (node: Node): void => {
    if (this.historyActionInProgress) return
    this.dirtyNodes.add(node.getId())
  }

  onStartUndo = (): void => {
    this.historyActionInProgress = true
  }

  onEndUndo = (): void => {
    this.historyActionInProgress = false
  }

  onStartRedo = (): void => {
    this.historyActionInProgress = true
  }

  onEndRedo = (): void => {
    this.historyActionInProgress = false
  }
}
