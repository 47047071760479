import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { AttributeStoreListener } from './types'
import { ReadOnlyNode } from 'application/node'

export class AttributeStore implements ClientUpdateListener {
  private document: ReadOnlyDocument
  private selectedIds: string[]
  private nodes: ReadOnlyNode[]
  private listeners: { [key: string]: AttributeStoreListener }

  constructor(document: ReadOnlyDocument) {
    this.document = document
    this.selectedIds = []
    this.nodes = []
    this.listeners = {}
  }

  subscribe = (key: string, listener: AttributeStoreListener) => {
    this.listeners[key] = listener
    listener.onAttributes(this.nodes)
  }

  unsubscribe = (key: string) => {
    delete this.listeners[key]
  }

  getTypes = (): Update['type'][] => {
    return ['node_updated', 'selection']
  }

  onUpdate = (updates: Update[]) => {
    for (const update of updates) {
      switch (update.type) {
        case 'node_updated':
          this.updateAttribute(update.data.id)
          break
        case 'selection':
          this.updateSelection(update.data.ids)
          break
      }
    }
  }

  private updateAttribute = (id: string) => {
    if (!this.selectedIds.includes(id)) return
    this.updateAttributes()
  }

  private updateSelection = (ids: string[]) => {
    this.selectedIds = ids
    this.updateAttributes()
  }

  private updateAttributes = () => {
    this.nodes = this.selectedIds
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
    this.notifyListeners()
  }

  private notifyListeners = () => {
    for (const key in this.listeners) {
      this.listeners[key].onAttributes(this.nodes)
    }
  }
}
