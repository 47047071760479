import ToolbarTextButtonLarge from 'components/Library/Components/Toolbar/ToolbarTextButtonLarge/ToolbarTextButtonLarge'
import { useRef, useState } from 'react'
import ScreenSizePopup from './ScreenSizePopup/ScreenSizePopup'

export default function ScreenSizeButton() {
  const buttonRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <ToolbarTextButtonLarge
        buttonRef={buttonRef}
        text="Device Sizes"
        active={open}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <ScreenSizePopup buttonRef={buttonRef} close={() => setOpen(false)} />
      )}
    </>
  )
}
