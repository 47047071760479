import {
  AttributeAnimationEffectEasing,
  AttributeAnimationEffectEvent,
} from 'application/attributes'
import { truncate } from 'application/math'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import DropdownTargetInput from 'components/Library/Components/Inputs/TargetInput/DropdownTargetInput'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'

interface EventSettingsProps {
  options: string[]

  event: AttributeAnimationEffectEvent
  updateEvent: (event: AttributeAnimationEffectEvent) => void
}

export default function EventSettings({
  options,
  event,
  updateEvent,
}: EventSettingsProps) {
  return (
    <PanelSection>
      <PanelRow>
        <TextBlock width={96} mode={'label2'}>
          Target
        </TextBlock>
        <DropdownTargetInput
          width={120}
          icon={'Target'}
          id={event.targetId || ''}
          placeholder={'Select'}
          options={options}
          selectedOption={event.targetType === 'self' ? 'self' : undefined}
          selectOption={(v) => {
            switch (v) {
              case 'self':
                updateEvent({ ...event, targetType: 'self', targetId: '' })
                break
              case 'none':
                updateEvent({ ...event, targetType: 'target', targetId: '' })
                break
            }
          }}
          dropdownOptions={targetInputDropdownOptions}
          onSelect={(id) => {
            if (!id) return
            updateEvent({ ...event, targetType: 'target', targetId: id })
          }}
          commit={true}
        />
      </PanelRow>
      {!event.start && (
        <>
          <PanelRow>
            <TextBlock width={96} mode={'label2'}>
              Start Time
            </TextBlock>
            <NumberBlock
              icon={'Play'}
              tooltipKey={'AnimationStartTime'}
              value={event.startTime / 1000}
              setValue={(v) =>
                updateEvent({ ...event, startTime: min(v * 1000) })
              }
              increment={(v) =>
                updateEvent({
                  ...event,
                  startTime: min(event.startTime + v * 1000),
                })
              }
              decrement={(v) =>
                updateEvent({
                  ...event,
                  startTime: min(event.startTime - v * 1000),
                })
              }
              min={0}
              unit={'s'}
              step={0.1}
              decimals={1}
            />
          </PanelRow>
          <PanelRow>
            <TextBlock width={96} mode={'label2'}>
              Duration
            </TextBlock>
            <NumberBlock
              icon={'Duration'}
              tooltipKey={'AnimationDuration'}
              value={event.duration / 1000}
              setValue={(v) =>
                updateEvent({ ...event, duration: min(v * 1000) })
              }
              increment={(v) =>
                updateEvent({
                  ...event,
                  duration: min(event.duration + v * 1000),
                })
              }
              decrement={(v) =>
                updateEvent({
                  ...event,
                  duration: min(event.duration - v * 1000),
                })
              }
              min={0}
              unit={'s'}
              step={0.1}
              decimals={1}
            />
          </PanelRow>
          <PanelRow>
            <TextBlock width={96} mode={'label2'}>
              Easing
            </TextBlock>
            <Dropdown
              width={96}
              tooltipKey={'AnimationEasing'}
              selected={event.easing}
              mixed={false}
              options={dropdownOptions}
              select={(o) => updateEvent({ ...event, easing: o })}
              commit={true}
            />
          </PanelRow>
        </>
      )}
    </PanelSection>
  )
}

function min(value: number): number {
  return truncate(Math.max(0, value))
}

const dropdownOptions: dropdownOption<AttributeAnimationEffectEasing>[] = [
  {
    value: 'linear',
    text: 'Linear',
  },
  {
    value: 'ease',
    text: 'Ease',
  },
  {
    value: 'ease-in',
    text: 'Ease In',
  },
  {
    value: 'ease-out',
    text: 'Ease Out',
  },
  {
    value: 'ease-in-out',
    text: 'Ease In Out',
  },
]

const targetInputDropdownOptions: dropdownOption<string>[] = [
  {
    value: 'self',
    text: 'Self',
  },
  {
    value: 'none',
    text: 'None',
  },
]
