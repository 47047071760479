import { IconKey } from 'assets/iconLibrary'
import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import IconSetBlock from 'components/Library/Containers/IconSetBlock/IconSetBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { useRef, useState } from 'react'
import FontPopup from '../../../../../Library/Components/Inputs/FontPopup/FontPopup'
import { tooltipKey } from 'assets/tooltips'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { AttributeSizeAuto, AttributeTextAlign } from 'application/attributes'
import { FontData, FontWeight } from 'application/text'
import FillInputRow from 'components/Library/Components/Inputs/FillInputRow/FillInputRow'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import Icon from 'components/Library/Components/Icon/Icon/Icon'
import { DesignColor } from 'themes'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import { weightToNumber } from 'application/text/utils'

export default function TextSettings() {
  const fontButtonRef = useRef<HTMLDivElement>(null)
  const [fontOpen, setFontOpen] = useState(false)

  const textPanel = usePanelHelper(editor.getUI().getTextPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!textPanel.settings || !textPanel.settings.attributes) return <></>
  if (!overrides.settings) return <></>

  const { attributes } = textPanel.settings
  const fontFamily = attributes['text.font.family']
  const fontWeight = attributes['text.font.weight']
  const fontSize = attributes['text.font.size']
  const color = attributes['text.color']
  const lineHeight = attributes['text.lineHeight']
  const letterSpacing = attributes['text.letterSpacing']
  const alignment = attributes['text.align']
  const widthAuto = attributes['size.w.auto']
  const heightAuto = attributes['size.h.auto']

  const { hasOverride } = overrides.handlers

  if (fontFamily === undefined) return <></>
  if (fontWeight === undefined) return <></>
  if (fontSize === undefined) return <></>
  if (lineHeight === undefined) return <></>
  if (letterSpacing === undefined) return <></>
  if (alignment === undefined) return <></>

  const {
    getFontData,
    setFontFamily,
    setFontWeight,
    setFontSize,
    slideFontSize,
    setColor,
    clearMixedColor,
    setLineHeight,
    slideLineHeight,
    setLetterSpacing,
    slideLetterSpacing,
    setAlignment,
    setSizeAuto,
  } = textPanel.handlers

  const resizeMode = getResizeMode(widthAuto, heightAuto)
  const updateMode = (mode: 'both' | 'height' | 'fixed') =>
    updateResizeMode(mode, widthAuto, heightAuto, setSizeAuto)

  const fontData = getFontData(fontFamily)
  const fontLabel = fontData?.label || 'Missing Font'
  const fontMissing = fontFamily !== 'Mixed' && fontData === null

  return (
    <PanelSection>
      <PanelRow>
        <TitleButtonBlock title={'Font'} active={true} />
      </PanelRow>
      <PanelRow>
        <OverrideReset keys={['text.font.family']}>
          <ButtonBlock
            tooltipKey={'FontFamily'}
            buttonRef={fontButtonRef}
            width={192}
            text={fontFamily === 'Mixed' ? 'Mixed' : fontLabel}
            onClick={() => setFontOpen(!fontOpen)}
            mixed={fontFamily === 'Mixed'}
            styles={{ family: fontFamily === 'Mixed' ? 'inter' : fontFamily }}
            selected={fontOpen}
            hasOverride={hasOverride(['text.font.family'])}
          />
        </OverrideReset>
        {fontMissing && (
          <Tooltip tooltipKey={'FontMissing'} direction={'left'}>
            <PanelBlock width={24} paddingH={4}>
              <Icon icon={'Warning'} size={16} color={DesignColor('warning')} />
            </PanelBlock>
          </Tooltip>
        )}
      </PanelRow>
      <PanelRow>
        <OverrideReset keys={['text.font.weight']}>
          <Dropdown
            tooltipKey={'FontWeight'}
            options={!fontMissing ? getWeightOptions(fontData) : []}
            selected={fontWeight === 'Mixed' ? null : fontWeight}
            select={(v) => setFontWeight(v)}
            mixed={fontWeight === 'Mixed'}
            empty={'Missing Font'}
            hasOverride={hasOverride(['text.font.weight'])}
          />
        </OverrideReset>
        <OverrideReset keys={['text.font.size']}>
          <NumberBlock
            icon="FontSize"
            tooltipKey="FontSize"
            value={fontSize}
            setValue={setFontSize}
            min={1}
            increment={(v) => slideFontSize(v)}
            decrement={(v) => slideFontSize(-v)}
            presets={fontSizePresets}
            hasOverride={hasOverride(['text.font.size'])}
          />
        </OverrideReset>
      </PanelRow>
      <PanelRow>
        <IconSetBlock>
          {alignModes.map((mode) => (
            <OverrideReset key={mode.direction} keys={['text.align']}>
              <IconBlock
                key={mode.direction}
                icon={alignToIcon(mode.direction)}
                tooltipKey={mode.tooltipKey}
                selected={alignment === mode.direction}
                hasOverride={hasOverride(['text.align'])}
                onClick={() => setAlignment(mode.direction)}
              />
            </OverrideReset>
          ))}
        </IconSetBlock>
        <OverrideReset keys={['text.lineHeight']}>
          <NumberBlock
            icon="LineSpacing"
            tooltipKey="FontLineSpacing"
            value={lineHeight}
            setValue={setLineHeight}
            min={-100}
            max={100}
            increment={(v) => slideLineHeight(v)}
            decrement={(v) => slideLineHeight(-v)}
            hasOverride={hasOverride(['text.lineHeight'])}
          />
        </OverrideReset>
      </PanelRow>
      <PanelRow>
        <IconSetBlock>
          {resizeModes.map((mode) => (
            <OverrideReset
              key={mode.mode}
              keys={['size.w.auto', 'size.h.auto']}
            >
              <IconBlock
                key={mode.mode}
                icon={resizeModeToIcon(mode.mode)}
                tooltipKey={mode.tooltipKey}
                selected={resizeMode === mode.mode}
                onClick={() => updateMode(mode.mode)}
                hasOverride={hasOverride(['size.w.auto', 'size.h.auto'])}
              />
            </OverrideReset>
          ))}
        </IconSetBlock>
        <OverrideReset keys={['text.letterSpacing']}>
          <NumberBlock
            icon="LetterSpacing"
            tooltipKey="FontLetterSpacing"
            value={letterSpacing}
            setValue={setLetterSpacing}
            min={-100}
            max={100}
            increment={(v) => slideLetterSpacing(v)}
            decrement={(v) => slideLetterSpacing(-v)}
            hasOverride={hasOverride(['text.letterSpacing'])}
          />
        </OverrideReset>
      </PanelRow>
      {color && color !== 'Mixed' && (
        <OverrideReset keys={['text.color']}>
          <FillInputRow
            fill={color}
            updateFill={setColor}
            modes={['color', 'gradient']}
            hasOverride={hasOverride(['text.color'])}
          />
        </OverrideReset>
      )}
      {color === 'Mixed' && (
        <PanelRow onMouseUp={clearMixedColor}>
          <TextBlock width={216} mode={'label2'}>
            {'Click to clear mixed colors'}
          </TextBlock>
        </PanelRow>
      )}
      {fontOpen && (
        <FontPopup
          close={() => setFontOpen(false)}
          font={fontFamily}
          setFont={setFontFamily}
          sectionRef={fontButtonRef}
        />
      )}
    </PanelSection>
  )
}

const fontSizePresets = [
  12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 64, 96, 128, 256,
]

const alignModes: { direction: AttributeTextAlign; tooltipKey: tooltipKey }[] =
  [
    { direction: 'left', tooltipKey: 'FontAlignLeft' },
    { direction: 'center', tooltipKey: 'FontAlignCenter' },
    { direction: 'right', tooltipKey: 'FontAlignRight' },
  ]

type ResizeMode = 'both' | 'height' | 'fixed'

const resizeModes: { mode: ResizeMode; tooltipKey: tooltipKey }[] = [
  { mode: 'both', tooltipKey: 'FontHugBoth' },
  { mode: 'height', tooltipKey: 'FontHugHeight' },
  { mode: 'fixed', tooltipKey: 'FontFixedSize' },
]

function alignToIcon(align: string): IconKey {
  switch (align) {
    case 'left':
      return 'TextAlignLeft'
    case 'center':
      return 'TextAlignCenterH'
    case 'right':
      return 'TextAlignRight'
    default:
      return 'TextAlignLeft'
  }
}

function getResizeMode(
  widthAuto: 'Mixed' | AttributeSizeAuto,
  heightAuto: 'Mixed' | AttributeSizeAuto
): ResizeMode {
  if (widthAuto === 'hug' && heightAuto === 'hug') return 'both'
  if (heightAuto === 'hug' && widthAuto !== 'hug') return 'height'
  return 'fixed'
}

function updateResizeMode(
  mode: ResizeMode,
  widthAuto: 'Mixed' | AttributeSizeAuto,
  heightAuto: 'Mixed' | AttributeSizeAuto,
  setSizeAuto: (w: AttributeSizeAuto, h: AttributeSizeAuto) => void
) {
  switch (mode) {
    case 'both':
      if (widthAuto === 'hug' && heightAuto === 'hug') return
      setSizeAuto('hug', 'hug')
      break
    case 'height':
      if (heightAuto === 'hug' && widthAuto !== 'hug') return
      if (widthAuto === 'Mixed') setSizeAuto('fixed', 'hug')
      else if (widthAuto === 'hug') setSizeAuto('fixed', 'hug')
      else setSizeAuto(widthAuto, 'hug')
      break
    case 'fixed':
      if (widthAuto !== 'hug' && heightAuto !== 'hug') return
      const newWidthAuto =
        widthAuto === 'hug' || widthAuto === 'Mixed' ? 'fixed' : widthAuto
      const newHeightAuto =
        heightAuto === 'hug' || heightAuto === 'Mixed' ? 'fixed' : heightAuto
      setSizeAuto(newWidthAuto, newHeightAuto)
  }
}

function resizeModeToIcon(mode: ResizeMode): IconKey {
  switch (mode) {
    case 'both':
      return 'HugWidth'
    case 'height':
      return 'TextHugHeight'
    case 'fixed':
      return 'TextFixedSize'
  }
}

function getWeightOptions(
  fontData: FontData | null
): dropdownOption<FontWeight>[] {
  if (!fontData) {
    return [
      {
        text: 'Regular',
        value: 'regular',
        styles: { weight: 'regular', family: 'inter' },
      },
    ]
  }

  const family = fontData.key
  const weights = fontData.weights

  return weights
    .map((weight) => ({
      text: weightToLabel(weight),
      value: weight,
      styles: { weight, family },
    }))
    .sort((a, b) => weightToNumber(a.value) - weightToNumber(b.value))
}

function weightToLabel(weight: FontWeight): string {
  switch (weight) {
    case 'thin':
      return 'Thin'
    case 'extra-light':
      return 'Extra Light'
    case 'light':
      return 'Light'
    case 'regular':
      return 'Regular'
    case 'medium':
      return 'Medium'
    case 'semi-bold':
      return 'Semi Bold'
    case 'bold':
      return 'Bold'
    case 'extra-bold':
      return 'Extra Bold'
    case 'black':
      return 'Black'
  }
}
