import { State } from 'application/textEditor'
import { UpdateHandler } from './update'

export class TextEditorUpdateAccumulator {
  private handler: UpdateHandler

  constructor(handler: UpdateHandler) {
    this.handler = handler
  }

  onEditText = (currentId: string | null): void => {
    this.handler.addUpdate({
      type: 'edit_text',
      data: {
        id: currentId,
      },
    })
  }

  onTextState = (current: State | null): void => {
    this.handler.addUpdate({
      type: 'text_editor_state',
      data: {
        state: current,
      },
    })
  }
}
