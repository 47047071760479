import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import { useState } from 'react'
import { tooltipKey } from 'assets/tooltips'
import Tooltip from '../../Tooltip/Tooltip'
import useAction from 'hooks/editor/useAction'

interface TitleButtonBlockProps {
  width?: 120 | 168 | 192

  title: string
  tooltipKey?: tooltipKey

  active?: boolean
  disabled?: boolean
  hasOverride?: boolean

  onClick?: () => void
}

export default function TitleButtonBlock({
  width = 192,
  title,
  tooltipKey,
  active = false,
  disabled = false,
  hasOverride = false,
  onClick,
}: TitleButtonBlockProps) {
  const action = useAction()
  const [hover, setHover] = useState(false)

  const textColor = getTextColor(
    active,
    hover && !action,
    disabled,
    hasOverride
  )

  return (
    <div
      style={{
        width: width,
        height: 24,
        padding: '0px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <TooltipWrapper tooltipKey={tooltipKey}>
        <Text styles={{ weight: 'bold' }} color={textColor}>
          {title}
        </Text>
      </TooltipWrapper>
    </div>
  )
}

function TooltipWrapper({
  tooltipKey,
  children,
}: {
  tooltipKey?: tooltipKey
  children: React.ReactNode
}) {
  return tooltipKey ? (
    <Tooltip tooltipKey={tooltipKey}>{children}</Tooltip>
  ) : (
    <>{children}</>
  )
}

function getTextColor(
  active: boolean,
  hovered: boolean,
  disabled: boolean,
  hasOverride: boolean
) {
  if (disabled) return DesignColor('text2')
  return active || hovered
    ? DesignColor(hasOverride ? 'overrideParent' : 'text1')
    : DesignColor(hasOverride ? 'overrideParent' : 'text2')
}
