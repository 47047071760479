import LayerDropdownIcon from './LayerDropdownIcon'
import LayerIcon from './LayerIcon'
import LayerPaddingBlock from './LayerPaddingBlock'
import LayerNameInput from './LayerNameInput'
import LayerLockBlock from './LayerLockBlock'
import LayerHiddenBlock from './LayersHiddenBlock'
import { AttributeType } from 'application/attributes'

interface LayerRowContentProps {
  depth: number

  layerKey: string

  name: string
  setName: (name: string) => void

  editing: boolean
  setEditing: (editing: boolean) => void

  type: AttributeType
  componentDescendant: boolean

  canOpen: boolean
  open: boolean
  toggleOpen: () => void

  parentLocked: boolean
  locked: boolean
  setLocked: (locked: boolean) => void

  hidden: boolean
  parentHidden: boolean
  setHidden: (visible: boolean) => void

  panelHovered: boolean
  selected: boolean
  hovered: boolean
}

export default function LayerRowContent({
  depth,
  name,
  setName,
  editing,
  setEditing,
  type,
  componentDescendant,
  canOpen,
  open,
  toggleOpen,
  parentLocked,
  locked,
  setLocked,
  parentHidden,
  hidden,
  setHidden,
  panelHovered,
  selected,
  hovered,
}: LayerRowContentProps) {
  return (
    <>
      <LayerPaddingBlock depth={depth} />
      <LayerDropdownIcon
        visible={panelHovered && canOpen}
        depth={depth}
        open={open}
        toggleOpen={toggleOpen}
      />
      <LayerIcon
        type={type}
        componentDescendent={componentDescendant}
        hidden={hidden || parentHidden}
      />
      <LayerNameInput
        type={type}
        name={name}
        setName={setName}
        editing={editing}
        setEditing={setEditing}
        hidden={hidden || parentHidden}
      />
      <LayerLockBlock
        componentDescendant={componentDescendant}
        active={hovered || selected}
        parentLocked={parentLocked}
        locked={locked}
        setLocked={setLocked}
        hidden={hidden || parentHidden}
      />
      <LayerHiddenBlock
        componentDescendant={componentDescendant}
        active={hovered || selected}
        parentHidden={parentHidden}
        hidden={hidden}
        setHidden={setHidden}
        layerLocked={locked || parentLocked}
      />
    </>
  )
}
