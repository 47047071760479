import Icon from 'components/Library/Components/Icon/Icon/Icon'
import useClosePopup from 'hooks/ui/useClosePopup'
import { useRef, useState } from 'react'
import { DesignColor } from 'themes'
import {
  MenuDrawerPopupButton,
  MenuDrawerPopupContainer,
} from '../../../../Menus/MenuDrawerPopup'
import Text from 'components/Library/Text/Text'

interface ProjectCardActionButtonProps {
  premium: boolean
  cardHovered: boolean

  open: boolean
  setOpen: (open: boolean) => void

  openRenamePopup: () => void
  openDeletePopup: () => void
  manageProject: () => void
}

export default function ProjectCardActionButton({
  premium,
  cardHovered,
  open,
  setOpen,
  openRenamePopup,
  openDeletePopup,
  manageProject,
}: ProjectCardActionButtonProps) {
  const sourceRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)

  useClosePopup({
    ref: popupRef,
    close: () => setOpen(false),
    exceptionRef: sourceRef,
  })

  const background = !cardHovered ? DesignColor('grayInputTint') : 'transparent'

  return (
    <>
      <div
        ref={sourceRef}
        style={{
          height: 'fit-content',
          padding: cardHovered ? `0 8px` : `2px 4px`,
          background: background,
          borderRadius: 4,
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(!open)
        }}
      >
        {!cardHovered && (
          <Text color={DesignColor(premium ? 'text1' : 'text2')}>
            {premium ? 'Premium' : 'Free'}
          </Text>
        )}
        {cardHovered && (
          <Icon
            icon={'ThreeDots'}
            size={16}
            color={DesignColor(hover ? 'inputHighlight' : 'text1')}
          />
        )}
      </div>
      <MenuDrawerPopupContainer
        open={open}
        setOpen={setOpen}
        sourceRef={sourceRef}
        direction={'right'}
      >
        <MenuDrawerPopupButton
          label="Rename"
          onClick={() => {
            openRenamePopup()
            setOpen(false)
          }}
        />
        <MenuDrawerPopupButton
          label="Delete"
          onClick={() => {
            openDeletePopup()
            setOpen(false)
          }}
        />
        <MenuDrawerPopupButton
          label="Manage"
          onClick={() => {
            manageProject()
            setOpen(false)
          }}
        />
      </MenuDrawerPopupContainer>
    </>
  )
}
