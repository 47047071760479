import ToolbarNumberBlock from 'components/Library/Components/Toolbar/ToolbarNumberBlock/ToolbarNumberBlock'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function SizeInputs() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPreviewPanel()
  )
  if (!settings) return <></>

  const { width, height } = settings
  const { setWidth, setHeight } = handlers

  return (
    <>
      <ToolbarNumberBlock
        icon="Width"
        tooltipKey="PreviewWidth"
        value={width}
        setValue={(value: number) => setWidth(value)}
      />
      <ToolbarNumberBlock
        icon="Height"
        tooltipKey="PreviewHeight"
        value={height}
        setValue={(value: number) => setHeight(value)}
      />
    </>
  )
}
