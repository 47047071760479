import {
  AttributeImageResize,
  AttributeType,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { Size } from 'application/shapes'
import { ReadOnlyNode } from 'application/node'

type ImagePanelKeys = 'image.src' | 'image.resize'

type ImagePanelAttributes = Pick<MultiselectStyleMap, ImagePanelKeys> | null

export interface ImagePanelState {
  attributes: ImagePanelAttributes
}

export interface ImagePanelHandlers {
  setSrc: (src: string, size: Size) => void
  setResize: (value: AttributeImageResize) => void
  clearMixed: () => void
  resetSize: () => void
}

export class ImagePanel extends StyleAttributePanel<
  ImagePanelState,
  ImagePanelHandlers,
  ImagePanelKeys
> {
  getSettings(): ImagePanelState {
    return {
      attributes: this.attributes,
    }
  }

  getHandlers(): ImagePanelHandlers {
    return {
      setSrc: this.setSrc,
      setResize: this.setResize,
      clearMixed: this.clearMixed,
      resetSize: this.resetSize,
    }
  }

  private setResize = (value: AttributeImageResize): void => {
    this.setMulti({ 'image.resize': value })
  }

  private setSrc = (src: string, size: Size): void => {
    this.setMulti({
      'image.src': src,
      'image.originalSize.w': size.w,
      'image.originalSize.h': size.h,
    })
    this.commit()
  }

  private clearMixed = (): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    let src: string | undefined
    let w: number | undefined
    let h: number | undefined
    for (const node of nodes) {
      src = node.getStyleAttribute('image.src')
      w = node.getStyleAttribute('image.originalSize.w')
      h = node.getStyleAttribute('image.originalSize.h')
      if (src !== undefined && w !== undefined && h !== undefined) break
    }

    this.setMulti({
      'image.src': src,
      'image.originalSize.w': w,
      'image.originalSize.h': h,
    })
    this.commit()
  }

  private resetSize = (): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    for (const node of nodes) {
      const w = node.getStyleAttribute('image.originalSize.w')
      const h = node.getStyleAttribute('image.originalSize.h')
      if (w === undefined || h === undefined) continue
      this.setOne(node.getId(), {
        'size.w': w,
        'size.h': h,
        'size.w.auto': 'fixed',
        'size.h.auto': 'fixed',
        'size.ratio':
          node.getStyleAttribute('size.ratio.mode') === 'fixed'
            ? w / h
            : undefined,
      })
    }
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = ['image']
