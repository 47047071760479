import { Node } from 'application/node'
import { PostPasteRule } from '../types'
import { canFrameFillPage } from 'application/attributes'

export class FillPageRule implements PostPasteRule {
  postInsert = (node: Node, parent: Node): void => {
    if (!canFrameFillPage(node, parent)) return
    node.setStyleAttribute('size.w.auto', 'fill')
  }
}
