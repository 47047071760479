import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function BreakpointButtons() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPreviewPanel()
  )
  if (!settings) return <></>

  const { width } = settings
  const { setSize } = handlers

  return (
    <>
      {buttons.map((button, i) => (
        <ToolbarIconButton
          key={i}
          icon={button.icon}
          tooltipKey={button.tooltipKey}
          active={width <= button.bounds.max && width >= button.bounds.min}
          onClick={() => setSize(button.width, button.height, true)}
        />
      ))}
    </>
  )
}

type Button = {
  icon: IconKey
  tooltipKey: tooltipKey
  width: number
  height: number
  bounds: {
    min: number
    max: number
  }
}

const buttons: Button[] = [
  {
    icon: 'Desktop',
    tooltipKey: 'PreviewDesktop',
    width: 1440,
    height: 1000,
    bounds: {
      min: 1201,
      max: Infinity,
    },
  },
  {
    icon: 'Tablet',
    tooltipKey: 'PreviewTablet',
    width: 769,
    height: 1025,
    bounds: {
      min: 769,
      max: 1200,
    },
  },
  {
    icon: 'MobileH',
    tooltipKey: 'PreviewMobile',
    width: 481,
    height: 855,
    bounds: {
      min: 481,
      max: 768,
    },
  },
  {
    icon: 'Mobile',
    tooltipKey: 'PreviewMobile',
    width: 320,
    height: 569,
    bounds: {
      min: 0,
      max: 480,
    },
  },
]
