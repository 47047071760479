import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import Icon from 'components/Library/Components/Icon/Icon/Icon'
import Text from 'components/Library/Text/Text'
import { useState } from 'react'
import { DesignColor } from 'themes'

interface PlanDetailsProps {
  premium: boolean

  upgrade: (annual: boolean) => void
  cancel: () => void
}

export default function PlanDetails({
  premium,
  upgrade,
  cancel,
}: PlanDetailsProps) {
  const [annual, setAnnual] = useState(true)

  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        padding: '8px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'start',
        gap: 16,
      }}
    >
      <div
        style={{
          width: '100%',
          height: 'fit-content',
          padding: 16,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
          gap: 10,
          backgroundColor: DesignColor('panelTint'),
          boxShadow: `0 0 0 1px ${DesignColor('panelBorder')}`,
        }}
      >
        <div
          style={{
            width: '100%',
            height: 24,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <Text styles={{ size: 12, weight: 'bold' }}>Free</Text>
        </div>
        <Text>Repaint Domain</Text>
        <Text>5 Published Pages</Text>
      </div>
      <div
        style={{
          width: '100%',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'start',
          gap: 16,
          padding: 16,
          backgroundColor: DesignColor('panelTint'),
          boxShadow: `0 0 0 1px ${DesignColor('panelBorder')}`,
        }}
      >
        <div
          style={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            gap: 8,
          }}
        >
          <div
            style={{
              width: '100%',
              height: 24,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onClick={() => setAnnual(!annual)}
          >
            <Text styles={{ weight: 'bold' }}>Premium</Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <Text color={DesignColor('text2')}>Billed annually</Text>
              <div
                style={{
                  padding: 4,
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon
                  icon={annual ? 'CheckboxChecked' : 'CheckboxUnchecked'}
                  size={16}
                  color={DesignColor('text1')}
                />
              </div>
            </div>
          </div>
          <Text styles={{ weight: 'bold' }}>{`$${
            annual ? 15 : 20
          } / Month`}</Text>
          <Text>Custom Domain</Text>
          <Text>Unlimted Pages</Text>
        </div>
        <CTAButton
          width={'hug'}
          text={premium ? 'Cancel Plan' : 'Upgrade'}
          onClick={() => (premium ? cancel() : upgrade(annual))}
        />
      </div>
    </div>
  )
}
