import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import { useRef } from 'react'
import SettingsPopup from '../SettingsPopup/SettingsPopup'
import { atom, useRecoilState } from 'recoil'

export const settingsOpen = atom({
  key: 'settingsOpen',
  default: false,
})

export default function SettingsButton() {
  const buttonRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useRecoilState(settingsOpen)

  return (
    <>
      <ToolbarIconButton
        buttonRef={buttonRef}
        icon="Settings"
        tooltipKey="WebsiteSettings"
        tooltipDirection="left"
        active={open}
        onClick={() => setOpen(!open)}
      />
      {open && <SettingsPopup close={() => setOpen(false)} />}
    </>
  )
}
