import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import TitleRow from 'components/Library/Components/Button/TitleRow/TitleRow'
import { useRef } from 'react'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import FillInputRow from 'components/Library/Components/Inputs/FillInputRow/FillInputRow'
import { AttributeBorderSide, AttributeFill } from 'application/attributes'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'

export default function BorderSettings() {
  const sectionRef = useRef<HTMLDivElement>(null)

  const { settings, handlers } = usePanelHelper(editor.getUI().getBorderPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || settings.activationMode === 'none') return <></>

  const { width, left, top, bottom, right, color, side, activationMode } =
    settings
  const {
    activate,
    deactivate,
    setWidth,
    slideWidth,
    setSideValue,
    slideSideValue,
    setSide,
    clearMixedColor,
  } = handlers
  const { hasOverride, sectionHasOverride } = overrides.handlers

  const active = activationMode === 'remove'
  const mixed = color === 'Mixed'

  const handleClickRow = () => {
    if (active && mixed) {
      clearMixedColor()
    } else if (!active) {
      activate()
    }
  }

  const handleClickIcon = () => {
    if (active && !mixed) {
      deactivate()
    } else if (active && mixed) {
      clearMixedColor()
    } else {
      activate()
    }
  }

  const handleUpdateFill = (fill: AttributeFill) => {
    if (fill.type === 'color' && fill.color) {
      handlers.setColor(fill.color)
    }
  }

  const fill: AttributeFill | null =
    color !== 'Mixed' ? { type: 'color', color: color } : null

  return (
    <PanelSection sectionRef={sectionRef}>
      <TitleRow
        title={'Border'}
        active={active}
        canDeactivate={active && !mixed}
        sectionRef={sectionRef}
        onClickRow={handleClickRow}
        onClickIcon={handleClickIcon}
        overrideSectionKey={'border'}
        hasOverride={sectionHasOverride('border')}
      />
      {active && mixed && (
        <PanelRow>
          <TextBlock width={216} mode={'label2'}>
            {'Click + to clear mixed colors'}
          </TextBlock>
        </PanelRow>
      )}
      {active && !mixed && (
        <>
          {fill && (
            <OverrideReset keys={['border.color']}>
              <FillInputRow
                fill={fill}
                updateFill={handleUpdateFill}
                modes={['color']}
                hasOverride={hasOverride(['border.color'])}
              />
            </OverrideReset>
          )}
        </>
      )}
      {active && (
        <>
          <PanelRow>
            <OverrideReset
              keys={[
                'border.top',
                'border.right',
                'border.bottom',
                'border.left',
              ]}
            >
              <NumberBlock
                icon="BorderWidth"
                tooltipKey="BorderWidth"
                value={width}
                setValue={setWidth}
                increment={(v) => slideWidth(v)}
                decrement={(v) => slideWidth(-v)}
                dim={side === 'custom'}
                min={0}
                max={100}
                hasOverride={hasOverride([
                  'border.top',
                  'border.right',
                  'border.bottom',
                  'border.left',
                ])}
              />
            </OverrideReset>
            <OverrideReset keys={['border.side']}>
              <Dropdown
                options={borderModeOptions}
                hasIcon={true}
                tooltipKey="BorderMode"
                selected={side}
                select={(v) => {
                  if (v === 'Mixed') return
                  else setSide(v)
                }}
                mixed={side === 'Mixed'}
                mixedIcon={'BorderAll'}
                hasOverride={hasOverride(['border.side'])}
              />
            </OverrideReset>
          </PanelRow>
          {side === 'custom' && (
            <>
              <PanelRow>
                <OverrideReset keys={['border.left']}>
                  <NumberBlock
                    icon="BorderLeft"
                    tooltipKey="BorderWidthLeft"
                    value={left}
                    setValue={(value) => setSideValue(value, 'left')}
                    increment={(v) => slideSideValue(v, 'left')}
                    decrement={(v) => slideSideValue(-v, 'left')}
                    min={0}
                    max={100}
                    hasOverride={hasOverride(['border.left'])}
                  />
                </OverrideReset>
                <OverrideReset keys={['border.top']}>
                  <NumberBlock
                    icon="BorderTop"
                    tooltipKey="BorderWidthTop"
                    value={top}
                    setValue={(value) => setSideValue(value, 'top')}
                    increment={(v) => slideSideValue(v, 'top')}
                    decrement={(v) => slideSideValue(-v, 'top')}
                    min={0}
                    max={100}
                    hasOverride={hasOverride(['border.top'])}
                  />
                </OverrideReset>
              </PanelRow>
              <PanelRow>
                <OverrideReset keys={['border.right']}>
                  <NumberBlock
                    icon="BorderRight"
                    tooltipKey="BorderWidthRight"
                    value={right}
                    setValue={(value) => setSideValue(value, 'right')}
                    increment={(v) => setSideValue(v, 'right')}
                    decrement={(v) => setSideValue(-v, 'right')}
                    min={0}
                    max={100}
                    hasOverride={hasOverride(['border.right'])}
                  />
                </OverrideReset>
                <OverrideReset keys={['border.bottom']}>
                  <NumberBlock
                    icon="BorderBottom"
                    tooltipKey="BorderWidthBottom"
                    value={bottom}
                    setValue={(value) => setSideValue(value, 'bottom')}
                    increment={(v) => slideSideValue(v, 'bottom')}
                    decrement={(v) => slideSideValue(-v, 'bottom')}
                    min={0}
                    max={100}
                    hasOverride={hasOverride(['border.bottom'])}
                  />
                </OverrideReset>
              </PanelRow>
            </>
          )}
        </>
      )}
    </PanelSection>
  )
}

const borderModeOptions: dropdownOption<AttributeBorderSide>[] = [
  {
    icon: 'BorderAll',
    text: 'All',
    value: 'all',
  },
  {
    icon: 'BorderSplit',
    text: 'Custom',
    value: 'custom',
  },
  {
    icon: 'BorderTop',
    text: 'Top',
    value: 'top',
  },
  {
    icon: 'BorderBottom',
    text: 'Bottom',
    value: 'bottom',
  },
  {
    icon: 'BorderLeft',
    text: 'Left',
    value: 'left',
  },
  {
    icon: 'BorderRight',
    text: 'Right',
    value: 'right',
  },
]
