import { CameraService } from 'application/camera'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  Done,
  DonePassthrough,
  NotDone,
  NotDonePassthrough,
} from '../types'
import { Cursor } from 'application/cursor'

export class HandAction implements ActionHandler {
  private cameraService: CameraService

  private mode: 'wheel' | 'mouse' | 'key'
  private dragging: boolean

  constructor(cameraService: CameraService, mode: 'wheel' | 'mouse' | 'key') {
    this.cameraService = cameraService

    this.mode = mode
    this.dragging = mode === 'wheel'
  }

  getType = (): Action => {
    return 'hand'
  }

  getCursor = (): Cursor => {
    return this.dragging ? 'handClosed' : 'handOpen'
  }

  onMouseDown = (_: MouseEvent): ActionEventResult => {
    if (this.dragging) return NotDone
    this.dragging = true
    return NotDone
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    if (!this.dragging) return NotDone
    this.cameraService.panCameraDrag(e)
    return NotDone
  }

  onMouseUp = (_: MouseEvent): ActionEventResult => {
    if (this.mode === 'wheel') {
      this.dragging = false
      return Done
    }
    this.dragging = false
    return NotDone
  }

  onKeyDown = (e: KeyboardEvent): ActionEventResult => {
    if (this.getCancelKeys().includes(e.key)) {
      this.cancel()
      return Done
    } else {
      return NotDone
    }
  }

  onKeyUp = (e: KeyboardEvent): ActionEventResult => {
    if (this.mode === 'key' && e.key === ' ') {
      this.cancel()
      return DonePassthrough
    }
    return NotDonePassthrough
  }

  cancel = (): void => {
    this.dragging = false
  }

  private getCancelKeys = (): string[] => {
    return ['Escape', 'v', 'V', 'h', 'H']
  }
}
