import { CommandHandler, SetChildOrder } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import _ from 'lodash'

type ReorderFunction = (nodeId: string, order: string[]) => string[]

export class RearrangeAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.selection = selection
  }

  moveToFront = (): void => {
    const selection = this.selection.getSelected()
    if (selection.length === 0) return

    const sorted = this.sortByIndex(selection).reverse()
    for (const node of sorted) {
      this.reorder(node.getId(), this.reorderFront)
    }

    this.commit()
  }

  moveToBack = (): void => {
    const selection = this.selection.getSelected()
    if (selection.length === 0) return

    const sorted = this.sortByIndex(selection)
    for (const node of sorted) {
      this.reorder(node.getId(), this.reorderBack)
    }

    this.commit()
  }

  moveForward = (direction: 'h' | 'v'): void => {
    const selection = this.selection.getSelected()
    if (selection.length === 0) return

    const sorted = this.sortByIndex(selection).reverse()
    for (const node of sorted) {
      const parent = this.document.getParent(node)
      if (!parent) continue

      const parentMode = parent.getStyleAttribute('autolayout.mode')
      if (parentMode !== 'flex') continue

      const parentDirection = parent.getStyleAttribute('autolayout.direction')
      if (!parentDirection) continue
      switch (direction) {
        case 'h':
          if (!['row', 'wrap'].includes(parentDirection)) continue
          break
        case 'v':
          if (!['column', 'wrap'].includes(parentDirection)) continue
          break
      }

      this.reorder(node.getId(), this.reorderForward)
    }

    this.commit()
  }

  moveBackward = (direction: 'h' | 'v'): void => {
    const selection = this.selection.getSelected()
    if (selection.length === 0) return

    const sorted = this.sortByIndex(selection)
    for (const node of sorted) {
      const parent = this.document.getParent(node)
      if (!parent) continue

      const parentMode = parent.getStyleAttribute('autolayout.mode')
      if (parentMode !== 'flex') continue

      const parentDirection = parent.getStyleAttribute('autolayout.direction')
      if (!parentDirection) continue
      switch (direction) {
        case 'h':
          if (!['row', 'wrap'].includes(parentDirection)) continue
          break
        case 'v':
          if (!['column', 'wrap'].includes(parentDirection)) continue
          break
      }

      this.reorder(node.getId(), this.reorderBackward)
    }

    this.commit()
  }

  private reorderFront = (nodeId: string, order: string[]): string[] => {
    const index = order.indexOf(nodeId)
    if (index === 0) return order

    const newOrder = order.filter((id) => id !== nodeId)
    newOrder.unshift(nodeId)

    return newOrder
  }

  private reorderBack = (nodeId: string, order: string[]): string[] => {
    const index = order.indexOf(nodeId)
    if (index === order.length - 1) return order

    const newOrder = order.filter((id) => id !== nodeId)
    newOrder.push(nodeId)

    return newOrder
  }

  private reorderForward = (nodeId: string, order: string[]): string[] => {
    const index = order.indexOf(nodeId)
    if (index === order.length - 1) return order

    const newOrder = order.filter((id) => id !== nodeId)
    newOrder.splice(index + 1, 0, nodeId)

    return newOrder
  }

  private reorderBackward = (nodeId: string, order: string[]): string[] => {
    const index = order.indexOf(nodeId)
    if (index === 0) return order

    const newOrder = order.filter((id) => id !== nodeId)
    newOrder.splice(index - 1, 0, nodeId)

    return newOrder
  }

  private reorder = (nodeId: string, func: ReorderFunction): void => {
    const node = this.document.getNode(nodeId)
    if (!node) return

    const parent = this.document.getParent(node)
    if (!parent) return

    const children = parent.getChildren()
    if (!children) return

    const newChildren = func(nodeId, children)
    if (_.isEqual(newChildren, children)) return

    const command = this.buildChildOrderCommand(parent.getId(), newChildren)
    this.commandHandler.handle(command)
  }

  private sortByIndex = (nodes: ReadOnlyNode[]): ReadOnlyNode[] => {
    const indexMap: { [key: string]: number } = {}
    for (const node of nodes) {
      const id = node.getId()
      const parent = this.document.getParent(node)
      if (!parent) {
        indexMap[id] = 0
        continue
      }
      const children = parent.getChildren()
      if (!children) {
        indexMap[id] = 0
        continue
      }
      indexMap[id] = children.indexOf(id)
    }
    return nodes.sort((a, b) => indexMap[a.getId()] - indexMap[b.getId()])
  }

  private buildChildOrderCommand = (
    nodeId: string,
    newChildren: string[]
  ): SetChildOrder => {
    return {
      type: 'setChildOrder',
      params: {
        parentId: nodeId,
        children: newChildren,
      },
    }
  }

  private commit = (): void => {
    this.commandHandler.handle({ type: 'commit' })
  }
}
