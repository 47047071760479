import { ProjectFontDetailsUpdate, ProjectFontInfo } from 'application/service'
import { FontWeight } from 'application/text'
import { weightToNumber } from 'application/text/utils'
import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import useToast from 'hooks/editor/useToast'
import _ from 'lodash'
import { useRef, useState } from 'react'

interface FontSettingsProps {
  fonts: ProjectFontInfo[]

  uploadFont: (file: File) => Promise<ProjectFontInfo | null>
  updateFontInfo: (id: string, data: ProjectFontDetailsUpdate) => Promise<void>
  updateFontFile: (id: string, file: File) => Promise<void>
  deleteFont: (id: string) => Promise<void>

  close: () => void
}

export default function FontSettings({
  fonts,
  uploadFont,
  updateFontInfo,
  updateFontFile,
  deleteFont,
  close,
}: FontSettingsProps) {
  const toast = useToast()

  const uploadRef = useRef<HTMLInputElement>(null)
  const reuploadRef = useRef<HTMLInputElement>(null)
  const [localName, setLocalName] = useState<string>('')
  const [localWeight, setLocalWeight] = useState<FontWeight>('regular')
  const [editing, setEditing] = useState<string | null>(null)
  const editingFile = fonts.find((f) => f.id === editing)

  const handleFontUpload = async (file: File) => {
    try {
      const result = await uploadFont(file)
      if (!result) throw new Error('Failed to upload font')
      setLocalName(result.family)
      setLocalWeight(result.weight)
      setEditing(result.id)
    } catch (error) {
      toast('Unable to upload font, please try again.', 'error')
    }
  }

  const handleFontUpdate = async (
    id: string,
    data: ProjectFontDetailsUpdate
  ) => {
    try {
      await updateFontInfo(id, data)
    } catch (error) {
      toast('Unable to update font details, please try again.', 'error')
    }
  }

  const handleFontFileUpdate = async (id: string, file: File) => {
    try {
      await updateFontFile(id, file)
    } catch (error) {
      toast('Unable to update font file, please try again.', 'error')
    }
  }

  const handleSetEditing = (id: string | null) => {
    if (id === null) {
      setLocalName('')
      setLocalWeight('regular')
      setEditing(null)
    } else {
      setLocalName(fonts.find((f) => f.id === id)?.family || '')
      setLocalWeight(fonts.find((f) => f.id === id)?.weight || 'regular')
      setEditing(id)
    }
  }

  const handleDeleteFont = async (id: string) => {
    try {
      await deleteFont(id)
      setEditing(null)
      setLocalName('')
      setLocalWeight('regular')
    } catch (error) {
      toast('Unable to delete font, please try again.', 'error')
    }
  }

  return (
    <>
      <PanelSection width={'fill'}>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="title">
            Fonts
          </TextBlock>
          <IconBlock icon="X" onClick={close} />
        </PanelRow>
        <PanelRow width={'fill'} paddingL={8} paddingR={16}>
          <TextBlock width={'fill'} mode="label2">
            Upload a custom font file, only .ttf is supported.
          </TextBlock>
          {editing && (
            <CTAButton
              width={120}
              text={'Done'}
              onClick={() => handleSetEditing(null)}
            />
          )}
          {!editing && (
            <>
              <CTAButton
                width={120}
                text={'Upload'}
                onClick={() => {
                  if (!uploadRef.current) return
                  uploadRef.current.click()
                }}
              />
              <input
                ref={uploadRef}
                type="file"
                accept={'.ttf'}
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (!e.target.files) return
                  handleFontUpload(e.target.files[0])
                }}
              />
            </>
          )}
        </PanelRow>
      </PanelSection>
      {fonts.length > 0 && !editing && (
        <PanelSection width={'fill'}>
          <PanelRow width={'fill'} paddingL={8} paddingR={16}>
            <TextBlock width={'fill'} mode="title">
              Uploaded Fonts
            </TextBlock>
          </PanelRow>
          {fonts
            .sort(
              (a, b) =>
                a.family.localeCompare(b.family) ||
                weightToNumber(a.weight) - weightToNumber(b.weight)
            )
            .map((font) => (
              <FontRow
                key={font.id}
                font={font}
                open={() => handleSetEditing(font.id)}
              />
            ))}
        </PanelSection>
      )}
      {editing && editingFile && (
        <>
          <PanelSection width={'fill'}>
            <PanelRow width={'fill'}>
              <TextBlock width={96} mode={'label2'}>
                Family:
              </TextBlock>
              <TextInputBlock
                width={'fill'}
                value={localName}
                setValue={setLocalName}
                submit={() => {
                  handleFontUpdate(editingFile.id, { family: localName })
                }}
                submitOnBlur={true}
              />
            </PanelRow>
            <PanelRow width={'fill'}>
              <TextBlock width={96} mode={'label2'}>
                Weight:
              </TextBlock>
              <Dropdown
                width={192}
                options={weightDropdownOptions}
                selected={localWeight}
                select={(weight) => {
                  setLocalWeight(weight)
                  handleFontUpdate(editingFile.id, { weight })
                }}
              />
            </PanelRow>
            <PanelRow width={'fill'}>
              <TextBlock width={96} mode={'label2'}>
                File:
              </TextBlock>
              <TextBlock width={'fill'} mode={'label2'}>
                {editingFile.fileName}
              </TextBlock>
              <IconBlock
                icon={'Upload'}
                onClick={() => {
                  if (!reuploadRef.current) return
                  reuploadRef.current.click()
                }}
              />
              <input
                ref={reuploadRef}
                type="file"
                accept=".ttf,.otf,.woff,.woff2"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (!e.target.files) return
                  handleFontFileUpdate(editingFile.id, e.target.files[0])
                }}
              />
            </PanelRow>
          </PanelSection>
          <PanelSection width={'fill'}>
            <PanelRow width={'fill'}>
              <ButtonBlock
                text={'Delete Font'}
                onClick={() => handleDeleteFont(editingFile.id)}
              />
            </PanelRow>
          </PanelSection>
        </>
      )}
    </>
  )
}

function FontRow({ font, open }: { font: ProjectFontInfo; open: () => void }) {
  return (
    <PanelRow width={'fill'} paddingL={8} paddingR={16}>
      <TextBlock width={96} mode="label1">
        {font.family}
      </TextBlock>
      <TextBlock width={96} mode="label2">
        {_.capitalize(font.weight)}
      </TextBlock>
      <TextBlock width={192} mode="label2">
        {font.fileName}
      </TextBlock>
      <PanelBlock width={'fill'} />
      <IconBlock icon={'Settings'} onClick={open} />
    </PanelRow>
  )
}

const weightDropdownOptions: dropdownOption<FontWeight>[] = [
  {
    value: 'thin',
    text: 'Thin',
    styles: { weight: 'thin' },
  },
  {
    value: 'extra-light',
    text: 'Extra Light',
    styles: { weight: 'extra-light' },
  },
  {
    value: 'light',
    text: 'Light',
    styles: { weight: 'light' },
  },
  {
    value: 'regular',
    text: 'Regular',
    styles: { weight: 'regular' },
  },
  {
    value: 'medium',
    text: 'Medium',
    styles: { weight: 'medium' },
  },
  {
    value: 'semi-bold',
    text: 'Semi Bold',
    styles: { weight: 'semi-bold' },
  },
  {
    value: 'bold',
    text: 'Bold',
    styles: { weight: 'bold' },
  },
  {
    value: 'extra-bold',
    text: 'Extra Bold',
    styles: { weight: 'extra-bold' },
  },
  {
    value: 'black',
    text: 'Black',
    styles: { weight: 'black' },
  },
]
