import { AttributePositionAuto, StyleMap } from 'application/attributes'
import { dropdownOption } from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import DropdownNumberBlock from 'components/Library/Components/NumberBlock/DropdownNumberBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useState } from 'react'

export default function PositionRow() {
  const [displaySplit, setDisplaySplit] = useState(false)

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPositionPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || !handlers) return null

  const {
    setPosition,
    setPositionUnit,
    slidePosition,
    removePosition,
    collapsePosition,
  } = handlers
  const { hasOverride } = overrides.handlers
  const { x, y, attributes, disabled, displayMode } = settings
  if (!attributes) return <></>

  const top = attributes['position.top']
  const left = attributes['position.left']
  const bottom = attributes['position.bottom']
  const right = attributes['position.right']
  const topMode = attributes['position.top.auto']
  const leftMode = attributes['position.left.auto']
  const bottomMode = attributes['position.bottom.auto']
  const rightMode = attributes['position.right.auto']
  const leftPercent = attributes['position.left.percent']
  const topPercent = attributes['position.top.percent']
  const rightPercent = attributes['position.right.percent']
  const bottomPercent = attributes['position.bottom.percent']

  const positionSplit = rightMode !== 'none' || bottomMode !== 'none'
  const split = displayMode === 'css' && (displaySplit || positionSplit)
  const dropdownOptions = getDropdownOptions(displayMode)

  return (
    <>
      <PanelRow>
        <OverrideReset keys={getLeftKeys(split, disabled)}>
          <DropdownNumberBlock
            icon={split ? 'PositionL' : 'PositionX'}
            value={getValue(x, left, leftPercent, leftMode, displayMode)}
            setValue={(v) => setPosition(v, 'left')}
            selectedOption={leftMode === 'percent' ? 'percent' : 'fixed'}
            selectOption={(v) => {
              if (v === 'remove') removePosition('left')
              else setPositionUnit(v as 'percent' | 'fixed', 'left')
            }}
            dropdownOptions={dropdownOptions}
            increment={(v) => slidePosition(v, 'left')}
            decrement={(v) => slidePosition(-v, 'left')}
            disabled={disabled}
            unit={leftMode === 'percent' ? '%' : undefined}
            decimals={leftMode === 'percent' ? 1 : 0}
            min={-50_000}
            max={50_000}
            hasOverride={hasOverride(getLeftKeys(split, disabled))}
          />
        </OverrideReset>
        <OverrideReset keys={getTopKeys(split, disabled)}>
          <DropdownNumberBlock
            icon={split ? 'PositionT' : 'PositionY'}
            value={getValue(y, top, topPercent, topMode, displayMode)}
            setValue={(v) => setPosition(v, 'top')}
            selectedOption={topMode === 'percent' ? 'percent' : 'fixed'}
            selectOption={(v) => {
              if (v === 'remove') removePosition('top')
              else setPositionUnit(v as 'percent' | 'fixed', 'top')
            }}
            dropdownOptions={dropdownOptions}
            increment={(v) => slidePosition(v, 'top')}
            decrement={(v) => slidePosition(-v, 'top')}
            disabled={disabled}
            unit={topMode === 'percent' ? '%' : undefined}
            decimals={topMode === 'percent' ? 1 : 0}
            min={-50_000}
            max={50_000}
            hasOverride={hasOverride(getTopKeys(split, disabled))}
          />
        </OverrideReset>
        {displayMode === 'css' && (
          <IconBlock
            icon={'ConstraintCenterCenter'}
            selected={split}
            onClick={() => {
              if (split) {
                if (positionSplit) collapsePosition()
                setDisplaySplit(false)
              } else {
                setDisplaySplit(true)
              }
            }}
          />
        )}
      </PanelRow>
      {split && (
        <PanelRow>
          <OverrideReset keys={getRightKeys(disabled)}>
            <DropdownNumberBlock
              icon={'PositionR'}
              value={getCSSValue(right, rightPercent, rightMode)}
              setValue={(v) => setPosition(v, 'right')}
              setEmpty={() => removePosition('right')}
              selectedOption={rightMode === 'percent' ? 'percent' : 'fixed'}
              selectOption={(v) => {
                if (v === 'remove') removePosition('right')
                else setPositionUnit(v as 'percent' | 'fixed', 'right')
              }}
              dropdownOptions={cssPositionDropdownOptions}
              increment={(v) => slidePosition(v, 'right')}
              decrement={(v) => slidePosition(-v, 'right')}
              disabled={disabled}
              unit={rightMode === 'percent' ? '%' : undefined}
              decimals={rightMode === 'percent' ? 1 : 0}
              min={-50_000}
              max={50_000}
              hasOverride={hasOverride(getRightKeys(disabled))}
            />
          </OverrideReset>
          <OverrideReset keys={getBottomKeys(disabled)}>
            <DropdownNumberBlock
              icon={'PositionB'}
              value={getCSSValue(bottom, bottomPercent, bottomMode)}
              setValue={(v) => setPosition(v, 'bottom')}
              setEmpty={() => removePosition('bottom')}
              selectedOption={bottomMode === 'percent' ? 'percent' : 'fixed'}
              selectOption={(v) => {
                if (v === 'remove') removePosition('bottom')
                else setPositionUnit(v as 'percent' | 'fixed', 'bottom')
              }}
              dropdownOptions={cssPositionDropdownOptions}
              increment={(v) => slidePosition(v, 'bottom')}
              decrement={(v) => slidePosition(-v, 'bottom')}
              disabled={disabled}
              unit={bottomMode === 'percent' ? '%' : undefined}
              decimals={bottomMode === 'percent' ? 1 : 0}
              min={-50_000}
              max={50_000}
              hasOverride={hasOverride(getBottomKeys(disabled))}
            />
          </OverrideReset>
        </PanelRow>
      )}
    </>
  )
}

function getDropdownOptions(
  displayMode: 'Mixed' | 'canvas' | 'css'
): dropdownOption<'fixed' | 'percent' | 'remove'>[] {
  return displayMode === 'canvas'
    ? canvasPositionDropdownOptions
    : cssPositionDropdownOptions
}

const canvasPositionDropdownOptions: dropdownOption<
  'fixed' | 'percent' | 'remove'
>[] = [{ value: 'fixed', text: 'px' }]

const cssPositionDropdownOptions: dropdownOption<
  'fixed' | 'percent' | 'remove'
>[] = [
  { value: 'fixed', text: 'px' },
  { value: 'percent', text: '%' },
  { value: 'remove', text: 'Remove' },
]

function getValue(
  canvas: 'Mixed' | number,
  pixel: 'Mixed' | number | undefined,
  percent: 'Mixed' | number | undefined,
  mode: 'Mixed' | AttributePositionAuto,
  displayMode: 'Mixed' | 'canvas' | 'css'
) {
  if (displayMode === 'canvas') return canvas
  if (mode === 'Mixed') return 'Mixed'
  if (mode === 'percent') return percent
  return pixel
}

function getCSSValue(
  pixel: 'Mixed' | number | undefined,
  percent: 'Mixed' | number | undefined,
  mode: 'Mixed' | AttributePositionAuto
) {
  if (mode === 'Mixed') return 'Mixed'
  if (mode === 'percent') return percent
  return pixel
}

const getLeftKeys = (split: boolean, disabled: boolean): (keyof StyleMap)[] => {
  if (disabled) return []
  if (split) {
    return ['position.left', 'position.left.auto', 'position.left.percent']
  }
  return [
    'position.left',
    'position.left.auto',
    'position.left.percent',
    'position.right',
    'position.right.auto',
    'position.right.percent',
  ]
}

const getTopKeys = (split: boolean, disabled: boolean): (keyof StyleMap)[] => {
  if (disabled) return []
  if (split) {
    return ['position.top', 'position.top.auto', 'position.top.percent']
  }
  return [
    'position.top',
    'position.top.auto',
    'position.top.percent',
    'position.bottom',
    'position.bottom.auto',
    'position.bottom.percent',
  ]
}

const getRightKeys = (disabled: boolean): (keyof StyleMap)[] => {
  if (disabled) return []
  return ['position.right', 'position.right.auto', 'position.right.percent']
}

const getBottomKeys = (disabled: boolean): (keyof StyleMap)[] => {
  if (disabled) return []
  return ['position.bottom', 'position.bottom.auto', 'position.bottom.percent']
}
