import TextBlock from '../../Text/TextBlock/TextBlock'
import { AttributeAnimationTriggerType } from 'application/attributes'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'

interface AnimationRowProps {
  type: AttributeAnimationTriggerType
  select: () => void
}

export default function TriggerPopupRow({ type, select }: AnimationRowProps) {
  const panelRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered({ ref: panelRef })

  return (
    <div
      ref={panelRef}
      style={{
        width: '100%',
        height: 32,
        padding: '4px 24px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
        boxShadow: hovered
          ? `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
          : 'none',
      }}
      onClick={select}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <TextBlock width={192} mode={'label1'}>
        {getDisplayText(type)}
      </TextBlock>
    </div>
  )
}

function getDisplayText(type: AttributeAnimationTriggerType) {
  switch (type) {
    case 'click':
      return 'Click'
    case 'hover-in':
      return 'Hover'
    case 'hover-out':
      return 'Hover Out'
    case 'scroll-in':
      return 'Scroll Into View'
    case 'scroll-out':
      return 'Scroll Out Of View'
    case 'load':
      return 'Page Loads'
  }
}
