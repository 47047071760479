import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import { useState } from 'react'

interface ToolbarCTAButtonLightProps {
  buttonRef?: React.RefObject<HTMLDivElement>

  text: string
  active?: boolean

  onClick?: () => void
}

export default function ToolbarCTAButtonLight({
  buttonRef,
  text,
  active = false,
  onClick,
}: ToolbarCTAButtonLightProps) {
  const [hover, setHover] = useState(false)

  const boxShadow = getBoxShadow(hover || active)

  return (
    <div
      ref={buttonRef}
      style={{
        height: 28,
        padding: '0px 10px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: boxShadow,
        borderRadius: 4,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <Text styles={{ size: 12 }} color={DesignColor('text1')}>
        {text}
      </Text>
    </div>
  )
}

function getBoxShadow(active: boolean) {
  return `inset 0px 0px 0px 1px ${
    active ? DesignColor('inputHighlight') : DesignColor('panelBorder')
  }`
}
