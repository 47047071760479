export type OverrideMode = 'default' | 'tablet' | 'landscape' | 'mobile'

export const OverrideModes: OverrideMode[] = [
  'default',
  'tablet',
  'landscape',
  'mobile',
]

export const OverrideThreshold: {
  [key in OverrideMode]: { max: number; min: number }
} = {
  default: { max: Infinity, min: 1201 },
  tablet: { max: 1200, min: 769 },
  landscape: { max: 768, min: 481 },
  mobile: { max: 480, min: 0 },
}
