import {
  AttributeAnimationEffect,
  AttributeAnimationTriggerType,
} from 'application/attributes'
import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import Timeline from './Timeline/Timeline'
import EventSettings from './EventSettings/EventSettings'
import EventEffects from './EventEffects/EventEffects'
import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'

export default function EffectSettings() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getInteractionAnimationEffectPanel()
  )
  if (!settings || !settings.effect) return <></>

  const {
    close,
    toggleLoop,
    setSelectedEvent,
    addStartEvent,
    addEvent,
    deleteEvent,
    duplicateEvent,
    updateEvent,
    addEventStyle,
    removeEventStyle,
    getEventStyleOptions,
  } = handlers
  const { trigger, effect, selectedEventKey, targetOptions } = settings
  if (!trigger || !effect) return <></>

  const selectedEvent = getSelectedEvent(effect, selectedEventKey)

  return (
    <ScrollContainer>
      <PanelSection>
        <PanelRow>
          <TextBlock width={168} mode={'title'}>
            {getTriggerDisplayText(trigger)}
          </TextBlock>
          <CTAButton width={48} text={'Done'} onClick={close} />
        </PanelRow>
        {effect.type === 'timeline' && (
          <PanelRow>
            <ButtonBlock
              icon={effect.loop ? 'CheckboxChecked' : 'CheckboxUnchecked'}
              width={96}
              text={'Loop'}
              onClick={toggleLoop}
            />
          </PanelRow>
        )}
      </PanelSection>
      {effect.type === 'timeline' && (
        <Timeline
          effect={effect}
          selected={selectedEventKey}
          setSelected={setSelectedEvent}
          addEvent={addEvent}
          addStartEvent={addStartEvent}
          deleteEvent={deleteEvent}
          duplicateEvent={duplicateEvent}
        />
      )}
      {selectedEvent && (
        <EventSettings
          options={targetOptions}
          event={selectedEvent}
          updateEvent={updateEvent}
        />
      )}
      {selectedEvent && (
        <EventEffects
          event={selectedEvent}
          updateEvent={updateEvent}
          addStyle={addEventStyle}
          removeStyle={removeEventStyle}
          getStyleOptions={getEventStyleOptions}
        />
      )}
    </ScrollContainer>
  )
}

function getTriggerDisplayText(type: AttributeAnimationTriggerType) {
  switch (type) {
    case 'click':
      return 'Click'
    case 'hover-in':
      return 'Hover'
    case 'hover-out':
      return 'Hover Out'
    case 'scroll-in':
      return 'Scroll Into View'
    case 'scroll-out':
      return 'Scroll Out Of View'
    case 'load':
      return 'Page Load'
  }
}

function getSelectedEvent(
  effect: AttributeAnimationEffect,
  selected: string | null
) {
  switch (effect.type) {
    case 'timeline':
      return effect.events.find((e) => e.key === selected)
    case 'keyframe':
      return null
  }
}
