import { NodeCreateAction, NodeSelectionAction } from 'application/action'
import { AttributeType } from 'application/attributes'
import { CommandHandler } from 'application/client'
import { Rectangle } from 'application/shapes'

export class CreateAction {
  private commandHandler: CommandHandler
  private createAction: NodeCreateAction
  private select: NodeSelectionAction

  constructor(
    commandHandler: CommandHandler,
    createAction: NodeCreateAction,
    select: NodeSelectionAction
  ) {
    this.commandHandler = commandHandler
    this.createAction = createAction
    this.select = select
  }

  createCanvas = (): string => {
    const id = this.createAction.canvas()
    this.select.selectNodes([], true)
    this.commandHandler.handle({
      type: 'setSelectedCanvas',
      params: {
        id: id,
      },
    })
    return id
  }

  createNode = (type: AttributeType, rectangle?: Rectangle): void => {
    const id = this.createAction.insert(type, rectangle)
    this.select.selectNodes([id], true)
    this.commandHandler.handle({ type: 'commit' })
  }
}
