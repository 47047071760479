import { DesignColor } from 'themes'
import LoadingTitle from './LoadingTitle/LoadingTitle'

export default function LoadingGraphic() {
  return (
    <div
      style={{
        position: 'relative',
        width: 370,
        height: 370,
        paddingBottom: 24,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 24,
        borderRadius: 20,
        background: DesignColor('panelBackground'),
        boxShadow: `0px 0px 20px 0px ${DesignColor('popupShadow')}`,
      }}
    >
      <LoadingTitle />
    </div>
  )
}
