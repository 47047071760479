import React from 'react'

export type panelBlockHeight = 24 | 64 | 80
export type panelBlockWidth =
  | 24
  | 72
  | 96
  | 120
  | 168
  | 192
  | 216
  | 288
  | 360
  | 'fill'
export type panelBlockPadding = 0 | 4 | 8 | 12 | 16
export type panelBlockGap = 0 | 8

interface PanelBlockProps {
  width?: panelBlockWidth
  height?: panelBlockHeight

  paddingH?: panelBlockPadding
  paddingV?: panelBlockPadding

  paddingL?: panelBlockPadding
  paddingR?: panelBlockPadding

  direction?: 'row' | 'column'
  gap?: panelBlockGap

  align?: 'center' | 'left' | 'right'

  children?: React.ReactNode
}

export default function PanelBlock({
  width = 96,
  height = 24,
  direction = 'row',
  paddingH = 0,
  paddingV = 0,
  paddingL = 0,
  paddingR = 0,
  gap = 0,
  align = 'left',
  children,
}: PanelBlockProps) {
  return (
    <div
      style={{
        width: width === 'fill' ? '100%' : width,
        height: height,
        padding: getPadding(paddingH, paddingV, paddingL, paddingR),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: direction,
        alignItems: 'center',
        justifyContent: align,
        borderRadius: 4,
        gap: gap,
      }}
    >
      {children}
    </div>
  )
}

function getPadding(
  paddingH: panelBlockPadding,
  paddingV: panelBlockPadding,
  paddingL: panelBlockPadding,
  paddingR: panelBlockPadding
) {
  const left = paddingL || paddingH
  const right = paddingR || paddingH
  const top = paddingV
  const bottom = paddingV
  return `${top}px ${right}px ${bottom}px ${left}px`
}
