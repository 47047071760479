import { BaseMap, StyleMap, isAutolayoutChild } from 'application/attributes'
import {
  SetNodeAttribute,
  SetChildOrder,
  Command,
} from 'application/client/command'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { Point } from 'application/shapes'
import { ReorderHandler } from './reorder/reorder'
import { ParentOrder } from './reorder/types'
import { OffsetMap, PositionMap } from './types'
import {
  getAdjustedBottom,
  getAdjustedHeight,
  getAdjustedLeft,
  getAdjustedRight,
  getAdjustedTop,
  getAdjustedWidth,
} from 'application/units'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeMoveAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private reorderHandler: ReorderHandler

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    reorderHandler: ReorderHandler
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.reorderHandler = reorderHandler
  }

  move = (nodeIds: string[], point: Point, offsetMap: OffsetMap): void => {
    const nonAutolayoutChildren = this.filterNonAutolayoutChildren(nodeIds)
    const autolayoutChildren = this.filterAutolayoutChildren(nodeIds)

    const positionMap = this.createPositionMap(nodeIds, offsetMap, point)
    if (nonAutolayoutChildren.length > 0) {
      this.moveNodes(nonAutolayoutChildren, positionMap)
    } else {
      this.reorderNodes(autolayoutChildren, positionMap)
    }
  }

  private moveNodes = (
    nodes: ReadOnlyNode[],
    positionMap: PositionMap
  ): void => {
    const commands = this.buildMoveCommands(nodes, positionMap)
    this.commandHandler.handle(commands)
  }

  private reorderNodes = (
    nodes: ReadOnlyNode[],
    positionMap: PositionMap
  ): void => {
    const ids = nodes.map((n) => n.getId())
    const reorderded = this.reorderHandler.sortNodeChildren(ids, positionMap)
    const commands = this.buildReorderCommands(reorderded)
    this.commandHandler.handle(commands)
  }

  private filterNonAutolayoutChildren = (ids: string[]): ReadOnlyNode[] => {
    return ids
      .map((id) => {
        const node = this.document.getNode(id)
        if (!node) return undefined

        const parent = this.document.getParent(node)
        if (!parent) return node

        return isAutolayoutChild(node, parent) ? undefined : node
      })
      .filter((node) => node) as ReadOnlyNode[]
  }

  private filterAutolayoutChildren = (ids: string[]): ReadOnlyNode[] => {
    return ids
      .map((id) => {
        const node = this.document.getNode(id)
        if (!node) return undefined

        const parent = this.document.getParent(node)
        if (!parent) return undefined

        return isAutolayoutChild(node, parent) ? node : undefined
      })
      .filter((node) => node) as ReadOnlyNode[]
  }

  private buildMoveCommands = (
    nodes: ReadOnlyNode[],
    positionMap: PositionMap
  ): SetNodeAttribute[] => {
    return nodes.map((n) => {
      const parent = this.document.getParent(n)
      if (!parent) {
        return {
          type: 'setNodeAttribute',
          params: { id: n.getId(), base: {}, style: {} },
        }
      }

      const id = n.getId()
      const x = Math.round(positionMap[id].x)
      const y = Math.round(positionMap[id].y)
      const w = n.getBaseAttribute('w')
      const h = n.getBaseAttribute('h')
      const base: Partial<BaseMap> = { x, y, w, h }

      if (parent.getBaseAttribute('type') === 'canvas') {
        return {
          type: 'setNodeAttribute',
          params: { id: id, base: base, style: {} },
        }
      }
      const adjustedTop = getAdjustedTop(n, parent, y)
      const adjustedLeft = getAdjustedLeft(n, parent, x)
      const adjustedBottom = getAdjustedBottom(n, parent, y + h)
      const adjustedRight = getAdjustedRight(n, parent, x + w)
      const adjustedWidth = getAdjustedWidth(n, parent, w)
      const adjustedHeight = getAdjustedHeight(n, parent, h)
      const style: Partial<StyleMap> = {
        ...adjustedTop,
        ...adjustedLeft,
        ...adjustedBottom,
        ...adjustedRight,
        ...adjustedWidth,
        ...adjustedHeight,
      }

      return {
        type: 'setNodeAttribute',
        params: { id: id, base: base, style: style },
      }
    })
  }

  private buildReorderCommands = (order: ParentOrder): SetChildOrder[] => {
    return Object.keys(order).map((id) => {
      return {
        type: 'setChildOrder',
        params: {
          parentId: id,
          children: order[id],
        },
      }
    })
  }

  private createPositionMap = (
    ids: string[],
    offsets: OffsetMap,
    point: Point
  ): PositionMap => {
    const map: PositionMap = {}
    ids.forEach((id) => {
      const offset = offsets[id]
      map[id] = {
        x: point.x + offset.x,
        y: point.y + offset.y,
      }
    })
    return map
  }
}
