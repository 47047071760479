import { DesignColor } from 'themes'
import Icon from 'components/Library/Components/Icon/Icon/Icon'

interface LayerIconProps {
  visible: boolean
  depth: number

  open: boolean
  toggleOpen: () => void
}

export default function LayerDropdownIcon({
  visible,
  depth,
  open,
  toggleOpen,
}: LayerIconProps) {
  return (
    <div
      style={{
        position: depth === 0 ? 'absolute' : undefined,
        width: depth === 0 ? 16 : 24,
        left: depth === 0 ? 0 : undefined,
        height: 32,
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: visible ? 'pointer' : 'inherit',
      }}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation()
        toggleOpen()
      }}
    >
      {visible && (
        <RotationContainer rotation={open ? 90 : 0}>
          <Icon
            icon={'DropdownArrowRight'}
            size={10}
            color={DesignColor('text2')}
          />
        </RotationContainer>
      )}
    </div>
  )
}

function RotationContainer({
  children,
  rotation,
}: {
  children: React.ReactNode
  rotation: number
}) {
  return (
    <div
      style={{
        width: 16,
        height: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        transform: `rotate(${rotation}deg)`,
      }}
    >
      {children}
    </div>
  )
}
