import { DesignColor } from 'themes'
import { useEffect, useRef, useState } from 'react'

interface NumberInputProps {
  editing: boolean
  setEditing: (editing: boolean) => void

  value: number
  setValue: (value: number) => void

  min?: number
  max?: number
}

export default function NumberInput({
  editing,
  setEditing,
  value,
  setValue,
  min = 0,
  max = 4000,
}: NumberInputProps) {
  const [localValue, setLocalValue] = useState(value.toString())
  const [select, setSelect] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleBlur = () => {
    const parsed = parse(localValue, value)
    if (parsed === 'Mixed') return
    setValue(clampValue(parsed, min, max))
    setEditing(false)
  }

  const handleFocus = () => {
    setEditing(true)
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (!select && inputRef.current) {
      e.preventDefault()
      inputRef.current.select()
      setSelect(true)
      setEditing(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    switch (e.key) {
      case 'Enter':
        inputRef.current?.blur()
        break
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault()
        const direction = e.key === 'ArrowUp' ? 1 : -1
        const increment = e.shiftKey ? 10 * direction : direction
        const newValue = clampValue(value + increment, min, max)
        setLocalValue(newValue.toString())
        break
    }
  }

  useEffect(() => {
    if (editing) return
    setLocalValue(value.toString())
    setSelect(false)
  }, [editing, value])

  return (
    <input
      ref={inputRef}
      type="text"
      value={editing ? localValue : value}
      onChange={(e) => setLocalValue(e.target.value)}
      onClick={(e) => e.stopPropagation()}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onMouseUp={handleMouseUp}
      style={{
        width: 32,
        height: '100%',
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        color: DesignColor('text1'),
        fontFamily: 'Inter',
        fontWeight: 'regular',
        fontSize: 11,
        cursor: 'default',
      }}
    />
  )
}

function clampValue(
  value: number,
  min: number | undefined,
  max: number | undefined
) {
  if (min !== undefined && value < min) return min
  if (max !== undefined && value > max) return max
  return value
}

function parse(v: string, current: 'Mixed' | number) {
  if (v === 'Mixed') return current
  const parsed = parseFloat(v)
  if (isNaN(parsed)) return current
  return parsed
}
