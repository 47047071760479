import { DesignColor } from 'themes'

export type dropdownWidth = 96 | 120 | 192 | 216
export type targetInputWidth = 96 | 120 | 168 | 192 | 216

export type panelWidth = 168 | 232 | 400 | 800 | 'fill' | 'hug' | string
export type panelHeight = 'fill' | 'hug'
export type panelPosition = {
  position?: 'absolute'
  top?: number | string
  left?: number | string
  right?: number | string
  bottom?: number | string
  transform?: string
  zIndex?: number
}

export const panelShadow = '2px 2px 4px 0px rgba(0, 0, 0, 0.15)'
export const panelBorderFull = `inset 0px 0px 0px 1px ${DesignColor(
  'panelBorder'
)}`
export const panelBorderBottom = `inset 0px -1px 0px 0px ${DesignColor(
  'panelBorder'
)}`
