import Text from 'components/Library/Text/Text'
import useUploadImage from 'hooks/editor/useUploadImage'
import useHovered from 'hooks/ui/useHovered'
import { useRef } from 'react'
import { DesignColor } from 'themes'

interface SocialImageRowProps {
  socialImageUrl: string
  setSocialImage: (url: string) => void
}

export default function SocialImageRow({
  socialImageUrl,
  setSocialImage,
}: SocialImageRowProps) {
  const upload = useUploadImage()

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered({ ref: containerRef })

  const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    upload(async (i) => setSocialImage(i.url), e, undefined)
  }

  const url =
    socialImageUrl ||
    'https://dev-repaint-public-assets.s3.us-east-2.amazonaws.com/images/PlaceholderOpengraph.png'

  return (
    <div
      style={{
        width: '100%',
        padding: 8,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        gap: 16,
      }}
    >
      <div
        style={{
          width: 192,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'start',
          gap: 8,
        }}
      >
        <Text styles={{ weight: 'bold' }}>Social Image</Text>
        <Text maxWidth={192} wrap={true}>
          Social images are shown when people share your website on social
          media. The recommended size is 1200 x 630.
        </Text>
      </div>
      <div
        ref={containerRef}
        style={{
          width: 224,
          height: 'fit-content',
          padding: 16,
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: DesignColor('panelTint'),
          borderRadius: 4,
          boxShadow: hovered
            ? `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
            : 'none',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => inputRef.current?.click()}
      >
        <input
          type="file"
          accept=".png,.webp,.jpg,.jpeg,.gif,.svg,.avif"
          ref={inputRef}
          onChange={handleUploadImage}
          hidden
        />
        <img
          style={{ width: '100%', aspectRatio: '1200 / 630' }}
          src={url}
          alt="social"
        />
      </div>
    </div>
  )
}
