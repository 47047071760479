import {
  AttributeInteractionVisibility,
  AttributeType,
  MultiselectBaseMap,
  isInteractable,
} from 'application/attributes'
import { ReadOnlyNode } from 'application/node'
import { BaseAttributePanel } from '../baseAttributePanel'

type InteractionVisibilityPanelKeys = 'interaction.visibility'

type InteractionVisibilityPanelAttributes = Pick<
  MultiselectBaseMap,
  InteractionVisibilityPanelKeys
> | null

export interface InteractionVisibilityPanelState {
  allowed: boolean
  options: string[]
  attributes: InteractionVisibilityPanelAttributes
}

export interface InteractionVisibilityPanelHandlers {
  add: () => void
  remove: (index: number) => void
  update: (index: number, value: AttributeInteractionVisibility) => void
  clearMixed: () => void
}

export class InteractionVisibilityPanel extends BaseAttributePanel<
  InteractionVisibilityPanelState,
  InteractionVisibilityPanelHandlers,
  InteractionVisibilityPanelKeys
> {
  getSettings(): InteractionVisibilityPanelState {
    return {
      allowed: this.getAllowed(),
      options: this.getOptions(),
      attributes: this.attributes,
    }
  }

  getHandlers(): InteractionVisibilityPanelHandlers {
    return {
      add: this.add,
      remove: this.remove,
      update: this.update,
      clearMixed: this.clearMixed,
    }
  }

  private getAllowed = (): boolean => {
    return this.getNodes().length > 0
  }

  private getOptions = (): string[] => {
    return this.document
      .getNodes()
      .filter(
        (n) =>
          allowedTypes.includes(n.getBaseAttribute('type')) && isInteractable(n)
      )
      .map((n) => n.getId())
  }

  private add = (): void => {
    if (this.attributes === null) return

    const current = this.attributes['interaction.visibility']
    if (current === 'Mixed') return

    this.setMulti({
      'interaction.visibility': [
        ...(current || []),
        { mode: 'toggle', targetId: '' },
      ],
    })
    this.commit()
  }

  private remove = (index: number): void => {
    if (this.attributes === null) return

    const current = this.attributes['interaction.visibility']
    if (current === 'Mixed' || !current) return

    this.setMulti({
      'interaction.visibility': current.filter((_, i) => i !== index),
    })
    this.commit()
  }

  private update = (
    index: number,
    value: AttributeInteractionVisibility
  ): void => {
    if (this.attributes === null) return

    const current = this.attributes['interaction.visibility']
    if (current === 'Mixed' || !current) return

    this.setMulti({
      'interaction.visibility': current.map((item, i) =>
        i === index ? value : item
      ),
    })
  }

  private clearMixed = (): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    let interactions: AttributeInteractionVisibility[] = []
    for (const node of nodes) {
      const value = node.getBaseAttribute('interaction.visibility')
      if (!value || value.length === 0) continue
      interactions = value
      break
    }

    this.setMulti({ 'interaction.visibility': interactions })
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (n, _) => allowedTypes.includes(n.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'rectangle',
  'ellipse',
  'text',
  'image',
]
