import { ClientDocumentHelper } from 'application/client/document/helper'
import { DocumentSelection } from 'application/selection'
import { CopyAnimationSnapshot } from '../types'
import { Node } from 'application/node'
import _ from 'lodash'

export class PasteAnimation {
  private documentHelper: ClientDocumentHelper
  private documentSelection: DocumentSelection

  constructor(
    documentHelper: ClientDocumentHelper,
    documentSelection: DocumentSelection
  ) {
    this.documentHelper = documentHelper
    this.documentSelection = documentSelection
  }

  pasteAnimation = (snapshot: CopyAnimationSnapshot): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.applyToNode(node, snapshot)
    }
  }

  private getNodes = (): Node[] => {
    return this.documentSelection
      .getSelected()
      .map((n) => this.documentHelper.getNode(n.getId()))
      .filter((n) => n !== undefined) as Node[]
  }

  private applyToNode = (node: Node, snapshot: CopyAnimationSnapshot): void => {
    const animations = node.getBaseAttribute('animations')
    if (!animations) {
      node.setBaseAttribute('animations', [_.cloneDeep(snapshot.animation)])
    } else {
      animations.push(_.cloneDeep(snapshot.animation))
      node.setBaseAttribute('animations', animations)
    }
  }
}
