interface FullscreenDimContainerProps {
  id?: string

  panelRef?: React.RefObject<HTMLDivElement>

  direction?: 'column' | 'row'

  popup?: boolean

  children?: React.ReactNode
  zIndex?: number
}

export default function FullscreenDimContainer({
  id,
  panelRef,
  direction = 'column',
  popup,
  children,
  zIndex,
}: FullscreenDimContainerProps) {
  return (
    <div
      id={id}
      className={popup ? 'popup' : undefined}
      ref={panelRef}
      style={{
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: direction,
        alignItems: 'center',
        justifyContent: 'start',
        background: 'rgba(0, 0, 0, 0.60)',
        pointerEvents: 'auto',
        zIndex: zIndex,
      }}
    >
      {children}
    </div>
  )
}
