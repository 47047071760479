import { dropdownOption } from './MenuDropdown'
import { DesignColor } from 'themes'
import Icon from 'components/Library/Components/Icon/Icon/Icon'
import { useState } from 'react'

interface MenuDropdownDrawerRowProps<T> {
  option: dropdownOption<T>
  setSelected: (value: T) => void
  close: () => void
}

export default function MenuDropdownDrawerRow<T>({
  option,
  setSelected,
  close,
}: MenuDropdownDrawerRowProps<T>) {
  const [hover, setHover] = useState(false)

  return (
    <div
      key={option.label}
      style={{
        padding: '8px 12px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        background: hover ? DesignColor('grayInputTint') : 'none',
        color: DesignColor('text1'),
        fontSize: '12px',
        fontWeight: 'normal',
        cursor: 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        setSelected(option.value)
        close()
      }}
    >
      {option.iconKey && (
        <Icon icon={option.iconKey} size={16} color={DesignColor('text1')} />
      )}
      {option.label}
    </div>
  )
}
