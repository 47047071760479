import DropdownDrawerContainer from 'components/Library/Containers/DropdownDrawerContainer/DropdownDrawerContainer'
import { dropdownOption } from './Dropdown'
import DropdownDrawerRow from './DropdownDrawerRow'
import { useRef, useState } from 'react'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import {
  dropdownWidth,
  panelPosition,
} from 'components/Library/Containers/constants'
import useClosePopup from 'hooks/ui/useClosePopup'

interface DropdownDrawerProps<T> {
  close: () => void

  width?: dropdownWidth

  dropdownRef: React.RefObject<HTMLDivElement>

  options: dropdownOption<T>[]

  selected: T | null
  select: (value: T) => void
}

export default function DropdownDrawer<T>({
  close,
  width = 96,
  dropdownRef,
  options,
  selected,
  select,
}: DropdownDrawerProps<T>) {
  const drawerRef = useRef<HTMLDivElement>(null)
  const [hoveredRow, setHoveredRow] = useState<number>(-1)
  useClosePopup({
    ref: drawerRef,
    close: close,
    ignorePopup: false,
  })

  const position = getPosition(dropdownRef)

  return (
    <Portal>
      <DropdownDrawerContainer
        drawerRef={drawerRef}
        width={width}
        position={position}
        onMouseLeave={() => setHoveredRow(-1)}
      >
        {options.map((option, index) => (
          <DropdownDrawerRow
            key={index}
            width={width}
            icon={option.icon}
            title={option.text}
            subtitle={option.subtext}
            style={option.styles}
            selected={option.value === selected}
            hovered={hoveredRow === index}
            setHovered={(h) => (h ? setHoveredRow(index) : setHoveredRow(-1))}
            onClick={() => select(option.value)}
          />
        ))}
      </DropdownDrawerContainer>
    </Portal>
  )
}

function getPosition(
  dropdownRef: React.RefObject<HTMLDivElement>
): panelPosition {
  if (!dropdownRef.current) return { top: 0, left: 0 }
  const boundingBox = dropdownRef.current.getBoundingClientRect()
  return {
    position: 'absolute',
    top: boundingBox.top,
    left: boundingBox.left,
  }
}
