import { fonts } from 'assets/fonts'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { panelPosition } from 'components/Library/Containers/constants'
import useClosePopup from 'hooks/ui/useClosePopup'
import { useEffect, useRef, useState } from 'react'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'
import { editor } from 'index'
import { FontKey } from 'application/text'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import FontPopupRow from './FontPopupRow'
import { useSetRecoilState } from 'recoil'
import { settingsOpen } from 'components/Pages/Editor/RightTopbar/SettingsButton/SettingsButton'
import { settingsTab } from 'components/Pages/Editor/RightTopbar/SettingsPopup/SettingsPopup'

interface FontPopupProps {
  close: () => void

  font: 'Mixed' | FontKey
  setFont: (family: FontKey) => void

  sectionRef: React.RefObject<HTMLDivElement>
}

export default function FontPopup({
  close,
  font,
  setFont,
  sectionRef,
}: FontPopupProps) {
  const setOpen = useSetRecoilState(settingsOpen)
  const setTab = useSetRecoilState(settingsTab)

  const inputRef = useRef<HTMLInputElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({
    ref: popupRef,
    close: close,
    exceptionRef: sectionRef,
  })

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getFontPopupPanel()
  )
  const { load, loadExternalFont, loadInternalFont } = handlers

  const [search, setSearch] = useState('')
  const filteredCustomFonts = (settings?.fonts || [])
    .filter((f) => f.key.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => a.key.localeCompare(b.key))
  const filteredFonts = Object.values(fonts).filter((f) =>
    f.label.toLowerCase().includes(search.toLowerCase())
  )

  const handleSelectInternal = async (family: FontKey) => {
    await loadInternalFont(family)
    setFont(family)
    close()
  }

  const handleSelectExternal = async (family: FontKey) => {
    await loadExternalFont(family)
    setFont(family)
    close()
  }

  const handleSubmitSearch = async () => {
    if (search.length === 0) return
    if (filteredCustomFonts.length > 0) {
      handleSelectExternal(filteredCustomFonts[0].key)
    } else if (filteredFonts.length > 0) {
      handleSelectInternal(filteredFonts[0].key)
    }
  }

  const position = getPosition(sectionRef)

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
  }, [])

  useEffect(() => {
    load()
  }, [load])

  return (
    <Portal>
      <PanelContainer panelRef={popupRef} position={position}>
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode="title">
              Font
            </TextBlock>
            <IconBlock icon="X" onClick={close} />
          </PanelRow>
        </PanelSection>
        <PanelSection>
          <PanelRow>
            <TextInputBlock
              focusOnMount={true}
              icon={'Search'}
              width={192}
              value={search}
              setValue={setSearch}
              submit={handleSubmitSearch}
              submitOnBlur={false}
              placeholder={'Search Fonts'}
            />
            {search.length === 0 && (
              <IconBlock
                icon={'Upload'}
                onClick={() => {
                  setTab('fonts')
                  setOpen(true)
                  close()
                }}
              />
            )}
          </PanelRow>
        </PanelSection>
        <div
          style={{
            width: 232,
            height: 400,
            padding: '8px 0px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
          }}
        >
          {filteredCustomFonts.map((f) => (
            <FontPopupRow
              key={f.key}
              custom={true}
              text={f.key}
              onClick={() => handleSelectExternal(f.key)}
              hovered={search.length > 0}
              selected={f.key === font}
              styles={{ css: `${f.key}, Inter` }}
            />
          ))}
          {Object.values(filteredFonts).map((f, i) => (
            <FontPopupRow
              key={f.key}
              text={f.label}
              onClick={() => handleSelectInternal(f.key)}
              hovered={search.length > 0 && i === 0}
              selected={f.key === font}
              styles={{ css: `${f.css}, Inter` }}
            />
          ))}
          {filteredFonts.length === 0 && (
            <PanelRow>
              <TextBlock width={192} mode={'label2'}>
                No Matching Fonts
              </TextBlock>
            </PanelRow>
          )}
        </div>
      </PanelContainer>
    </Portal>
  )
}

function getPosition(
  sectionRef: React.RefObject<HTMLDivElement>
): panelPosition {
  if (!sectionRef.current) return { top: 0, left: 0 }
  const rect = sectionRef.current.getBoundingClientRect()
  if (rect.top + 496 > window.innerHeight) {
    return {
      position: 'absolute',
      bottom: 8,
      right: 248,
    }
  } else {
    return {
      position: 'absolute',
      top: rect.top,
      right: 248,
    }
  }
}
