import useToast from 'hooks/editor/useToast'
import { editor } from 'index'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'
import useNavigatePage from './useNavigatePage'

export default function useWebsocket() {
  const toast = useToast()
  const { toProjects } = useNavigatePage()
  const { open, subscribe, unsubscribe } = editor.getUI().getWebsocketService()

  const [closing, setClosing] = useState(false)

  useEffect(() => {
    const key = v4()
    subscribe(key, () => {
      if (closing) return
      setClosing(true)
      toast('Leaving editor due to being offline.', 'error')
      toProjects()
    })
    return () => {
      unsubscribe(key)
    }
  }, [subscribe, unsubscribe, toast, toProjects, closing])

  useEffect(() => {
    open()
  }, [open])
}
