import { Node } from 'application/node'
import { SizeEngineCalculator } from './types'
import { WriteDocument } from 'application/document'
import { LayoutDependencyMode } from '../types'
import { computeAvailableSpace, computeDynamicSize, minMaxSize } from './utils'
import { ReadOnlyDocument } from 'application/document'
import {
  isAutolayoutChild,
  isCanvasChild,
  isDynamicSizeAuto,
} from 'application/attributes'

export class DynamicSizeCalculator implements SizeEngineCalculator {
  update(
    node: Node,
    mode: LayoutDependencyMode,
    _: ReadOnlyDocument,
    document: WriteDocument
  ): void {
    const parent = document.getParent(node)
    if (!parent || isCanvasChild(parent)) return

    let width = node.getBaseAttribute('w')
    let height = node.getBaseAttribute('h')

    if (
      ['w', 'wh'].includes(mode) &&
      isDynamicSizeAuto(node.getStyleAttribute('size.w.auto'))
    ) {
      if (isAutolayoutChild(node, parent)) {
        switch (parent.getStyleAttribute('autolayout.direction')) {
          case 'row':
            width = this.computeDynamicMainAxis(node, 'w', document)
            break
          case 'column':
            width = this.computeDynamicCrossAxis(node, 'w', document)
            break
        }
      } else {
        width = this.computeDynamicNonAuto(node, 'w', document)
      }
    }

    if (
      ['h', 'wh'].includes(mode) &&
      isDynamicSizeAuto(node.getStyleAttribute('size.h.auto'))
    ) {
      if (isAutolayoutChild(node, parent)) {
        switch (parent.getStyleAttribute('autolayout.direction')) {
          case 'row':
            height = this.computeDynamicCrossAxis(node, 'h', document)
            break
          case 'column':
            height = this.computeDynamicMainAxis(node, 'h', document)
            break
        }
      } else {
        height = this.computeDynamicNonAuto(node, 'h', document)
      }
    }

    node.setBaseAttribute('w', Math.max(width, 0))
    node.setBaseAttribute('h', Math.max(height, 0))
  }

  private computeDynamicNonAuto(
    node: Node,
    axis: 'w' | 'h',
    document: WriteDocument
  ): number {
    const parent = document.getParent(node)
    if (!parent) return 0
    switch (node.getStyleAttribute(`size.${axis}.auto`)) {
      case 'fill':
        return minMaxSize(parent.getBaseAttribute(axis), axis, node)
      case 'percent':
        return minMaxSize(
          parent.getBaseAttribute(axis) *
            ((node.getStyleAttribute(`size.${axis}.percent`) || 0) / 100),
          axis,
          node
        )
      default:
        return 0
    }
  }

  private computeDynamicMainAxis(
    node: Node,
    axis: 'w' | 'h',
    document: WriteDocument
  ): number {
    return computeDynamicSize(node, axis, document)
  }

  private computeDynamicCrossAxis(
    node: Node,
    axis: 'w' | 'h',
    document: WriteDocument
  ): number {
    const autoKey = axis === 'w' ? 'size.w.auto' : 'size.h.auto'
    const percentKey = axis === 'w' ? 'size.w.percent' : 'size.h.percent'

    const parent = document.getParent(node)
    if (!parent) return 0

    const parentAvailable = computeAvailableSpace(parent, axis, document)
    if (!parentAvailable) return 0

    const auto = node.getStyleAttribute(autoKey)
    switch (auto) {
      case 'fill':
        return minMaxSize(parentAvailable, axis, node)
      case 'percent':
        return minMaxSize(
          (parentAvailable * (node.getStyleAttribute(percentKey) || 0)) / 100,
          axis,
          node
        )
    }

    return 0
  }
}
